import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { createEvent, getCompanyDetails } from "../../action/events";
import { fetchUserDetails } from "../../action/auth";
import moment from "moment";
import { setSelectedEventType } from "../../reducer/slice/eventSlice";
import { useDispatch } from "react-redux";

//images
import squar from "../../assets/images/squar.svg";
import WeddingForm from "./weddingForm";
import PreWeddingForm from "./preWeddingForm";
import CelebrationForm from "./celebrationForm";
import showNotification from "../notification/notification";
import { number } from "prop-types";




const EventDetailForm = ({ setIsWedding, changeStep }) => {

  const dispatch = useDispatch();
  const [eventTypes] = useState([
    { value: "Wedding", url: "/api/weddings/" },
    { value: "PreWedding", url: "/api/preweddings/" },
    { value: "Celebration", url: "/api/celebrations/" },
  ]);
  const [userData, setUserData] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await fetchUserDetails();
        const user = res.id.toString();
        setUserData(user);
      } catch (error) {
        showNotification("Error fetching user details. Please try again.", "error");
      }
    };

    fetchData();
  }, []);


  
  const addEventFormik = useFormik({
    initialValues: {
      company: "",
      name: "",
      date: "",
      venue: "",
      total_guests: "",
      communication_email: "",
      status: "Active",
      user: userData,
      location: "",
      flight_booking: false,
      ground_transport: false,
      event_type: "",
      weddingObj: {
        groom_name: "",
        bride_name: "",
        bride_family: [{ title: "", value: "" }],
        groom_family: [{ title: "", value: "" }],
        number_of_subevents: 10
      },
      celebrationObj: {
        host_name: "",
        celebration_type: "",
        is_single_event: false,
        event_details: ""
      },
      event_dates: "",
      celebration_type: "",
      is_single_event: false,
      event_details: "",
    },
    validate: (values) => {
      const errors = {};
      if (!values.event_type) {
        errors.event_type = "Please select event type";
      }
      if (!values.name) {
        errors.name = "Name is required";
      }
      if (!values.venue) {
        errors.venue = "Venue is required";
      }
      if (!values.date) {
        errors.date = "Date is required";
      }
      if (!values.location) {
        errors.location = "Location is required";
      }
      if (!values.total_guests) {
        errors.total_guests = "Total guests is required";
      } else if (!/^([1-9][0-9]{0,2}|10000)$/.test(values.total_guests)) {
        errors.total_guests = "Total number of guests are too many";
      }
      if (!values.communication_email) {
        errors.communication_email = "Email is required";
      } else if (!/\S+@\S+\.\S+/.test(values.communication_email)) {
        errors.communication_email = "Email is invalid";
      }

      if (values.event_type === "Wedding" || values.event_type === "PreWedding") {
        if (!values.weddingObj?.groom_name) {
          if (!errors.weddingObj) errors.weddingObj = {};
          errors.weddingObj.groom_name = "Groom name is required";
        }
        if (!values.weddingObj?.bride_name) {
          if (!errors.weddingObj) errors.weddingObj = {};
          errors.weddingObj.bride_name = "Bride name is required";
        }
      }

      if (values.event_type === "Celebration") {
        if (!values.celebrationObj?.host_name) {
          if (!errors.celebrationObj) errors.celebrationObj = {};
          errors.celebrationObj.host_name = "Host name is required";
        }
        if (!values.celebrationObj?.celebration_type) {
          if (!errors.celebrationObj) errors.celebrationObj = {};
          errors.celebrationObj.celebration_type = "Celebration type is required";
        }
      }

      return errors;
    },
    onSubmit: async (values) => {
      try {
        const url = eventTypes.find(
          (type) => type.value === values.event_type
        )?.url;

        if (!url) {
          showNotification("Please select an event type", "error");
          return;
        }

        // Update user value before submission
        values.user = userData;
        values.company = addEventFormik.values.company;

        // If it's a wedding event, copy the wedding details to the main level
        if (values.event_type === "Wedding" || values.event_type === "PreWedding") {
          values.groom_name = values.weddingObj?.groom_name;
          values.bride_name = values.weddingObj?.bride_name;
          values.bride_family = values.weddingObj?.bride_family;
          values.groom_family = values.weddingObj?.groom_family;
          values.number_of_subevents = values.weddingObj?.number_of_subevents;
        }

        // If it's a celebration event, copy the celebration details to the main level
        if (values.event_type === "Celebration") {
          values.host_name = values.celebrationObj?.host_name;
          values.celebration_type = values.celebrationObj?.celebration_type;
          values.is_single_event = values.celebrationObj?.is_single_event;
          values.event_details = values.celebrationObj?.event_details;
        }

        // Dispatch event type
        dispatch(setSelectedEventType(values.event_type));

        if (values.event_type === "Wedding") {
          changeStep(1, values, url);
        } else {
          changeStep(2, values, url);
        }
      } catch (error) {
        showNotification("Error submitting form. Please try again.", "error");
      }
    },
  });

  const addMoreFamilyMembers = (type) => {
    if (type === 1) {
      const currentBrideFamily = addEventFormik.values.weddingObj?.bride_family || [];
      addEventFormik.setFieldValue("weddingObj.bride_family", [
        ...currentBrideFamily,
        { title: "", value: "" },
      ]);
    } else if (type === 2) {
      const currentGroomFamily = addEventFormik.values.weddingObj?.groom_family || [];
      addEventFormik.setFieldValue("weddingObj.groom_family", [
        ...currentGroomFamily,
        { title: "", value: "" },
      ]);
    }
  };

  const deleteFamilyMembers = (type, ind) => {
    if (type === 1) {
      const currentBrideFamily = addEventFormik.values.weddingObj?.bride_family || [];
      addEventFormik.setFieldValue(
        "weddingObj.bride_family",
        currentBrideFamily.filter((value, index) => index !== ind)
      );
    } else if (type === 2) {
      const currentGroomFamily = addEventFormik.values.weddingObj?.groom_family || [];
      addEventFormik.setFieldValue(
        "weddingObj.groom_family",
        currentGroomFamily.filter((value, index) => index !== ind)
      );
    }
  };

  const getCompany = async () => {
    const res = await getCompanyDetails();
    if (res.status) {
      addEventFormik.setFieldValue("company", res.data.id);
    }
  };

  useEffect(() => {
    getCompany();
  }, []);

  const handleTextInput = (event) => {
    const newValue = event.target.value;

      addEventFormik.setFieldValue(event.target.name, newValue);
    
  };

  // Add logging to form submission handler
  const handleFormSubmit = (e) => {
    e.preventDefault();
    addEventFormik.handleSubmit();
  };

  if (!userData) return <div>Loading...</div>;

  return (
    <form onSubmit={handleFormSubmit}>
      <h2 className="form-sec-heading">
        <img src={squar} alt="" /> <span>Event Details</span>
      </h2>
      <div className="row">
        <div className="col-lg-4">
          <div className="input-box">
            <div className="input-label">Select Event Type</div>
            <div className="input_field">
              <select
                name="event_type"
                value={addEventFormik.values.event_type}
                onChange={(e) => {
                  if (e.target.value === "Wedding") {
                    setIsWedding(true);
                  } else {
                    setIsWedding(false);
                  }
                  addEventFormik.setFieldValue("event_type", e.target.value);
                }}
                onBlur={addEventFormik.handleBlur}
                className="input"
              >
                <option value="">Select Event Type</option>
                {eventTypes.map((value) => (
                  <option key={value.value} value={value.value}>
                    {value.value}
                  </option>
                ))}
              </select>
            </div>
            {addEventFormik.touched.event_type &&
              addEventFormik.errors.event_type ? (
              <p className="error">{addEventFormik.errors.event_type}</p>
            ) : null}
          </div>
        </div>
        <div className="col-md-4">
          <div className="input-box">
            <div className="input-label">Event Name</div>
            <div className="input_field">
              <input
                name="name"
                value={addEventFormik.values.name}
                onChange={handleTextInput}
                onBlur={addEventFormik.handleBlur}
                type="text"
                className="input"
                placeholder="Enter name"
              />
            </div>
            {addEventFormik.touched.name && addEventFormik.errors.name ? (
              <p className="error">{addEventFormik.errors.name}</p>
            ) : null}
          </div>
        </div>
        <div className="col-md-4">
          <div className="input-box">
            <div className="input-label">Venue</div>
            <div className="input_field">
              <input
                name="venue"
                value={addEventFormik.values.venue}
                onChange={handleTextInput}
                onBlur={addEventFormik.handleBlur}
                type="text"
                className="input"
                placeholder="Enter venue"
              />
            </div>
            {addEventFormik.touched.venue && addEventFormik.errors.venue ? (
              <p className="error">{addEventFormik.errors.venue}</p>
            ) : null}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-4">
          <div className="input-box">
            <div className="input-label">Select Date</div>
            <div className="input_field">
              <input
                type="date"
                className="input"
                onKeyDown={(e) => e.preventDefault()}
                min={moment().format("YYYY-MM-DD")}
                placeholder="Select Date"
                name="date"
                value={addEventFormik.values.date}
                onChange={(e) => {
                  addEventFormik.handleChange(e);
                  sessionStorage.setItem('date', e.target.value);
                }}
                onBlur={addEventFormik.handleBlur}
              />
            </div>
            {addEventFormik.touched.date && addEventFormik.errors.date ? (
              <p className="error">{addEventFormik.errors.date}</p>
            ) : null}
          </div>
        </div>
        <div className="col-lg-4">
          <div className="input-box">
            <div className="input-label">Approx. Number of Guests</div>
            <div className="input_field">
              <input
                type="text"
                className="input"
                placeholder="Enter Number of Guests"
                name="total_guests"
                value={addEventFormik.values.total_guests}
                onChange={(e) => {
                  addEventFormik.setFieldValue(
                    "total_guests",
                    e.target.value.replace(/\D/g, "")
                  );
                }}
                onBlur={addEventFormik.handleBlur}
              />
            </div>
            {addEventFormik.touched.total_guests && addEventFormik.errors.total_guests ? (
              <p className="error">{addEventFormik.errors.total_guests}</p>
            ) : null}
          </div>
        </div>
        <div className="col-md-4">
          <div className="input-box">
            <div className="input-label">Location</div>
            <div className="input_field">
              <input
                type="text"
                className="input"
                placeholder="Enter Location"
                name="location"
                value={addEventFormik.values.location}
                onChange={addEventFormik.handleChange}
                onBlur={addEventFormik.handleBlur}
              />
            </div>
            {addEventFormik.touched.location && addEventFormik.errors.location ? (
              <p className="error">{addEventFormik.errors.location}</p>
            ) : null}
          </div>
        </div>
      </div>
      <h2 className="form-sec-heading">
        <img src={squar} alt="" /> <span>Contact Details</span>
      </h2>

      <div className="row">
        <div className="col-md-4">
          <div className="input-box">
            <div className="input-label">Email</div>
            <div className="input_field">
              <input
                type="text"
                className="input"
                placeholder="Enter Email"
                name="communication_email"
                value={addEventFormik.values.communication_email}
                onChange={addEventFormik.handleChange}
                onBlur={addEventFormik.handleBlur}
              />
            </div>
            {addEventFormik.touched.communication_email && addEventFormik.errors.communication_email ? (
              <p className="error">{addEventFormik.errors.communication_email}</p>
            ) : null}
          </div>
        </div>
      </div>

      {addEventFormik.values.event_type === "Wedding" ? (
        <WeddingForm
          addEventFormik={addEventFormik}
          addMoreFamilyMembers={addMoreFamilyMembers}
          deleteFamilyMembers={deleteFamilyMembers}
        />
      ) : addEventFormik.values.event_type === "PreWedding" ? (
        <PreWeddingForm
          addEventFormik={addEventFormik}
          addMoreFamilyMembers={addMoreFamilyMembers}
          deleteFamilyMembers={deleteFamilyMembers}
        />
      ) : addEventFormik.values.event_type === "Celebration" ? (
        <CelebrationForm
          addEventFormik={addEventFormik}
          addMoreFamilyMembers={addMoreFamilyMembers}
          deleteFamilyMembers={deleteFamilyMembers}
        />
      ) : null}

      <div className="d-flex frm-sbmit-grp mt-3">
        <button 
          type="submit" 
          className="btn-theme bg-brown color-white"
          disabled={addEventFormik.isSubmitting}
        >
          {addEventFormik.isSubmitting ? "Processing..." : "Next"}
        </button>
      </div>
    </form>
  );
};

export default EventDetailForm;
