import { useFormik } from "formik";
import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  addUserHotelRooms,
  createEventHotel,
  fetchEventsList,
  fetchHotelsListing,
  fetchUserHotelRoomsDetails,
} from "../../action/hotels";
import { Link, useNavigate, useParams } from "react-router-dom";
import showNotification from "../../components/notification/notification";
import viewEdit from "../../assets/images/viewEdit.svg";
import addHotel from "../../assets/images/addHotel.svg";
import { fetchAllGuest } from "../../action/guests";

const DetailRoomToGuest = ({ id, onCloseCanvas }) => {
  const addHotelFormik = useFormik({
    initialValues: {
      guest: "",
      team: "",
      group: "",
      share_count: 0,
      room_number: "",
      billing: "",
      status: "Pending",
      checkin_date: "",
      checkout_date: "",
      remarks: "",
      selectedHotel: "",
    },
  });

  const getUserHotelRoomsDetails = async () => {
    const res = await fetchUserHotelRoomsDetails(id);
    if (res.status) {
      addHotelFormik.setFieldValue("guest", res.data[0]?.guestDetails?.name);
      addHotelFormik.setFieldValue("team", res.data[0].team);
      addHotelFormik.setFieldValue("group", res.data[0].group);
      addHotelFormik.setFieldValue("share_count", res.data[0].share_count);
      addHotelFormik.setFieldValue("room_number", res.data[0].room_number);
      addHotelFormik.setFieldValue("billing", res.data[0].billing);
      addHotelFormik.setFieldValue("status", res.data[0].status);
      addHotelFormik.setFieldValue("checkin_date", res.data[0].checkin_date);
      addHotelFormik.setFieldValue("checkout_date", res.data[0].checkout_date);
      addHotelFormik.setFieldValue("remarks", res.data[0].remarks);
      addHotelFormik.setFieldValue(
        "selectedHotel",
        res.data[0]?.hotelDetails?.name
      );
    }
  };

  useEffect(() => {
    getUserHotelRoomsDetails();
  }, []);

  return (
    <>
      <div class="payout_data-content">
        <form onSubmit={addHotelFormik.handleSubmit}>
          <div class="row">
            <div class="col-lg-12">
              <div class="input-box">
                <div class="input-label">Guest</div>
                <div class="input_field">
                  <input
                    readOnly
                    disabled
                    type="text"
                    class="input"
                    name="guest"
                    value={addHotelFormik.values.guest}
                    onChange={addHotelFormik.handleChange}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div class="input-box">
                <div class="input-label">Team</div>
                <div class="input_field">
                  <input
                    readOnly
                    disabled
                    type="text"
                    class="input"
                    name="team"
                    value={addHotelFormik.values.team}
                    onChange={addHotelFormik.handleChange}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div class="input-box">
                <div class="input-label">Group</div>
                <div class="input_field">
                  <input
                    readOnly
                    disabled
                    type="text"
                    class="input"
                    name="group"
                    value={addHotelFormik.values.group}
                    onChange={addHotelFormik.handleChange}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div class="input-box">
                <div class="input-label">Hotel</div>
                <div class="input_field">
                  <input
                    readOnly
                    disabled
                    type="text"
                    class="input"
                    name="selectedHotel"
                    value={addHotelFormik.values.selectedHotel}
                    onChange={addHotelFormik.handleChange}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div class="input-label"> Check In - Check Out</div>
            </div>
            <div className="col-lg-6">
              <div class="input-box checkIn">
                <div class="input_field">
                  <input
                    readOnly
                    disabled
                    type="text"
                    class="input"
                    name="checkin_date"
                    value={
                      addHotelFormik.values.checkin_date
                        ? moment(addHotelFormik.values.checkin_date).format(
                            "DD/MM/YYYY"
                          )
                        : ""
                    }
                    onChange={addHotelFormik.handleChange}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div class="input-box">
                <div class="input_field">
                  <input
                    readOnly
                    disabled
                    type="text"
                    class="input"
                    name="checkout_date"
                    value={
                      addHotelFormik.values.checkout_date
                        ? moment(addHotelFormik.values.checkout_date).format(
                            "DD/MM/YYYY"
                          )
                        : ""
                    }
                    onChange={addHotelFormik.handleChange}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div class="input-box">
                <div class="input-label">Share Count</div>
                <div class="input_field">
                  <input
                    readOnly
                    disabled
                    type="number"
                    class="input"
                    name="share_count"
                    value={addHotelFormik.values.share_count}
                    onChange={addHotelFormik.handleChange}
                  />
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div class="input-box">
                <div class="input-label">Room No.</div>
                <div class="input_field">
                  <input
                    readOnly
                    disabled
                    type="text"
                    class="input"
                    name="room_number"
                    value={addHotelFormik.values.room_number}
                    onChange={addHotelFormik.handleChange}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div class="input-box">
                <div class="input-label">Billing</div>
                <div class="input_field">
                  <input
                    readOnly
                    disabled
                    type="text"
                    class="input"
                    name="billing"
                    value={addHotelFormik.values.billing}
                    onChange={addHotelFormik.handleChange}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div class="input-box">
                <div class="input-label">Status</div>
                <div class="input_field">
                  <input
                    readOnly
                    disabled
                    type="text"
                    class="input"
                    name="status"
                    value={addHotelFormik.values.status}
                    onChange={addHotelFormik.handleChange}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div class="input-box">
                <div class="input-label">Remarks</div>
                <div class="input_field">
                  <input
                    readOnly
                    disabled
                    type="text"
                    class="input"
                    name="remarks"
                    value={addHotelFormik.values.remarks}
                    onChange={addHotelFormik.handleChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default DetailRoomToGuest;
