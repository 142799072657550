import React, { useState, useEffect } from "react";
import LeftSideBar from "../../components/whatsapp/leftSideBar";
import RightSideBar from "../../components/whatsapp/rightSideBar";
import {
  fetchGuests,
  getEventConversation,
  fetchMessages,
  sendMessage,
  createEventConversation,
} from "../../action/conversation";
import { useParams } from "react-router-dom";

const WhatsAppChat = () => {
  const { eventId } = useParams();
  const [guestList, setGuestList] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState({});
  const [conversationId, setConversationId] = useState(null);
  const [messages, setMessages] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchEventData = async () => {
      setLoading(true);
      try {
        const event = await getEventConversation(eventId);
        setSelectedEvent(event);
      } catch {
        setError("Failed to fetch event details.");
      } finally {
        setLoading(false);
      }
    };

    const fetchGuestsData = async () => {
      setLoading(true);
      try {
        const guests = await fetchGuests(eventId);
        setGuestList(guests.data.objects.guestDetails);
      } catch {
        setError("Failed to fetch guests.");
      } finally {
        setLoading(false);
      }
    };

    fetchEventData();
    fetchGuestsData();
  }, [eventId]);

  const handleSendMessage = async (messageText) => {
    if (!conversationId) return;

    const newMessage = {
      id: Date.now(),
      message: messageText,
      sender: "me",
      timestamp: new Date().toISOString(),
    };

    setMessages((prev) => ({
      ...prev,
      [conversationId]: [...(prev[conversationId] || []), newMessage],
    }));

    try {
      await sendMessage({
        conversation_id :conversationId,
        message_text :messageText
      });
    } catch {
      setError("Failed to send message.");
    }
  };

  const handleSelectGuest = async (guestId) => {
    setConversationId(guestId);
    try {
      const result = await createEventConversation({ event: eventId, guest: guestId });
      setConversationId(result.conversation.id);
    } catch {
      setError("Failed to create conversation.");
    }
  };

  return (
    <div className="whatsapp-chat-container">
      <div className="row">
        <div className="col-4">
        <LeftSideBar
        onSelectGuest={handleSelectGuest}
        guests={guestList}
        selectedEvent={selectedEvent}
        loading={loading}
        error={error}
      />
        </div>
        <div className="col-8">
        <RightSideBar
        conversationId={conversationId}
        messages={messages[conversationId] || []}
        onSendMessage={handleSendMessage}
        loading={loading}
        error={error}
      />
        </div>
      </div>
   
      
    </div>
  );
};

export default WhatsAppChat;
