import { requestWithToken, requestWithoutToken } from "../helper/helper";

const login = async (payload) => {
  try {
    const response = await requestWithoutToken("POST", `/api/login/`, payload);

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchUserDetails = async () => {
  try {
    const response = await requestWithToken("GET", `/api/user/`);

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

export { login, fetchUserDetails };
