import { requestWithToken } from "../helper/helper";

const getCountryDetails = async () => {
  try {
    const response = await requestWithToken("GET", "/api/location/countries/");  
    return response.data;  
  } catch (err) {
    return err.response.data;  
  }
};


export {
    getCountryDetails
};