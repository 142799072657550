import React, { useState, useEffect, useRef } from "react";

// images
import arrowUpIcon from "../../assets/images/arrow-up.svg";
import { chatWithAi } from "../../action/aiChat";

const AiChatBox = () => {
  const [question, setQuestion] = useState("");
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const chatBoxRef = useRef(null);

  const messageAi = async (message) => {
    const payload = {
      message,
    };

    // Update messages state to include the user's message
    setMessages((prevMessages) => [...prevMessages, { user: true, text: message }]);

    setQuestion("");
    setLoading(true); // Set loading to true

    try {
      // Make the API call
      const res = await chatWithAi(payload);

      // Update messages state to include the AI's response
      setMessages((prevMessages) => [...prevMessages, { user: false, text: res.message }]);
    } catch (err) {
      // Handle the error
      setMessages((prevMessages) => [...prevMessages, { user: false, text: "An error occurred. Please try again." }]);
    } finally {
      setLoading(false); // Set loading to false
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter" && question.trim() !== "") {
      messageAi(question);
    }
  };

  useEffect(() => {
    if (chatBoxRef.current) {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    }
  }, [messages, loading]);

  return (
    <div className="container mx-4 my-4 card-custom card3 mb-4">
      <div className="card3-head">
        <div className="ai-iconbx">
          <img src="images/ai-chat.svg" alt="" />
        </div>
        <span>Ask AI</span>
      </div>
      <div className="chat-box" ref={chatBoxRef} style={{ maxHeight: '400px', overflowY: 'auto', padding: '10px' }}>
        {messages.map((message, index) => (
          <div key={index} className={`chat-row ${message.user ? "send" : "receive"}`}>
            <div
              className={`chat-${message.user ? "send" : "receive"}`}
              style={{
                whiteSpace: 'pre-wrap',
                padding: '10px',
                margin: '5px 0',
                borderRadius: '10px',
                background: message.user ? '#e1ffc7' : '#f1f0f0',
                fontSize: '14px' // Adjust the font size here
              }}
            >
              {message.text}
            </div>
          </div>
        ))}
        {loading && (
          <div className="chat-row receive">
            <div className="chat-receive" style={{ padding: '10px', margin: '5px 0', borderRadius: '10px', background: '#f1f0f0' }}>
              <div className="loading">
                <span className="loading__dot"></span>
                <span className="loading__dot"></span>
                <span className="loading__dot"></span>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="chat-bottom">
        <input
          type="text"
          name=""
          id=""
          className="chat-input"
          value={question}
          onChange={(e) => setQuestion(e.target.value)}
          onKeyPress={handleKeyPress}
          style={{ width: 'calc(100% - 50px)', padding: '10px', fontSize: '14px'  }} // Adjust the font size here
        />
        <button
          className="btn-theme circle sendicon"
          onClick={() => {
            if (question.trim() !== "") {
              messageAi(question);
            }
          }}
          style={{ padding: '10px', marginLeft: '10px' }}
        >
          <img src={arrowUpIcon} alt="" />
        </button>
      </div>
      <style>{`
        .loading {
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .loading__dot {
          width: 8px;
          height: 8px;
          margin: 0 2px;
          background: #999;
          border-radius: 50%;
          animation: loading 0.8s infinite alternate;
        }
        @keyframes loading {
          0% {
            opacity: 0.3;
          }
          100% {
            opacity: 1;
          }
        }
        .loading__dot:nth-child(1) {
          animation-delay: 0s;
        }
        .loading__dot:nth-child(2) {
          animation-delay: 0.2s;
        }
        .loading__dot:nth-child(3) {
          animation-delay: 0.4s;
        }
      `}</style>
    </div>
  );
};

export default AiChatBox;
