import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  searchText: "",
};

export const searchText = createSlice({
  name: "searchText",
  initialState,
  reducers: {
    changeSearchText: (state, provider) => {
      state.value = provider.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { changeSearchText } = searchText.actions;

export default searchText.reducer;
