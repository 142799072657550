import React, { useState, useEffect } from 'react';
import { fetchUserPermissions, updateUserPermissions } from '../../action/teams';
import Swal from 'sweetalert2';
import { FaUsers } from 'react-icons/fa';
import MyModal from "../model";
import 'bootstrap/dist/css/bootstrap.min.css';

const ManageTeams = () => {
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [selectedPermissions, setSelectedPermissions] = useState({});
    const [modalOpen, setModalOpen] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await fetchUserPermissions();
                const formattedUsers = result.users_permissions.map((user) => ({
                    user_id: user.user_id,
                    user_name: user.user_name,
                    permissions: user.permissions
                }));
                setUsers(formattedUsers);
            } catch (error) {
                console.error('Error fetching user permissions:', error);
            }
        };

        fetchData();
    }, []);

    const handleUpdateClick = (user) => {
        setSelectedUser(user);
        setSelectedPermissions({ ...user.permissions });
        setModalOpen(true);
    };

    const handlePermissionChange = (permissionKey) => {
        setSelectedPermissions((prev) => ({
            ...prev,
            [permissionKey]: !prev[permissionKey],
        }));
    };

    const handleUpdatePermissions = async () => {
        try {
            const response = await updateUserPermissions(selectedUser.user_id, selectedPermissions);
            if (response?.status) {
                Swal.fire({
                    icon: 'success',
                    title: 'Updated!',
                    text: 'Permissions updated successfully.',
                });
                setModalOpen(false);
                setUsers((prevUsers) =>
                    prevUsers.map((user) =>
                        user.user_id === selectedUser.user_id
                            ? { ...user, permissions: { ...selectedPermissions } }
                            : user
                    )
                );
            }
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Error!',
                text: 'Failed to update permissions.',
            });
        }
    };
    
    return (
        <div className="container mt-4 manage-teams">
            <h1 className="mb-4 text-center">
                <FaUsers className="me-2" /> Manage Teams
            </h1>
            <div className="table-responsive">
                <table className="table table-striped table-bordered">
                    <thead className="table-dark">
                        <tr>
                            <th>User ID</th>
                            <th>User Name</th>
                            <th>Permissions</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {users.map((user) => (
                            <tr key={user.user_id}>
                                <td>{user.user_id}</td>
                                <td>{user.user_name}</td>
                                <td>
                                    {Object.entries(user.permissions)
                                        .filter(([key, value]) => value)
                                        .map(([key]) => key)
                                        .join(', ') || 'No Permissions'}
                                </td>
                                <td>
                                    <button
                                        className="btn btn-primary btn-sm"
                                        onClick={() => handleUpdateClick(user)}
                                    >
                                        Edit
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            {modalOpen && (
                <MyModal
                    show={modalOpen}
                    handleClose={() => setModalOpen(false)}
                    label={`Update Permissions for ${selectedUser?.user_name}`}
                >
                    <div className="permissions-list">
                        {Object.keys(selectedPermissions).map((permissionKey) => (
                            <div className="form-check" key={permissionKey}>
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    checked={selectedPermissions[permissionKey]}
                                    onChange={() => handlePermissionChange(permissionKey)}
                                />
                                <label className="form-check-label">
                                    {permissionKey}
                                </label>
                            </div>
                        ))}
                    </div>
                    <div className="d-flex justify-content-end mt-3">
                        <button
                            className="btn btn-success me-2"
                            onClick={handleUpdatePermissions}
                        >
                            Update
                        </button>
                        <button
                            className="btn btn-secondary"
                            onClick={() => setModalOpen(false)}
                        >
                            Cancel
                        </button>
                    </div>
                </MyModal>
            )}
        </div>
    );
};

export default ManageTeams;
