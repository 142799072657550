import { useFormik } from "formik";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { createEventHotel, fetchEventsList } from "../../action/hotels";
import { Link, useNavigate, useParams } from "react-router-dom";
import showNotification from "../../components/notification/notification";
import viewEdit from "../../assets/images/viewEdit.svg";
import addHotel from "../../assets/images/addHotel.svg";

const HotelDetails = ({ onCloseCanvas }) => {
  const { eventId } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [events, setEvents] = useState([]);
  const addHotelFormik = useFormik({
    initialValues: {
      event: eventId,
      name: "",
      total_rooms: "",
    },
    validate: (values) => {
      const errors = {};
      if (!values.name) {
        errors.name = "Name is required";
      }
      if (!values.total_rooms) {
        errors.total_rooms = "Total rooms is required";
      } else if (!/^([1-9][0-9]{0,2}|10000)$/.test(values.total_rooms)) {
        errors.total_rooms = "Total rooms no. is to big";
      }
      return errors;
    },
    onSubmit: async (values) => {
      const res = await createEventHotel(values.event, values);
      showNotification("Hotel added successfully");
      onCloseCanvas();
    },
  });

  return (
    <>
      <div class="payout_data-content">
        <div className="viewAllDtl-box">
          <Link className="viewAllDtl" to="">
            View all details <img src={viewEdit} />
          </Link>
        </div>
      </div>
      <div className="hotel-detail-content">
        <div className="hotel-detail-box">
          <div className="hotel-detail-head">
            <div className="hotel-detail-headLeft">The Orchid</div>
            <div className="hotel-detail-headRight">
              <Link to="">Edit</Link>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3">
              {" "}
              <div className="htl_dtl-ttl">Agent:</div>
            </div>
            <div className="col-lg-9">
              <div className="htl_dtl-desc">Tanish Agrawal</div>
            </div>
            <div className="col-lg-3">
              {" "}
              <div className="htl_dtl-ttl">Mobile:</div>
            </div>
            <div className="col-lg-9">
              <div className="htl_dtl-desc">98765 43210</div>
            </div>
            <div className="col-lg-3">
              {" "}
              <div className="htl_dtl-ttl">Email ID:</div>
            </div>
            <div className="col-lg-9">
              <div className="htl_dtl-desc">tansih.agarwal@gmail.com</div>
            </div>
            <div className="col-lg-3">
              {" "}
              <div className="htl_dtl-ttl">Address:</div>
            </div>
            <div className="col-lg-9">
              <div className="htl_dtl-desc">
                10, Pali Mala Rd, Adarsh Nagar,Bandra West, Mumbai, Maharashtra
                400050, India
              </div>
            </div>
            <div className="col-lg-6">
              <div className="htl_dtl-btm-text">
                <div className="htl_dtl-ttl">Total Rooms:</div>
                <div className="htl_dtl-desc">130</div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="htl_dtl-btm-text">
                <div className="htl_dtl-ttl">Total Guests:</div>
                <div className="htl_dtl-desc">410</div>
              </div>
            </div>
          </div>
        </div>
        <button className="addHotel_box">
          <div className="addHotel_box-content">
            <img src={addHotel} alt="" /> Add Hotel
          </div>
        </button>
      </div>
    </>
  );
};

export default HotelDetails;
