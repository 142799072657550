/* global FB */
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import CustomDropdown from "../../components/customSearchDropdown.js";
import { useDispatch, useSelector } from "react-redux";
import { changeHeaderState } from "../../reducer/slice/header";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import showNotification from "../../components/notification/notification";
import { fetchEventDetails, fetchActiveEvents } from "../../action/events";
import { setSelectedEventType } from "../../reducer/slice/eventSlice";
import { fetchAllGuests } from "../../reducer/slice/guestListSlice";
import { Link } from "react-router-dom";
import * as conversation from "../../action/conversation.js";
import { SendEmails } from "../../action/communication";
import { fetchTemplates, sendTemplateMessage } from '../../action/templates.js';

export function CommunicationPage() {
  const { eventId } = useParams();
  const dispatch = useDispatch();
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [optionsOfTemplates, setOptionsOfTemplates] = useState([]);
  const [templateParameters, setTemplateParameters] = useState({});

  const selectedEventType = useSelector((state) => state.events.selectedEventType);
  const guestAllList = useSelector((state) => state.guestList.allGuests);

  useEffect(() => {
    // Fetch event details and guest list when component mounts
    const fetchEventData = async () => {
      const res = await fetchEventDetails(eventId);
      if (res.status) {
        dispatch(setSelectedEventType(res.data));
        dispatch(fetchAllGuests({ eventId }));
        dispatch(
              changeHeaderState({
                showDefault: false,
                headerTitle: "Communication Management",
                navigationList: [
                  { name: "Events", url: "/events" },
                  {
                    name: res.data.name,
                    url: "/event-details/"+eventId,
                  },
                  {
                    name: "Communication Management",
                    url: "",
                  },
                ],
              })
            );
        
      }
    };
    fetchEventData();
  }, [dispatch, eventId]);

  useEffect(() => {
    const loadTemplates = async () => {
      try {
        const response = await fetchTemplates();
        if (response.data) {
          const formattedTemplates = response.data.map(template => ({
            text: template.name,
            id: template.id,
            content: template.content,
            parameters: template.parameters
          }));
          setOptionsOfTemplates(formattedTemplates);
          
          if (formattedTemplates.length > 0) {
            setSelectedTemplate(formattedTemplates[0]);
            initializeParameters(formattedTemplates[0].parameters);
          }
        }
      } catch (error) {
        console.error('Error loading templates:', error);
      }
    };

    loadTemplates();

   
  }, []);

  const initializeParameters = (parameters) => {
    const paramObj = {};
    parameters.forEach(param => {
      paramObj[param] = '';
    });
    setTemplateParameters(paramObj);
  };

  const addEventFormik = useFormik({
    initialValues: {
      event: eventId,
      guests: [],
      sendEmail: false,
      sendWhatsapp: false,
      whatsappTemplate: "",
      emailMessage: "",
      whatsappTemplateMessage: "",
    },
    validate: (values) => {
      const errors = {};
      if (!values.event) {
        errors.event = "Please select event";
      }
      return errors;
    },
  });

  const handleOptionChange = (selectedGuests) => {
    // selectedGuests already contains id and name
    setSelectedOptions(selectedGuests);
  };

  const handleTemplateChange = (option) => {
    setSelectedTemplate(option);
    setIsOpen(false);
    initializeParameters(option.parameters);
    addEventFormik.setFieldValue("whatsappTemplate", option.text);
    addEventFormik.setFieldValue("whatsappTemplateMessage", option.content);
  };

  const handleParameterChange = (param, value) => {
    setTemplateParameters(prev => ({
      ...prev,
      [param]: value
    }));
  };

  const formatMessageWithParameters = (template, parameters) => {
    let messageText = template.content;
    template.parameters.forEach(param => {
      messageText = messageText.replace(`[${param}]`, parameters[param] || '');
    });
    return messageText;
  };

  // Create a shared modal template for both email and WhatsApp
  const createResultModalHtml = (response) => {
    const { results, success_count, failed_count, total } = response;
    
    return `
      <div class="error-modal">
        <div class="error-summary">
          <div class="summary-item ${success_count === total ? 'success' : 'warning'}">
            <div class="summary-label">Total Recipients</div>
            <div class="summary-value">${total}</div>
          </div>
          <div class="summary-item success">
            <div class="summary-label">Successful</div>
            <div class="summary-value">${success_count}</div>
          </div>
          <div class="summary-item error">
            <div class="summary-label">Failed</div>
            <div class="summary-value">${failed_count}</div>
          </div>
        </div>
        
        <div class="results-container">
          ${failed_count > 0 || success_count > 0 ? `
            <div class="results-grid">
              ${failed_count > 0 ? `
                <div class="error-details">
                  <h4>Failed Deliveries</h4>
                  <div class="error-list">
                    ${results
                      .filter(r => r.status === "error")
                      .map(r => `
                        <div class="error-item">
                          <div class="recipient-info">
                            <span class="recipient-name">${r.guest_name}</span>
                            <span class="error-message">${r.message}</span>
                          </div>
                        </div>
                      `).join('')}
                  </div>
                </div>
              ` : ''}
              
              ${success_count > 0 ? `
                <div class="success-details">
                  <h4>Successful Deliveries</h4>
                  <div class="success-list">
                    ${results
                      .filter(r => r.status === "success")
                      .map(r => `
                        <div class="success-item">
                          <div class="recipient-info">
                            <span class="recipient-name">${r.guest_name}</span>
                            ${r.message_sid ? `<span class="message-sid">Message ID: ${r.message_sid}</span>` : ''}
                          </div>
                        </div>
                      `).join('')}
                  </div>
                </div>
              ` : ''}
            </div>
          ` : ''}
        </div>
      </div>
      
      <style>
        .error-modal {
          text-align: left;
          max-height: 70vh;
          overflow-y: auto;
        }
        
        .error-summary {
          display: flex;
          justify-content: space-around;
          align-items: center;
          gap: 1rem;
          margin-bottom: 1.5rem;
          padding: 1rem;
          background: #f8f9fa;
          border-radius: 8px;
        }
        
        .summary-item {
          flex: 1;
          text-align: center;
          padding: 1rem;
          border-radius: 6px;
          background: white;
          box-shadow: 0 2px 4px rgba(0,0,0,0.05);
          min-width: 120px;
          max-width: 200px;
        }
        
        .summary-label {
          font-size: 0.9rem;
          color: #6c757d;
          margin-bottom: 0.5rem;
        }
        
        .summary-value {
          font-size: 1.5rem;
          font-weight: bold;
        }
        
        .success .summary-value { color: #28a745; }
        .error .summary-value { color: #dc3545; }
        .warning .summary-value { color: #ffc107; }
        
        .results-container {
          margin-top: 1.5rem;
        }
        
        .results-grid {
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
          gap: 1.5rem;
        }
        
        .error-details, .success-details {
          background: white;
          border-radius: 8px;
          padding: 1rem;
          box-shadow: 0 2px 4px rgba(0,0,0,0.05);
        }
        
        .error-details h4, .success-details h4 {
          color: #495057;
          margin-bottom: 1rem;
          font-size: 1.1rem;
          padding-bottom: 0.5rem;
          border-bottom: 2px solid #f8f9fa;
        }
        
        .error-list, .success-list {
          max-height: 300px;
          overflow-y: auto;
        }
        
        .error-item, .success-item {
          padding: 0.75rem;
          margin-bottom: 0.5rem;
          border-radius: 6px;
          background: #fff;
          box-shadow: 0 1px 3px rgba(0,0,0,0.05);
        }
        
        .error-item {
          border-left: 3px solid #dc3545;
          background: #fff5f5;
        }
        
        .success-item {
          border-left: 3px solid #28a745;
          background: #f6fff8;
        }
        
        .recipient-info {
          display: flex;
          flex-direction: column;
          gap: 0.25rem;
        }
        
        .recipient-name {
          font-weight: 500;
          color: #212529;
        }
        
        .error-message {
          font-size: 0.9rem;
          color: #dc3545;
        }
        
        .message-sid {
          font-size: 0.8rem;
          color: #6c757d;
        }
        
        @media (max-width: 768px) {
          .results-grid {
            grid-template-columns: 1fr;
          }
        }
      </style>
    `;
  };

  const handleSendWhatsapp = async () => {
    try {
      if (!selectedTemplate || selectedOptions.length === 0) {
        Swal.fire({
          title: "Error",
          text: "Please select a template and recipients",
          icon: "error"
        });
        return;
      }

      // Check if all parameters are filled
      const missingParams = selectedTemplate.parameters.filter(param => !templateParameters[param]);
      if (missingParams.length > 0) {
        Swal.fire({
          title: "Error",
          text: `Please fill in all template parameters: ${missingParams.join(', ')}`,
          icon: "error"
        });
        return;
      }

      // Format message with parameters once
      let messageText = selectedTemplate.content;
      for (const param of selectedTemplate.parameters) {
        const value = templateParameters[param] || '';
        messageText = messageText.replace(`[${param}]`, value);
      }

      Swal.fire({
        title: "Please wait...",
        text: "Sending WhatsApp messages...",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      // Get parameter values in the correct order
      const parameterValues = selectedTemplate.parameters.map(param => templateParameters[param] || '');
      
      // Prepare request data
      const requestData = {
        event: eventId,
        template_name: selectedTemplate.text,
        parameters: parameterValues,
        guests: selectedOptions.map(guest => guest.id),
        message_text: messageText
      };

      console.log('Sending WhatsApp request with data:', requestData);
      
      try {
        // Send all messages in a single request
        const response = await sendTemplateMessage(requestData);
        console.log('Received response:', response);

        Swal.close();

        if (response.status === "completed" || response.status === "error") {
          Swal.fire({
            title: response.success_count === response.total ? "Success" : 
                  (response.success_count > 0 ? "Partial Success" : "Failed"),
            html: createResultModalHtml(response),
            icon: response.success_count === response.total ? "success" : 
                  (response.success_count > 0 ? "warning" : "error"),
            width: '800px',
            confirmButtonText: 'Close',
            confirmButtonColor: '#3085d6'
          });
        } else {
          throw new Error(response.message || "Failed to send messages");
        }
      } catch (error) {
        console.error("API Error:", error);
        
        // Create an error response object for the modal
        const errorResponse = {
          status: "error",
          total: selectedOptions.length,
          success_count: 0,
          failed_count: selectedOptions.length,
          results: selectedOptions.map(guest => ({
            guest_id: guest.id,
            guest_name: guest.name,
            status: "error",
            message: error.response?.data?.message || error.message || "Failed to send message"
          }))
        };

        Swal.fire({
          title: "Failed",
          html: createResultModalHtml(errorResponse),
          icon: "error",
          width: '800px',
          confirmButtonText: 'Close',
          confirmButtonColor: '#3085d6'
        });
      }

    } catch (error) {
      console.error("Error in handleSendWhatsapp:", error);
      
      // Create an error response object for the modal
      const errorResponse = {
        status: "error",
        total: selectedOptions.length,
        success_count: 0,
        failed_count: selectedOptions.length,
        results: selectedOptions.map(guest => ({
          guest_id: guest.id,
          guest_name: guest.name,
          status: "error",
          message: error.message || "An unexpected error occurred"
        }))
      };

      Swal.fire({
        title: "Error",
        html: createResultModalHtml(errorResponse),
        icon: "error",
        width: '800px',
        confirmButtonText: 'Close',
        confirmButtonColor: '#3085d6'
      });
    }
  };

  const handleSendEmail = async () => {
    try {
      if (!selectedTemplate || selectedOptions.length === 0) {
        Swal.fire({
          title: "Error",
          text: "Please select a template and recipients",
          icon: "error"
        });
        return;
      }

      // Check if all parameters are filled
      const missingParams = selectedTemplate.parameters.filter(param => !templateParameters[param]);
      if (missingParams.length > 0) {
        Swal.fire({
          title: "Error",
          text: `Please fill in all template parameters: ${missingParams.join(', ')}`,
          icon: "error"
        });
        return;
      }

      // Format message with parameters once
      let messageText = selectedTemplate.content;
      for (const param of selectedTemplate.parameters) {
        const value = templateParameters[param] || '';
        messageText = messageText.replace(`[${param}]`, value);
      }

      // Show loading alert for all emails
      Swal.fire({
        title: "Please wait...",
        text: "Sending emails to selected recipients...",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      // Send all guests in a single request
      const response = await SendEmails({
        event: eventId,
        guests: selectedOptions.map(guest => guest.id),
        message_text: messageText,
      });

      Swal.close();

      if (response.status === "completed" || response.status === "error") {
        Swal.fire({
          title: response.success_count === response.total ? "Success" : 
                (response.success_count > 0 ? "Partial Success" : "Failed"),
          html: createResultModalHtml(response),
          icon: response.success_count === response.total ? "success" : 
                (response.success_count > 0 ? "warning" : "error"),
          width: '800px',
          confirmButtonText: 'Close',
          confirmButtonColor: '#3085d6'
        });
      } else {
        throw new Error(response.detail || "Failed to send emails");
      }

    } catch (error) {
      Swal.close();
      console.error("Error sending email:", error);
      Swal.fire({
        title: "Error",
        text: error.message || "Failed to send emails",
        icon: "error"
      });
    }
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    if (name === "sendEmail" && checked) {
      addEventFormik.setFieldValue("sendEmail", true);
      addEventFormik.setFieldValue("sendWhatsapp", false);
    } else if (name === "sendWhatsapp" && checked) {
      addEventFormik.setFieldValue("sendWhatsapp", true);
      addEventFormik.setFieldValue("sendEmail", false);
    } else {
      addEventFormik.handleChange(e);
    }
  };

  return (
    <div className="payout_data-content">
      <div className="payout_data-content-box">
        <form onSubmit={addEventFormik.handleSubmit}>
          <div className="row">
            <div className="col-lg-7">
              <div className="card-custom card3">
                <div className="inline-checkbox">
                  <div className="custom_checkbox">
                    <input
                      name="sendEmail"
                      id="sendEmail"
                      type="checkbox"
                      className="m-2"
                      checked={addEventFormik.values.sendEmail}
                      onChange={handleCheckboxChange}
                    />
                    <label htmlFor="sendEmail">Email</label>
                  </div>

                  <div className="custom_checkbox">
                    <input
                      id="sendWhatsapp"
                      name="sendWhatsapp"
                      type="checkbox"
                      className="m-2"
                      checked={addEventFormik.values.sendWhatsapp}
                      onChange={handleCheckboxChange}
                    />
                    <label htmlFor="sendWhatsapp">Whatsapp</label>
                  </div>
                </div>

                <div className="row">
                  <div className="selected-event-name">
                    {selectedEventType.name}
                  </div>

                  <div className="col-lg-6">
                    <div className="input-box">
                      <div className="input-label">Select Guests</div>
                      <div className="custom-dropdown-box">
                        <CustomDropdown
                          options={guestAllList}
                          placeholder="Select guests"
                          onChange={handleOptionChange}
                          selectedOptions={selectedOptions}
                          message={"guest selected"}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6">
                    {(addEventFormik.values.sendWhatsapp || addEventFormik.values.sendEmail) && (
                      <div>
                        <div className="input-box">
                          <div className="input-label">Select Template</div>
                          <div className="input_field">
                            <div className="custom-dropdown">
                              <div
                                className="custom-dropdown__control"
                                onClick={() => setIsOpen(!isOpen)}
                              >
                                <div className="custom-dropdown__selected">
                                  {selectedTemplate ? selectedTemplate.text : "Select Template"}
                                </div>
                                <div className="custom-dropdown__arrow">
                                  {isOpen ? "▲" : "▼"}
                                </div>
                              </div>
                              {isOpen && (
                                <div className="custom-dropdown__menu">
                                  <div className="custom-dropdown__options">
                                    {optionsOfTemplates.map((template) => (
                                      <div
                                        key={template.id}
                                        className="custom-dropdown__option"
                                        onClick={() => handleTemplateChange(template)}
                                      >
                                        {template.text}
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            {(addEventFormik.values.sendWhatsapp || addEventFormik.values.sendEmail) && selectedTemplate && (
              <div className="col-lg-5">
                <div className="card-custom card3">
                  <div className="input-box">
                    <div className="input-label">Message Template</div>
                    <div className="input_field">
                      <textarea
                        rows={8}
                        value={selectedTemplate.content}
                        readOnly
                        className="input"
                        placeholder="Message Template"
                      />
                    </div>

                    {selectedTemplate.parameters && selectedTemplate.parameters.length > 0 && (
                      <div className="template-parameters mt-3">
                        <div className="input-label">Template Parameters</div>
                        {selectedTemplate.parameters.map((param, index) => (
                          <div key={index} className="input_field mt-2">
                            <input
                              type="text"
                              className="input"
                              placeholder={param}
                              value={templateParameters[param] || ''}
                              onChange={(e) => handleParameterChange(param, e.target.value)}
                            />
                          </div>
                        ))}
                      </div>
                    )}

                    <button
                      type="button"
                      className="btn-theme btn bg-dark mt-3"
                      onClick={addEventFormik.values.sendWhatsapp ? handleSendWhatsapp : handleSendEmail}
                    >
                      Send Message
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </form>
      </div>
    </div>
  );
}

export default CommunicationPage;
