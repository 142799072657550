import Dropdown from "react-bootstrap/Dropdown";

function BasicDropdown({ title, items, customClass }) {
  return (
    <Dropdown>
      <Dropdown.Toggle
        variant="success"
        id="dropdown-basic"
        className={customClass}
      >
        {title}
      </Dropdown.Toggle>

      <Dropdown.Menu className="custom-dropdown-menu">
        {items.map((item, index) => (
          <Dropdown.Item
            key={index}
            onClick={item.onClick}
            className="custom-dropdown-item"
          >
            {item.name}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default BasicDropdown;
