import { requestWithToken } from "../helper/helper";

const getDashboardDetails = async () => {
  try {
    const response = await requestWithToken("GET", `/api/company_dashboard/`);

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchAllEvents = async (type, pageNo, pageSize, searchText) => {
  try {
    const response = await requestWithToken(
      "GET",
      `/api/allevent/?type=${type}&pageno=${pageNo}&pagesize=${pageSize}&search=${searchText}`
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchAllEventCounts = async (searchText) => {
  try {
    const response = await requestWithToken(
      "GET",
      `/api/allevent/counts?search=${searchText}`
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchActiveEvents = async (eventType, pageNo, pageSize, searchText) => {
  try {
    const response = await requestWithToken(
      "GET",
      eventType
        ? `/api/allevent/active?event_type=${eventType}&pageno=${pageNo}&pagesize=${pageSize}&search=${searchText}`
        : `/api/allevent/active?&pageno=${pageNo}&pagesize=${pageSize}&search=${searchText}`
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchPastEvents = async (eventType, pageNo, pageSize, searchText) => {
  try {
    const response = await requestWithToken(
      "GET",
      eventType
        ? `/api/allevent/past?event_type=${eventType}&pageno=${pageNo}&pagesize=${pageSize}&search=${searchText}`
        : `/api/allevent/past?&pageno=${pageNo}&pagesize=${pageSize}&search=${searchText}`
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchSubscriptionDetails = async (id) => {
  try {
    const response = await requestWithToken("GET", `/api/subscriptions/${id}/`);

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchSubscriptionPlan = async () => {
  try {
    const response = await requestWithToken("GET", `/api/plans/`);

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const createEvent = async (payload, url) => {
  try {
    const response = await requestWithToken("POST", `${url}`, payload);

    if (response?.data) {
      return response.data;
    } else {
      return response.response.data;
    }
  } catch (err) {
    return err.response.data;
  }
};

const updateEvent = async (id, payload, url) => {
  try {
    const response = await requestWithToken("PUT", `${url}${id}/`, payload);
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const getCompanyDetails = async () => {
  try {
    const response = await requestWithToken("GET", `/api/company_details/`);

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchEventDetails = async (id, type) => {
  try {
    const response = await requestWithToken("GET", `/api/allevent/${id}`);

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchEventSummary = async (id) => {
  try {
    const response = await requestWithToken(
      "GET",
      `/api/events/${id}/eventguestsummarry/`
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchEventFlightSummary = async (id) => {
  try {
    const response = await requestWithToken(
      "GET",
      `/api/flight-booking-summary/`
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const removeEvent = async (url, id) => {
  try {
    const response = await requestWithToken("DELETE", `/api/${url}/${id}/`);

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

export {
  getDashboardDetails,
  createEvent,
  updateEvent,
  getCompanyDetails,
  fetchEventDetails,
  removeEvent,
  fetchEventSummary,
  fetchEventFlightSummary,
  fetchSubscriptionPlan,
  fetchSubscriptionDetails,
  fetchAllEvents,
  fetchAllEventCounts,
  fetchActiveEvents,
  fetchPastEvents,
};
