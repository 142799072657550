import { useFormik } from "formik";
import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  addUserHotelRooms,
  createEventHotel,
  editUserHotelRooms,
  fetchEventsList,
  fetchHotelsListing,
  fetchUserHotelRoomsDetails,
} from "../../action/hotels";
import { Link, useNavigate, useParams } from "react-router-dom";
import showNotification from "../../components/notification/notification";
import viewEdit from "../../assets/images/viewEdit.svg";
import addHotel from "../../assets/images/addHotel.svg";
import { fetchAllGuest } from "../../action/guests";

const EditRoomToGuest = ({ id, onCloseCanvas }) => {
  const { eventId } = useParams();

  const [allGuests, setAllGuests] = useState([]);
  const [hotelList, setHotelList] = useState([]);

  const addHotelFormik = useFormik({
    initialValues: {
      guest: "",
      team: "",
      group: "",
      share_count: 0,
      room_number: "",
      billing: "",
      status: "Pending",
      checkin_date: "",
      checkout_date: "",
      remarks: "",
      selectedHotel: "",
    },
    validate: (values) => {
      const errors = {};
      if (!values.guest) {
        errors.guest = "Guest is required";
      }
      if (!values.selectedHotel) {
        errors.selectedHotel = "Hotel is required";
      }
      if (!values.checkin_date) {
        errors.checkin_date = "Checkin Date is required";
      }
      if (!values.checkout_date) {
        errors.checkout_date = "Checkout Date is required";
      }
      if (!values.share_count) {
        errors.share_count = "Share Count is required";
      }
      if (!values.room_number) {
        errors.room_number = "Room number is required";
      }

      return errors;
    },
    onSubmit: async (values) => {
      const res = await editUserHotelRooms(values.selectedHotel, id, values);

      showNotification("Room details updated successfully");
      onCloseCanvas();
    },
  });

  const getAllGuests = async () => {
    const res = await fetchAllGuest(eventId);
    if (res.status) {
      setAllGuests(res.data.objects.guestDetails);
    }
  };

  const getHotelsList = async () => {
    const res = await fetchHotelsListing(eventId, 1, 10);
    if (res.status) {
      setHotelList(res.data.objects);
    }
  };

  const getUserHotelRoomsDetails = async () => {
    const res = await fetchUserHotelRoomsDetails(id);
    if (res.status) {
      addHotelFormik.setFieldValue("guest", res.data[0].guest);
      addHotelFormik.setFieldValue("team", res.data[0].team);
      addHotelFormik.setFieldValue("group", res.data[0].group);
      addHotelFormik.setFieldValue("share_count", res.data[0].share_count);
      addHotelFormik.setFieldValue("room_number", res.data[0].room_number);
      addHotelFormik.setFieldValue("billing", res.data[0].billing);
      addHotelFormik.setFieldValue("status", res.data[0].status);
      addHotelFormik.setFieldValue(
        "checkin_date",
        moment(res.data[0].checkin_date).format("yyyy-MM-DD")
      );
      addHotelFormik.setFieldValue(
        "checkout_date",
        moment(res.data[0].checkout_date).format("yyyy-MM-DD")
      );
      addHotelFormik.setFieldValue("remarks", res.data[0].remarks);
      addHotelFormik.setFieldValue("selectedHotel", res.data[0].hotel);
      addHotelFormik.setFieldValue("room_type", res.data[0].room_type);
    }
  };

  useEffect(() => {
    getAllGuests();
    getHotelsList();
    getUserHotelRoomsDetails();
  }, []);

  return (
    <>
      <div class="payout_data-content">
        <div className="viewAllDtl-box">
          <Link className="viewAllDtl" to="">
            View all details <img src={viewEdit} />
          </Link>
        </div>
        <form onSubmit={addHotelFormik.handleSubmit}>
          <div class="row">
            <div class="col-lg-12">
              <div class="input-box">
                <div class="input-label">Guest</div>
                <div class="input_field">
                  <select
                    name="guest"
                    value={addHotelFormik.values.guest}
                    onChange={addHotelFormik.handleChange}
                    class="input"
                  >
                    <option value="">Select guest</option>
                    {allGuests.map((value) => {
                      return <option value={value.id}>{value.name}</option>;
                    })}
                  </select>
                  {addHotelFormik.touched.guest &&
                  addHotelFormik.errors.guest ? (
                    <p className="error">{addHotelFormik.errors.guest}</p>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div class="input-box">
                <div class="input-label">Team</div>
                <div class="input_field">
                  <input
                    type="text"
                    class="input"
                    placeholder="Team"
                    name="team"
                    value={addHotelFormik.values.team}
                    onChange={addHotelFormik.handleChange}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div class="input-box">
                <div class="input-label">Group</div>
                <div class="input_field">
                  <input
                    type="text"
                    class="input"
                    placeholder="Group"
                    name="group"
                    value={addHotelFormik.values.group}
                    onChange={addHotelFormik.handleChange}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div class="input-box">
                <div class="input-label">Hotel</div>
                <div class="input_field">
                  <select
                    name="selectedHotel"
                    value={addHotelFormik.values.selectedHotel}
                    onChange={addHotelFormik.handleChange}
                    class="input"
                  >
                    <option value="">Select Hotel</option>
                    {hotelList.map((value) => {
                      return <option value={value.id}>{value.name}</option>;
                    })}
                  </select>
                  {addHotelFormik.touched.selectedHotel &&
                  addHotelFormik.errors.selectedHotel ? (
                    <p className="error">
                      {addHotelFormik.errors.selectedHotel}
                    </p>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div class="input-label"> Check In - Check Out</div>
            </div>
            <div className="col-lg-6">
              <div class="input-box checkIn">
                <div class="input_field">
                  <input
                    type="date"
                    class="input "
                    placeholder="Enter checkIn date"
                    onKeyDown={(e) => e.preventDefault()}
                    min={moment().format("YYYY-MM-DD")}
                    name="checkin_date"
                    value={addHotelFormik.values.checkin_date}
                    onChange={addHotelFormik.handleChange}
                  />
                  {addHotelFormik.touched.checkin_date &&
                  addHotelFormik.errors.checkin_date ? (
                    <p className="error">
                      {addHotelFormik.errors.checkin_date}
                    </p>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div class="input-box">
                <div class="input_field">
                  <input
                    type="date"
                    class="input"
                    placeholder="Enter Checkout Date"
                    onKeyDown={(e) => e.preventDefault()}
                    min={moment(addHotelFormik.values.checkin_date).format(
                      "YYYY-MM-DD"
                    )}
                    name="checkout_date"
                    value={addHotelFormik.values.checkout_date}
                    onChange={addHotelFormik.handleChange}
                  />
                  {addHotelFormik.touched.checkout_date &&
                  addHotelFormik.errors.checkout_date ? (
                    <p className="error">
                      {addHotelFormik.errors.checkout_date}
                    </p>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div class="input-box">
                <div class="input-label">Share Count</div>
                <div class="input_field">
                  <input
                    type="number"
                    class="input"
                    placeholder="Enter Share Count"
                    name="share_count"
                    value={addHotelFormik.values.share_count}
                    onChange={addHotelFormik.handleChange}
                  />
                  {addHotelFormik.touched.share_count &&
                  addHotelFormik.errors.share_count ? (
                    <p className="error">{addHotelFormik.errors.share_count}</p>
                  ) : null}
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div class="input-box">
                <div class="input-label">Room No.</div>
                <div class="input_field">
                  <input
                    type="text"
                    class="input"
                    placeholder="Enter Room No."
                    name="room_number"
                    value={addHotelFormik.values.room_number}
                    onChange={addHotelFormik.handleChange}
                  />
                  {addHotelFormik.touched.room_number &&
                  addHotelFormik.errors.room_number ? (
                    <p className="error">{addHotelFormik.errors.room_number}</p>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div class="input-box">
                <div class="input-label">Room Type.</div>
                <div class="input_field">
                  <input
                    type="text"
                    class="input"
                    placeholder="Enter Room Type"
                    name="room_type"
                    value={addHotelFormik.values.room_type}
                    onChange={addHotelFormik.handleChange}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div class="input-box">
                <div class="input-label">Billing</div>
                <div class="input_field">
                  <input
                    type="text"
                    class="input"
                    placeholder="Enter Billing"
                    name="billing"
                    value={addHotelFormik.values.billing}
                    onChange={addHotelFormik.handleChange}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div class="input-box">
                <div class="input-label">Status</div>
                <div class="input_field">
                  <select
                    class="input"
                    name="status"
                    value={addHotelFormik.values.status}
                    onChange={addHotelFormik.handleChange}
                  >
                    <option value="Pending">Pending</option>
                    <option value="Done">Done</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div class="input-box">
                <div class="input-label">Remarks</div>
                <div class="input_field">
                  <input
                    type="text"
                    class="input"
                    placeholder="Enter Remark"
                    name="remarks"
                    value={addHotelFormik.values.remarks}
                    onChange={addHotelFormik.handleChange}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="btn-box frm-sbmit-grp">
            <button
              type="button"
              className="btn-theme outline"
              onClick={() => {
                onCloseCanvas();
              }}
            >
              {" "}
              Cancel
            </button>
            <button type="submit" className="btn-theme bg-dark">
              Save
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default EditRoomToGuest;
