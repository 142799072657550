import React from "react";

const CustomNotification = ({ message, type }) => {
  return (
    <>
      {type === "danger" ? (
        <div className="check danger">
          <i className="fa-solid fa-circle-exclamation"></i> &nbsp; &nbsp;  {" "}
          <span>{message}</span>
        </div>
      ) : (
        <div className="check">
          <i className="far fa-check-circle color"></i> &nbsp; &nbsp;  {" "}
          <span>{message}</span>
        </div>
      )}
    </>
  );
};

export default CustomNotification;
