import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import editIcon from "../../assets/images/help.svg";
import moment from "moment";
import {
  fetchFlightBookingsList,
  fetchLogisticList,
} from "../../action/logistics";
import { Offcanvas } from "react-bootstrap";
import LogisticAdd from "./logisticsAdd";
import FlightAdd from "./flightAdd";
import FlightEdit from "./flightEdit";
import LogisticEdit from "./logisticEdit";
import { useSelector } from "react-redux";
import CustomSearchBox from "../../components/customSearchBox";
import CustomSelectWithIcon from "../../components/CustomSelectWithIcon";
import printIcon from "../../assets/images/print.svg";
import addBrown from "../../assets/images/add-brown.svg";

const LogisticList = () => {
  const { guestId } = useParams();
  const userDetails = useSelector((state) => state.userDetails.value);

  const [loading, setShowLoading] = useState(false);
  const [logisticDetails, setLogisticDetails] = useState([]);
  const [flightBookings, setFlightBookings] = useState([]);
  const [show, setShow] = useState(false);
  const [showEditLogistic, setShowEditLogistic] = useState(false);
  const [showFlight, setShowFlight] = useState(false);
  const [showEditFlight, setShowEditFlight] = useState(false);
  const [selectedID, setSelectedID] = useState("");

  const handleClose = () => setShow(false);
  const handleFlightClose = () => setShowFlight(false);
  const handleShow = () => setShow(true);
  const handleFlightShow = () => setShowFlight(true);

  const handleCloseAndRefresh = () => {
    setShow(false);
    setShowFlight(false);
    setShowEditFlight(false);
    setShowEditLogistic(false);
    getFlightBookingList();
    getLogisticList();
  };

  const getLogisticList = async () => {
    setShowLoading(true);
    const res = await fetchLogisticList(guestId);
    if (res.status) {
      setShowLoading(false);
      setLogisticDetails(res.data);
    }
  };

  const getFlightBookingList = async () => {
    setShowLoading(true);
    const res = await fetchFlightBookingsList(guestId);
    if (res.status) {
      setShowLoading(false);
      setFlightBookings(res.data);
    }
  };

  useEffect(() => {
    getLogisticList();
    getFlightBookingList();
  }, [guestId]);

  return (
    <div>
      <div class="dashboard_head">
        {/* <h5>
          {eventDetails.event_type} - {eventDetails?.name}
        </h5> */}

        <div class="searchbar-box">
          {/* <div class="searchbar-box-left">
            <div class="searchbar-box-left_content">
              <CustomSelect extraclass="w-100" />
              <button class="btn-theme bg-dark">Apply</button>
            </div>
          </div> */}
          <div class="searchbar-box-mid">
            <div class="searchbar-box-mid_content-left">
              <CustomSearchBox />
            </div>
            <div class="searchbar-box-mid_content-right">
              <CustomSelectWithIcon />
            </div>
          </div>
          <div class="searchbar-box-right">
            <div class="searchbar-box-right-content">
              {/* <button className="iconbtn bg-dark">
                <img src={uploadIcon} alt="" />
              </button> */}
              <button
                className="iconbtn"
                onClick={() => {
                  window.print();
                }}
              >
                <img src={printIcon} alt="" />
              </button>

              {userDetails?.is_companyAdmin ? (
                <button
                  className="btn-theme dotted-border"
                  onClick={handleShow}
                >
                  <img src={addBrown} />
                  <span>Add Guest</span>
                </button>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
      <div class="payout_data-content">
        <h3>Logistic List</h3>
        <div class="payout_table-box">
          <table class="payout_table">
            <thead>
              <tr class="tableHead">
                <th>Travel Mode</th>
                <th>Travel Own</th>
                <th>Arrival Airport</th>
                <th>Arrival Time</th>
                <th>Departure Airport</th>
                <th>Departure Time</th>
                <th>Car Type</th>
                <th>Car Number</th>
                <th>Driver info</th>
                {userDetails?.is_companyAdmin ? <th>Action</th> : <></>}
              </tr>
            </thead>
            <tbody>
              {!loading ? (
                !logisticDetails?.length ? (
                  <tr>
                    <td colSpan={11} className="text-center">
                      No logistics found
                    </td>
                  </tr>
                ) : (
                  logisticDetails.map((value) => {
                    return (
                      <tr>
                        <td class="table_amount">{value.travel_mode}</td>
                        <td>
                          {value.travel_own_arrangement ? "true" : "false"}
                        </td>
                        <td>{value.arrival_airport}</td>
                        <td>
                          {moment(value.arrival_time).format("DD/MM/YYYY")}
                        </td>
                        <td>{value.departure_airport}</td>
                        <td>
                          {moment(value.departure_time).format("DD/MM/YYYY")}
                        </td>
                        <td>{value.car_type}</td>
                        <td>{value.car_number}</td>
                        <td>{value.driver_info}</td>
                        {userDetails?.is_companyAdmin ? (
                          <td>
                            <div className="action-div">
                              <span
                                onClick={() => {
                                  setSelectedID(value.id);
                                  setShowEditLogistic(true);
                                }}
                              >
                                <img className="btn-icon" src={editIcon} />
                              </span>
                            </div>
                          </td>
                        ) : (
                          <></>
                        )}
                      </tr>
                    );
                  })
                )
              ) : (
                <tr>
                  <td colSpan={9}>
                    <div className="loader-box">
                      <div className="loader"></div>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      <div class="payout_data-content mt-5">
        <h3>Flight Booking</h3>
        <div class="payout_table-box">
          <table class="payout_table">
            <thead>
              <tr class="tableHead">
                <th>Airline</th>
                <th>Arrival airport</th>
                <th>Arrival time</th>
                <th>Departure airport</th>
                <th>Departure time</th>
                <th>Flight number</th>
                <th>Ticket issued</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {!loading ? (
                !flightBookings?.length ? (
                  <tr>
                    <td colSpan={8} className="text-center">
                      No flights found
                    </td>
                  </tr>
                ) : (
                  flightBookings.map((value) => {
                    return (
                      <tr>
                        <td class="table_amount">{value.airline}</td>
                        <td>{value.arrival_airport}</td>
                        <td>
                          {" "}
                          {moment(value.arrival_time).format("DD/MM/YYYY")}
                        </td>
                        <td>{value.departure_airport}</td>
                        <td>
                          {" "}
                          {moment(value.departure_time).format("DD/MM/YYYY")}
                        </td>
                        <td>{value.flight_number}</td>
                        <td>{value.ticket_issued ? "True" : "False"}</td>
                        <td>
                          <div className="action-div">
                            <span
                              onClick={() => {
                                setSelectedID(value.id);
                                setShowEditFlight(true);
                              }}
                            >
                              <img className="btn-icon" src={editIcon} />
                            </span>
                          </div>
                        </td>
                      </tr>
                    );
                  })
                )
              ) : (
                <tr>
                  <td colSpan={9}>
                    <div className="loader-box">
                      <div className="loader"></div>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      <Offcanvas show={show} onHide={handleClose} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Add Logistic</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <LogisticAdd closeCanvas={handleCloseAndRefresh} />
        </Offcanvas.Body>
      </Offcanvas>
      <Offcanvas
        show={showEditLogistic}
        onHide={() => {
          setShowEditLogistic(false);
        }}
        placement="end"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Edit Logistic</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <LogisticEdit id={selectedID} closeCanvas={handleCloseAndRefresh} />
        </Offcanvas.Body>
      </Offcanvas>

      <Offcanvas show={showFlight} onHide={handleFlightClose} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Add Flight Details</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <FlightAdd closeCanvas={handleCloseAndRefresh} />
        </Offcanvas.Body>
      </Offcanvas>

      <Offcanvas
        show={showEditFlight}
        onHide={() => {
          setShowEditFlight(false);
        }}
        placement="end"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Edit Flight Details</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <FlightEdit id={selectedID} closeCanvas={handleCloseAndRefresh} />
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default LogisticList;
