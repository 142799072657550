// /api.js
import { requestWithToken } from "../helper/helper";

// Fetch all event conversations
export const fetchEventConversations = async () => {
  try {
    const response = await requestWithToken("GET", "/api/event-conversations/");
    return response.data;
  } catch (err) {
    console.error("Error fetching event conversations:", err);
    return err.response?.data || { message: err.message };
  }
};

// Retrieve a specific event conversation
export const getEventConversation = async (conversationId) => {
  try {
    const response = await requestWithToken("GET", `api/event-conversations/${conversationId}/`);
    return response.data;
  } catch (err) {
    console.error("Error retrieving event conversation:", err);
    return err.response?.data || { message: err.message };
  }
};

// Create a new event conversation
export const createEventConversation = async (payload) => {
  try {
    const response = await requestWithToken("POST", "/api/event-conversations/", payload);
    return response.data;
  } catch (err) {
    console.error("Error creating event conversation:", err);
    return err.response?.data || { message: err.message };
  }
};

// Fetch all messages for a conversation
export const fetchMessages = async (conversationId) => {
  try {
    const response = await requestWithToken("GET", `/api/messages/?conversation=${conversationId}`);
    return response.data;
  } catch (err) {
    console.error("Error fetching messages:", err);
    return err.response?.data || { message: err.message };
  }
};

// Send a message in a conversation
export const sendMessage = async (payload) => {
  try {
    const response = await requestWithToken("POST", "/api/whatsapp/send/", payload);
    return response.data;
  } catch (err) {
    console.error("Error sending message:", err);
    return err.response?.data || { message: err.message };
  }
};

// Fetch all guests for an event
export const fetchGuests = async (eventId) => {
  try {
    const response = await requestWithToken("GET", `api/events/${eventId}/allguests/`);
    return response.data;
  } catch (err) {
    console.error("Error fetching guests:", err);
    return err.response?.data || { message: err.message };
  }
};

// Receive a WhatsApp message (simulate webhook for testing purposes)
export const receiveWhatsAppMessage = async (payload) => {
  try {
    const response = await requestWithToken("POST", "/api/whatsapp/receive/", payload);
    return response.data;
  } catch (err) {
    console.error("Error receiving WhatsApp message:", err);
    return err.response?.data || { message: err.message };
  }
};

export const api = {
  fetchEventConversations,
  getEventConversation,
  createEventConversation,
  fetchMessages,
  sendMessage,
  fetchGuests,
  receiveWhatsAppMessage
};
