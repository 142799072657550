/* global FB */
import React, { useEffect, useState } from "react";
import {
  getDashboardDetails,
} from "../../action/events";


import { useDispatch, useSelector } from "react-redux";
import BasicDropdown from "../dropDown";
import Swal from "sweetalert2";
// images
import teamMemberIcon from "../../assets/images/u.svg";
import searchIcon from "../../assets/images/search.svg";
import coinsIcon from "../../assets/images/coin.svg";
import { changeSearchText } from "../../reducer/slice/search";
import { Link, useNavigate } from "react-router-dom";
import { changeHeaderState } from "../../reducer/slice/header";
import MyModal from "../model";
import { requestWithToken } from "../../helper/helper";


const DashboardHeader = () => {
  const dispatch = useDispatch();
  const userDetails = useSelector((state) => state.userDetails.value);
  const headerStateValue = useSelector((state) => state.headerState.value);
  const [companyDetails, setCompanyDetails] = useState([]);
  const [coins, setCoins] = useState([]);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [planDetails, setPlanDetails] = useState({});
  const [searchText, setSearchText] = useState("");
  const [isLogedIn, setIsLogedIn] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openCreateTeamMemberPopup, setOpenCreateTeamMemberPopup] =
    useState(false);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [baseURL, setBaseUrl] = useState(process.env.REACT_APP_API_ENDPOINT);
  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem("token")) {
      setIsLogedIn(true);
    }
  }, []);

  const getCompany = async () => {
    const res = await getDashboardDetails();
    if (res?.status) {
      setCompanyDetails(res.data);
      setCoins(res.data.company.coins)

    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "No Permission!",
        confirmButtonText: "Ok",
      }).then((result) => {
        if (result.isConfirmed) {
          localStorage.clear();
          navigate("/login");
        }
      });

    }
  };

  useEffect(() => {
    getCompany();
  }, []);

  useEffect(() => {
    dispatch(changeSearchText(searchText));
  }, [searchText]);

  const handleLogOut = () => {
    localStorage.removeItem("token");
    setIsLogedIn(false);
    navigate("/");
  };
  function FacebookSDKInitializer() {
    useEffect(() => {
      const loadFacebookSDK = () => {
        window.fbAsyncInit = function () {
          FB.init({
            appId: '1030288362049862',
            autoLogAppEvents: true,
            xfbml: true,
            version: 'v20.0'
          });
        };

        const script = document.createElement('script');
        script.src = "https://connect.facebook.net/en_US/sdk.js";
        script.async = true;
        script.defer = true;
        script.crossOrigin = "anonymous";

        document.body.appendChild(script);

        return () => {
          document.body.removeChild(script);
        };
      };
      console.log("Facebook SDK initialized");
      loadFacebookSDK();
    }, []);

    return null;
  }
  const dropdownItems = [
    {
      name: "Profile",
      onClick: () => {
        navigate("/profile");
      },
    },
    {
      name: "Logout",
      onClick: () => {
        setOpenModal(true);
      },
    },
  ];

  const createTeamMember = async (e) => {
    console.log('clicking create team member');
    e.preventDefault();
    try {
      const res = await requestWithToken("POST", "/api/company_user_signup", {
        email: email,
        password: password,
        first_name: firstName,
        last_name: lastName,
        company: companyDetails?.company?.id,
      });
      console.log({ res });

      if (res?.status) {
        setOpenCreateTeamMemberPopup(false);
        setOpenSuccessModal(true);
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "No Permission!",
        })
      }

    } catch (err) {
      console.log(err);
    }
  };
  return (
    <div className="headerbar">
      {headerStateValue?.showDefault ? (
        <div className="teamblock">
          <div className="active-user-block">
            {companyDetails?.user?.slice(0, 4)?.map((value) => {
              return (
                <span className="active-user">
                  {`${value?.first_name[0] ?? value.email[0]}`.toUpperCase()}
                  {`${value?.last_name[0] ?? value.email[1]}`.toUpperCase()}
                </span>
              );
            })}
            {companyDetails?.user?.length > 4 ? (
              <span className="active-user">
                +{companyDetails?.user?.length - 4}
              </span>
            ) : (
              <></>
            )}
          </div>

          {userDetails?.is_companyAdmin ? (
            <div className="addbtnblock">
              <button
                className="btn-theme dotted-border"
                onClick={() => setOpenCreateTeamMemberPopup(true)}
              >
                <img src={teamMemberIcon} alt="" />
                <span>Team Member</span>
              </button>
            </div>
          ) : (
            <></>
          )}
        </div>
      ) : (
        <div className="pageHeading">
          <div className="heading1">{headerStateValue?.headerTitle}</div>
          <div className="bridcrum">
            {headerStateValue?.navigationList.map((value, index) => {
              return (
                <>
                  {value?.url ? (
                    <Link
                      to={value?.url}
                      className={
                        headerStateValue?.navigationList.length - 1 == index
                          ? "active"
                          : ""
                      }
                    >
                      {value.name}{" "}
                    </Link>
                  ) : (
                    <Link
                      // to={value?.url}
                      className={
                        headerStateValue?.navigationList.length - 1 === index
                          ? "active"
                          : ""
                      }
                    >
                      {value.name}
                    </Link>
                  )}
                </>
              );
            })}
          </div>
        </div>
      )}
      {openCreateTeamMemberPopup && (
        <MyModal
          label={"Create a Team Member"}
          titleclass={"title-heading1"}
          show={openCreateTeamMemberPopup}
          handleClose={() => {
            setOpenCreateTeamMemberPopup(false);
          }}
        >
          <form>
            <div className="container-of-modal-team-member">
              <input
                type="text"
                placeholder="First-name"
                className="input-team-member"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              ></input>
              <input
                type="text"
                placeholder="Last-name"
                className="input-team-member"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              ></input>
              <input
                type="email"
                placeholder="Email"
                className="input-team-member"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              ></input>
              <input
                type="password"
                placeholder="Password"
                className="input-team-member"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              ></input>
              <button
                className="btn-theme bg-dark"
                onClick={createTeamMember}
                type="submit"
              >
                Create
              </button>
            </div>
          </form>
        </MyModal>
      )}

      {openSuccessModal && (
        <MyModal
          titleclass={"title-heading1"}
          show={openSuccessModal}
          handleClose={() => {
            setOpenSuccessModal(false);
          }}
        >
          <div className="modelBody-content">
            <div className="del_ttl_custom">
              Successfully created a team member.
            </div>

            <div class="btn-box model_btns">
              <button
                class="btn-theme outline modenBtn"
                onClick={() => {
                  setOpenSuccessModal(false);
                }}
              >
                Ok
              </button>
            </div>
          </div>
        </MyModal>
      )}

      <div className="header-right">
        {headerStateValue?.showDefault ? (
          <>
            <div className="searchbox">
              <input
                type="search"
                name=""
                className="searchinput"
                id=""
                placeholder="Search..."
                value={searchText}
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
              />
              <img src={searchIcon} alt="" className="searchicon" />
            </div>

            <div className="manage-box">
              <div className="coin">
                <img src={coinsIcon} alt="" />
                <span>
                  { coins }
                </span>
              </div>
              {userDetails?.is_companyAdmin ? (
                <button className="btn-theme bg-dark" onClick={()=>{
                  navigate("/settings");
                }}>Manage</button>
              ) : (
                <></>
              )}
            </div>
          </>
        ) : (
          <></>
        )}
      </div>

      <div className="user-login-block">
        <div className="user-login-detail-box">
          <div className="user-img">
            {companyDetails?.company?.logo ? (
              <img src={(baseURL + companyDetails?.company?.logo)} alt="" />
            ) : (
              <img src={teamMemberIcon} alt="" />
            )}
          </div>
          <div className="user-login-detail">
            <span className="welcomebbk">Welcome Back!</span>
            {userDetails?.first_name.length ? (
              <span className="login-username">
                {userDetails?.first_name} {userDetails?.last_name}
              </span>
            ) : (
              <span className="login-username">{userDetails?.email}</span>
            )}

            {openModal && (
              <MyModal
                show={openModal}
                handleClose={() => {
                  setOpenModal(false);
                }}
              >
                <div className="modelBody-content">
                  <div className="del_ttl_custom">
                    Are you sure you want to logout?
                  </div>

                  <div class="btn-box model_btns">
                    <button
                      class="btn-theme outline modenBtn"
                      onClick={() => {
                        setOpenModal(false);
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      class="btn-theme bg-brown color_white modenBtn"
                      onClick={() => {
                        handleLogOut();
                      }}
                    >
                      Confirm
                    </button>
                  </div>
                </div>
              </MyModal>
            )}
          </div>
        </div>

        {isLogedIn && (
          <div className="drpcontainer">
            <BasicDropdown
              items={dropdownItems}
              customClass="custom-dropdown-header"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default DashboardHeader;
