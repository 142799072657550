import { useFormik } from "formik";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { addGuest, editGuest, fetchAccomendationDetailByGuestId, fetchGuestDetails, fetchLogisticDetailsByGuestId } from "../../action/guests";
import showNotification from "../../components/notification/notification";
import { Link, useNavigate, useParams } from "react-router-dom";
import { uploadFile } from "../../action/upload";
import { fetchAllSubEvents, fetchSubEvents } from "../../action/subEvents";
import CustomDropdown from "../../components/customSearchDropdown";
import viewEdit from "../../assets/images/viewEdit.svg";
import { guestValidationSchema } from "./validation";
import { useDispatch } from "react-redux";
import { changeHeaderState } from "../../reducer/slice/header";
import { Loader } from "../../components/loader";

const GuestDetails = ({ closeCanvas }) => {
  const { eventId, guestId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [type, setType] = useState("Form");
  const [eventType, setEventType] = useState("");
  const [loadingGuest, setLoadingGuest] = useState(false);
  const [accomodation, setAccomodation] = useState({
    loading: false,
    data: null
  });
  const [logistics, setLogistics] = useState({
    loading: false,
    data: null
  });

  const [activeTab, setActiveTab] = useState(0)
  const [guestListFile, setGuestListFile] = useState();
  const [subEvents, setSubEvents] = useState([]);
  const addGuestFormik = useFormik({
    initialValues: {
      // company: cpId,
      event: eventId,
      subevent: [],
      name: "",
      email: "",
      contact_number: "",
      contact_number_prefix: "+91",
      whatsapp_verified: false,
      rsvp: "No",
      notes: "",
      hampergift: "",
      salutation: "Mr. ",
      Address: "",
      group: "",
      subgroup: "",
      alternate_contact_number: "",
      alternate_contact_number_prefix: "+91",
      save_the_date_email: false,
      save_the_date_whatsapp: false,
      whatsapp_verified: false,
      wedding_invite: "Pending",
      reception_invite: "Pending",
      to_be_invited_for: "",
    },
    validationSchema: guestValidationSchema,
    onSubmit: async (values) => {
      //   values.name = values.salutation + "" + values.name;
      //   const res = await editGuest(values, eventId, guestDetails.id);
      //   if (res.status) {
      //     closeCanvas();
      //     showNotification("Guest updated successfully", "success");
      //   } else {
      //     showNotification(res.message, "danger");
      //   }
    },
  });

  const uploadGuestListFile = async () => {
    const formData = new FormData();
    formData.append("file", guestListFile);
    formData.append("document_type", "Guestlist");
    formData.append("event_id", eventId);
    const res = await uploadFile(formData);
    if (res.status) {
      closeCanvas();
      showNotification("Guests added successfully", "success");
    }
  };

  const getSubEvents = async () => {
    const res = await fetchAllSubEvents(eventId);
    console.log(res, "jkjk");
    if (res.status) {
      setEventType(res.data.data?.eventDetails?.event_type || "");
      setSubEvents(
        res.data.data.subEventDetails.map((value) => {
          return { value: value.id, label: value.name };
        })
      );
      dispatch(
        changeHeaderState({
          showDefault: false,
          headerTitle: "Guests Management",
          navigationList: [
            { name: "Events", url: "/events" },
            {
              name: res?.data?.data.eventDetails.name,
              url: "",
            },
            {
              name: "Guest Details",
              url: "",
            },
          ],
        })
      );
    }
  };
  const seprateSalutationFromName = (name) => {
    if (name?.startsWith("Mr.")) {
      return "Mr."
    } else if (name?.startsWith("Mrs.")) {
      return "Mrs."
    } else if (name?.startsWith("Ms.")) {
      return "Ms."
    } else {
      return ""
    }
  }

  const seprateNameFromName = (name) => {
    if (name?.startsWith("Mr.")) {
      return name.replace("Mr. ", "")
    } else if (name?.startsWith("Mrs.")) {
      return name.replace("Mrs. ", "")
    } else if (name?.startsWith("Ms.")) {
      return name.replace("Ms. ", "")
    } else {
      return name
    }
  }

  const getGuestDetails = async () => {
    setLoadingGuest(true)
    const guestDetails = await fetchGuestDetails(guestId);
    if (guestDetails?.status) {
      console.log(guestDetails, 'sguestDetails')
      addGuestFormik.setFieldValue("subevent", guestDetails?.data?.sub_events?.length ? guestDetails?.data?.sub_events.map(l => l.subevent) : []);
      addGuestFormik.setFieldValue("rsvp", guestDetails?.data?.rsvp);
      addGuestFormik.setFieldValue(
        "whatsapp_verified",
        guestDetails?.data?.whatsapp_verified
      );
      addGuestFormik.setFieldValue("name", seprateNameFromName(guestDetails?.data?.name));
      addGuestFormik.setFieldValue("salutation", seprateSalutationFromName(guestDetails?.data?.name));
      addGuestFormik.setFieldValue("email", guestDetails?.data?.email);
      addGuestFormik.setFieldValue("Address", guestDetails?.data?.Address);
      addGuestFormik.setFieldValue("group", guestDetails?.data?.group);
      addGuestFormik.setFieldValue("subgroup", guestDetails?.data?.subgroup);
      addGuestFormik.setFieldValue(
        "contact_number",
        guestDetails?.data?.contact_number
      );
      addGuestFormik.setFieldValue("hampergift", guestDetails?.data?.hampergift);
      addGuestFormik.setFieldValue("notes", guestDetails?.data?.notes);
      addGuestFormik.setFieldValue(
        "alternate_contact_number",
        guestDetails?.data?.alternate_contact_number
      );
      addGuestFormik.setFieldValue(
        "save_the_date_email",
        guestDetails?.data?.save_the_date_email
      );
      addGuestFormik.setFieldValue(
        "save_the_date_whatsapp",
        guestDetails?.data?.save_the_date_whatsapp
      );
      addGuestFormik.setFieldValue(
        "wedding_invite",
        guestDetails?.data?.wedding_invite
      );
      addGuestFormik.setFieldValue(
        "reception_invite",
        guestDetails?.data?.reception_invite
      );
      addGuestFormik.setFieldValue(
        "to_be_invited_for",
        guestDetails?.data?.to_be_invited_for
      );
      addGuestFormik.setFieldValue(
        "whatsapp_verified",
        guestDetails?.data?.whatsapp_verified
      );

      setLoadingGuest(false)

    }

  };

  const getAccomodation = async () => {
    setAccomodation((v) => ({
      ...v,
      loading: true
    }))
    try {
      const data = await fetchAccomendationDetailByGuestId(guestId);
      if (data?.status) {
        setAccomodation((v) => ({
          ...v,
          loading: false,
          data: data.data?.[0] || null
        }))

      }
    } catch (error) {
      setAccomodation((v) => ({
        ...v,
        loading: false,
        data: null
      }))
    }

  };
  const getLogistics = async () => {
    setLogistics((v) => ({
      ...v,
      loading: true
    }))
    try {
      const data = await fetchLogisticDetailsByGuestId(guestId);
      if (data?.status) {
        setLogistics((v) => ({
          ...v,
          loading: false,
          data: data.data?.[0] || null
        }))

      }
    } catch (error) {
      setLogistics((v) => ({
        ...v,
        loading: false,
        data: null
      }))
    }

  };

  console.log(logistics, 'fetchAccomendationDetailByGuestId', accomodation)
  useEffect(() => {
    getGuestDetails()
    getAccomodation()
    getLogistics()
  }, [guestId]);

  useEffect(() => {
    getSubEvents();
  }, [eventId]);



  const handleSubevents = (e) => {
    console.log(e.target.checked, "ss", e.target.value);
    if (e.target.checked) {
      addGuestFormik.setFieldValue("subevent", [
        ...addGuestFormik.values.subevent,
        Number(e.target.id),
      ]);
    } else {
      const v = addGuestFormik.values.subevent.filter(
        (el) => el != Number(e.target.id)
      );
      addGuestFormik.setFieldValue("subevent", [...v]);
    }
  };
  const closePanel = (e) => {
    e.preventDefault();
    closeCanvas();
  };
  console.log("addGuestFormik", addGuestFormik.errors, subEvents);
  return (
    <>
      <form onSubmit={addGuestFormik.handleSubmit}>
        <div className="guest-detail-container">
          {/* {!loadingGuest && <div className="radio_btn-box">
            <div className="radio_btn">
              <input
                type="radio"
                id="male"
                name="gender"
                disabled
                checked={addGuestFormik.values.salutation === "Mr."}
                onChange={() =>
                  addGuestFormik.setFieldValue("salutation", "Mr.")
                }
              />
              <label for="male">Mr.</label>
            </div>
            <div className="radio_btn">
              <input
                type="radio"
                id="female"
                name="gender"
                disabled
                checked={addGuestFormik.values.salutation === "Mrs."}
                onChange={() =>
                  addGuestFormik.setFieldValue("salutation", "Mrs.")
                }
              />
              <label for="female">Mrs.</label>
            </div>
            <div className="radio_btn">
              <input
                type="radio"
                id="grl"
                name="gender"
                disabled
                checked={addGuestFormik.values.salutation === "Ms."}
                onChange={() =>
                  addGuestFormik.setFieldValue("salutation", "Ms.")
                }
              />
              <label for="grl">Ms.</label>
            </div>
          </div>} */}
          <div className="row">
            {
              loadingGuest
                ?
                <div className="col-lg-6">
                  <Loader />
                </div>
                :
                <div className="col-lg-6">
                  <div className="card-custom card3">
                    <form>
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="input-box">
                            <div className="input-label">Salutation</div>
                            <div className="input_field">
                              <input
                                name="name"
                                type="text"
                                disabled
                                className="input"
                                value={addGuestFormik.values.salutation}
                                onChange={addGuestFormik.handleChange}
                                placeholder="Enter Salutation"
                              />
                            </div>
                            {/* {addGuestFormik.touched.name &&
                          addGuestFormik.errors.name ? (
                          <p className="error">{addGuestFormik.errors.name}</p>
                        ) : null} */}
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="input-box">
                            <div className="input-label">Guest Name</div>
                            <div className="input_field">
                              <input
                                name="name"
                                type="text"
                                disabled
                                className="input"
                                value={addGuestFormik.values.name}
                                onChange={addGuestFormik.handleChange}
                                placeholder="Guest Name"
                              />
                            </div>
                            {/* {addGuestFormik.touched.name &&
                          addGuestFormik.errors.name ? (
                          <p className="error">{addGuestFormik.errors.name}</p>
                        ) : null} */}
                          </div>
                        </div>

                        <div className="col-lg-3">
                          <div className="input-box">
                            <div className="input-label">Group</div>
                            <div className="input_field">
                              <input
                                name="group"
                                type="text"
                                disabled
                                className="input"
                                value={addGuestFormik.values.group}
                                onChange={addGuestFormik.handleChange}
                                placeholder="Group Name"
                              />
                            </div>
                            {/* {addGuestFormik.touched.group &&
                      addGuestFormik.errors.group ? (
                      <p className="error">{addGuestFormik.errors.group}</p>
                    ) : null} */}
                          </div>
                        </div>

                        <div className="col-lg-3">
                          <div className="input-box">
                            <div className="input-label">Sub Group</div>
                            <div className="input_field">
                              <input
                                name="subgroup"
                                type="text"
                                disabled
                                className="input"
                                value={addGuestFormik.values.subgroup}
                                onChange={addGuestFormik.handleChange}
                                placeholder="Subgroup Name"
                              />
                            </div>
                            {/* {addGuestFormik.touched.subgroup &&
                      addGuestFormik.errors.subgroup ? (
                      <p className="error">{addGuestFormik.errors.subgroup}</p>
                    ) : null} */}
                          </div>
                        </div>
                        <div className="col-lg-3">
                          <div className="input-box">
                            <div className="input-label">Mobile</div>
                            <div className="input_field">
                              <input
                                name="contact_number"
                                type="tel"
                                disabled
                                className="input"
                                placeholder="Add Mobile"
                                value={addGuestFormik.values.contact_number}
                                onChange={addGuestFormik.handleChange}
                                maxLength={10}
                              />
                            </div>
                            {addGuestFormik.touched.contact_number &&
                              addGuestFormik.errors.contact_number ? (
                              <p className="error">
                                {addGuestFormik.errors.contact_number}
                              </p>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-3">
                          <div className="input-box">
                            <div className="input-label">Alt Mobile</div>
                            <div className="input_field">
                              <input
                                name="alternate_contact_number"
                                type="tel"
                                className="input"
                                disabled
                                placeholder="Add Alt Mobile"
                                value={
                                  addGuestFormik.values.alternate_contact_number
                                }
                                onChange={addGuestFormik.handleChange}
                                maxLength={10}
                              />
                            </div>
                            {addGuestFormik.touched.alternate_contact_number &&
                              addGuestFormik.errors.alternate_contact_number ? (
                              <p className="error">
                                {addGuestFormik.errors.alternate_contact_number}
                              </p>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="input-box">
                            <div className="input-label">Email ID</div>
                            <div className="input_field">
                              <input
                                name="email"
                                type="email"
                                disabled
                                className="input"
                                placeholder="Enter Guest Email"
                                value={addGuestFormik.values.email}
                                onChange={addGuestFormik.handleChange}
                              />
                            </div>
                            {addGuestFormik.touched.email &&
                              addGuestFormik.errors.email ? (
                              <p className="error">{addGuestFormik.errors.email}</p>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="input-box">
                            <div className="input-label">Hamper Gift</div>
                            <div className="input_field">
                              <input
                                name="hampergift"
                                disabled
                                type="text"
                                className="input"
                                placeholder="Hampergift"
                                value={addGuestFormik.values.hampergift}
                                onChange={addGuestFormik.handleChange}
                              />
                            </div>
                            {addGuestFormik.touched.hampergift &&
                              addGuestFormik.errors.hampergift ? (
                              <p className="error">{addGuestFormik.errors.hampergift}</p>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="input-box">
                            <div className="input-label">To be invited for</div>
                            <div className="input_field">
                              <input
                                name="to_be_invited_for"
                                type="text"
                                disabled
                                className="input"
                                placeholder="To be invited for"
                                value={addGuestFormik.values.to_be_invited_for}
                                onChange={addGuestFormik.handleChange}
                              />
                            </div>
                            {addGuestFormik.touched.to_be_invited_for &&
                              addGuestFormik.errors.to_be_invited_for ? (
                              <p className="error">
                                {addGuestFormik.errors.to_be_invited_for}
                              </p>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="input-box">
                            <div className="input-label">Wedding Invite</div>
                            <div className="input_field">
                              <select
                                name="wedding_invite"
                                className="input"
                                disabled
                                value={addGuestFormik.values.wedding_invite}
                                onChange={addGuestFormik.handleChange}
                              >
                                {/* <option value="">Select Wedding Invite</option> */}
                                <option value="Sent">Sent</option>
                                <option value="Pending">Pending</option>
                              </select>
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-6">
                          <div className="input-box">
                            <div className="input-label">Reception Invite</div>
                            <div className="input_field">
                              <select
                                name="reception_invite"
                                className="input"
                                disabled
                                value={addGuestFormik.values.reception_invite}
                                onChange={addGuestFormik.handleChange}
                              >
                                {/* <option value="">Select Reception Invite</option> */}
                                <option value="Sent">Sent</option>
                                <option value="Pending">Pending</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="input-box">
                            <div className="input-label">RSVP</div>
                            <div className="input_field">
                              <select
                                name="rsvp"
                                className="input"
                                disabled
                                value={addGuestFormik.values?.rsvp}
                                onChange={addGuestFormik.handleChange}
                              >
                                {/* <option value="">Select RSVP</option> */}
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                                <option value="Maybe">Maybe</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="input-box">
                            <div className="input-label">Address</div>
                            <div className="input_field">
                              {/* <input
                            name="Address"
                            type="text"
                            disabled
                            className="input"
                            placeholder="Enter Guest Name"
                            value={addGuestFormik.values.Address}
                            onChange={addGuestFormik.handleChange}
                          /> */}
                              <textarea
                                name="Address"
                                rows="3"
                                disabled
                                className="input"
                                placeholder="Enter Address"
                                value={addGuestFormik.values.Address}
                                onChange={addGuestFormik.handleChange}
                              />
                            </div>
                            {/* {addGuestFormik.touched.Address &&
                          addGuestFormik.errors.Address ? (
                          <p className="error">
                            {addGuestFormik.errors.Address}
                          </p>
                        ) : null} */}
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="input-box">
                            <div className="input-label">Notes</div>
                            <div className="input_field">
                              <textarea
                                name="notes"
                                rows="3"
                                disabled
                                className="input"
                                placeholder="Notes"
                                value={addGuestFormik.values.notes}
                                onChange={addGuestFormik.handleChange}
                              />
                            </div>
                            {/* <p className="error">Please Select Your Nationality</p> */}
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="input-box">
                            <div className="custom_checkbox mg-1">
                              <input
                                id="f66"
                                name="f66"
                                disabled
                                type="checkbox"
                                checked={addGuestFormik.values.whatsapp_verified}
                                onChange={(e) =>
                                  addGuestFormik.setFieldValue(
                                    "whatsapp_verified",
                                    e.target.checked
                                  )
                                }
                              />
                              <label for="f66">whatsapp verified</label>
                            </div>
                          </div>
                        </div>



                        {eventType == "Wedding" && (
                          <>
                            <div className="col-lg-12">
                              <div className="input-label">Sub-Events</div>

                              {subEvents.map((el, i) => {
                                return (
                                  <div className="col-lg-3" key={`sev-${i + 1}`}>
                                    <div className="custom_checkbox mg-1">
                                      <input
                                        id={el.value}
                                        name="subevents"
                                        type="checkbox"
                                        disabled
                                        onChange={handleSubevents}
                                        checked={addGuestFormik.values?.subevent?.includes(
                                          el.value
                                        )}
                                      />
                                      <label for={el.value}>{el.label}</label>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </>
                        )}
                      </div>
                    </form>
                  </div>
                </div>
            }
            <div className="col-lg-6">
              <div className="card-custom card3">
                <div className="guest_tabs">
                  <div className="tab_content">
                    <div onClick={() => setActiveTab(0)} className={`tab_pane ${activeTab === 0 ? "active" : ""}`}>Logistics</div>
                    <div onClick={() => setActiveTab(1)} className={`tab_pane ${activeTab === 1 ? "active" : ""}`}>Accommodation</div>
                    <div onClick={() => setActiveTab(2)} className={`tab_pane ${activeTab === 2 ? "active" : ""}`}>Communication</div>
                  </div>
                </div>

                {activeTab === 0
                  ?
                  <div className="row mg-t-1">
                    {
                      logistics.loading ? <div className="col-lg-12"><Loader /></div> :
                        <>
                          <div className="col-lg-4">
                            <div className="input-box">
                              <div className="input-label">Pre Stay</div>
                              <div className="input_field">
                                {/* <select
                       name="wedding_invite"
                       className="input">
                       <option value="Sent">Sent</option>
                       <option value="Pending">Pending</option>
                     </select> */}
                                <input className="input" disabled value={logistics.data?.pre_stay || ""} />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div className="input-box">
                              <div className="input-label">Check In Date</div>
                              <div className="input_field">
                                {/* <input
                       name="alternate_contact_number"
                       type="date"
                       className="input"
                       placeholder="Enter Check In Date"
                     /> */}
                                <input className="input" disabled value={logistics.data?.pre_check_in_date ? moment(logistics.data?.pre_check_in_date).format("DD/MM/YYYY") : ""} />

                              </div>
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div className="input-box">
                              <div className="input-label">Arrival Date</div>
                              <div className="input_field">
                                {/* <input
                       name="alternate_contact_number"
                       type="date"
                       className="input"
                       placeholder="Enter Arrival Date"
                     /> */}
                                <input className="input" disabled value={logistics.data?.pre_arrival_date ? moment(logistics.data?.pre_arrival_date).format("DD/MM/YYYY") : ""} />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div className="input-box">
                              <div className="input-label">Pickup From</div>
                              <div className="input_field">
                                {/* <input
                       name="alternate_contact_number"
                       type="text"
                       className="input"
                       placeholder="Enter Pickup From"
                     /> */}
                                <input className="input" disabled value={logistics.data?.pre_pickup_from || ""} />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div className="input-box">
                              <div className="input-label">Flight No.</div>
                              <div className="input_field">
                                {/* <input
                       name="alternate_contact_number"
                       type="tel"
                       className="input"
                       placeholder="Enter"
                     /> */}
                                <input className="input" disabled value={logistics.data?.pre_flight_no || ""} />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div className="input-box">
                              <div className="input-label">Airline</div>
                              <div className="input_field">
                                {/* <input
                       name="alternate_contact_number"
                       type="tel"
                       className="input"
                       placeholder="Enter Airline"
                     /> */}
                                <input className="input" disabled value={logistics.data?.pre_airline || ""} />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div className="input-box">
                              <div className="input-label">Arrival Time</div>
                              <div className="input_field">
                                <input className="input" disabled value={logistics.data?.pre_arrival_time || ""} />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div className="input-box">
                              <div className="input-label">Pickup From</div>
                              <div className="input_field">
                                <input className="input" disabled value={logistics.data?.pre_pickup_from || ""} />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div className="input-box">
                              <div className="input-label">Post Stay</div>
                              <div className="input_field">
                                {/* <select
                       name="wedding_invite"
                       className="input">
                       <option value="Sent">No</option>
                       <option value="Pending">Yes</option>
                     </select> */}
                                <input className="input" disabled value={logistics.data?.post_stay || ""} />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div className="input-box">
                              <div className="input-label">Check Out Date</div>
                              <div className="input_field">
                                <input className="input" disabled value={logistics.data?.post_check_out_date ? moment(logistics.data?.post_check_out_date).format("DD/MM/YYYY") : ""} />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div className="input-box">
                              <div className="input-label">Drop To</div>
                              <div className="input_field">
                                <input className="input" disabled value={logistics.data?.post_drop_to || ""} />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div className="input-box">
                              <div className="input-label">Departure Date</div>
                              <div className="input_field">
                                <input className="input" disabled value={logistics.data?.post_departure_date ? moment(logistics.data?.post_departure_date).format("DD/MM/YYYY") : ""} />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div className="input-box">
                              <div className="input-label">Departure Time</div>
                              <div className="input_field">
                                {/* <input
                       name="alternate_contact_number"
                       type="tel"
                       className="input"
                       placeholder="Enter Departure Time"
                     /> */}
                                <input className="input" disabled value={logistics.data?.post_departure_date ? moment(logistics.data?.post_departure_date).format("hh:mm") : ""} />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div className="input-box">
                              <div className="input-label">Flight No.</div>
                              <div className="input_field">
                                <input className="input" disabled value={logistics.data?.post_flight_no || ""} />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div className="input-box">
                              <div className="input-label">Airline</div>
                              <div className="input_field">
                                <input className="input" disabled value={logistics.data?.post_airline || ""} />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="input-box">
                              <div className="input-label">Exception Transfer</div>
                              <div className="input_field">
                                <input className="input" disabled value={logistics.data?.exception_transfer || ""} />
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-6">
                            <div className="input-box">
                              <div className="input-label">Remarks</div>
                              <div className="input_field">
                                <input className="input" disabled value={logistics.data?.remarks || ""} />
                              </div>
                            </div>
                          </div>
                        </>
                    }
                  </div>
                  : ""}








                {activeTab === 1
                  ?
                  <div className="row mg-t-1">
                    {
                      accomodation.loading ? <div className="col-lg-12"><Loader /></div> :
                        <>
                          <div className="row mg-t-1">
                            <div className="col-lg-8">
                              <div className="input-box">
                                <div className="input-label">Hotel Name</div>
                                <div className="input_field">
                                  {/* <select
                       name="wedding_invite"
                       className="input">
                       <option value="Sent">Sent</option>
                       <option value="Pending">Pending</option>
                     </select> */}
                                  <input className="input" disabled value={accomodation.data?.hotelDetails?.name || ""} />
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-8">
                              <div className="input-box">
                                <div className="input-label">Check In - Check Out</div>
                                <div className="input_field d-flex align-items-center">
                                  {/* <input
                       name="alternate_contact_number"
                       type="date"
                       className="input"
                       placeholder="Enter Check In Date"
                     /> */}
                                  <input className="input" disabled value={accomodation.data?.checkin_date ? moment(accomodation.data?.checkin_date).format("DD MMMM YYYY") : ""} />
                                  -
                                  <input className="input" disabled value={accomodation.data?.checkout_date ? moment(accomodation.data?.checkout_date).format("DD MMMM YYYY") : ""} />

                                </div>
                              </div>
                            </div>
                            <div className="col-lg-4">
                              <div className="input-box">
                                <div className="input-label">Room No.</div>
                                <div className="input_field">
                                  {/* <input
                       name="alternate_contact_number"
                       type="date"
                       className="input"
                       placeholder="Enter Arrival Date"
                     /> */}
                                  <input className="input" disabled value={accomodation?.data?.room_number || ""} />
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-4">
                              <div className="input-box">
                                <div className="input-label">Category</div>
                                <div className="input_field">
                                  {/* <input
                       name="alternate_contact_number"
                       type="text"
                       className="input"
                       placeholder="Enter Pickup From"
                     /> */}
                                  <input className="input" disabled value={accomodation?.data?.room_type || ""} />
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-4">
                              <div className="input-box">
                                <div className="input-label">Billing</div>
                                <div className="input_field">
                                  {/* <input
                       name="alternate_contact_number"
                       type="tel"
                       className="input"
                       placeholder="Enter"
                     /> */}
                                  <input className="input" disabled value={accomodation?.data?.billing || ""} />
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-4">
                              <div className="input-box">
                                <div className="input-label">Room Share</div>
                                <div className="input_field">
                                  {/* <input
                       name="alternate_contact_number"
                       type="tel"
                       className="input"
                       placeholder="Enter Airline"
                     /> */}
                                  <input className="input" disabled value={accomodation?.data?.share_count || ""} />
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-8">
                              <div className="input-box">
                                <div className="input-label">Remarks</div>
                                <div className="input_field">
                                  <input className="input" disabled value={accomodation?.data?.remarks || ""} />
                                </div>
                              </div>
                            </div>

                          </div>
                        </>}
                  </div>
                  : ""}





                {activeTab === 2
                  ?
                  <div className="row mg-t-1">
                    {
                      loadingGuest ? <div className="col-lg-12"><Loader /></div> :
                        <>
                          <div className="row mg-t-1">
                            <div className="col-lg-12">
                              <div className="row mb-4">
                                <div className="col-lg-8">
                                  <div className="input-label">Invitations sent</div>
                                </div>
                                <div className="col-lg-4">
                                  <div className="input-label">Response</div>

                                </div>
                              </div>

                              <div className="row">
                                <div className="col-lg-5">
                                  <div className="input-label">Save The Date Invite</div>

                                  <div className="custom_checkbox-box">
                                    <div className="custom_checkbox mg-1">
                                      <input
                                        id="f5"
                                        name="f5"
                                        disabled
                                        type="checkbox"
                                        checked={addGuestFormik.values.save_the_date_whatsapp}
                                        onChange={(e) =>
                                          addGuestFormik.setFieldValue(
                                            "save_the_date_whatsapp",
                                            e.target.checked
                                          )
                                        }
                                      />
                                      <label for="f5">WhatsApp</label>
                                    </div>
                                    <div className="custom_checkbox mg-1">
                                      <input
                                        id="f6"
                                        name="f6"
                                        disabled
                                        type="checkbox"
                                        checked={addGuestFormik.values.save_the_date_email}
                                        onChange={(e) =>
                                          addGuestFormik.setFieldValue(
                                            "save_the_date_email",
                                            e.target.checked
                                          )
                                        }
                                      />
                                      <label for="f6">Email</label>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-3" />
                                <div className="col-lg-4">
                                  <input className="input" disabled value={"No"} />

                                </div>
                              </div>

                              <div className="row">
                                <div className="col-lg-5">
                                  <div className="input-label">Wedding Invite</div>

                                  <div className="custom_checkbox-box">
                                    <div className="custom_checkbox mg-1">
                                      <input
                                        id="f5"
                                        name="f5"
                                        disabled
                                        type="checkbox"
                                      // checked={addGuestFormik.values.save_the_date_whatsapp}
                                      // onChange={(e) =>
                                      //   addGuestFormik.setFieldValue(
                                      //     "save_the_date_whatsapp",
                                      //     e.target.checked
                                      //   )
                                      // }
                                      />
                                      <label for="f5">WhatsApp</label>
                                    </div>
                                    <div className="custom_checkbox mg-1">
                                      <input
                                        id="f6"
                                        name="f6"
                                        disabled
                                        type="checkbox"
                                      // checked={addGuestFormik.values.save_the_date_email}
                                      // onChange={(e) =>
                                      //   addGuestFormik.setFieldValue(
                                      //     "save_the_date_email",
                                      //     e.target.checked
                                      //   )
                                      // }
                                      />
                                      <label for="f6">Email</label>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-3" />
                                <div className="col-lg-4">
                                  <input className="input" disabled value={addGuestFormik.values.wedding_invite} />
                                </div>
                              </div>


                              <div className="row">
                                <div className="col-lg-5">
                                  <div className="input-label">Reception Invite</div>

                                  <div className="custom_checkbox-box">
                                    <div className="custom_checkbox mg-1">
                                      <input
                                        id="f5"
                                        name="f5"
                                        disabled
                                        type="checkbox"
                                      // checked={addGuestFormik.values.save_the_date_whatsapp}
                                      // onChange={(e) =>
                                      //   addGuestFormik.setFieldValue(
                                      //     "save_the_date_whatsapp",
                                      //     e.target.checked
                                      //   )
                                      // }
                                      />
                                      <label for="f5">WhatsApp</label>
                                    </div>
                                    <div className="custom_checkbox mg-1">
                                      <input
                                        id="f6"
                                        name="f6"
                                        disabled
                                        type="checkbox"
                                      // checked={addGuestFormik.values.save_the_date_email}
                                      // onChange={(e) =>
                                      //   addGuestFormik.setFieldValue(
                                      //     "save_the_date_email",
                                      //     e.target.checked
                                      //   )
                                      // }
                                      />
                                      <label for="f6">Email</label>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-3" />
                                <div className="col-lg-4">
                                  <input className="input" disabled value={addGuestFormik.values.reception_invite} />
                                </div>
                              </div>
                            </div>
                          </div>
                        </>}
                  </div>

                  : ""}

              </div>
            </div>
          </div>
          {/* <div className="btn-box frm-sbmit-grp justify-start">
            <button
              className="btn-theme outline"
              onClick={(e) => closePanel(e)}>
              Cancel
            </button>
            <button
              className="btn-theme bg-dark "
              onClick={(e) => closePanel(e)}>
              Save
            </button>
          </div> */}
        </div>
      </form>
    </>
  );
};

export default GuestDetails;
