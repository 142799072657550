import { requestWithToken } from "../helper/helper";

const fetchGuestList = async (id, pageNo, pageSize, search) => {
  try {
    const response = await requestWithToken(
      "GET",
      `/api/events/${id}/guests/?pageno=${pageNo}&pagesize=${pageSize}&search=${search}`
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchAllGuest = async (eventId) => {
  try {
    const response = await requestWithToken(
      "GET",
      `/api/events/${eventId}/allguests`
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchGuestDetails = async (guestId) => {
  try {
    const response = await requestWithToken(
      "GET",
      `/api/guestdetails/${guestId}`
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchLogisticDetailsByGuestId = async (guestId) => {
  try {
    const response = await requestWithToken(
      "GET",
      `/api/guests/${guestId}/logistics`
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchAccomendationDetailByGuestId = async (guestId) => {
  try {
    const response = await requestWithToken(
      "GET",
      `/api/guest-accomodation-details/${guestId}`
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const addGuest = async (payload, id) => {
  try {
    const response = await requestWithToken(
      "POST",
      `/api/events/${id}/guests/`,
      payload
    );

    if (response?.data) {
      return response.data;
    } else {
      return response.response.data;
    }
  } catch (err) {
    return err.response.data;
  }
};

const editGuest = async (payload, id, questId) => {
  try {
    const response = await requestWithToken(
      "PUT",
      `/api/events/${id}/guests/${questId}`,
      payload
    );

    if (response?.data) {
      return response.data;
    } else {
      return response.response.data;
    }
  } catch (err) {
    return err.response.data;
  }
};

const deleteGuest = async (id, questId) => {
  const response = await requestWithToken(
    "DELETE",
    `/api/events/${id}/guests/${questId}`
  );
  return response
};
export {
  fetchGuestList,
  addGuest,
  fetchGuestDetails,
  editGuest,
  fetchAllGuest,
  deleteGuest,
  fetchLogisticDetailsByGuestId,
  fetchAccomendationDetailByGuestId
};
