import React, { useState, useEffect, useRef } from "react";
import sendMessageIcon from "../../assets/images/send.svg";
import "./RightSideBar.css";

const RightSideBar = ({ conversationId, messages = [], onSendMessage, loading, error, guest = {} }) => {
  const [messageText, setMessageText] = useState("");
  const messageContainerRef = useRef(null);

  // Automatically scroll to the bottom of the messages when new ones are added
  useEffect(() => {
    if (messageContainerRef.current) {
      messageContainerRef.current.scrollTop = messageContainerRef.current.scrollHeight;
    }
  }, [messages]);

  const handleSendMessage = async () => {
    if (messageText.trim()) {
      await onSendMessage(messageText);
      setMessageText("");
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  return (
    <div className="right-side-bar">
      <div className="header">
        <h2>{guest.name || "Guest Name"}</h2>
      </div>

      <div className="messages-container" ref={messageContainerRef}>
        {loading ? (
          <p className="loading">Loading messages...</p>
        ) : error ? (
          <p className="error">{error}</p>
        ) : !conversationId ? (
          <p className="no-conversation">Select a guest to start chatting.</p>
        ) : messages.length > 0 ? (
          messages.map((message, index) => (
            <div
              key={index}
              className={`message ${message.sender === "me" ? "sent" : "received"}`}
            >
              <div className="message-content">{message.message}</div>
              <div className="message-timestamp">
                {new Date(message.timestamp).toLocaleTimeString()}
              </div>
            </div>
          ))
        ) : (
          <p className="no-messages">No messages yet.</p>
        )}
      </div>

      {conversationId && (
        <div className="message-input-container">
          <textarea
            value={messageText}
            onChange={(e) => setMessageText(e.target.value)}
            onKeyDown={handleKeyDown}
            placeholder="Type a message..."
            rows="2"
          />
          <button
            className="send-button"
            onClick={handleSendMessage}
            disabled={loading}
          >
            <img src={sendMessageIcon} alt="Send" />
          </button>
        </div>
      )}
    </div>
  );
};

export default RightSideBar;
