import { API_URL } from "../utils/constants";
import axios from "axios";
import { requestWithToken } from "../helper/helper";

// Loading spinner component styles
const spinnerStyles = `
  .upload-spinner-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }

  .upload-spinner-container {
    background: white;
    padding: 30px;
    border-radius: 8px;
    text-align: center;
    min-width: 300px;
  }

  .upload-spinner {
    width: 50px;
    height: 50px;
    border: 5px solid #f3f3f3;
    border-top: 5px solid #3498db;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    margin: 0 auto 15px;
  }

  .upload-progress-text {
    margin-top: 15px;
    color: #333;
    font-size: 14px;
  }

  .upload-progress-bar {
    width: 100%;
    height: 10px;
    background-color: #f3f3f3;
    border-radius: 5px;
    margin-top: 15px;
    overflow: hidden;
  }

  .upload-progress-bar-fill {
    height: 100%;
    background-color: #3498db;
    transition: width 0.3s ease;
    width: 0%;
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

// Add styles to document head
const styleSheet = document.createElement("style");
styleSheet.innerText = spinnerStyles;
document.head.appendChild(styleSheet);

// Loading spinner component with progress bar
const showSpinner = (message, progress = 0) => {
  // Remove any existing spinners first
  hideSpinner();
  
  const spinner = document.createElement('div');
  spinner.className = 'upload-spinner-overlay';
  spinner.innerHTML = `
    <div class="upload-spinner-container">
      <div class="upload-spinner"></div>
      <div class="upload-progress-text">${message}</div>
      <div class="upload-progress-bar">
        <div class="upload-progress-bar-fill" style="width: ${progress}%"></div>
      </div>
    </div>
  `;
  document.body.appendChild(spinner);
  return spinner;
};

const updateSpinnerProgress = (progress) => {
  const progressBar = document.querySelector('.upload-progress-bar-fill');
  if (progressBar) {
    progressBar.style.width = `${progress}%`;
  }
};

const updateSpinnerText = (message) => {
  const progressText = document.querySelector('.upload-progress-text');
  if (progressText) {
    progressText.textContent = message;
  }
};

const hideSpinner = () => {
  const spinners = document.querySelectorAll('.upload-spinner-overlay');
  spinners.forEach(spinner => spinner.remove());
};

export const uploadFile = async (formData, onProgress) => {
  let spinner = null;
  try {
    spinner = showSpinner('Preparing to upload guest file...', 0);
    const token = localStorage.getItem("token");
    
    // Get the file from formData
    const file = formData.get('file');
    const documentType = formData.get('document_type');
    const eventId = formData.get('event_id');

    // Validate file type
    const validTypes = ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'text/csv', 'application/pdf'];
    if (!validTypes.includes(file.type)) {
      hideSpinner();
      return {
        status: false,
        message: "Please upload a valid Excel, CSV or PDF file"
      };
    }

    // If file is too large (>10MB), show warning
    if (file.size > 10 * 1024 * 1024) {
      hideSpinner();
      return {
        status: false,
        message: "File size should be less than 10MB"
      };
    }

    updateSpinnerText('Uploading guest file...');
    updateSpinnerProgress(0);

    const response = await axios.post(`${API_URL}api/file-upload/`, formData, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'multipart/form-data'
      },
      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        updateSpinnerText(`Uploading file... ${percentCompleted}%`);
        updateSpinnerProgress(percentCompleted);
        if (onProgress) {
          onProgress(percentCompleted);
        }
      },
      timeout: 300000 // 5 minute timeout
    });

    if (response?.data?.status) {
      // For guest list uploads, show processing message
      if (documentType === 'Guestlist') {
        updateSpinnerText('Upload complete. Processing guest list...');
        updateSpinnerProgress(100);
        
        // Give user time to see the completion message
        await new Promise(resolve => setTimeout(resolve, 1000));
        hideSpinner();
        
        return {
          status: true,
          message: response.data.message || "Guest list is being processed. The guests will appear in your list shortly.",
          data: response.data
        };
      }
      return {
        status: true,
        data: response.data
      };
    } else {
      return {
        status: false,
        message: response.data?.message || "Error uploading file"
      };
    }
  } catch (error) {
    console.error("Error uploading file:", error);
    
    // Handle specific error types
    if (error.code === 'ECONNABORTED') {
      return {
        status: false,
        message: "The upload request timed out. Please try again."
      };
    }
    
    if (error.code === 'ECONNREFUSED') {
      return {
        status: false,
        message: "Could not connect to the server. Please check your connection and try again."
      };
    }
    
    if (error.response?.status === 413) {
      return {
        status: false,
        message: "The file is too large to upload."
      };
    }
    
    return {
      status: false,
      message: error.response?.data?.message || "Error uploading file. Please try again."
    };
  } finally {
    if (spinner) {
      hideSpinner();
    }
  }
};

export const uploadLogo = async (formData) => {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.post(
      `${API_URL}api/upload_company_logo`,
      formData,
      {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'multipart/form-data'
        }
      }
    );
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

export const deleteLogo = async () => {
  try {
    const response = await requestWithToken(
      "DELETE",
      "/api/delete_company_logo"
    );
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};
