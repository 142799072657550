import { requestWithToken } from "../helper/helper";

const getUserInfo = async () => {
    try {
      const response = await requestWithToken(
        "GET",
        `api/users/me`
      );
      return response;
    } catch (err) {
      return err.response.data;
    }
};

const updateUserInfo = async (data,user_id) => {
  try {
    const response = await requestWithToken(
      "PUT",
      `api/user/update/`+user_id, 
      data
    );
    return response;
  } catch (err) {
    console.error("Error updating user:", err.response?.data || err.message);
    return err.response?.data || { status: false, error: "An unknown error occurred" };
  }
};


export {
  getUserInfo,
  updateUserInfo
};
