import { useFormik } from "formik";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { addGuest } from "../../action/guests";
import showNotification from "../../components/notification/notification";
import { useNavigate, useParams } from "react-router-dom";
import {
  createFlightBooking,
  createLogistic,
  editFlightBooking,
  fetchFlightBookingDetails,
} from "../../action/logistics";

const FlightEdit = ({ id, closeCanvas }) => {
  const { eventId, guestId } = useParams();
  const [loading, setLoading] = useState(false);
  const addLogisticFormik = useFormik({
    initialValues: {
      guest: guestId,
      flight_number: "",
      airline: "",
      arrival_airport: "",
      departure_airport: "",
      arrival_time: "",
      departure_time: "",
      government_id: "",
      ticket_issued: false,
    },
    validate: (values) => {
      const errors = {};
      if (!values.flight_number) {
        errors.flight_number = "Flight number is required";
      }
      if (!values.airline) {
        errors.airline = "Airline name is required";
      }
      if (!values.arrival_airport) {
        errors.arrival_airport = "Arrival airport is required";
      }
      if (!values.departure_airport) {
        errors.departure_airport = "Departure airport is required";
      }
      if (!values.arrival_time) {
        errors.arrival_time = "Arrival time is required";
      }
      if (!values.departure_time) {
        errors.departure_time = "Departure time is required";
      }

      return errors;
    },
    onSubmit: async (values) => {
      const formData = new FormData();
      formData.append("government_id", values.government_id);
      formData.append("guest", values.guest);
      formData.append("flight_number", values.flight_number);
      formData.append("airline", values.airline);
      formData.append("arrival_airport", values.arrival_airport);
      formData.append("departure_airport", values.departure_airport);
      formData.append("arrival_time", values.arrival_time);
      formData.append("departure_time", values.departure_time);
      formData.append("ticket_issued", values.ticket_issued);

      const res = await editFlightBooking(id, formData);
      showNotification("Flight details updated successfully");
      closeCanvas();
    },
  });

  const getFlightDetails = async () => {
    setLoading(true);
    const res = await fetchFlightBookingDetails(id);
    setLoading(false);
    addLogisticFormik.setFieldValue("flight_number", res.flight_number);
    addLogisticFormik.setFieldValue("airline", res.airline);
    addLogisticFormik.setFieldValue("arrival_airport", res.arrival_airport);
    addLogisticFormik.setFieldValue(
      "arrival_time",
      moment(res.arrival_time).format("YYYY-MM-DD")
    );
    addLogisticFormik.setFieldValue("departure_airport", res.departure_airport);
    addLogisticFormik.setFieldValue(
      "departure_time",
      moment(res.departure_time).format("YYYY-MM-DD")
    );
    addLogisticFormik.setFieldValue("government_id", res.government_id);
    addLogisticFormik.setFieldValue("ticket_issued", res.ticket_issued);
  };

  useEffect(() => {
    getFlightDetails();
  }, [id, eventId, guestId]);

  return (
    <div className="m-3">
      {!loading ? (
        <form onSubmit={addLogisticFormik.handleSubmit}>
          <div class="row">
            <div className="col-lg-6">
              <div class="input-box">
                <div class="input-label">Flight number</div>
                <div class="input_field">
                  <input
                    name="flight_number"
                    type="text"
                    class="input"
                    placeholder="Enter details"
                    value={addLogisticFormik.values.flight_number}
                    onChange={addLogisticFormik.handleChange}
                    onBlur={addLogisticFormik.handleBlur}
                  />
                </div>
              </div>
              {addLogisticFormik.touched.flight_number &&
              addLogisticFormik.errors.flight_number ? (
                <p className="error">
                  {addLogisticFormik.errors.flight_number}
                </p>
              ) : null}
            </div>
            <div className="col-lg-6">
              <label style={{ marginTop: "53px" }}>
                <input
                  name="ticket_issued"
                  type="checkbox"
                  value={addLogisticFormik.values.ticket_issued}
                  checked={addLogisticFormik.values.ticket_issued}
                  onChange={addLogisticFormik.handleChange}
                />{" "}
                Ticket Issued
              </label>
            </div>
            <div class="input-box mt-3">
              <div class="input-label">Airline</div>
              <div class="input_field">
                <input
                  name="airline"
                  type="text"
                  class="input"
                  placeholder="Enter details"
                  value={addLogisticFormik.values.airline}
                  onChange={addLogisticFormik.handleChange}
                  onBlur={addLogisticFormik.handleBlur}
                />
              </div>
            </div>
            {addLogisticFormik.touched.airline &&
            addLogisticFormik.errors.airline ? (
              <p className="error">{addLogisticFormik.errors.airline}</p>
            ) : null}
            <div class="input-box mt-3">
              <div class="input-label">Arrival Airport</div>
              <div class="input_field">
                <input
                  name="arrival_airport"
                  type="text"
                  class="input"
                  placeholder="Enter details"
                  value={addLogisticFormik.values.arrival_airport}
                  onChange={addLogisticFormik.handleChange}
                  onBlur={addLogisticFormik.handleBlur}
                />
              </div>
            </div>
            {addLogisticFormik.touched.arrival_airport &&
            addLogisticFormik.errors.arrival_airport ? (
              <p className="error">
                {addLogisticFormik.errors.arrival_airport}
              </p>
            ) : null}

            <div className="input-box">
              <div class="input-box">
                <div class="input-label">Departure Airport</div>
                <div class="input_field">
                  <input
                    type="text"
                    class="input"
                    placeholder="Enter details"
                    name="departure_airport"
                    value={addLogisticFormik.values.departure_airport}
                    onChange={addLogisticFormik.handleChange}
                  />
                </div>
              </div>
            </div>
            {addLogisticFormik.touched.departure_airport &&
            addLogisticFormik.errors.departure_airport ? (
              <p className="error">
                {addLogisticFormik.errors.departure_airport}
              </p>
            ) : null}
            <div class="input-box mt-3">
              <div class="input-label">Arrival Time</div>
              <div class="input_field">
                <input
                  type="date"
                  class="input"
                  min={moment().format("YYYY-MM-DD")}
                  placeholder="Select Date"
                  name="arrival_time"
                  value={addLogisticFormik.values.arrival_time}
                  onChange={addLogisticFormik.handleChange}
                />
              </div>
            </div>
            {addLogisticFormik.touched.arrival_time &&
            addLogisticFormik.errors.arrival_time ? (
              <p className="error">{addLogisticFormik.errors.arrival_time}</p>
            ) : null}
            <div class="input-box mt-3">
              <div class="input-label">Departure Time</div>
              <div class="input_field">
                <input
                  type="date"
                  class="input"
                  min={moment().format("YYYY-MM-DD")}
                  placeholder="Select Date"
                  name="departure_time"
                  value={addLogisticFormik.values.departure_time}
                  onChange={addLogisticFormik.handleChange}
                />
              </div>
            </div>
            {addLogisticFormik.touched.departure_time &&
            addLogisticFormik.errors.departure_time ? (
              <p className="error">{addLogisticFormik.errors.departure_time}</p>
            ) : null}
          </div>

          <div class="d-flex about-button-center mt-3">
            <button type="submit" class="submit-btn active">
              Submit
            </button>
          </div>
        </form>
      ) : (
        <div className="loader-box">
          <div className="loader"></div>
        </div>
      )}
    </div>
  );
};

export default FlightEdit;
