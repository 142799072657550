import { requestWithToken } from "../helper/helper";

const sendWhatsappMessage = async (payload) => {
    try {
        const response = await requestWithToken("POST", "/api/webhook/", { payload });
        return response;
    } catch (error) {
        console.log(error);
    }
};
const recivedWhatsappMessage = async (payload) => {
    try {
        const response = await requestWithToken("get", "/api/webhook/", { payload });
        return response;
    } catch (error) {
        console.log(error);
    }
};

export { sendWhatsappMessage , recivedWhatsappMessage };