import { usePagination } from "./paginationRange";
import arrow from "../../assets/images/l-cheveron.svg";
const Pagination = (props) => {
  const {
    onPageChange,
    totalCount,
    currentPage,
    siblingCount = 1,
    pageSize,
    changePageSize,
    showPageSizeView = true,
  } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  const onNext = () => {
    if (currentPage === lastPage) {
      onPageChange(currentPage);
    } else {
      onPageChange(currentPage + 1);
    }
  };

  const onPrevious = () => {
    if (currentPage == 1) {
      onPageChange(currentPage);
    } else {
      onPageChange(currentPage - 1);
    }
  };

  let lastPage = paginationRange
    ? paginationRange[paginationRange.length - 1]
    : 20;

  return (
    <div className="row">
      <div className="drBottom">
        {paginationRange.length > 1 ? (
          <div className="pagination">
            <div
              style={{ cursor: currentPage === 1 ? "no-drop" : "pointer" }}
              className={`pagePrev page_arrow ${
                currentPage === 1 ? "disable" : ""
              }`}
              onClick={onPrevious}
            >
              <img src={arrow} alt="" />
            </div>
            <div className="pageNo-box">
              {paginationRange.map((pageNumber) => {
                return pageNumber !== "..." ? (
                  <div
                    className={
                      pageNumber === currentPage ? "pageNo active" : " pageNo"
                    }
                    onClick={() => onPageChange(pageNumber)}
                  >
                    {pageNumber}
                  </div>
                ) : (
                  <p className="dots">...</p>
                );
              })}
            </div>
            <div
              style={{
                cursor: currentPage === lastPage ? "no-drop" : "pointer",
              }}
              onClick={onNext}
              className={`pageNext page_arrow ${
                currentPage === lastPage ? "disable" : ""
              }`}
            >
              <img src={arrow} alt="" />
            </div>
          </div>
        ) : (
          <></>
        )}

        {showPageSizeView && (
          <div className="pageSelect">
            <span className="pageSelectTxt">view</span>
            <select
              className="form-select form-select-sm"
              value={pageSize}
              onChange={(e) => {
                changePageSize(Number(e.target.value));
              }}
            >
              <option value={5}>5</option>
              <option value={10}>10</option>
              <option value={15}>15</option>
              <option value={20}>20</option>
              <option value={25}> 25</option>
            </select>
            {/* <span className="pageSelectTxt">out of 25</span> */}
          </div>
        )}
      </div>
    </div>
  );
};

export default Pagination;

// export default PaginationView;
