import React from "react";

import deleteIcon from "../../assets/images/trash-brown.svg";
import squar from "../../assets/images/squar.svg";
import addBrown from "../../assets/images/add-brown.svg";

const PreWeddingForm = ({
  addEventFormik,
  addMoreFamilyMembers,
  deleteFamilyMembers,
}) => {
  const handleTextInput = (event) => {
    const { name, value } = event.target;
    const regex = /^[a-zA-Z\s]*$/;
    if (regex.test(value)) {
      addEventFormik.setFieldValue(name, value);
    }
  };


  return (
    <>
      <div className="row">
        <h2 className="form-sec-heading">
          <img src={squar} alt="" /> <span>Wedding Details</span>
        </h2>

        <div className="col-lg-4">
          <div className="input-box">
            <div className="input-label">Groom Name</div>
            <div className="input_field">
              <input
                name="preWeddingObj.groom_name"
                value={addEventFormik.values.preWeddingObj?.groom_name || ""}
                onChange={handleTextInput}
                onBlur={addEventFormik.handleBlur}
                type="text"
                className="input"
                placeholder="Enter name"
              />
            </div>
          </div>
        </div>

        <div className="col-lg-4">
          <div className="input-box">
            <div className="input-label">Bride Name</div>
            <div className="input_field">
              <input
                name="preWeddingObj.bride_name"
                value={addEventFormik.values.preWeddingObj?.bride_name || ""}
                onChange={handleTextInput}
                onBlur={addEventFormik.handleBlur}
                type="text"
                className="input"
                placeholder="Enter name"
              />
            </div>
          </div>
        </div>
      </div>

    
      <h2 className="form-sec-heading">
        <img src={squar} alt="" /> <span>Bride Family Details</span>
      </h2>
      <div className="row">
        {(addEventFormik.values?.weddingObj?.bride_family || addEventFormik.values?.bride_family)?.map((value, index) => {
          const isEditable = !value?.title || !value?.value;

          return (
            <div className="col-lg-4" key={index}>
              <div>
                <div className="input-box">
                  <div
                    className="input-label"
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    Member Title {index + 1}{" "}
                    <div>
                      {/* Show delete button only if there is more than one member or if the value is not empty */}
                      {(addEventFormik.values?.weddingObj?.bride_family?.length > 1 || isEditable) && (
                        <img
                          style={{ width: "20px", cursor: "pointer" }}
                          src={deleteIcon}
                          onClick={() => deleteFamilyMembers(1, index, 'wedding')}
                        />
                      )}
                    </div>
                  </div>
                  <div className="input_field">
                    <input
                      name={`preWeddingObj.bride_family[${index}].title`}
                      value={value?.title ?? ""}
                      onChange={handleTextInput}
                      onBlur={addEventFormik.handleBlur}
                      type="text"
                      className="input"
                      placeholder="Eg (Mother, Father)"
                    />
                  </div>
                </div>
              </div>
              <div>
                <div className="input-box">
                  <div className="input-label">Member Name {index + 1}</div>
                  <div className="input_field">
                    <input
                      name={`preWeddingObj.bride_family[${index}].value`}
                      value={value?.value ?? ""}
                      onChange={handleTextInput}
                      onBlur={addEventFormik.handleBlur}
                      type="text"
                      className="input"
                      placeholder="Name"
                    />
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>

      <div className="btnggrp mt-4">
        <button
          className="btn-theme dotted-border"
          type="button"
          onClick={() => addMoreFamilyMembers(1, 'wedding')}
        >
          <img src={addBrown} />
          <span>Add More Bride Family Member</span>
        </button>
      </div>

      <h2 className="form-sec-heading">
        <img src={squar} alt="" /> <span>Groom Family Details</span>
      </h2>
      <div className="row">
        {(addEventFormik.values?.weddingObj?.groom_family || addEventFormik.values?.groom_family)?.map((value, index) => {
          const isEditable = !value?.title || !value?.value;

          return (
            <div className="col-lg-4" key={index}>
              <div>
                <div className="input-box">
                  <div className="input-label" style={{ display: "flex", justifyContent: "space-between" }}>
                    Member Title {index + 1}{" "}
                    <div>
                      {/* Show delete button only if there is more than one member or if the value is not empty */}
                      {(addEventFormik.values?.weddingObj?.groom_family?.length > 1 || isEditable) && (
                        <img
                          style={{ width: "20px", cursor: "pointer" }}
                          src={deleteIcon}
                          onClick={() => deleteFamilyMembers(2, index, 'wedding')}
                        />
                      )}
                    </div>
                  </div>
                  <div className="input_field">
                    <input
                      name={`preWeddingObj.groom_family[${index}].title`}
                      value={value?.title ?? ""}
                      onChange={handleTextInput}
                      onBlur={addEventFormik.handleBlur}
                      type="text"
                      className="input"
                      placeholder="Eg (Mother, Father)"
                    />
                  </div>
                </div>
              </div>
              <div>
                <div className="input-box">
                  <div className="input-label">Member Name {index + 1}</div>
                  <div className="input_field">
                    <input
                      name={`preWeddingObj.groom_family[${index}].value`}
                      value={value?.value ?? ""}
                      onChange={handleTextInput}
                      onBlur={addEventFormik.handleBlur}
                      type="text"
                      className="input"
                      placeholder="Name"
                    />
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>

      <div className="btnggrp mt-4">
        <button
          className="btn-theme dotted-border"
          type="button"
          onClick={() => addMoreFamilyMembers(2, 'wedding')}
        >
          <img src={addBrown} />
          <span>Add More Groom Family Member</span>
        </button>
      </div>
    </>
  );
};

export default PreWeddingForm;
