import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchActiveEvents } from "../../action/events";

export const fetchActiveEventsAsync = createAsyncThunk(
  "events/fetchActiveEvents",
  async (eventType) => {
    const response = await fetchActiveEvents(eventType, 1, 3, "");
    return response.data.objects;
  }
);

const eventSlice = createSlice({
  name: "events",
  initialState: {
    selectedEventType: "",
    activeEvents: [],
  },
  reducers: {
    setSelectedEventType: (state, action) => {
      state.selectedEventType = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchActiveEventsAsync.fulfilled, (state, action) => {
      state.activeEvents = action.payload;
    });
  },
});

export const { setSelectedEventType } = eventSlice.actions;

export default eventSlice.reducer;
