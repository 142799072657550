import { configureStore } from "@reduxjs/toolkit";

import userDetails from "./slice/userDetails";
import changeSearchText from "./slice/search";
import headerState from "./slice/header";
import eventReducer from "./slice/eventSlice";
import guestReducer from "./slice/guestListSlice";

export const store = configureStore({
  reducer: {
    userDetails: userDetails,
    changeSearchText: changeSearchText,
    headerState: headerState,
    events: eventReducer,
    guestList: guestReducer,
  },
});
