import React from "react";
import ReactDOM from "react-dom/client";
import CustomNotification from "./showNotification.js";

const showNotification = (message, type) => {
  const container = document.createElement("div");
  document.body.appendChild(container);

  const root = ReactDOM.createRoot(container);
  root.render(<CustomNotification message={message} type={type} />);

  // Remove the notification from the DOM after a delay (e.g., 3 seconds)
  setTimeout(() => {
    root.unmount();
    document.body.removeChild(container);
  }, 2000);
};

export default showNotification;
