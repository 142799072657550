import { useFormik } from "formik";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { createEvent, getCompanyDetails } from "../../action/events";
import { useNavigate } from "react-router-dom";
import showNotification from "../../components/notification/notification";
import WeddingForm from "../../components/eventForms/weddingForm";
import PreWeddingForm from "../../components/eventForms/preWeddingForm";
import CelebrationForm from "../../components/eventForms/celebrationForm";
import "./eventAdd.css";

import squar from "../../assets/images/squar.svg";
import EventDetailForm from "../../components/eventForms/eventDetailForm";
import SubEventForm from "../../components/eventForms/subEventForm";
import GuestAddForm from "../../components/eventForms/guestAddForm";
import { addGuest } from "../../action/guests";
import { createSubEvent } from "../../action/subEvents";
import { uploadFile } from "../../action/upload";
import Swal from "sweetalert2";
import { getCoinRequirement } from "../../action/coinSettings";
const EventAdd = () => {
  const navigate = useNavigate();
  const [currentStatus, setCurrentStatus] = useState(0);
  const [isWedding, setIsWedding] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [eventCreated, setEventCreated] = useState(false);
  const [createdEventId, setCreatedEventId] = useState(null);
  const [isManualEntry, setIsManualEntry] = useState(false);
  const [stepsValidity, setStepsValidity] = useState({
    0: false,
    1: false,
    2: false
  });

  const [eventUrlType, setEventUrlType] = useState("");
  const [eventDetail, setEventDetails] = useState({});
  const [subEventDetails, setSubEventDetails] = useState([]);
  const [guestDetails, setGuestDetails] = useState([]);
  const [selectedFile, setSelectedFile] = useState("");
  const [showUploadSheet, setShowUploadSheet] = useState(false);

  useEffect(() => {
    const checkCoinRequirements = async () => {
      try {
        const [weddingCoins, preWeddingCoins, celebrationCoins, subEventCoins] = await Promise.all([
          getCoinRequirement("Wedding"),
          getCoinRequirement("PreWedding"), 
          getCoinRequirement("Celebration"),
          getCoinRequirement("SubEvent")
        ]);

        if (!weddingCoins.status || !preWeddingCoins.status || !celebrationCoins.status || !subEventCoins.status) {
          throw new Error("Failed to fetch coin requirements");
        }


        const priceText = `
          <div style="text-align: left; margin-top: 10px;">
            <p><strong>Event Creation Costs:</strong></p>
            <ul style="list-style: none; padding-left: 0;">
              <li>• Wedding Event: ${weddingCoins.data?.data?.required_coins || 1} coins</li>
              <li>• Pre-Wedding Event: ${preWeddingCoins.data?.data?.required_coins || 1} coins</li>
              <li>• Celebration Event: ${celebrationCoins.data?.data?.required_coins || 1} coins</li>
              <li>• Sub Event: ${subEventCoins.data?.data?.required_coins || 1} coins per sub-event</li>
            </ul>
            <p style="margin-top: 10px; color: #666;">
              <i>Note: If you don't have enough coins, please visit the Manage Subscription page.</i>
            </p>
          </div>
        `;

        await Swal.fire({
          title: "Event Creation Costs",
          html: priceText,
          icon: "info",
          showCancelButton: true,
          showDenyButton: true,
          confirmButtonText: "Continue",
          denyButtonText: "Go to Subscription",
          cancelButtonText: "Cancel",
          reverseButtons: true,
          customClass: {
            popup: 'swal-wide',
            content: 'text-left',
            confirmButton: 'swal2-confirm',
            denyButton: 'swal2-deny',
            cancelButton: 'swal2-cancel'
          }
        }).then((result) => {
          if (result.isDenied) {
            navigate("/subscription");
          } else if (result.isDismissed) {
                }
        });

      } catch (error) {
        console.error("Error checking coin requirements:", error);
        Swal.fire({
          title: "Error",
          text: "Unable to fetch coin requirements. Please try again later.",
          icon: "error",
          confirmButtonText: "OK"
        }).then(() => {
            });
      } finally {
      }
    };

    checkCoinRequirements();
  }, [navigate]);

  const createGuest = async (eventId, payload) => {
    try {
      const res = await addGuest(payload, eventId);
      if (res?.status) {
        return { status: true, data: res.data };
      } else {
        await Swal.fire({
          title: "Error",
          text: res?.message || "Failed to add guests",
          icon: "error",
          confirmButtonText: "OK"
        });
        return { status: false };
      }
    } catch (error) {
      await Swal.fire({
        title: "Error",
        text: "An error occurred while adding guests",
        icon: "error",
        confirmButtonText: "OK"
      });
      return { status: false };
    }
  };

  const uploadGuestListFile = async (eventId, file) => {
    try {
      setIsUploading(true);
      const formData = new FormData();
      formData.append("file", file);
      formData.append("event_id", eventId);

      const res = await uploadFile(formData);
            
      if (res?.status) {
        setIsManualEntry(false);
        return { status: true, data: res.data };
      } else {
        const result = await Swal.fire({
          title: "Error",
          text: res?.message || "Failed to upload guest list",
          icon: "error",
          confirmButtonText: "Try Again",
          showCancelButton: true,
          cancelButtonText: "Add Manually"
        });
        
        if (result.isDismissed) {
          // User clicked "Try Again"
          setIsManualEntry(false);
          setShowUploadSheet(true);
          return { status: false };
        } else if (result.isCanceled) {
          // User clicked "Add Manually"
          setSelectedFile("");
          setShowUploadSheet(false);
          setIsManualEntry(true);
          return { status: false };
        }
        return { status: false };
      }
    } catch (error) {
      const result = await Swal.fire({
        title: "Error",
        text: "An error occurred while uploading guest list",
        icon: "error",
        confirmButtonText: "Try Again",
        showCancelButton: true,
        cancelButtonText: "Add Manually"
      });

      if (result.isDismissed) {
        // User clicked "Try Again"
        setIsManualEntry(false);
        setShowUploadSheet(true);
        return { status: false };
      } else if (result.isCanceled) {
        // User clicked "Add Manually"
        setSelectedFile("");
        setShowUploadSheet(false);
        setIsManualEntry(true);
      }
      return { status: false };
    } finally {
      setIsUploading(false);
    }
  };

  const handleFileSelect = (file) => {
    setSelectedFile(file);
    setShowUploadSheet(true);
    setIsManualEntry(false);
  };

  const handleFileUpload = async (file) => {
    if (!createdEventId || !file) return;
    
    try {
      setIsProcessing(true);
      const result = await uploadGuestListFile(createdEventId, file);
      if (result?.status) {
        await Swal.fire({
          title: "Success",
          text: "Guest list uploaded successfully",
          icon: "success",
          confirmButtonText: "OK"
        });
        navigate("/events");
      }
    } catch (error) {
      console.error("Error uploading file:", error);
    } finally {
      setIsProcessing(false);
    }
  };

  const addSubEvents = async (eventId, value) => {
    try {
      let allGuestDetails = [...guestDetails];
      if (value.length) {
        const res = await createSubEvent(eventId, value);
        if (res?.status) {
          res.data.map((mainSub) => {
            guestDetails.map((value, index1) => {
              allGuestDetails[index1].company = eventDetail.company;
              allGuestDetails[index1].event = eventId;

              value.subevent.map((sub, index2) => {
                if (mainSub.name === sub) {
                  allGuestDetails[index1].subevent[index2] = mainSub.id;
                }
              });
            });
          });
        } else {
          await Swal.fire({
            title: "Error",
            text: res?.message || "Failed to create sub-events",
            icon: "error",
            confirmButtonText: "OK"
          });
          return { status: false };
        }
      }

      let finalResult;
      if (selectedFile) {
        finalResult = await uploadGuestListFile(eventId, selectedFile);
      } else {
        finalResult = await createGuest(eventId, allGuestDetails);
      }

      if (finalResult?.status) {
        return { status: true, data: finalResult.data };
      }
      return { status: false };
    } catch (error) {
      await Swal.fire({
        title: "Error",
        text: "An error occurred while creating sub-events",
        icon: "error",
        confirmButtonText: "OK"
      });
      return { status: false };
    }
  };

  const addEvent = async (eventDetail, url) => {
    if (isProcessing) return;
    console.log("eventDetail", eventDetail);
    try {
      setIsProcessing(true);

      // If we already have created an event, use that instead of creating a new one
      let eventId = createdEventId;
      
      if (!eventCreated) {
        const creatingEventDialog = Swal.fire({
          title: "Creating Event",
          html: "Please wait...",
          allowOutsideClick: false,
          allowEscapeKey: false,
          showConfirmButton: false,
          willOpen: () => {
            Swal.showLoading();
          }
        });

        const res = await createEvent(eventDetail, url);
        await creatingEventDialog.close();

        if (!res?.status) {
          await Swal.fire({
            title: "Error",
            text: res?.message || "Failed to create event",
            icon: "error",
            confirmButtonText: "OK"
          });
          return false;
        }
        
        eventId = res.data.id;
        setCreatedEventId(eventId);
        setEventCreated(true);
      }

      let finalResult;
      try {
        if (eventDetail.event_type !== "Wedding") {
          let allGuestDetails = [...guestDetails];
          guestDetails.map((value, index1) => {
            allGuestDetails[index1].company = eventDetail.company;
            allGuestDetails[index1].event = eventId;
          });
          
          if (selectedFile) {
            finalResult = await uploadGuestListFile(eventId, selectedFile);
          } else {
            finalResult = await createGuest(eventId, allGuestDetails);
          }
        } else {
          finalResult = await addSubEvents(eventId, subEventDetails);
        }

        if (finalResult?.status) {
          await Swal.fire({
            title: "Success",
            text: "Event Created Successfully",
            icon: "success",
            confirmButtonText: "OK",
            allowOutsideClick: false,
            allowEscapeKey: false
          });
          navigate("/events");
          return true;
        }
      } catch (error) {
        await Swal.fire({
          title: "Error",
          text: "Failed to process guest information. Please try again.",
          icon: "error",
          confirmButtonText: "OK"
        });
        return false;
      }
    } catch (error) {
      await Swal.fire({
        title: "Error",
        text: "An error occurred while creating the event",
        icon: "error",
        confirmButtonText: "OK"
      });
      return false;
    } finally {
      setIsProcessing(false);
    }
  };

  const finalApi = async () => {
    if (isProcessing) return;
    try {
      const result = await addEvent(eventDetail, eventUrlType);
      if (!result) {
        setSelectedFile("");
        setCurrentStatus(2);
      }
    } catch (error) {
      console.error("Error in finalApi:", error);
    }
  };

  useEffect(() => {
    const handleEventCreation = async () => {
      if (isUploading || isProcessing) return;

      if (!eventCreated && (guestDetails.length || selectedFile)) {
        await finalApi();
      } else if (eventCreated && selectedFile) {
        await handleFileUpload(selectedFile);
      }
    };
    handleEventCreation();
  }, [guestDetails, selectedFile]);

  const handleStepChange = async (step, data, type) => {
    console.log("skipping guest addition before");

    if (data && type == 'skip') {
      console.log("skipping guest addition");
      await addEvent(eventDetail, eventUrlType);
      return;
    }
    
    if (data && type) {
      setEventDetails(data);
      setEventUrlType(type);
      setStepsValidity(prev => ({
        ...prev,
        [currentStatus]: true
      }));
    }
    setCurrentStatus(step);
  };

  const handleTabClick = (step) => {
    if (step < currentStatus) {
      handleStepChange(step);
      return;
    }

    if (step === currentStatus + 1 && stepsValidity[currentStatus]) {
      handleStepChange(step);
      return;
    }
  };

  return (
    <div className="content-body">
      <div className="dashboard_head">
        <div className="dashboard_head-left">
          <h1 className="heading1">Create an Event</h1>
        </div>
      </div>
      <div className="tab-bar-sec">
        <ul className="tab-bar">
          <li 
            className={currentStatus === 0 ? "active" : ""} 
            onClick={() => handleTabClick(0)}
            style={{ cursor: 'pointer' }}
          >
            Event Details
          </li>
          {isWedding && (
            <li 
              className={currentStatus === 1 ? "active" : ""} 
              onClick={() => handleTabClick(1)}
              style={{ 
                cursor: stepsValidity[0] || currentStatus >= 1 ? 'pointer' : 'not-allowed',
                opacity: stepsValidity[0] || currentStatus >= 1 ? 1 : 0.6 
              }}
            >
              Function Details
            </li>
          )}
          <li 
            className={currentStatus === 2 ? "active" : ""} 
            onClick={() => handleTabClick(2)}
            style={{ 
              cursor: (stepsValidity[1] || currentStatus >= 2 || (!isWedding && stepsValidity[0])) ? 'pointer' : 'not-allowed',
              opacity: (stepsValidity[1] || currentStatus >= 2 || (!isWedding && stepsValidity[0])) ? 1 : 0.6 
            }}
          >
            Guest Details
          </li>
        </ul>
      </div>
      <div className="form_data-content">
        <div style={{ display: currentStatus === 0 ? "block" : "none" }}>
          <EventDetailForm
            setIsWedding={setIsWedding}
            changeStep={(step, data, type) => {
              handleStepChange(step, data, type);
              if (data) {
                setStepsValidity(prev => ({
                  ...prev,
                  0: true
                }));
              }
            }}
          />
        </div>
        {isWedding && (
          <div style={{ display: currentStatus === 1 ? "block" : "none" }}>
            <SubEventForm
              changeStep={(step, data, type) => {
                setCurrentStatus(step);
                if (type === 1) {
                  setShowUploadSheet(true);
                } else if (data) {
                  setSubEventDetails(data);
                  setStepsValidity(prev => ({
                    ...prev,
                    1: true
                  }));
                }
              }}
            />
          </div>
        )}
        <div style={{ display: currentStatus === 2 ? "block" : "none" }}>
          <GuestAddForm
            isWedding={isWedding}
            showUploadSheet={showUploadSheet && !isManualEntry}
            mySubEvents={subEventDetails}
            changeStep={(step, data, type) => {
              setCurrentStatus(step);
              if (data && type) {
                if (type === 1) {
                  handleFileSelect(data);
                }
                else if (type == 'skip') {
                  handleStepChange(step, data, 'skip');
                }
                else {
                  setGuestDetails(data);
                }
                setStepsValidity(prev => ({
                  ...prev,
                  2: true
                }));
              }
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default EventAdd;
