// subscriptionService.js or your service file
import { requestWithToken, callStripeWebhook } from "../helper/helper";

// Fetch subscription plans
const getSubscriptionPlans = async () => {
  try {
    const response = await requestWithToken("GET", "api/plans/");
    return response;
  } catch (err) {
    return err.response?.data || { status: false, error: "An unknown error occurred" };
  }
};

// Create a new Stripe checkout session
const createCheckoutSession = async (data) => {
  try {
    const response = await requestWithToken("POST", "api/create-checkout-session/", data);
    return response;
  } catch (err) {
    return err.response?.data || { status: false, error: "An unknown error occurred" };
  }
};

// Example of calling the webhook after a successful payment
const handlePaymentSuccess = async (sessionId) => {
  const success = true; // or false, depending on the payment status
  try {
    const response = await callStripeWebhook(sessionId, success);
    console.log('Webhook triggered successfully:', response);
  } catch (err) {
    console.error('Error triggering webhook:', err);
  }
};

export {
  getSubscriptionPlans,
  createCheckoutSession,
  handlePaymentSuccess,
};
