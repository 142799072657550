import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
  fetchEventDetails,
  getDashboardDetails,
  removeEvent,
} from "../../action/events";
import editIcon from "../../assets/images/help.svg";
import moment from "moment";
import showNotification from "../../components/notification/notification";
import MyModal from "../../components/model";
import { useSelector } from "react-redux";
import { fetchSubEvents } from "../../action/subEvents";
import { Offcanvas } from "react-bootstrap";
import SubEventAdd from "./addSubEvents";
import SubEventEdit from "./editSubEvents";
import Pagination from "../../components/pagination/paginationView";

const SubEventList = () => {
  const { eventId } = useParams();
  const userDetails = useSelector((state) => state.userDetails.value);
  const [eventDetails, setEventDetails] = useState({});
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [loading, setShowLoading] = useState(false);
  const [subEvents, setSubEvents] = useState([]);
  const [showAddSubEvent, setShowAddSubEvent] = useState(false);
  const [showEditSubEvent, setShowEditSubEvent] = useState(false);
  const [selectedSubEvent, setSelectedSubEvent] = useState("");

  const getSubEventsList = async () => {
    setShowAddSubEvent(false);
    setShowEditSubEvent(false);
    setShowLoading(true);
    const res = await fetchSubEvents(eventId, pageNo, pageSize);
    if (res.status) {
      setTotalRecords(res.data.total_items);
      setEventDetails(res.data.data.eventDetails);
      setSubEvents(res.data.data.subEventDetails);
      setShowLoading(false);
    }
  };

  useEffect(() => {
    getSubEventsList();
  }, [pageNo, pageSize]);

  return (
    <div>
      <div class="dashboard_head">
        <div class="dashboard_head-left">
          <div class="dashboard_title">{eventDetails?.name} - Sub Events</div>
        </div>
        {userDetails?.is_companyAdmin ? (
          <div class="dashboard_head-right">
            <button className="submit-btn active" onClick={setShowAddSubEvent}>
              Add Sub Event
            </button>
          </div>
        ) : (
          <></>
        )}
      </div>
      <div class="payout_data-content">
        <div class="payout_table-box">
          <table class="payout_table">
            <thead>
              <tr class="tableHead">
                <th>Name</th>
                <th>Date</th>
                <th>Venue</th>
                <th>Description</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {!loading ? (
                !subEvents?.length ? (
                  <tr>
                    <td colSpan="9" className="text-center">
                      No Sub Events found
                    </td>
                  </tr>
                ) : (
                  subEvents?.map((value) => {
                    return (
                      <tr>
                        <td>{value.name}</td>
                        <td>{moment(value.date).format("DD/MM/YYYY")}</td>
                        <td>{value.venue}</td>
                        <td>{value.description}</td>
                        <td>
                          <button
                            className="submit-btn active"
                            onClick={() => {
                              setSelectedSubEvent(value.id);
                              setShowEditSubEvent(true);
                            }}
                          >
                            <img className="btn-icon" src={editIcon} />
                          </button>
                        </td>
                      </tr>
                    );
                  })
                )
              ) : (
                <tr>
                  <td colSpan={9}>
                    <div className="loader-box">
                      <div className="loader"></div>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      <div className="page-footer">
        <Pagination
          currentPage={pageNo}
          totalCount={totalRecords}
          pageSize={pageSize}
          onPageChange={(page) => setPageNo(page)}
          changePageSize={(page) => {
            setPageSize(page);
            setPageNo(1);
          }}
        />
      </div>

      <Offcanvas
        show={showAddSubEvent}
        onHide={() => {
          setShowAddSubEvent(false);
        }}
        placement="end"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Add Sub Event</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <SubEventAdd closeCanvas={getSubEventsList} />
        </Offcanvas.Body>
      </Offcanvas>

      <Offcanvas
        show={showEditSubEvent}
        onHide={() => {
          setShowEditSubEvent(false);
        }}
        placement="end"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Edit Sub Event</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <SubEventEdit closeCanvas={getSubEventsList} id={selectedSubEvent} />
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default SubEventList;
