import React from "react";
import Slider from "react-slick";
import moment from "moment";
import defaultEventIcon from "../../assets/images/eventimg1.jpg";
import starIcon from "../../assets/images/star.svg";
import guestIcon from "../../assets/images/guest.svg";
import logisticIcon from "../../assets/images/logis.svg";
import acomoIcon from "../../assets/images/acomo.svg";
import communicationIcon from "../../assets/images/communication.svg";
import calendarIcon from "../../assets/images/calendor.svg";
import locationIcon from "../../assets/images/location.svg";
import { Link } from "react-router-dom";

const SliderCard = ({ activeEvents, colClass = "col-md-12" }) => {
  console.log("hi from slider card");
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    className: "slider",
    arrows: true,
    autoplay: true,
    autoplaySpeed: 3000,
    cssEase: "linear",
    fade: true, 
    pauseOnHover: true, 
    swipeToSlide: true, 
    draggable: true, 
  };
  console.log(activeEvents);
  return (
    <div className={colClass}>
      <div className="slider-container">
        <Slider {...settings}>
          {activeEvents.map((value) => (
            <div key={value.id} className="card-custom card2">
              <Link to={`/event-details/${value.id}`}>
                <div className="row">
                  <div className="col-md-8">
                    <div className="card2-leftbox">
                      <div className="card2-infobx-left">
                        <img src={defaultEventIcon} className="feimg" alt="Event" />
                      </div>
                      <div className="card2-infobx-right">
                        <div className="headingbx">
                          <div className="heading4">{value.event_type}</div>
                          <h3 className="heading3">{value.name}</h3>
                        </div>
                        <ul className="li-with-icon">
                          <li>
                            <span className="feaicon">
                              <img src={calendarIcon} alt="Calendar" />
                            </span>
                            <p>{moment(value.date).format("DD-MM MMM YYYY, hh:mm a")}</p>
                          </li>
                          <li>
                            <span className="feaicon">
                              <img src={locationIcon} alt="Location" />
                            </span>
                            <p>{value.location}</p>
                          </li>
                          <li>
                            <span className="feaicon">
                              <img src={starIcon} alt="Location" />
                            </span>
                            <p>{value.venue}</p>
                          </li>
                          <li>
                            {value.event_type === "Wedding" && (
                              <span className="feaicon">
                                <img src={starIcon} alt="Star" />
                              </span>
                            )}
                            <p>
                              {value?.event_object?.sub_events
                                ?.slice(0, 2)
                                ?.map((subEvent, index) => {
                                  return `${subEvent.name}${index !== 1 ? "," : ""} `;
                                })}
                              {value?.event_object?.sub_events?.length > 2 && (
                                <span className="moreitem">
                                  +{value?.event_object?.sub_events?.length - 2} More
                                </span>
                              )}
                            </p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 d-flex align-items-center">
                    <div className="featurebx">
                      <ul>
                        <li>
                          <Link
                            to={`/guests/${value?.id}/${value?.company}`}
                            className="featureLink"
                          >
                            <span className="feaicon">
                              <img src={guestIcon} alt="Guest Management" />
                            </span>
                            <span>Guest Management</span>
                          </Link>
                        </li>
                        <li>
                          <Link to={`/logistics/${value?.id}`}>
                            <span className="feaicon">
                              <img src={logisticIcon} alt="Logistics" />
                            </span>
                            <span>Logistics</span>
                          </Link>
                        </li>
                        <li>
                          <Link to={`/hotels/${value?.id}`}>
                            <span className="feaicon">
                              <img src={acomoIcon} alt="Accommodation" />
                            </span>
                            <span>Accommodation</span>
                          </Link>
                        </li>
                        <li>
                          <Link to={`/communication/${value?.id}`}>
                            <span className="feaicon">
                              <img src={communicationIcon} alt="Communication" />
                            </span>
                            <span>Communication</span>
                          </Link>
                        </li>
                        <li>
                          <Link to={`/conversation/${value?.id}`}>
                            <span className="feaicon">
                              <img src={communicationIcon} alt="Conversation" />
                            </span>
                            <span>Conversation</span>
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default SliderCard;
