import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { SideNav } from "../components/sideNav";
import { fetchUserDetails } from "../action/auth";
import { addUserDetails } from "../reducer/slice/userDetails";
import { useDispatch } from "react-redux";
import DashboardHeader from "../components/headers/dahsboardHeader";

export const ProtectedRoute = (props) => {
  const { children } = props;
  // const tokenOfWhatsApp = Cookies.get('whatsapp_token');
  let location = useLocation();
  const dispatch = useDispatch();
  const token = localStorage.getItem("token");

  // useEffect(() => {
  //   // const tokenOfWhatsApp = getTokenOfWhatsApp();
  //   // if (tokenOfWhatsApp) {
  //   //   const messageData = {
  //   //     messaging_product: "whatsapp",
  //   //     to: "PHONE_NUMBER",
  //   //     type: "text",
  //   //     text: {
  //   //       preview_url: false,
  //   //       body: "Hello,  message",
  //   //     },
  //   //   };
  //   // }
  // }, [dispatch]);

  const getUserDetails = async () => {
    const res = await fetchUserDetails();
    dispatch(addUserDetails(res));
  };

  if (token) {
    getUserDetails();
  }

  if (!token) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return (
    <div class="page-container">
      <SideNav />
      <div className="content-body">
        {location.pathname != "/add-event" ? <DashboardHeader /> : <></>}

        {children}
      </div>
    </div>
  );
};
