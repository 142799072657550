import { requestWithToken } from "../helper/helper";

const createSubEvent = async (eventId, payload) => {
  try {
    const response = await requestWithToken(
      "POST",
      `/api/events/${eventId}/subevents/`,
      payload
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const updateSubEvent = async (eventId, subEventId, payload) => {
  try {
    const response = await requestWithToken(
      "PUT",
      `/api/events/${eventId}/subevents/${subEventId}/`,
      payload
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchSubEvents = async (eventId, pageNo, pageSize) => {
  try {
    const response = await requestWithToken(
      "GET",
      `/api/events/${eventId}/subevents/?pageno=${pageNo}&pagesize=${pageSize}`
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchAllSubEvents = async (eventId) => {
  try {
    const response = await requestWithToken(
      "GET",
      `/api/events/${eventId}/allsubevents`
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchSubEventSummary = async (eventId) => {
  try {
    const response = await requestWithToken(
      "GET",
      `/api/subeventsummarry/${eventId}/`
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchSubEventsDetails = async (eventId, subEventId) => {
  try {
    const response = await requestWithToken(
      "GET",
      `/api/events/${eventId}/subevents/${subEventId}/`
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

export {
  createSubEvent,
  fetchSubEvents,
  fetchSubEventsDetails,
  updateSubEvent,
  fetchAllSubEvents,
  fetchSubEventSummary,
};
