import * as yup from "yup";

export const guestValidationSchema = yup.object({
  name: yup
    .string()
    .required("Name is required")
    .min(2, "Name must be at least 2 characters")
    .max(50, "Name must not exceed 50 characters"),
  
  email: yup
    .string()
    .required("Email is required")
    .email("Please enter a valid email address"),
  
  contact_number: yup
    .string()
    .required("Mobile number is required")
    .min(10, "contact number must be at least 10 digits"),
  
  alternate_contact_number: yup
    .string()
    .nullable()
    .min(10, "Alternate contact number must be at least 10 digits")
    .transform((value) => (value === "" ? null : value)),
  
  Address: yup
    .string()
    .required("Address is required")
    .min(5, "Address must be at least 5 characters"),
  
  group: yup
    .string()
    .required("Group is required")
    .min(2, "Group must be at least 2 characters"),
  
  subgroup: yup
    .string()
    .required("Subgroup is required")
    .min(2, "Subgroup must be at least 2 characters"),
  
  notes: yup
    .string()
    .max(500, "Notes must not exceed 500 characters"),
  
  hampergift: yup
    .string()
    .max(100, "Hamper gift must not exceed 100 characters"),
  
  to_be_invited_for: yup
    .string()
    .required("Please specify who this guest is to be invited for"),
  
  wedding_invite: yup
    .string()
    .oneOf(["Sent", "Pending"], "Invalid wedding invite status")
    .required("Wedding invite status is required"),
  
  reception_invite: yup
    .string()
    .oneOf(["Sent", "Pending"], "Invalid reception invite status")
    .required("Reception invite status is required"),
  
  rsvp: yup
    .string()
    .oneOf(["Yes", "No", "Maybe"], "Invalid RSVP status")
    .required("RSVP status is required"),
  
  salutation: yup
    .string()
    .nullable(),
});



