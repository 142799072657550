import { requestWithToken, requestWithoutToken } from "../helper/helper";
import Swal from "sweetalert2";

const fetchUserPermissions = async () => {
  try {
    Swal.fire({
      title: "Loading",
      text: "Please wait",
      allowOutsideClick: false,
      onBeforeOpen: () => {
        Swal.showLoading();
      },
    });
    const response = await requestWithToken("GET", "/api/permissions/");
    Swal.close();
    return response.data;
  } catch (err) {
    Swal.close();
    return err.response.data;
  }
};


// Update permissions for a specific user
const updateUserPermissions = async (user_id, permissions) => {
  try {
    const payload = {
      user_id: user_id,
      permissions: permissions, // permissions is an object with permission names as keys and booleans as values
    };

    Swal.fire({
      title: "Loading",
      text: "Please wait",
      allowOutsideClick: false,
      onBeforeOpen: () => {
        Swal.showLoading();
      },
    });
    const response = await requestWithToken("POST", `/api/permissions/`, {
      payload
    });
    Swal.close();

    return { status: true, data: response.data };
  } catch (err) {
    console.error("Error:", err);
    Swal.close();
    return { status: false, error: err.message };
  }
};

export { fetchUserPermissions, updateUserPermissions };
