import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import moment from "moment";
import {
  deleteLogistic,
  fetchAllLogisticList,
  fetchEventLogisticList,
} from "../../action/logistics";
import printIcon from "../../assets/images/print.svg";
import addBrown from "../../assets/images/add-brown.svg";
import CustomSearchBox from "../../components/customSearchBox";
import CustomSelectWithIcon from "../../components/CustomSelectWithIcon";
import Pagination from "../../components/pagination/paginationView";
import editIcon from "../../assets/images/edit-brown.svg";
import eyeIcon from "../../assets/images/eye-brown.svg";
import trashIcon from "../../assets/images/trash-brown.svg";
import { Offcanvas } from "react-bootstrap";
import LogisticAdd from "./logisticsAdd";
import { useDispatch } from "react-redux";
import { changeHeaderState } from "../../reducer/slice/header";
import LogisticEdit from "./logisticEdit";
import LogisticDetails from "./logisticDetails";
import MyModal from "../../components/model";
import showNotification from "../../components/notification/notification";
import CustomSelectWithIconGuest from "../../components/CustomSelectWithIconGuest";
import { fetchEventDetails } from "../../action/events";
import { Loader } from "../../components/loader";

const AllLogisticList = () => {
  const { eventId } = useParams();
  const dispatch = useDispatch();
  const [logisticDetails, setLogisticDetails] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editLogisticCanvas, setEditLogisticCanvas] = useState(false);
  const [viewLogisticCanvas, setViewLogisticCanvas] = useState(false);
  const [selectedLogistic, setSelectedLogistic] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedLogisticDetails, setSelectedLogisticDetails] = useState({});
  const [search, setSearch] = useState("");
  const getAllLogisticList = async () => {
    setLoading(true);
    setShow(false);
    setEditLogisticCanvas(false);
    const prefixKeys = (obj, prefix) => {
      const newObj = {};
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          const newKey = prefix + key;
          newObj[newKey] = obj[key];
        }
      }
      return newObj;
    }
    const res = await fetchEventLogisticList(eventId, pageNo, pageSize, search);
    if (res.status) {
      setLogisticDetails(res.data.objects?.map(el => {
        const gdata = prefixKeys(el.guest_data, 'guest_data.');
        return {
          ...el,
          ...gdata
        }
      }));
      setTotalRecords(res.data.total_items);
      setLoading(false);
    }
  };

  const getEventDetails = async () => {
    const res = await fetchEventDetails(eventId);
    if (res.status) {
      dispatch(
        changeHeaderState({
          showDefault: false,
          headerTitle: "Logistic Management",
          navigationList: [
            { name: "Events", url: "/events" },
            {
              name: res.data.name,
              url: "",
            },
            {
              name: "Logistic Management",
              url: "",
            },
          ],
        })
      );
    }
  };

  useEffect(() => {
    getEventDetails();
  }, []);

  useEffect(() => {
    getAllLogisticList();
  }, [pageNo, pageSize, search]);

  const removeLogistic = async (id) => {
    const res = await deleteLogistic(id);
    setShowDeleteModal(false);
    getAllLogisticList();
    showNotification("Logistic deleted successfully", "success");
  };

  const handleSort = (setData, sortType, keyName) => {
    if (sortType === 'asc') {
      setColumns((c) => {
        for (let x of c) {
          x.sortType = '';
          if (x.keyName === keyName) {
            x.sortType = 'dsc';
          }
        }
        return [...c]
      })

      setData((f) => {
        f.sort((a, b) => {
          if (!a[keyName]) {
            return 1;
          }
          if (!b[keyName]) {
            return -1;
          }
          if (a[keyName].toString().toLowerCase() < b[keyName].toString().toLowerCase()) {
            return 1;
          }
          if (a[keyName].toString().toLowerCase() > b[keyName].toString().toLowerCase()) {
            return -1;
          }
          return 0;
        })
        return [...f]
      })
    } else if (sortType === 'dsc') {
      setColumns((c) => {
        for (let x of c) {
          x.sortType = '';
          if (x.keyName === keyName) {
            x.sortType = 'asc';
          }
        }
        return [...c]
      })
      setData((f) => {
        f.sort((a, b) => {
          if (!a[keyName]) {
            return -1;
          }
          if (!b[keyName]) {
            return 1;
          }
          if (a[keyName].toString().toLowerCase() < b[keyName].toString().toLowerCase()) {
            return -1;
          }
          if (a[keyName].toString().toLowerCase() > b[keyName].toString().toLowerCase()) {
            return 1;
          }
          return 0;
        })
        return [...f]
      })
    } else {
      setColumns((c) => {
        for (let x of c) {
          x.sortType = '';
          if (x.keyName === keyName) {
            x.sortType = 'asc';
          }
        }
        return [...c]
      })
      setData((f) => {
        f.sort((a, b) => {
          if (!a[keyName]) {
            return -1;
          }
          if (!b[keyName]) {
            return 1;
          }
          if (a[keyName].toString().toLowerCase() < b[keyName].toString().toLowerCase()) {
            return -1;
          }
          if (a[keyName].toString().toLowerCase() > b[keyName].toString().toLowerCase()) {
            return 1;
          }
          return 0;
        })
        return [...f]
      })
    } 
  }
  const [columns, setColumns] = useState([
    {
      name: "Guest Name",
      keyName: "guest_data.name",
      locked: true,
      show: true,
      sort: true,
      sortType: "", // asc or dsc,
      handleSort: (data, sortType) => handleSort(data, sortType, "guest_data.name"),
      render: (v) => <div className="roundedcell">{v || "-"}</div>
    },
    {
      name: "Travel Mode",
      keyName: "travel_mode",
      locked: true,
      show: true,
      sort: true,
      sortType: "", // asc or dsc,
      handleSort: (data, sortType) => handleSort(data, sortType, "travel_mode"),
      render: (v) => <div className="roundedcell">{v || "-"}</div>
    },
    {
      name: "Team",
      keyName: "team",
      locked: false,
      show: true,
      sort: true,
      sortType: "", // asc or dsc,
      handleSort: (data, sortType) => handleSort(data, sortType, "team"),
      render: (v) => <div className="roundedcell">{v || "-"}</div>
    },
    {
      name: "Group",
      keyName: "group",
      locked: false,
      show: true,
      sort: true,
      sortType: "", // asc or dsc,
      handleSort: (data, sortType) => handleSort(data, sortType, "group"),
      render: (v) => <div className="roundedcell">{v || "-"}</div>
    },
    {
      name: "Address",
      keyName: "Address",
      locked: false,
      show: true,
      sort: true,
      sortType: "", // asc or dsc,
      handleSort: (data, sortType) => handleSort(data, sortType, "Address"),
      render: (v) => <div className="roundedcell">{v || "-"}</div>
    },
    {
      name: "ID Card",
      keyName: "card_number",
      locked: false,
      show: true,
      sort: true,
      sortType: "", // asc or dsc,
      handleSort: (data, sortType) => handleSort(data, sortType, "card_number"),
      render: (v) => <div className="roundedcell">{v || "-"}</div>
    },
    {
      name: "Exception Transfer",
      keyName: "exception_transfer",
      locked: false,
      show: true,
      sort: true,
      sortType: "", // asc or dsc,
      handleSort: (data, sortType) => handleSort(data, sortType, "exception_transfer"),
      render: (v) => v || "-"
    },
    {
      name: "Remarks",
      keyName: "remarks",
      locked: false,
      show: true,
      sort: true,
      sortType: "", // asc or dsc,
      handleSort: (data, sortType) => handleSort(data, sortType, "remarks"),
      render: (v) => <div>{v || "-"}</div>
    },
    {
      name: "Pre Stay",
      keyName: "pre_stay",
      locked: false,
      show: true,
      sort: true,
      sortType: "", // asc or dsc,
      handleSort: (data, sortType) => handleSort(data, sortType, "pre_stay"),
      render: (v) => {
        return (
          <>
            {v === "Yes" ? <div className="statusbtn green">{v}</div> : <div className="statusbtn red">{v}</div>}
          </>
        );
      },
    },
    {
      name: "Pre Check In Date",
      keyName: "pre_check_in_date",
      locked: false,
      show: true,
      sort: true,
      sortType: "", // asc or dsc,
      handleSort: (data, sortType) => handleSort(data, sortType, "pre_check_in_date"),
      render: (v) => {
        return (
          <>
            {v ? moment(v).format("DD/MM/YYYY") : "-"}
          </>
        );
      },
    },
    {
      name: "Pre Arrival Date",
      keyName: "pre_arrival_date",
      locked: false,
      show: true,
      sort: true,
      sortType: "", // asc or dsc,
      handleSort: (data, sortType) => handleSort(data, sortType, "pre_arrival_date"),
      render: (v) => {
        return (
          <>
            {v ? moment(v).format("DD/MM/YYYY") : "-"}
          </>
        );
      },
    },
    {
      name: "Pre Pickup From",
      keyName: "pre_pickup_from",
      locked: false,
      show: true,
      sort: true,
      sortType: "", // asc or dsc,
      handleSort: (data, sortType) => handleSort(data, sortType, "pre_pickup_from"),
      render: (v) => v || "-",
    },
    {
      name: "Pre Flight No.",
      keyName: "pre_flight_no",
      locked: false,
      show: true,
      sort: true,
      sortType: "", // asc or dsc,
      handleSort: (data, sortType) => handleSort(data, sortType, "pre_flight_no"),
      render: (v) => v || "-",
    },
    {
      name: "Pre Airline",
      keyName: "pre_airline",
      locked: false,
      show: true,
      sort: true,
      sortType: "", // asc or dsc,
      handleSort: (data, sortType) => handleSort(data, sortType, "pre_airline"),
      render: (v) => v || "-",
    },
    {
      name: "Pre Airline Time",
      keyName: "pre_arrival_time",
      locked: false,
      show: true,
      sort: true,
      sortType: "", // asc or dsc,
      handleSort: (data, sortType) => handleSort(data, sortType, "pre_arrival_time"),
      render: (v) => v || "-",
    },
    {
      name: "Pre Arrival From",
      keyName: "pre_arrival_from",
      locked: false,
      show: true,
      sort: true,
      sortType: "", // asc or dsc,
      handleSort: (data, sortType) => handleSort(data, sortType, "pre_arrival_from"),
      render: (v) => v || "-",
    },
    {
      name: "Post stay",
      keyName: "post_stay",
      locked: false,
      show: true,
      sort: true,
      sortType: "", // asc or dsc,
      handleSort: (data, sortType) => handleSort(data, sortType, "post_stay"),
      render: (v) => v || "-",
    },
    {
      name: "Post Checkout Date",
      keyName: "post_check_out_date",
      locked: false,
      show: true,
      sort: true,
      sortType: "", // asc or dsc,
      handleSort: (data, sortType) => handleSort(data, sortType, "post_check_out_date"),
      render: (v) => {
        return (
          <>
            {v ? moment(v).format("DD/MM/YYYY") : "-"}
          </>
        );
      },
    },
    {
      name: "Post Drop To",
      keyName: "post_drop_to",
      locked: false,
      show: true,
      sort: true,
      sortType: "", // asc or dsc,
      handleSort: (data, sortType) => handleSort(data, sortType, "post_drop_to"),
      render: (v) => v || "-",
    },
    {
      name: "Post Departure Date",
      keyName: "post_departure_date",
      locked: false,
      show: true,
      sort: true,
      sortType: "", // asc or dsc,
      handleSort: (data, sortType) => handleSort(data, sortType, "post_departure_date"),
      render: (v) => {
        return (
          <>
            {v ? moment(v).format("DD/MM/YYYY") : "-"}
          </>
        );
      },
    },
    {
      name: "Post Flight No.",
      keyName: "post_flight_no",
      locked: false,
      show: true,
      sort: true,
      sortType: "", // asc or dsc,
      handleSort: (data, sortType) => handleSort(data, sortType, "post_flight_no"),
      render: (v) => v || "-",
    },
    {
      name: "Post Airline",
      keyName: "post_airline",
      locked: false,
      show: true,
      sort: true,
      sortType: "", // asc or dsc,
      handleSort: (data, sortType) => handleSort(data, sortType, "post_airline"),
      render: (v) => v || "-",
    },
    {
      name: "Car Type",
      keyName: "car_type",
      locked: false,
      show: true,
      sort: true,
      sortType: "", // asc or dsc,
      handleSort: (data, sortType) => handleSort(data, sortType, "car_type"),
      render: (v) => v || "-",
    },
    {
      name: "Car Number",
      keyName: "car_number",
      locked: false,
      show: true,
      sort: true,
      sortType: "", // asc or dsc,
      handleSort: (data, sortType) => handleSort(data, sortType, "car_number"),
      render: (v) => v || "-",
    },
    {
      name: "Driver Info",
      keyName: "driver_info",
      locked: false,
      show: true,
      sort: true,
      sortType: "", // asc or dsc,
      handleSort: (data, sortType) => handleSort(data, sortType, "driver_info"),
      render: (v) => v || "-",
    },
    {
      name: "Travel Own Arrangement",
      keyName: "travel_own_arrangement",
      locked: false,
      show: true,
      sort: true,
      sortType: "", // asc or dsc,
      handleSort: (data, sortType) => handleSort(data, sortType, "travel_own_arrangement"),
      render: (v) => {
        return (
          <>
            {v ? <div className="statusbtn green">Yes</div> : <div className="statusbtn red">No</div>}
          </>
        );
      },
    },
  ]);

  const lockedCol = columns.filter((el) => el.locked);
  const unlockedCol = columns.filter((el) => !el.locked);

  return (
    <div>
      <div class="dashboard_head">
        {/* <h5>
          {eventDetails.event_type} - {eventDetails?.name}
        </h5> */}

        <div class="searchbar-box">
          {/* <div class="searchbar-box-left">
            <div class="searchbar-box-left_content">
              <CustomSelect extraclass="w-100" />
              <button class="btn-theme bg-dark">Apply</button>
            </div>
          </div> */}
          <div class="searchbar-box-mid">
            <div class="searchbar-box-mid_content-left">
              <CustomSearchBox
                search={search}
                placeholder="Search"
                setSearch={setSearch}
              />
            </div>
            <div class="searchbar-box-mid_content-right">
              <CustomSelectWithIconGuest
                columns={columns}
                setColumns={setColumns}
              />
            </div>
          </div>
          <div class="searchbar-box-right">
            <div class="searchbar-box-right-content">
              {/* <button className="iconbtn bg-dark">
                <img src={uploadIcon} alt="" />
              </button> */}
              <button
                className="iconbtn"
                onClick={() => {
                  window.print();
                }}
              >
                <img src={printIcon} alt="" />
              </button>

              <button className="btn-theme dotted-border" onClick={setShow}>
                <img src={addBrown} />
                <span>Add Logistic</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="payout_data-content">
        <div class="custom-table-container custom-scroll-bar custom-scroll-bar2">
        {
            !loading && !logisticDetails?.length
              ?
              <div colSpan={20} className="text-center">No data found</div>
              :
              ""
          }
          {
            loading
              ?
              <Loader />
              :
              ""
          }
          {
            !loading && logisticDetails.length
            ?
            <table class="custom-table">
            <thead>
              <tr class="tableHead">
                {/* <th class="fixed-col">
                  <div className="roundedcell">Guest Name</div>
                </th>
                <th class="fixed-col">
                  <div className="roundedcell">Travel Mode</div>
                </th>
                <th>Team</th>
                <th>Group</th>
                <th>Address</th>
                <th>ID Card</th>
                <th>Exception Transfer</th>
                <th>Remarks</th>
                <th>Pre Stay</th>
                <th>Pre Check In Date</th>
                <th>Pre Arrival Date</th>
                <th>Pre Pickup From</th>
                <th>Pre Flight No.</th>
                <th>Pre Airline</th>
                <th>Pre Airline Time</th>
                <th>Pre Arrival From</th>
                <th>Post Stay</th>
                <th>Post Checkout Date</th>
                <th>Post Drop To</th>
                <th>Post Departure Date</th>
                <th>Post Flight No.</th>
                <th>Post Flight Name.</th>
                <th>Car Type</th>
                <th>Car Number</th>
                <th>Driver Info</th>
                <th>Travel Own Arrangement</th> */}
                {lockedCol.map((l, i) => {
                  return (
                    <th
                      class="fixed-col"
                      key={`fcol-${i + 1}`}
                      onClick={() => {
                        if (l.sort) {
                          l.handleSort(setLogisticDetails, l.sortType)
                        }
                      }}
                      hidden={!l.show}>
                      <div className="roundedcell">{l.name}&nbsp; {l.sort ? <i className={`fa fa-sort${l.sortType === 'asc' ? '-asc' : l.sortType === 'dsc' ? '-desc' : ''}`} ariaHidden="true"/> : ""}</div>
                    </th>
                  );
                })}

                {
                  unlockedCol.map((l, i) => {
                    return (
                      <th key={`ufcol-${i + 1}`} 
                      onClick={() => {
                        if (l.sort) {
                          l.handleSort(setLogisticDetails, l.sortType)
                        }
                      }}
                      hidden={!l.show}>
                        <div className="roundedcell">{l.name}&nbsp; {l.sort ? <i className={`fa fa-sort${l.sortType === 'asc' ? '-asc' : l.sortType === 'dsc' ? '-desc' : ''}`} ariaHidden="true"/> : ""}</div>
                      </th>
                    )
                  })
                }
                <th class="fixed-col">
                  <div className="roundedcell">Action</div>
                </th>
              </tr>
            </thead>
            <tbody>
              {logisticDetails.length ? (
                logisticDetails?.map((value) => {
                  return (
                    <tr>
                      {lockedCol.map((l, i) => {
                        return (
                          <td
                            class="fixed-col"
                            key={`dfcol-${i + 1}`}
                            hidden={!l.show}>
                            {l.render(value[l.keyName])}
                          </td>
                        );
                      })}
                      {unlockedCol.map((l, i) => {
                        return (
                          <td
                            key={`fdcol-${i + 1}`}
                            hidden={!l.show}>
                            {l.render(value[l.keyName])}
                          </td>
                        );
                      })}
                      {/* <td class="fixed-col">
                        <div className="roundedcell">
                          {value?.guest_data?.name}
                        </div>
                      </td>
                      <td class="fixed-col">
                        <div className="roundedcell">{value.travel_mode}</div>
                      </td>
                      <td>{value.team}</td>
                      <td>{value.group}</td>
                      <td>{value.address}</td>
                      <td>{value.card_number}</td>
                      <td>{value.exception_transfer}</td>
                      <td>{value.remarks}</td>
                      <td>
                        {value.pre_stay == "Yes" ? (
                          <div className="statusbtn green">
                            {value.pre_stay}
                          </div>
                        ) : (
                          <div className="statusbtn red">{value.pre_stay}</div>
                        )}
                      </td>
                      <td>
                        {value?.pre_check_in_date
                          ? moment(value.pre_check_in_date).format("DD/MM/YYYY")
                          : ""}
                      </td>
                      <td>
                        {value?.pre_arrival_date
                          ? moment(value.pre_arrival_date).format("DD/MM/YYYY")
                          : ""}
                      </td>
                      <td>{value?.pre_pickup_from}</td>
                      <td>{value?.pre_flight_no}</td>
                      <td>{value?.pre_airline}</td>
                      <td>{value?.pre_arrival_time ? "" : ""}</td>
                      <td>{value?.pre_arrival_from}</td>
                      <td>{value?.post_stay}</td>



                      <td>
                        {value?.post_check_out_date
                          ? moment(value?.post_check_out_date).format(
                            "DD/MM/YYYY"
                          )
                          : ""}
                      </td>
                      <td>{value?.post_drop_to}</td>
                      <td>
                        {value?.post_departure_date
                          ? moment(value?.post_departure_date).format(
                            "DD/MM/YYYY"
                          )
                          : ""}
                      </td>

                      <td>{value?.post_flight_no}</td>
                      <td>{value?.post_airline}</td>
                      <td>{value?.car_type}</td>
                      <td>{value?.car_number}</td>
                      <td>{value?.driver_info}</td>
                      <td>
                        {value?.travel_own_arrangement ? (
                          <div className="statusbtn green">Yes</div>
                        ) : (
                          <div className="statusbtn red">No</div>
                        )}
                      </td> */}

                      <td class="fixed-col">
                        <div className="action-div roundedcell">
                          <span
                            onClick={() => {
                              setEditLogisticCanvas(true);
                              setSelectedLogistic(value.id);
                            }}
                          >
                            <img className="btn-icon" src={editIcon} />
                          </span>
                          <span
                            onClick={() => {
                              setViewLogisticCanvas(true);
                              setSelectedLogistic(value.id);
                            }}
                          >
                            <img className="btn-icon" src={eyeIcon} />
                          </span>
                          <span
                            onClick={() => {
                              setShowDeleteModal(true);
                              setSelectedLogistic(value.id);
                              setSelectedLogisticDetails(value.name);
                              setSelectedLogisticDetails({
                                name: value?.guest_data?.name,
                                mode: value.travel_mode,
                              });
                            }}
                          >
                            <img
                              className="btn-icon no-border"
                              src={trashIcon}
                            />
                          </span>
                        </div>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={14}>
                    <div className="text-center">
                      <p>No Data Found</p>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          :
          ""
          }
        </div>
      </div>
      {!loading && logisticDetails.length ? <div className="page-footer">
        <Pagination
          currentPage={pageNo}
          totalCount={totalRecords}
          pageSize={pageSize}
          onPageChange={(page) => setPageNo(page)}
          changePageSize={(page) => {
            setPageSize(page);
            setPageNo(1);
          }}
        />
      </div>:""}

      <MyModal
        label={""}
        titleclass={""}
        showHeader={false}
        show={showDeleteModal}
        handleClose={() => {
          setShowDeleteModal(false);
        }}
      >
        <div className="modelBody-content">
          <div className="del_ttl">
            Are you sure you want to delete this logistic?
          </div>
          <div className="del_txt">
            Logistic For: {selectedLogisticDetails?.name} -{" "}
            {selectedLogisticDetails.mode}
          </div>
          <div class="btn-box model_btns">
            <button
              class="btn-theme outline modenBtn"
              onClick={() => {
                setShowDeleteModal(false);
              }}
            >
              Cancel
            </button>
            <button
              type="button"
              class="btn-theme bg-brown color_white modenBtn"
              onClick={() => {
                removeLogistic(selectedLogistic);
              }}
            >
              Delete
            </button>
          </div>
        </div>
      </MyModal>

      <Offcanvas show={show} onHide={setShow} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Add Logistic</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <LogisticAdd closeCanvas={getAllLogisticList} />
        </Offcanvas.Body>
      </Offcanvas>

      <Offcanvas
        show={editLogisticCanvas}
        onHide={setEditLogisticCanvas}
        placement="end"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Edit Logistic</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <LogisticEdit
            id={selectedLogistic}
            closeCanvas={getAllLogisticList}
          />
        </Offcanvas.Body>
      </Offcanvas>

      <Offcanvas
        show={viewLogisticCanvas}
        onHide={setViewLogisticCanvas}
        placement="end"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Logistic Detail</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <LogisticDetails id={selectedLogistic} />
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default AllLogisticList;
