import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchGuestList, fetchAllGuest } from "../../action/guests";

export const fetchGuests = createAsyncThunk(
  "guests/fetchGuests",
  async ({ eventId, pageNo, pageSize, search }) => {
    const response = await fetchGuestList(eventId, pageNo, pageSize, search);

    return response.data.objects.guestDetails;
  }
);

export const fetchAllGuests = createAsyncThunk(
  "guests/fetchAllGuests",
  async ({ eventId }) => {
    const response = await fetchAllGuest(eventId);

    return response.data.objects.guestDetails;
  }
);

const guestListSlice = createSlice({
  name: "guestList",
  initialState: {
    guestList: [],
    allGuests: [],
  },
  reducers: {
    setGuestList: (state, action) => {
      state.guestList = action.payload;
    },
    setAllGuests: (state, action) => {
      state.allGuests = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchGuests.fulfilled, (state, action) => {
      state.guestList = action.payload;
    });
    builder.addCase(fetchAllGuests.fulfilled, (state, action) => {
      state.allGuests = action.payload;
    });
  },
});

export const { setGuestList } = guestListSlice.actions;

export default guestListSlice.reducer;
