import React from "react";
import LogoText from "../assets/images/logo-text.png";
import LogoIcon from "../assets/images/logo-icon.png";

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer-container">
        <div className="footer-content">
          <span>Powered by</span>
          <img src={LogoIcon} />
          <img
            src={LogoText}
            className="footerLOgo-text"
          />
        </div>
      </div>
    </div>
  );
};

export default Footer;
