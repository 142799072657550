import { useFormik } from "formik";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { addGuest, fetchAllGuest } from "../../action/guests";
import showNotification from "../../components/notification/notification";
import { Link, useNavigate, useParams } from "react-router-dom";
import { createLogistic } from "../../action/logistics";

const LogisticAdd = ({ closeCanvas }) => {
  const { eventId } = useParams();
  const [allGuests, setAllGuests] = useState([]);

  const addLogisticFormik = useFormik({
    initialValues: {
      guest: "",
      travel_mode: "",
      team: "",
      group: "",
      address: "",
      card_number: "",
      pre_stay: "No",
      pre_pickup_from: "",
      pre_flight_no: "",
      pre_airline: "",
      pre_arrival_from: "",
      post_stay: "No",
      post_drop_to: "",
      post_flight_no: "",
      post_airline: "",
      exception_transfer: "No",
      remarks: "",
      car_type: "",
      car_number: "",
      driver_info: "",
      travel_own_arrangement: false,
    },
    validate: (values) => {
      const errors = {};
      if (!values.guest) {
        errors.guest = "Guest is required";
      }
      if (!values.travel_mode) {
        errors.travel_mode = "Travel mode is required";
      }
      return errors;
    },
    onSubmit: async (values) => {
      const res = await createLogistic(values);
      showNotification("Logistic details added successfully");
      closeCanvas();
    },
  });

  const getAllGuests = async () => {
    const res = await fetchAllGuest(eventId);
    if (res.status) {
      setAllGuests(res.data.objects.guestDetails);
    }
  };

  useEffect(() => {
    getAllGuests();
  }, []);

  return (
    <div className="addGuest-container custom-scroll-bar">
      <div class="payout_data-content">
        <form onSubmit={addLogisticFormik.handleSubmit}>
          <div className="row">
            <div class="input-box">
              <div class="input-label">Guest</div>
              <div class="input_field">
                <select
                  name="guest"
                  value={addLogisticFormik.values.guest}
                  onChange={addLogisticFormik.handleChange}
                  class="input"
                >
                  <option value="">Select guest</option>
                  {allGuests.map((value) => {
                    return <option value={value.id}>{value.name}</option>;
                  })}
                </select>
                {addLogisticFormik.touched.guest &&
                addLogisticFormik.errors.guest ? (
                  <p className="error">{addLogisticFormik.errors.guest}</p>
                ) : null}
              </div>
            </div>

            <div class="input-box">
              <div class="input-label">Travel Mode</div>
              <div class="input_field">
                <select
                  name="travel_mode"
                  value={addLogisticFormik.values.travel_mode}
                  onChange={addLogisticFormik.handleChange}
                  class="input"
                >
                  <option value="">Select mode</option>
                  <option value="Flight">Flight</option>
                  <option value="Car">Car</option>
                  <option value="Train">Train</option>
                </select>
              </div>
            </div>
            {addLogisticFormik.touched.travel_mode &&
            addLogisticFormik.errors.travel_mode ? (
              <p className="error">{addLogisticFormik.errors.travel_mode}</p>
            ) : null}

            <div className="col-lg-6">
              <div class="input-box">
                <div class="input-label">Team</div>
                <div class="input_field">
                  <input
                    type="text"
                    class="input"
                    placeholder="Team Groom/Bride"
                    name="team"
                    value={addLogisticFormik.values.team}
                    onChange={addLogisticFormik.handleChange}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div class="input-box">
                <div class="input-label">Group</div>
                <div class="input_field">
                  <input
                    type="text"
                    class="input"
                    placeholder="School Friend"
                    name="group"
                    value={addLogisticFormik.values.group}
                    onChange={addLogisticFormik.handleChange}
                  />
                </div>
              </div>
            </div>
            <div class="input-box">
              <div class="input-label">Address</div>
              <div class="input_field">
                <textarea
                  type="text"
                  class="input"
                  placeholder="Address"
                  name="address"
                  rows={3}
                  value={addLogisticFormik.values.address}
                  onChange={addLogisticFormik.handleChange}
                />
              </div>
            </div>

            <div class="input-box">
              <div class="input-label">ID Card Number</div>
              <div class="input_field">
                <input
                  type="text"
                  class="input"
                  placeholder="Enter ID Card Number"
                  name="card_number"
                  value={addLogisticFormik.values.card_number}
                  onChange={addLogisticFormik.handleChange}
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div class="input-box">
                <div class="input-label">Pre Stay</div>
                <div class="input_field">
                  <select
                    name="pre_stay"
                    value={addLogisticFormik.values.pre_stay}
                    onChange={addLogisticFormik.handleChange}
                    class="input"
                  >
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div class="input-box">
                <div class="input-label">Check In Date</div>
                <div class="input_field">
                  <input
                    type="date"
                    class="input"
                    onKeyDown={(e) => e.preventDefault()}
                    min={moment().format("YYYY-MM-DD")}
                    placeholder="Select Date"
                    name="pre_check_in_date"
                    value={addLogisticFormik.values.pre_check_in_date}
                    onChange={addLogisticFormik.handleChange}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div class="input-box">
                <div class="input-label">Arrival Date</div>
                <div class="input_field">
                  <input
                    type="date"
                    class="input"
                    onKeyDown={(e) => e.preventDefault()}
                    min={moment(
                      addLogisticFormik.values.pre_check_in_date
                    ).format("YYYY-MM-DD")}
                    placeholder="Select Date"
                    name="pre_arrival_date"
                    value={addLogisticFormik.values.pre_arrival_date}
                    onChange={addLogisticFormik.handleChange}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div class="input-box">
                <div class="input-label">Pickup From</div>
                <div class="input_field">
                  <input
                    type="text"
                    class="input"
                    placeholder="Pickup From"
                    name="pre_pickup_from"
                    value={addLogisticFormik.values.pre_pickup_from}
                    onChange={addLogisticFormik.handleChange}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div class="input-box">
                <div class="input-label">Flight Number</div>
                <div class="input_field">
                  <input
                    type="text"
                    class="input"
                    placeholder="Enter Flight number"
                    name="pre_flight_no"
                    value={addLogisticFormik.values.pre_flight_no}
                    onChange={addLogisticFormik.handleChange}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div class="input-box">
                <div class="input-label">Airline</div>
                <div class="input_field">
                  <input
                    type="text"
                    class="input"
                    placeholder="Airline name"
                    name="pre_airline"
                    value={addLogisticFormik.values.pre_airline}
                    onChange={addLogisticFormik.handleChange}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div class="input-box">
                <div class="input-label">Arrival Time</div>
                <div class="input_field">
                  <input
                    type="time"
                    class="input"
                    placeholder="Select Date"
                    name="pre_arrival_time"
                    value={addLogisticFormik.values.pre_arrival_time}
                    onChange={addLogisticFormik.handleChange}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div class="input-box">
                <div class="input-label">Arrival From</div>
                <div class="input_field">
                  <input
                    type="text"
                    class="input"
                    placeholder="Arrival From"
                    name="pre_arrival_from"
                    value={addLogisticFormik.values.pre_arrival_from}
                    onChange={addLogisticFormik.handleChange}
                  />
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div class="input-box">
                <div class="input-label">Post Stay</div>
                <div class="input_field">
                  <select
                    name="post_stay"
                    value={addLogisticFormik.values.post_stay}
                    onChange={addLogisticFormik.handleChange}
                    class="input"
                  >
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div class="input-box">
                <div class="input-label">Check Out Date</div>
                <div class="input_field">
                  <input
                    type="date"
                    class="input"
                    onKeyDown={(e) => e.preventDefault()}
                    min={moment(
                      addLogisticFormik.values.pre_check_in_date
                    ).format("YYYY-MM-DD")}
                    placeholder="Select Date"
                    name="post_check_out_date"
                    value={addLogisticFormik.values.post_check_out_date}
                    onChange={addLogisticFormik.handleChange}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div class="input-box">
                <div class="input-label">Drop To</div>
                <div class="input_field">
                  <input
                    type="text"
                    class="input"
                    placeholder="Drop to"
                    name="post_drop_to"
                    value={addLogisticFormik.values.post_drop_to}
                    onChange={addLogisticFormik.handleChange}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div class="input-box">
                <div class="input-label">Departure Date</div>
                <div class="input_field">
                  <input
                    type="date"
                    class="input"
                    onKeyDown={(e) => e.preventDefault()}
                    min={moment(
                      addLogisticFormik.values.post_check_out_date
                    ).format("YYYY-MM-DD")}
                    placeholder="Select Date"
                    name="post_departure_date"
                    value={addLogisticFormik.values.post_departure_date}
                    onChange={addLogisticFormik.handleChange}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div class="input-box">
                <div class="input-label">Flight No.</div>
                <div class="input_field">
                  <input
                    type="text"
                    class="input"
                    placeholder="Drop to"
                    name="post_flight_no"
                    value={addLogisticFormik.values.post_flight_no}
                    onChange={addLogisticFormik.handleChange}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div class="input-box">
                <div class="input-label">Airline</div>
                <div class="input_field">
                  <input
                    type="text"
                    class="input"
                    placeholder="Airline name"
                    name="post_airline"
                    value={addLogisticFormik.values.post_airline}
                    onChange={addLogisticFormik.handleChange}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div class="input-box">
                <div class="input-label">Car Type</div>
                <div class="input_field">
                  <input
                    type="text"
                    class="input"
                    placeholder="Car Type"
                    name="car_type"
                    value={addLogisticFormik.values.car_type}
                    onChange={addLogisticFormik.handleChange}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div class="input-box">
                <div class="input-label">Car Number</div>
                <div class="input_field">
                  <input
                    type="text"
                    class="input"
                    placeholder="Car Number"
                    name="car_number"
                    value={addLogisticFormik.values.car_number}
                    onChange={addLogisticFormik.handleChange}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div class="input-box">
                <div class="input-label">Driver Info</div>
                <div class="input_field">
                  <input
                    type="text"
                    class="input"
                    placeholder="Driver Info."
                    name="driver_info"
                    value={addLogisticFormik.values.driver_info}
                    onChange={addLogisticFormik.handleChange}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div class="input-box">
                <div class="input-label">Travel Own Arrangement</div>
                <div class="input_field">
                  <input
                    type="checkbox"
                    class="input"
                    name="travel_own_arrangement"
                    value={addLogisticFormik.values.travel_own_arrangement}
                    onChange={addLogisticFormik.handleChange}
                  />
                </div>
              </div>
            </div>

            <div class="input-box">
              <div class="input-label">Exception Transfer</div>
              <div class="input_field">
                <select
                  name="exception_transfer"
                  value={addLogisticFormik.values.exception_transfer}
                  onChange={addLogisticFormik.handleChange}
                  class="input"
                >
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </select>
              </div>
            </div>

            <div class="input-box">
              <div class="input-label">Remarks</div>
              <div class="input_field">
                <input
                  type="text"
                  class="input"
                  placeholder="Remarks"
                  name="remarks"
                  value={addLogisticFormik.values.remarks}
                  onChange={addLogisticFormik.handleChange}
                />
              </div>
            </div>
          </div>

          <button type="submit" className="btn-theme bg-dark">
            Save
          </button>
        </form>
      </div>
    </div>
  );
};

export default LogisticAdd;
