import React from "react";
import searchIcon from "../assets/images/search.svg";

const CustomSearchBox = ({ search, setSearch, placeholder }) => {
  return (
    <div className="customSearch_container">
      <input
        type="text"
        value={search}
        placeholder={placeholder || ""}
        className="search_input"
        onChange={(e) => setSearch(e.target.value)}
      />
      <div className="searchIcon_box">
        <img src={searchIcon} />
      </div>
    </div>
  );
};

export default CustomSearchBox;
