// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.left-side-bar {
  border-right: 1px solid #ddd;
  height: 100vh;
  overflow-y: auto;
  background-color: #f7f7f7;
  display: flex;
  flex-direction: column;
}

.left-side-bar-header {
  padding: 15px;
  background-color: #49ca59;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.left-side-bar-header img {
  width: 40px;
  height: 40px;
  margin-bottom: 10px;
}

.guest-list {
  flex: 1 1;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.guest-item {
  display: flex;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #ddd;
  cursor: pointer;
  transition: background-color 0.3s;
}

.guest-item:hover {
  background-color: #e6e6e6;
}

.guest-item img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
}

.guest-item div {
  flex-grow: 1;
}

.guest-item p {
  font-weight: bold;
  margin: 0;
}

.guest-item small {
  color: #666;
  font-size: 0.9rem;
}
`, "",{"version":3,"sources":["webpack://./src/components/whatsapp/LeftSideBar.css"],"names":[],"mappings":"AAAA;EACE,4BAA4B;EAC5B,aAAa;EACb,gBAAgB;EAChB,yBAAyB;EACzB,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,yBAAyB;EACzB,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,mBAAmB;AACrB;;AAEA;EACE,SAAO;EACP,qBAAqB;EACrB,UAAU;EACV,SAAS;AACX;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,aAAa;EACb,6BAA6B;EAC7B,eAAe;EACf,iCAAiC;AACnC;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,iBAAiB;EACjB,SAAS;AACX;;AAEA;EACE,WAAW;EACX,iBAAiB;AACnB","sourcesContent":[".left-side-bar {\r\n  border-right: 1px solid #ddd;\r\n  height: 100vh;\r\n  overflow-y: auto;\r\n  background-color: #f7f7f7;\r\n  display: flex;\r\n  flex-direction: column;\r\n}\r\n\r\n.left-side-bar-header {\r\n  padding: 15px;\r\n  background-color: #49ca59;\r\n  color: white;\r\n  display: flex;\r\n  flex-direction: column;\r\n  align-items: center;\r\n  text-align: center;\r\n}\r\n\r\n.left-side-bar-header img {\r\n  width: 40px;\r\n  height: 40px;\r\n  margin-bottom: 10px;\r\n}\r\n\r\n.guest-list {\r\n  flex: 1;\r\n  list-style-type: none;\r\n  padding: 0;\r\n  margin: 0;\r\n}\r\n\r\n.guest-item {\r\n  display: flex;\r\n  align-items: center;\r\n  padding: 10px;\r\n  border-bottom: 1px solid #ddd;\r\n  cursor: pointer;\r\n  transition: background-color 0.3s;\r\n}\r\n\r\n.guest-item:hover {\r\n  background-color: #e6e6e6;\r\n}\r\n\r\n.guest-item img {\r\n  width: 50px;\r\n  height: 50px;\r\n  border-radius: 50%;\r\n  margin-right: 10px;\r\n}\r\n\r\n.guest-item div {\r\n  flex-grow: 1;\r\n}\r\n\r\n.guest-item p {\r\n  font-weight: bold;\r\n  margin: 0;\r\n}\r\n\r\n.guest-item small {\r\n  color: #666;\r\n  font-size: 0.9rem;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
