import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
  fetRoomBookedStatus,
  fetchHotelsListing,
  fetchRoomsList,
} from "../../action/hotels";
import { Offcanvas } from "react-bootstrap";
import RoomBookingAdd from "./roomBookingAdd";
import moment from "moment";
import { useSelector } from "react-redux";
import Pagination from "../../components/pagination/paginationView";

const HotelRoomList = () => {
  const { hotelId, eventId } = useParams();
  const userDetails = useSelector((state) => state.userDetails.value);

  const [loading, setShowLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [isAllRoomBooked, setIsAllRoomBooked] = useState(0);
  const [roomList, setRoomList] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);

  const getHotelsList = async () => {
    setShowLoading(true);
    const res = await fetchRoomsList(hotelId, pageNo, pageSize);
    if (res.status) {
      setShowLoading(false);
      setTotalRecords(res.data.total_items);
      setRoomList(res.data.objects);
    }
  };

  const getRoomBookedStatus = async () => {
    const res = await fetRoomBookedStatus(hotelId);
    if (res.status) {
      setIsAllRoomBooked(res?.data[0]?.rooms_vacant);
    }
  };

  useEffect(() => {
    getHotelsList();
    getRoomBookedStatus();
  }, []);

  return (
    <div>
      <div class="dashboard_head">
        <div class="dashboard_head-left">
          <div class="dashboard_title">Hotel Rooms</div>
        </div>
        <div class="dashboard_head-right">
          {isAllRoomBooked && userDetails?.is_companyAdmin ? (
            <button
              class="submit-btn active"
              onClick={() => {
                setShow(true);
              }}
            >
              Add Room to Guests
            </button>
          ) : (
            <></>
          )}
        </div>
      </div>
      <div class="payout_data-content">
        <div class="payout_table-box">
          <table class="payout_table">
            <thead>
              <tr class="tableHead">
                <th>Room Number</th>
                <th>Room Type</th>
                <th>Checkin Date</th>
                <th>Checkout Date</th>
                <th>Guest Name</th>
                <th>Guest Mobile No</th>
              </tr>
            </thead>
            <tbody>
              {!loading ? (
                !roomList?.length ? (
                  <tr>
                    <td colSpan={11} className="text-center">
                      No rooms booking found
                    </td>
                  </tr>
                ) : (
                  roomList.map((value) => {
                    return (
                      <tr>
                        <td>{value.room_number}</td>
                        <td>{value.room_type}</td>
                        <td>
                          {moment(value.checkin_date).format("DD-MM-YYYY")}
                        </td>
                        <td>
                          {moment(value.checkout_date).format("DD-MM-YYYY")}
                        </td>
                        <td>{value.guest.name}</td>
                        <td>{value.guest.contact_number}</td>
                      </tr>
                    );
                  })
                )
              ) : (
                <tr>
                  <td colSpan={7}>
                    <div className="loader-box">
                      <div className="loader"></div>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      <div className="page-footer">
        <Pagination
          currentPage={pageNo}
          totalCount={totalRecords}
          pageSize={pageSize}
          onPageChange={(page) => setPageNo(page)}
          changePageSize={(page) => {
            setPageSize(page);
            setPageNo(1);
          }}
        />
      </div>

      <Offcanvas
        show={show}
        onHide={() => {
          setShow(false);
        }}
        placement="end"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Add Guest</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <RoomBookingAdd
            closeCanvas={() => {
              setShow(false);
              getHotelsList();
            }}
          />
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default HotelRoomList;
