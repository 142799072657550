import { useFormik } from "formik";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  createSubEvent,
  fetchSubEventsDetails,
  updateSubEvent,
} from "../../action/subEvents";
import showNotification from "../../components/notification/notification";

const SubEventEdit = ({ id, closeCanvas }) => {
  const { eventId } = useParams();
  const addEventFormik = useFormik({
    initialValues: {
      wedding: eventId,
      name: "",
      date: "",
      venue: "",
      description: "",
    },
    validate: (values) => {
      const errors = {};
      if (!values.name) {
        errors.name = "Please input event name";
      }
      if (!values.date) {
        errors.status = "Please select date";
      }
      return errors;
    },
    onSubmit: async (values) => {
      const res = await updateSubEvent(eventId, id, values);
      if (res.status) {
        showNotification("Sub event updated successfully");
        closeCanvas();
      }
    },
  });

  const getDetails = async () => {
    const res = await fetchSubEventsDetails(eventId, id);
    if (res.status) {
      addEventFormik.setFieldValue("name", res.data.name);
      addEventFormik.setFieldValue("date", res.data.date);
      addEventFormik.setFieldValue("venue", res.data.venue);
      addEventFormik.setFieldValue("description", res.data.description);
    }
  };

  useEffect(() => {
    getDetails();
  }, [eventId]);

  return (
    <div className="m-3">
      <form onSubmit={addEventFormik.handleSubmit}>
        <div class="input-box">
          <div class="input-label">Sub Event Name</div>
          <div class="input_field">
            <input
              name="name"
              value={addEventFormik.values.name}
              onChange={addEventFormik.handleChange}
              onBlur={addEventFormik.handleBlur}
              type="text"
              class="input"
              placeholder="Enter name"
            />
          </div>
        </div>
        {addEventFormik.touched.name && addEventFormik.errors.name ? (
          <p className="error">{addEventFormik.errors.name}</p>
        ) : null}

        <div class="input-box">
          <div class="input-label">Select Date</div>
          <div class="input_field">
            <input
              type="date"
              class="input"
              min={moment().format("YYYY-MM-DD")}
              placeholder="Select Date"
              name="date"
              value={addEventFormik.values.date}
              onChange={addEventFormik.handleChange}
              onBlur={addEventFormik.handleBlur}
            />
          </div>
        </div>
        {addEventFormik.touched.date && addEventFormik.errors.date ? (
          <p className="error">{addEventFormik.errors.date}</p>
        ) : null}

        <div class="input-box">
          <div class="input-label">Venue</div>
          <div class="input_field">
            <input
              name="venue"
              value={addEventFormik.values.venue}
              onChange={addEventFormik.handleChange}
              onBlur={addEventFormik.handleBlur}
              type="text"
              class="input"
              placeholder="Enter venue"
            />
          </div>
        </div>
        {addEventFormik.touched.venue && addEventFormik.errors.venue ? (
          <p className="error">{addEventFormik.errors.venue}</p>
        ) : null}

        <div class="input-box">
          <div class="input-label">Description</div>
          <div class="input_field">
            <textarea
              type="text"
              class="input"
              rows={5}
              placeholder="Enter sub event description"
              name="description"
              value={addEventFormik.values.description}
              onChange={addEventFormik.handleChange}
              onBlur={addEventFormik.handleBlur}
            ></textarea>
          </div>
        </div>

        <div class="d-flex about-button-center mt-3">
          <button type="submit" class="submit-btn active">
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default SubEventEdit;
