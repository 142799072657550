import { requestWithToken } from "../helper/helper";

const uploadFile = async (formData) => {
  try {
    const response = await requestWithToken(
      "POST",
      `/api/file-upload/`,
      formData
    );

    return response;
  } catch (err) {
    return err.response.data;
  }
};
const uploadLogo = async (formData) => {
  try {
    const response = await requestWithToken(
      "POST",
      `/api/upload_company_logo`,
      formData
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};
const deleteLogo = async () => {
  try {
    const response = await requestWithToken(
      "DELETE",
      "/api/delete_company_logo"
       );
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

export { uploadFile ,uploadLogo ,deleteLogo };
