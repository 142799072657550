import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { deleteGuest, fetchGuestList } from "../../action/guests";
import editIcon from "../../assets/images/edit-brown.svg";
import eyeIcon from "../../assets/images/eye-brown.svg";
import excelicon from "../../assets/images/excelicon.svg";
import trashIcon from "../../assets/images/trash-brown.svg";
import printIcon from "../../assets/images/print.svg";
import exclefile from "../../assets/files/guests.xlsx";
import addBrown from "../../assets/images/add-brown.svg";
import Offcanvas from "react-bootstrap/Offcanvas";
import GuestAdd from "./guestAdd";
import GuestEdit from "./guestEdit";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "../../components/pagination/paginationView";
import CustomSearchBox from "../../components/customSearchBox";
import { changeHeaderState } from "../../reducer/slice/header";
import GuestDetails from "./guestDetails";
import { Modal } from "react-bootstrap";
import showNotification from "../../components/notification/notification";
import CustomSelectWithIconGuest from "../../components/CustomSelectWithIconGuest";
import { Loader } from "../../components/loader";
import { setGuestList } from "../../reducer/slice/guestListSlice";
import filterIcon from "../../assets/images/filter-brown.svg";

const GuestList = () => {
  const { eventId } = useParams();
  const dispatch = useDispatch();
  const userDetails = useSelector((state) => state.userDetails.value);

  const [show, setShow] = useState(false);
  const [loading, setShowLoading] = useState(false);
  const [guestList, setGuestListLocal] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [editGuest, setEditGuest] = useState(false);
  const [detailsGuest, setDetailsGuest] = useState(false);
  const [showDelete, setShowdelete] = useState(false);
  const [search, setSearch] = useState("");
  const [selectedGuest, setSelectedGuest] = useState(null);
  const [eventDetails, setEventDetails] = useState({});
  const [filteredGuests, setFilteredGuests] = useState([]);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [filters, setFilters] = useState({});

  const handleSort = (setData, sortType, keyName) => {
    if (sortType === "asc") {
      setColumns((c) => {
        const updatedColumns = c.map((col) => {
          if (col.keyName === keyName) {
            return { ...col, sortType: "dsc" };
          }
          return { ...col, sortType: "" };
        });
        return updatedColumns;
      });

      setData((f) => {
        const sortedData = [...f].sort((a, b) => {
          if (!a[keyName]) {
            return 1;
          }
          if (!b[keyName]) {
            return -1;
          }
          if (
            a[keyName].toString().toLowerCase() <
            b[keyName].toString().toLowerCase()
          ) {
            return 1;
          }
          if (
            a[keyName].toString().toLowerCase() >
            b[keyName].toString().toLowerCase()
          ) {
            return -1;
          }
          return 0;
        });
        return sortedData;
      });
    } else if (sortType === "dsc") {
      setColumns((c) => {
        const updatedColumns = c.map((col) => {
          if (col.keyName === keyName) {
            return { ...col, sortType: "asc" };
          }
          return { ...col, sortType: "" };
        });
        return updatedColumns;
      });
      setData((f) => {
        const sortedData = [...f].sort((a, b) => {
          if (!a[keyName]) {
            return -1;
          }
          if (!b[keyName]) {
            return 1;
          }
          if (
            a[keyName].toString().toLowerCase() <
            b[keyName].toString().toLowerCase()
          ) {
            return -1;
          }
          if (
            a[keyName].toString().toLowerCase() >
            b[keyName].toString().toLowerCase()
          ) {
            return 1;
          }
          return 0;
        });
        return sortedData;
      });
    } else {
      setColumns((c) => {
        const updatedColumns = c.map((col) => {
          if (col.keyName === keyName) {
            return { ...col, sortType: "asc" };
          }
          return { ...col, sortType: "" };
        });
        return updatedColumns;
      });
      setData((f) => {
        const sortedData = [...f].sort((a, b) => {
          if (!a[keyName]) {
            return -1;
          }
          if (!b[keyName]) {
            return 1;
          }
          if (
            a[keyName].toString().toLowerCase() <
            b[keyName].toString().toLowerCase()
          ) {
            return -1;
          }
          if (
            a[keyName].toString().toLowerCase() >
            b[keyName].toString().toLowerCase()
          ) {
            return 1;
          }
          return 0;
        });
        return sortedData;
      });
    }
  };
  const [columns, setColumns] = useState([
    {
      name: "Name",
      keyName: "name",
      locked: true,
      show: true,
      sort: true,
      sortType: "", // asc or dsc,
      handleSort: (data, sortType) => handleSort(data, sortType, "name"),
      render: (v) => (
        <div className="roundedcell justify-content-start">{v || "-"}</div>
      ),
    },
    {
      name: "Email",
      keyName: "email",
      locked: true,
      show: true,
      sort: true,
      sortType: "", // asc or dsc,
      handleSort: (data, sortType) => handleSort(data, sortType, "email"),
      render: (v) => <div className="roundedcell">{v || "-"}</div>,
    },
    {
      name: "Group",
      keyName: "group",
      locked: false,
      show: true,
      sort: true,
      sortType: "", // asc or dsc,
      handleSort: (data, sortType) => handleSort(data, sortType, "group"),
      render: (v) => <div className="roundedcell">{v || "-"}</div>,
    },
    {
      name: "Sub Group",
      keyName: "subgroup",
      locked: false,
      show: true,
      sort: true,
      sortType: "", // asc or dsc,
      handleSort: (data, sortType) => handleSort(data, sortType, "subgroup"),
      render: (v) => <div className="roundedcell">{v || "-"}</div>,
    },
    {
      name: "Address",
      keyName: "Address",
      locked: false,
      show: true,
      sort: true,
      sortType: "", // asc or dsc,
      handleSort: (data, sortType) => handleSort(data, sortType, "Address"),
      render: (v) => <div className="roundedcell">{v || "-"}</div>,
    },
    {
      name: "Contact Number",
      keyName: "contact_number",
      locked: true,
      show: true,
      sort: true,
      sortType: "", // asc or dsc,
      handleSort: (data, sortType) =>
        handleSort(data, sortType, "contact_number"),
      render: (v) => <div className="roundedcell">{v || "-"}</div>,
    },
    {
      name: "Alternate Contact Number",
      keyName: "alternate_contact_number",
      locked: false,
      show: true,
      sort: true,
      sortType: "", // asc or dsc,
      handleSort: (data, sortType) =>
        handleSort(data, sortType, "alternate_contact_number"),
      render: (v) => v || "-",
    },
    {
      name: "Hampergift",
      keyName: "hampergift",
      locked: false,
      show: true,
      sort: true,
      sortType: "", // asc or dsc,
      handleSort: (data, sortType) => handleSort(data, sortType, "hampergift"),
      render: (v) => <div>{v || "-"}</div>,
    },
    {
      name: "Notes",
      keyName: "notes",
      locked: false,
      show: true,
      sort: true,
      sortType: "", // asc or dsc,
      handleSort: (data, sortType) => handleSort(data, sortType, "notes"),
      render: (v) => <div>{v || "-"}</div>,
    },
    {
      name: "Reception Invite",
      keyName: "reception_invite",
      locked: false,
      show: true,
      sort: true,
      sortType: "", // asc or dsc,
      handleSort: (data, sortType) =>
        handleSort(data, sortType, "reception_invite"),
      render: (v) => {
        return (
          <>
            {v === "Pending" && <div className="statusbtn red">{v}</div>}
            {v === "Sent" && <div className="statusbtn green">{v}</div>}
          </>
        );
      },
    },
    {
      name: "Wedding Invite",
      keyName: "wedding_invite",
      locked: false,
      show: true,
      sort: true,
      sortType: "", // asc or dsc,
      handleSort: (data, sortType) =>
        handleSort(data, sortType, "wedding_invite"),
      render: (v) => {
        return (
          <>
            {v === "Pending" && <div className="statusbtn red">{v}</div>}
            {v === "Sent" && <div className="statusbtn green">{v}</div>}
          </>
        );
      },
    },
    {
      name: "RSVP",
      keyName: "rsvp",
      locked: false,
      show: true,
      sort: true,
      sortType: "", // asc or dsc,
      handleSort: (data, sortType) => handleSort(data, sortType, "rsvp"),
      render: (v) => {
        return (
          <>
            {v === "No" && <div className="statusbtn red">{v}</div>}
            {v === "Yes" && <div className="statusbtn green">{v}</div>}
            {v === "Maybe" && <div className="statusbtn orange">{v}</div>}
          </>
        );
      },
    },
    {
      name: "Save the date email",
      keyName: "save_the_date_email",
      locked: false,
      show: true,
      sort: true,
      sortType: "", // asc or dsc,
      handleSort: (data, sortType) =>
        handleSort(data, sortType, "save_the_date_email"),
      render: (v) => {
        return (
          <div className={`statusbtn ${v ? "green" : "red"}`}>
            {v ? "Yes" : "No"}
          </div>
        );
      },
    },
    {
      name: "Save the date whatsapp",
      keyName: "save_the_date_whatsapp",
      locked: false,
      show: true,
      sort: true,
      sortType: "", // asc or dsc,
      handleSort: (data, sortType) =>
        handleSort(data, sortType, "save_the_date_whatsapp"),
      render: (v) => {
        return (
          <div className={`statusbtn ${v ? "green" : "red"}`}>
            {v ? "Yes" : "No"}
          </div>
        );
      },
    },
    {
      name: "To be invited for",
      keyName: "to_be_invited_for",
      locked: false,
      show: true,
      sort: true,
      sortType: "", // asc or dsc,
      handleSort: (data, sortType) =>
        handleSort(data, sortType, "to_be_invited_for"),
      render: (v) => <div className="roundedcell">{v}</div>,
    },
    {
      name: "Whatsapp verified",
      keyName: "whatsapp_verified",
      locked: false,
      show: true,
      sort: true,
      sortType: "", // asc or dsc,
      handleSort: (data, sortType) =>
        handleSort(data, sortType, "whatsapp_verified"),
      render: (v) => {
        return (
          <div className={`statusbtn ${v ? "green" : "red"}`}>
            {v ? "Yes" : "No"}
          </div>
        );
      },
    },
  ]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleCloseAndRefresh = () => {
    setShowdelete(false);
    setDetailsGuest(false);
    setShow(false);
    setEditGuest(false);
    setSelectedGuest(null);
    getGuestsList();
  };

  const handleDeleteGuest = async () => {
    const res = await deleteGuest(eventId, selectedGuest.id);
    if (res.status) {
      handleCloseAndRefresh();
      showNotification("Guest deleted successfully", "success");
    } else {
      showNotification(res.message, "danger");
    }
  };

  const handleDownload = () => {
    const fileUrl = exclefile;
    const link = document.createElement('a');
    link.href = fileUrl;
    link.download = 'template.xlsx';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const getGuestsList = async () => {
    setShowLoading(true);
    const res = await fetchGuestList(eventId, pageNo, pageSize, "");
    if (res?.status) {
      setShowLoading(false);
      const guests = res?.data.objects.guestDetails;
      setGuestListLocal(guests);
      setFilteredGuests(guests);
      setEventDetails(res?.data.objects.eventDetails);
      setTotalRecords(res?.data.total_items || 0);
      dispatch(setGuestList(guests));

      dispatch(
        changeHeaderState({
          showDefault: false,
          headerTitle: "Guests Management",
          navigationList: [
            { name: "Events", url: "/events" },
            {
              name: res?.data.objects.eventDetails.name,
              url: res?.data.objects?.guestDetails?.[0]?.event
                ? `/event-details/${res?.data.objects?.guestDetails?.[0]?.event}`
                : `/event-details/${eventId}`
            },
            {
              name: "Guest Management",
              url: "",
            },
          ],
        })
      );
    }
  };

  // Add new function to handle local updates
  const handleGuestUpdate = (updatedGuest, action) => {
    if (action === 'add') {
      setGuestListLocal(prevList => [updatedGuest, ...prevList]);
      setFilteredGuests(prevList => [updatedGuest, ...prevList]);
    } else if (action === 'edit') {
      setGuestListLocal(prevList => 
        prevList.map(guest => guest.id === updatedGuest.id ? updatedGuest : guest)
      );
      setFilteredGuests(prevList => 
        prevList.map(guest => guest.id === updatedGuest.id ? updatedGuest : guest)
      );
    }
  };

  useEffect(() => {
    getGuestsList();

    // Add event listener for refreshing guest list
    const handleRefresh = (event) => {
      const { guest, action } = event.detail;
      if (guest) {
        handleGuestUpdate(guest, action);
      } else {
        getGuestsList(); // Fallback to API refresh if no guest data
      }
    };
    window.addEventListener('refreshGuestList', handleRefresh);

    // Cleanup
    return () => {
      window.removeEventListener('refreshGuestList', handleRefresh);
    };
  }, [eventId, pageNo, pageSize]);

  useEffect(() => {
    let filtered = [...guestList];

    // Apply search filter
    if (search) {
      const searchTerm = search.toLowerCase();
      const visibleColumns = columns.filter(col => col.show).map(col => col.keyName);
      
      filtered = filtered.filter(guest => {
        return visibleColumns.some(columnKey => {
          const value = guest[columnKey];
          if (value === null || value === undefined) return false;
          return value.toString().toLowerCase().includes(searchTerm);
        });
      });
    }

    // Apply column filters
    if (Object.keys(filters).length > 0) {
      filtered = filtered.filter(guest => {
        return Object.entries(filters).every(([key, filterValue]) => {
          if (!filterValue) return true;
          
          const value = guest[key];
          if (value === null || value === undefined) return false;

          // Handle boolean filters
          if (filterValue === 'true') return value === true;
          if (filterValue === 'false') return value === false;

          // Handle text and select filters
          return value.toString().toLowerCase().includes(filterValue.toLowerCase());
        });
      });
    }

    setFilteredGuests(filtered);
  }, [search, guestList, columns, filters]);

  const lockedCol = columns.filter((el) => el.locked);
  const unlockedCol = columns.filter((el) => !el.locked);

  const getFilterInputType = (column) => {
    switch (column.keyName) {
      case 'rsvp':
        return 'select';
      case 'wedding_invite':
      case 'reception_invite':
        return 'select';
      case 'save_the_date_email':
      case 'save_the_date_whatsapp':
      case 'whatsapp_verified':
        return 'boolean';
      default:
        return 'text';
    }
  };

  const FilterModal = ({ show, handleClose, columns, filters, setFilters, applyChanges }) => {
    const [tempFilters, setTempFilters] = useState({...filters});

    const handleApply = () => {
      setFilters(tempFilters);
      applyChanges();
      handleClose();
    };

    const renderFilterInput = (column) => {
      const inputType = getFilterInputType(column);
      
      switch (inputType) {
        case 'select':
          if (column.keyName === 'rsvp') {
            return (
              <select 
                className="form-select"
                value={tempFilters[column.keyName] || ''}
                onChange={(e) => setTempFilters({...tempFilters, [column.keyName]: e.target.value})}
              >
                <option value="">All</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
                <option value="Maybe">Maybe</option>
              </select>
            );
          } else {
            return (
              <select 
                className="form-select"
                value={tempFilters[column.keyName] || ''}
                onChange={(e) => setTempFilters({...tempFilters, [column.keyName]: e.target.value})}
              >
                <option value="">All</option>
                <option value="Sent">Sent</option>
                <option value="Pending">Pending</option>
              </select>
            );
          }
        case 'boolean':
          return (
            <select 
              className="form-select"
              value={tempFilters[column.keyName] || ''}
              onChange={(e) => setTempFilters({...tempFilters, [column.keyName]: e.target.value})}
            >
              <option value="">All</option>
              <option value="true">Yes</option>
              <option value="false">No</option>
            </select>
          );
        default:
          return (
            <input
              type="text"
              className="form-control"
              placeholder={`Filter by ${column.name}`}
              value={tempFilters[column.keyName] || ''}
              onChange={(e) => setTempFilters({...tempFilters, [column.keyName]: e.target.value})}
            />
          );
      }
    };

    return (
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Filter Table</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="filter-grid">
            {columns.map((column, index) => (
              <div key={index} className="filter-item mb-3">
                <label className="form-label">{column.name}</label>
                {renderFilterInput(column)}
              </div>
            ))}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button 
            className="btn-theme outline" 
            onClick={() => {
              setTempFilters({});
              setFilters({});
              applyChanges();
              handleClose();
            }}
          >
            Clear All
          </button>
          <button className="btn-theme outline" onClick={handleClose}>Cancel</button>
          <button className="btn-theme bg-brown color_white" onClick={handleApply}>Apply</button>
        </Modal.Footer>
      </Modal>
    );
  };

  return (
    <div>
      <div class="dashboard_head">
        {/* <h5>
          {eventDetails.event_type} - {eventDetails?.name}
        </h5> */}

        <div class="searchbar-box">
          {/* <div class="searchbar-box-left">
            <div class="searchbar-box-left_content">
              <CustomSelect extraclass="w-100" />
              <button class="btn-theme bg-dark">Apply</button>
            </div>
          </div> */}
          <div class="searchbar-box-mid">
            <div class="searchbar-box-mid_content-left">
              <CustomSearchBox
                placeholder="Search guest"
                search={search}
                setSearch={setSearch}
              />
            </div>
            <div class="searchbar-box-mid_content-right">
              <CustomSelectWithIconGuest
                columns={columns}
                setColumns={setColumns}
              />
            </div>
          </div>
          <div class="searchbar-box-right">
            <div class="searchbar-box-right-content">
              <button className="iconbtn" onClick={() => setShowFilterModal(true)}>
                <img src={filterIcon} alt="Filter" />
              </button>
              <button className="iconbtn">
                <img
                  src={excelicon}
                  alt="Download Excel Template"
                  onClick={handleDownload}
                  style={{ cursor: 'pointer' }}
                />
              </button>
              <button
                className="iconbtn"
                onClick={() => {
                  window.print();
                }}
              >
                <img src={printIcon} alt="" />
              </button>

              {userDetails?.is_companyAdmin ? (
                <button
                  className="btn-theme dotted-border"
                  onClick={handleShow}
                >
                  <img src={addBrown} />
                  <span>Add Guest</span>
                </button>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
      <div class="payout_data-content">
        <div class="custom-table-container custom-scroll-bar custom-scroll-bar2">
          {!loading && !filteredGuests?.length ? (
            <div colSpan={20} className="text-center">
              No data found
            </div>
          ) : (
            ""
          )}
          {loading ? <Loader /> : ""}
          {!loading && filteredGuests.length ? (
            <table class="custom-table">
              <thead>
                <tr class="tableHead">
                  {lockedCol.map((l, i) => {
                    return (
                      <th
                        class="fixed-col"
                        key={`fcol-${i + 1}`}
                        onClick={() => {
                          if (l.sort) {
                            l.handleSort(setGuestListLocal, l.sortType);
                          }
                        }}
                        hidden={!l.show}
                      >
                        <div className="roundedcell">
                          {l.name}&nbsp;{" "}
                          {l.sort ? (
                            <i
                              className={`fa fa-sort${l.sortType === "asc"
                                  ? "-asc"
                                  : l.sortType === "dsc"
                                    ? "-desc"
                                    : ""
                                }`}
                              ariaHidden="true"
                            />
                          ) : (
                            ""
                          )}
                        </div>
                      </th>
                    );
                  })}

                  {unlockedCol.map((l, i) => {
                    return (
                      <th
                        key={`ufcol-${i + 1}`}
                        onClick={() => {
                          if (l.sort) {
                            l.handleSort(setGuestListLocal, l.sortType);
                          }
                        }}
                        hidden={!l.show}
                      >
                        <div className="roundedcell">
                          {l.name}&nbsp;{" "}
                          {l.sort ? (
                            <i
                              className={`fa fa-sort${l.sortType === "asc"
                                  ? "-asc"
                                  : l.sortType === "dsc"
                                    ? "-desc"
                                    : ""
                                }`}
                              ariaHidden="true"
                            />
                          ) : (
                            ""
                          )}
                        </div>
                      </th>
                    );
                  })}

                  <th class="fixed-col">
                    <div className="roundedcell">Action</div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {filteredGuests.map((value) => {
                  return (
                    <tr>
                      {lockedCol.map((l, i) => {
                        return (
                          <td
                            class="fixed-col"
                            key={`dfcol-${i + 1}`}
                            hidden={!l.show}
                          >
                            {l.render(value[l.keyName])}
                          </td>
                        );
                      })}
                      {unlockedCol.map((l, i) => {
                        return (
                          <td key={`fdcol-${i + 1}`} hidden={!l.show}>
                            {l.render(value[l.keyName])}
                          </td>
                        );
                      })}

                      <td class="fixed-col">
                        <div className="action-div roundedcell">
                          <div
                            className="action-icon-box"
                            onClick={() => {
                              setEditGuest(true);
                              setSelectedGuest(value);
                            }}
                          >
                            <img className="btn-icon" src={editIcon} />
                          </div>
                          {/* <div style={{ width: 30 }}>
                          <img className="btn-icon" src={eyeIcon} onClick={() => { setDetailsGuest(true); setSelectedGuest(value) }} />
                        </div> */}
                          <Link
                            style={{ width: 30 }}
                            to={`/details-guest/${eventId}/${value.id}`}
                          >
                            <img
                              className="btn-icon"
                              src={eyeIcon}
                              onClick={() => {
                                setDetailsGuest(true);
                                setSelectedGuest(value);
                              }}
                            />
                          </Link>
                          <div style={{ width: 30 }}>
                            <img
                              className="btn-icon no-border"
                              src={trashIcon}
                              onClick={() => {
                                setShowdelete(true);
                                setSelectedGuest(value);
                              }}
                            />
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          ) : (
            ""
          )}
        </div>
      </div>
      {!loading && filteredGuests.length ? (
        <div className="page-footer">
          <Pagination
            currentPage={pageNo}
            totalCount={totalRecords}
            pageSize={pageSize}
            onPageChange={(page) => setPageNo(page)}
            changePageSize={(page) => {
              setPageSize(page);
              setPageNo(1);
            }}
          />
        </div>
      ) : (
        ""
      )}

      <Offcanvas show={show} onHide={handleClose} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Add Guest</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <GuestAdd closeCanvas={handleCloseAndRefresh} />
        </Offcanvas.Body>
      </Offcanvas>

      <Offcanvas
        show={editGuest}
        onHide={() => {
          setEditGuest(false);
          setSelectedGuest(null);
        }}
        placement="end"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Edit Guest</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <GuestEdit
            guestDetails={selectedGuest}
            closeCanvas={handleCloseAndRefresh}
          />
        </Offcanvas.Body>
      </Offcanvas>

      <Offcanvas
        show={detailsGuest}
        onHide={() => {
          setDetailsGuest(false);
          setSelectedGuest(null);
        }}
        placement="end"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Guest Details</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <GuestDetails
            guestDetails={selectedGuest}
            closeCanvas={handleCloseAndRefresh}
          />
        </Offcanvas.Body>
      </Offcanvas>

      <>
        <Modal
          show={showDelete}
          onHide={handleCloseAndRefresh}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Body>
            <div className="modelBody-content">
              <div className="del_ttl">
                Are you sure you want to delete this guest?
              </div>
              <div className="del_txt">Guest Name: {selectedGuest?.name}</div>
              {/* <div className="del_desc">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Pellentesque congue aliquam enim.
              </div> */}
              <div class="btn-box model_btns">
                <button
                  class="btn-theme outline modenBtn"
                  onClick={handleCloseAndRefresh}
                >
                  Cancel
                </button>
                <button
                  class="btn-theme bg-brown color_white modenBtn"
                  onClick={handleDeleteGuest}
                >
                  Delete
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>

      <FilterModal
        show={showFilterModal}
        handleClose={() => setShowFilterModal(false)}
        columns={columns}
        filters={filters}
        setFilters={setFilters}
        applyChanges={() => {
          handleClose();
        }}
      />
    </div>
  );
};

export default GuestList;
