import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { callStripeWebhook } from '../helper/helper'; // Your helper function to call the webhook API
import Swal from 'sweetalert2'; // Import SweetAlert2
import { shallowEqual } from 'react-redux';

const WebhookHandler = () => {
  // Get the query parameters from the URL
  const location = useLocation();
  const navigate = useNavigate(); // Hook for navigation

  useEffect(() => {
    // Extract the query parameters from the URL (success and session_id)
    const params = new URLSearchParams(location.search);
    const success = params.get('success') === 'true'; // Convert to boolean
    const sessionId = params.get('session_id');

  if(success){
    Swal.fire({
      title: 'Payment Successful!',
      text: 'Your payment has been processed successfully.',
      icon: 'success',
      confirmButtonText: 'Go to settings'
    }).then(() => {
      // Navigate to the dashboard (or another route) upon success
      navigate('/settings');
    });

  }
            


    
  }, [location, navigate]); // Add navigate to the dependency array

  return (
    <div>
      <h1>Processing Payment...</h1>
      <p>Please wait while we verify your payment.</p>
    </div>
  );
};

export default WebhookHandler;