import { RouterProvider } from "react-router-dom";
import route from "../src/routes/route";
import "./styles/style.css";
import "./styles/login.css";
import Footer from "./components/footer";

function App() {
  return <RouterProvider router={route} />;
}

export default App;
