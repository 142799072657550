export const PageNotFound = () => {
  return (
    <div
      style={{
        height: "100vh",
        width: "100%",
        display: "flex",
        alignItems: "center",
        placeContent: "center",
      }}
    >
      <div style={{ fontSize: "50px", fontWeight: "700", color: "red" }}>
        Page Not Found
      </div>
    </div>
  );
};
