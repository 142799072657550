import { useFormik } from "formik";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { createEventHotel } from "../../action/hotels";
import showNotification from "../../components/notification/notification";

const HotelAdd = ({ onCloseCanvas }) => {
  const { eventId } = useParams();
  const navigate = useNavigate();

  const addHotelFormik = useFormik({
    initialValues: {
      name: "",
      agent_name: "",
      contact_numbers: [],
      email_ids: [],
      address: "",
      total_rooms: "",
      number_of_guests: "",
    },
    validate: (values) => {
      const errors = {};
      if (!values.name) {
        errors.name = "Name is required";
      }
      if (!values.address) {
        errors.address = "Address is required";
      }
      if (!values.total_rooms) {
        errors.total_rooms = "Room number is required";
      }
      if (!values.number_of_guests) {
        errors.number_of_guests = "Guest number is required";
      }
      return errors;
    },
    onSubmit: async (values) => {
      const res = await createEventHotel(eventId, values);
      showNotification("Hotel added successfully");
      onCloseCanvas();
    },
  });

  return (
    <div>
      <form onSubmit={addHotelFormik.handleSubmit}>
        <div className="addHotel_container">
          <div className="row">
            <div class="col-lg-12">
              <div class="input-box">
                <div class="input-label">Hotel Name</div>
                <div class="input_field">
                  <input
                    type="text"
                    class="input"
                    placeholder="Enter Hotel Name"
                    name="name"
                    value={addHotelFormik.values.name}
                    onChange={addHotelFormik.handleChange}
                  />
                  {addHotelFormik.touched.name && addHotelFormik.errors.name ? (
                    <p className="error">{addHotelFormik.errors.name}</p>
                  ) : null}
                </div>
              </div>
            </div>
            <div class="col-lg-12">
              <div class="input-box">
                <div class="input-label">Agent Name</div>
                <div class="input_field">
                  <input
                    type="text"
                    class="input"
                    placeholder="Enter Agent Name"
                    name="agent_name"
                    value={addHotelFormik.values.agent_name}
                    onChange={addHotelFormik.handleChange}
                  />
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="input-box">
                <div class="input-label">Contact Number</div>
                <div class="input_field">
                  <input
                    type="text"
                    class="input"
                    placeholder="Enter Contact Number"
                    name="contact_numbers[0]"
                    pattern="\d{10}"
                    value={addHotelFormik.values.contact_numbers[0]}
                    onChange={(e) => {
                      addHotelFormik.setFieldValue(
                        "contact_numbers[0]",
                        e.target.value.replace(/\D/g, "")
                      );
                    }}
                  />
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="input-box">
                <div class="input-label">Email ID</div>
                <div class="input_field">
                  <input
                    type="email"
                    class="input"
                    placeholder="Enter Email ID"
                    name="email_ids[0]"
                    value={addHotelFormik.values.email_ids[0]}
                    onChange={(e) => {
                      addHotelFormik.setFieldValue(
                        "email_ids[0]",
                        e.target.value
                      );
                    }}
                  />
                </div>
              </div>
            </div>

            {/* <div className="col-lg-6">
              <button class="btn-theme dotted-border  mb-3">
                <img src="/static/media/add-brown.a8b841be67158a86e955fbe1801c5a9c.svg" />
                <span>Add Contact</span>
              </button>
            </div>
            <div className="col-lg-6">
              <button class="btn-theme dotted-border mb-3">
                <img src="/static/media/add-brown.a8b841be67158a86e955fbe1801c5a9c.svg" />
                <span>Add Email</span>
              </button>
            </div> */}
            <div class="col-lg-12">
              <div class="input-box">
                <div class="input-label">Address</div>
                <div class="input_field">
                  <input
                    type="text"
                    class="input"
                    placeholder="Enter Address"
                    name="address"
                    value={addHotelFormik.values.address}
                    onChange={addHotelFormik.handleChange}
                  />
                  {addHotelFormik.touched.address &&
                  addHotelFormik.errors.address ? (
                    <p className="error">{addHotelFormik.errors.address}</p>
                  ) : null}
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="input-box">
                <div class="input-label">No. of Rooms</div>
                <div class="input_field">
                  <input
                    type="text"
                    class="input"
                    placeholder="Enter No. of Rooms"
                    name="total_rooms"
                    value={addHotelFormik.values.total_rooms}
                    onChange={(e) => {
                      addHotelFormik.setFieldValue(
                        "total_rooms",
                        e.target.value.replace(/\D/g, "")
                      );
                    }}
                  />
                  {addHotelFormik.touched.total_rooms &&
                  addHotelFormik.errors.total_rooms ? (
                    <p className="error">{addHotelFormik.errors.total_rooms}</p>
                  ) : null}
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="input-box">
                <div class="input-label">No. of Guests</div>
                <div class="input_field">
                  <input
                    type="text"
                    class="input"
                    placeholder="Enter No. of Guests"
                    name="number_of_guests"
                    value={addHotelFormik.values.number_of_guests}
                    onChange={(e) => {
                      addHotelFormik.setFieldValue(
                        "number_of_guests",
                        e.target.value.replace(/\D/g, "")
                      );
                    }}
                  />
                  {addHotelFormik.touched.number_of_guests &&
                  addHotelFormik.errors.number_of_guests ? (
                    <p className="error">
                      {addHotelFormik.errors.number_of_guests}
                    </p>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <div className="btn-box frm-sbmit-grp">
            <button
              type="button"
              className="btn-theme outline"
              onClick={() => {
                onCloseCanvas();
              }}
            >
              {" "}
              Cancel
            </button>
            <button type="submit" className="btn-theme bg-dark">
              Save
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default HotelAdd;
