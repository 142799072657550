import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
} from "react-router-dom";

import { ProtectedRoute } from "../routes/protectedRoutes";
import { PageNotFound } from "../pages/404Page";
import Dashboard from "../pages/dashboard";
import Login from "../pages/login";
import EventList from "../pages/events/eventList";
import EventAdd from "../pages/events/eventAdd";
import GuestList from "../pages/guests/guestList";
import GuestAdd from "../pages/guests/guestAdd";
import HotelList from "../pages/hotels/hoteList";
import HotelAdd from "../pages/hotels/hotesAdd";
import EventDetails from "../pages/events/eventDetails";
import EventEdit from "../pages/events/eventEdit";
import GuestDetails from "../pages/guests/guestDetails";
import LogisticList from "../pages/logistics/logisticsList";
import HotelRoomList from "../pages/roomBooking/roomBookingList";
import AllLogisticList from "../pages/logistics/allLogisticList";
import AllFlightList from "../pages/logistics/allFlightsList";
import ProfilePage from "../pages/profile";
import CommunicationPage from "../pages/communication/communication";
import ConversationPage from "../pages/conversation/whatsappchat";
import Chating from "../pages/communication/chatbox";
import SubEventList from "../pages/subEvetns/subEventsList";
import Settings from "../pages/settings";
import PrivacyPolicy from "../components/privacy-policy";
import FacebookCallback from "../components/facebook/callback.js";
import Plans from "../components/subscription/plans.js";
import WebhookHandler from "../components/webhookHandler.js";
import ManageTeams from "../components/teams/manageTeams.js";

const route = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route
        path="/"
        element={
          <ProtectedRoute>
            <Dashboard />
          </ProtectedRoute>
        }
      ></Route>
      {/* events routes */}
      <Route
        path="/events"
        element={
          <ProtectedRoute>
            <EventList />
          </ProtectedRoute>
        }
      ></Route>
      <Route
        path="/add-event"
        element={
          <ProtectedRoute>
            <EventAdd />
          </ProtectedRoute>
        }
      ></Route>
      <Route
        path="/edit-event/:id/:eventType"
        element={
          <ProtectedRoute>
            <EventEdit />
          </ProtectedRoute>
        }
      ></Route>
      <Route
        path="/event-details/:id"
        element={
          <ProtectedRoute>
            <EventDetails />
          </ProtectedRoute>
        }
      ></Route>
      <Route
        path="/sub-event/:eventId"
        element={
          <ProtectedRoute>
            <SubEventList />
          </ProtectedRoute>
        }
      ></Route>

      {/* guests routes */}
      <Route
        path="/guests/:eventId/:cpId"
        element={
          <ProtectedRoute>
            <GuestList />
          </ProtectedRoute>
        }
      ></Route>
      <Route
        path="/add-guest/:eventId"
        element={
          <ProtectedRoute>
            <GuestAdd />
          </ProtectedRoute>
        }
      ></Route>
      <Route
        path="/details-guest/:eventId/:guestId"
        element={
          <ProtectedRoute>
            <GuestDetails />
          </ProtectedRoute>
        }
      ></Route>
      <Route
        path="/edit-event/:id"
        element={
          <ProtectedRoute>
            <EventEdit />
          </ProtectedRoute>
        }
      ></Route>
      <Route
        path="/logistics/:eventId"
        element={
          <ProtectedRoute>
            <AllLogisticList />
          </ProtectedRoute>
        }
      ></Route>
      <Route
        path="/all-flight"
        element={
          <ProtectedRoute>
            <AllFlightList />
          </ProtectedRoute>
        }
      ></Route>
      <Route
        path="/subscription"
        element={
          <ProtectedRoute>
            <Plans />
          </ProtectedRoute>
        }
      ></Route>

      <Route
        path="/edit-event/:id"
        element={
          <ProtectedRoute>
            <EventAdd />
          </ProtectedRoute>
        }
      ></Route>

      {/* hotels routes */}
      <Route
        path="/hotels/:eventId"
        element={
          <ProtectedRoute>
            <HotelList />
          </ProtectedRoute>
        }
      ></Route>
      <Route
        path="/add-hotel"
        element={
          <ProtectedRoute>
            <HotelAdd />
          </ProtectedRoute>
        }
      ></Route>
      <Route
        path="/edit-event/:id"
        element={
          <ProtectedRoute>
            <EventAdd />
          </ProtectedRoute>
        }
      ></Route>

      {/* hotel rooms routes */}
      <Route
        path="/rooms/:hotelId/:eventId"
        element={
          <ProtectedRoute>
            <HotelRoomList />
          </ProtectedRoute>
        }
      ></Route>

      <Route
        path="/profile"
        element={
          <ProtectedRoute>
            <ProfilePage />
          </ProtectedRoute>
        }
      ></Route>
      <Route
        path="/webhook/"
        element={
          <ProtectedRoute>
            <WebhookHandler />
          </ProtectedRoute>
        }
      ></Route>

      <Route
        path="/facebook/callback"
        element={
          <ProtectedRoute>
            <FacebookCallback />
          </ProtectedRoute>
        }
      ></Route>

      <Route
        path="/settings"
        element={
          <ProtectedRoute>
            <Settings />
          </ProtectedRoute>
        }
      ></Route>

      <Route
        path="/communication/:eventId"
        element={
          <ProtectedRoute>
            <CommunicationPage />
          </ProtectedRoute>
        }
      ></Route>
      <Route
        path="/manage-teams"
        element={
          <ProtectedRoute>
            <ManageTeams />
          </ProtectedRoute>
        }
      ></Route>
      <Route
        path="/conversation/:eventId"
        element={
          <ProtectedRoute>
            <ConversationPage />
          </ProtectedRoute>
        }
      ></Route>
      <Route path="/privacy-policy" element={<PrivacyPolicy />}></Route>

      <Route
        path="/chating"
        element={
          <ProtectedRoute>
            <Chating />
          </ProtectedRoute>
        }
      ></Route>

      <Route path="/login" element={<Login />} />
      <Route path="*" element={<PageNotFound />} />
    </Route>
  )
);

export default route;
