import React from "react";
import { Modal, Button } from "react-bootstrap";

const MyModal = ({
  show,
  handleClose,
  showHeader = true,
  label,
  titleclass,
  ...props
}) => {
  const { children } = props;
  return (
    <Modal show={show} onHide={handleClose}>
      {showHeader ? (
        <Modal.Header closeButton>
          <Modal.Title className={titleclass}>{label}</Modal.Title>
        </Modal.Header>
      ) : (
        <></>
      )}
      <Modal.Body>{children}</Modal.Body>
    </Modal>
  );
};

export default MyModal;
