import React, { useEffect, useState } from "react";
import moment from "moment";
import { fetchAllFlightBookingsList } from "../../action/logistics";

const AllFlightList = () => {
  const [loading, setShowLoading] = useState(false);
  const [flightBookings, setFlightBookings] = useState([]);

  const getFlightBookingList = async () => {
    setShowLoading(true);
    const res = await fetchAllFlightBookingsList();
    if (res.status) {
      setShowLoading(false);
      setFlightBookings(res.data);
    }
  };

  useEffect(() => {
    getFlightBookingList();
  }, []);

  return (
    <div>
      <div class="dashboard_head">
        <div class="dashboard_head-left">
          <h3>All Flight Booking</h3>
        </div>
      </div>
      <div class="payout_data-content">
        <div class="payout_table-box">
          <table class="payout_table">
            <thead>
              <tr class="tableHead">
                <th>
                  Airline
                </th>
                <th>
                  Arrival airport
                </th>
                <th>
                  Arrival time
                </th>
                <th>
                  Departure airport
                </th>
                <th>
                  Departure time
                </th>
                <th>
                  Flight number
                </th>
                <th>
                  Ticket issued
                </th>
              </tr>
            </thead>
            <tbody>
              {!loading ? (
                !flightBookings?.length ? (
                  <tr>
                    <td colSpan={8} className="text-center">
                      No flights found
                    </td>
                  </tr>
                ) : (
                  flightBookings.map((value) => {
                    return (
                      <tr>
                        <td class="table_amount">{value.airline}</td>
                        <td>{value.arrival_airport}</td>
                        <td>
                          {" "}
                          {moment(value.arrival_time).format("DD/MM/YYYY")}
                        </td>
                        <td>{value.departure_airport}</td>
                        <td>
                          {" "}
                          {moment(value.departure_time).format("DD/MM/YYYY")}
                        </td>
                        <td>{value.flight_number}</td>
                        <td>{value.ticket_issued ? "True" : "False"}</td>
                      </tr>
                    );
                  })
                )
              ) : (
                <tr>
                  <td colSpan={9}>
                    <div className="loader-box">
                      <div className="loader"></div>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default AllFlightList;
