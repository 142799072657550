import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import addBrown from "../../assets/images/add-brown.svg";
import deleteIcon from "../../assets/images/trash-brown.svg";
import people from "../../assets/images/people.svg";
import sent from "../../assets/images/sent.svg";
import not from "../../assets/images/not.svg";
import wait from "../../assets/images/waiting.svg";
import wait2 from "../../assets/images/waiting2.svg";
import graph1 from "../../assets/images/grp1.svg";
import graph2 from "../../assets/images/logistics.svg";
import graph3 from "../../assets/images/acmdtion.svg";
import graph4 from "../../assets/images/cmt.svg";
import graphArrow from "../../assets/images/grpArrow.svg";
import leftChevoron from "../../assets/images/left-chevoron.svg";
import rightChevoron from "../../assets/images/right-chevoron.svg";
import ReactECharts from "echarts-for-react";
import editIcon from "../../assets/images/edit-brown.svg";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

// images
import calendarIcon from "../../assets/images/calendor.svg";
import locationIcon from "../../assets/images/location.svg";

import {
  fetchEventDetails,
  fetchEventFlightSummary,
  fetchEventSummary,
  removeEvent,
} from "../../action/events";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Offcanvas } from "react-bootstrap";
import SubEventAdd from "../subEvetns/addSubEvents";
import moment from "moment";
import MyModal from "../../components/model";
import ActiveEventCard from "../../components/eventCards/activeEventCard";
import { fetchHotelsSummary } from "../../action/hotels";
import { fetchSubEventSummary } from "../../action/subEvents";
import { fetchLogisticSummary } from "../../action/logistics";
import { changeHeaderState } from "../../reducer/slice/header";
import { useDispatch } from "react-redux";

const EventDetails = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [eventDetails, setEventDetails] = useState({});
  const [showdelete, setShowdelete] = useState(false);
  const [show, setShow] = useState(false);
  const handleDelete = () => setShow(!show);
  const [addSubEventCanvas, setAddSubEventCanvas] = useState(false);
  const [selectedDetails, setSelectedDetails] = useState({});
  const [hotelSummary, setHotelSummary] = useState([]);
  const [subEventList, setSubEventList] = useState([]);
  const [logisticSummary, setLogisticSummary] = useState([]);
  const [chartOptionNew, setChartOptionNew] = useState({});

  const [chartOption, setChartOption] = useState({
    tooltip: {
      trigger: "item",
    },
    legend: {
      top: "90%",
      left: "center",
      itemWidth: 14, // Width of the legend item
      itemHeight: 14, // Height of the legend item
      itemStyle: {
        borderRadius: 10, // Adjust this value for more or less rounding
      },
    },
    series: [
      {
        name: "Access From",
        type: "pie",
        radius: ["65%", "55%"],
        data: [
          { value:100, name: "Attending" },
          { value:100, name: "Canceled" },
        ],
      },
    ],
  });

  const [accomodationChartOption, setAccomodationChartOption] = useState({
    xAxis: {
      type: "category",
      data: ["Booked", "Alloted", "Vacant"],
    },
    yAxis: {
      type: "value",
    },
    show: true,
    series: [
      {
        data: [hotelSummary?.bocked??0, hotelSummary?.alloted??0, hotelSummary?.vacant??0],
        type: "bar",
        label: {
          show: true,
          position: "inside",
          Options: "bottom",
        },
        itemStyle: {
          color: "#FF5733", 
        },
      },
    ],
  });

  const [eventSummary, setEventSummary] = useState({});

  const getEventDetails = async () => {
    setAddSubEventCanvas(false);
    const res = await fetchEventDetails(id);
    if (res.status) {
      setEventDetails(res.data);
      dispatch(
        changeHeaderState({
          showDefault: false,
          headerTitle: res.data.name,
          navigationList: [
            { name: "Events", url: "/events" },
            {
              name: res.data.name,
              url: "",
            },
            {
              name: "Event Dashboard",
              url: "",
            },
          ],
        })
      );
    }
  };

  const getSubEvents = async () => {
    const res = await fetchSubEventSummary(id);
    if (res.status) {
      setSubEventList(res.data?.event_object);
    }
  };

  const getLogisticSummary = async () => {
    const res = await fetchLogisticSummary(id);
    if (res.status) {
      setLogisticSummary(res.data);
    }
  };

  const getEventSummary = async () => {
    setLoading(true);
    const res = await fetchEventSummary(id);
    if (res.status) {
      let updatedChartOption = { ...chartOption };
      
      updatedChartOption.series[0].data[0].value =
        res.data.objects?.guestDetails[0].attended_count ?? 0;
      updatedChartOption.series[0].data[1].value =
        res.data.objects?.guestDetails[0].not_attended_count ?? 0;
      
      setChartOption(updatedChartOption);
      setChartOptionNew(updatedChartOption);
      console.log("🚀 ~ getEventSummary ~ updatedChartOption:", updatedChartOption);
    }
  };
  const getHotelList = async () => {
    setLoading(true);
    const res = await fetchHotelsSummary(id);
    if (res.status) {
      let totalRoom = 0;
      let totalRoomBooked = 0;

      let testVar = { ...accomodationChartOption };

      res.data.map((value) => {
        totalRoom = totalRoom + value.total_rooms;
        totalRoomBooked = totalRoomBooked + value.room_booking_count;
      });

      testVar.series[0].data[0] = totalRoomBooked;
      testVar.series[0].data[1] = totalRoom;
      setAccomodationChartOption(testVar);
      setLoading(false);
    }
  };

  const deleteEvent = async (url, id) => {
    const res = await removeEvent(url, id);
    navigate("/events");
  };

  useEffect(() => {
    getEventDetails();
    getHotelList();
    getEventSummary();
    getSubEvents();
    getLogisticSummary();
  }, [id]);

  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <img src={rightChevoron} alt="" />,
    prevArrow: <img src={leftChevoron} alt="" />,
  };

  return (
    <div className="event-detail-sec">
      {!loading ? (
        <>
          <div className="row">
            <div className="col-lg-12">
              <div className="event-btn-bar">
                <div className="event-btn-bar-left">
                  {eventDetails.event_type === "Wedding" && (
                    <button
                      className="btn-theme dotted-border"
                    onClick={() => {
                      setAddSubEventCanvas(true);
                    }}
                  >
                    <img src={addBrown} alt="" /> <span>New Sub-Event</span>
                  </button>
                  )}
                </div>
                <div className="wrapper-of-icons">
                  <div
                    className="action-icon-box"
                    onClick={
                      () => {
                        navigate(`/edit-event/${id}`);
                      }} 
                  >
                    <img className="btn-icon" src={editIcon} alt='edit' />
                  </div>
                  <div className="event-btn-bar-right">
                    <button
                      className="iconbtn no-bg"
                      onClick={() => {
                        setShowdelete(!showdelete);
                        setSelectedDetails({
                          url:
                            eventDetails.event_type == "Wedding"
                              ? "weddings"
                              : eventDetails.event_type == "PreWedding"
                              ? "preweddings"
                              : "celebrations",
                          id: eventDetails.id,
                        });
                        handleDelete();
                      }}
                    >
                      <img src={deleteIcon} alt="delete" />
                    </button>
                  </div>
                </div>
              </div>
              <div className="row">
                <ActiveEventCard value={eventDetails} colClass={"col-lg-8"} />
                {eventDetails.event_type == "Wedding" ? (
                  <div className="col-lg-4">
                    <div className="event_detail-card_box">
                      <Slider {...settings}>
                        {subEventList?.sub_events?.map((value) => {
                          return (
                            <>
                              <div className="evnt_dtl-head">
                                <div className="evnt_dtl-head-left">
                                  <div className="evt_name">{value?.name}</div>
                                  <div className="evt_location">
                                    <img
                                      src="/static/media/location.9c6495a5ee8e34b10053845394dbbf68.svg"
                                      alt=""
                                    />
                                    <span class="feaicon">
                                      {value?.description}
                                    </span>
                                  </div>
                                </div>
                          
                              </div>
                              <div className="evnt_dtn-progressBar-box">
                                <div
                                  className="evnt_dtn-progressBar box1"
                                  style={{
                                    width: `${
                                      value?.guest_counts?.attended_count * 100
                                    }%`,
                                  }}
                                ></div>
                                <div
                                  className="evnt_dtn-progressBar box2"
                                  style={{
                                    width: `${
                                      value?.guest_counts?.not_attended_count *
                                      100
                                    }%`,
                                  }}
                                ></div>
                              </div>
                              <div className="invite_container">
                                <div className="invite-box">
                                  <img src={sent} alt="" />
                                  <span>Attending</span>
                                </div>
                                <div className="invite-box">
                                  <img src={not} alt="" />
                                  <span>Not attending</span>
                                </div>
                              </div>
                            </>
                          );
                        })}
                      </Slider>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
              <div className="row mt-3 g-2 mg-t-1 mgb-2">
                <div className="col-lg-3">
                  <div className="event_graph-card">
                    <div className="event_graph-card-head">
                      <div className="event_graph-card-head-left">
                        <img src={graph1} alt="" />
                        <span className="graph_ttl">Guest Management</span>
                      </div>
                      <div className="event_graph-card-head-right">
                        <Link to={`/guests/${eventDetails.id}/${eventDetails?.company}`}>
                          <img src={graphArrow} alt="" />
                        </Link>
                      </div>
                    </div>
                    <div className="event_graph-card-body">
                      <div className="event_graph-ttl">Total Guests</div>
                   
                      {!loading ? (
                        <ReactECharts
                          option={chartOptionNew}
                          style={{ height: "18vw", width: "100%" }}
                        />
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="event_graph-card">
                    <div className="event_graph-card-head">
                      <div className="event_graph-card-head-left">
                        <img src={graph2} alt="" />
                        <span className="graph_ttl">Logistics</span>
                      </div>
                      <div className="event_graph-card-head-right">
                      <Link to={`/logistics/${eventDetails.id}`}>
                          <img src={graphArrow} alt="" />
                        </Link>
                      </div>
                    </div>
                    <div className="event_graph-card-body">
                      <div className="event_graph-ttl">Tickets</div>
                      <div className="event_graph-content">
                        <div className="event_ticket">
                          <div className="ticket_count">
                            {logisticSummary?.flight}
                          </div>
                          <div className="ticket_status success">Flight</div>
                        </div>
                        <div className="event_ticket">
                          <div className="ticket_count">
                            {logisticSummary?.train}
                          </div>
                          <div className="ticket_status pending">Train</div>
                        </div>
                        <div className="event_ticket">
                          <div className="ticket_count">
                            {logisticSummary?.car}
                          </div>
                          <div className="ticket_status success">Car</div>
                        </div>
                        <div className="event_graph-btm">Train</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="event_graph-card">
                    <div className="event_graph-card-head">
                      <div className="event_graph-card-head-left">
                        <img src={graph3} alt="" />
                        <span className="graph_ttl" aria-readonly="true">Accommodation</span>
                      </div>
                      <div className="event_graph-card-head-right">
                      <Link to={`/hotels/${eventDetails.id}`}>
                          <img src={graphArrow} alt="" />
                        </Link>
                      </div>
                    </div>
                    <div className="event_graph-card-body">
                      <div className="event_graph-ttl">Total Rooms</div>

                      {!loading ? (
                        <ReactECharts
                          option={accomodationChartOption}
                          style={{ height: "18vw", width: "100%" }}
                        />
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
                {/* <div className="col-lg-3">
                  <div className="event_graph-card">
                    <div className="event_graph-card-head">
                      <div className="event_graph-card-head-left">
                        <img src={graph4} alt="" />
                        <span className="graph_ttl">Communication</span>
                      </div>
                      <div className="event_graph-card-head-right">
                        <img src={graphArrow} alt="" />
                      </div>
                    </div>
                    <div className="event_graph-card-body">
                      <div className="event_graph-content">
                        <div className="comunication_card">
                          <div className="comunication_card-text">
                            Save The Date
                          </div>
                          <div className="comunication_card-progress-Box">
                            <div
                              className="comunication_card-progress"
                              style={{ width: "70%" }}
                            ></div>
                          </div>
                          <div className="comunication_card-counts">
                            720/872
                          </div>
                        </div>
                        <div className="comunication_card">
                          <div className="comunication_card-text">
                            Save The Date
                          </div>
                          <div className="comunication_card-progress-Box">
                            <div
                              className="comunication_card-progress"
                              style={{ width: "70%" }}
                            ></div>
                          </div>
                          <div className="comunication_card-counts">
                            720/872
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          {" "}
          <div className="loader"></div>
        </>
      )}

      <MyModal
        label={""}
        titleclass={""}
        showHeader={false}
        show={show}
        handleClose={() => {
          setShow(false);
        }}
      >
        <div className="modelBody-content">
          <div className="del_ttl">
            Are you sure you want to delete this event?
          </div>
          <div className="del_txt">Event Name : {eventDetails.name}</div>
          <div class="btn-box model_btns">
            <button
              class="btn-theme outline modenBtn"
              onClick={() => {
                setShow(false);
              }}
            >
              Cancel
            </button>
            <button
              class="btn-theme bg-brown color_white modenBtn"
              onClick={() => {
                deleteEvent(selectedDetails.url, selectedDetails.id);
              }}
            >
              Delete
            </button>
          </div>
        </div>
      </MyModal>

      <Offcanvas
        show={addSubEventCanvas}
        onHide={() => {
          setAddSubEventCanvas(false);
        }}
        placement="end"
      >
        {eventDetails.event_type === "Wedding" && (
          <>
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>Sub Event Add</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <SubEventAdd closeCanvas={getEventDetails} />
            </Offcanvas.Body>
          </>
        )}
      </Offcanvas>
      
    </div>
  );
};

export default EventDetails;
