import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import { fetchEventDetails, getCompanyDetails, updateEvent } from "../../action/events";
import { getUserInfo } from "../../action/user";
import showNotification from "../../components/notification/notification";
import WeddingForm from "../../components/eventForms/weddingForm";
import PreWeddingForm from "../../components/eventForms/preWeddingForm";
import CelebrationForm from "../../components/eventForms/celebrationForm";

const EventEdit = () => {
  const { id, eventType } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [userinfo, setUser] = useState({});

  const processEventObject = (obj) => {
    return Object.keys(obj).reduce((acc, key) => {
      if (key === 'wedding_invite' || key === 'reception_invite') {
        try {
          // Handle 'nan' values and convert them to empty objects
          if (!obj[key] || obj[key] === 'nan' || obj[key] === '"nan"') {
            acc[key] = '{}';
          } else {
            // Ensure the value is a valid JSON string
            const parsed = typeof obj[key] === 'string' ?
              JSON.parse(obj[key]) :
              obj[key];
            acc[key] = JSON.stringify(parsed);
          }
        } catch (e) {
          acc[key] = '{}';
        }
      } else if (typeof obj[key] === 'object' && obj[key] !== null) {
        acc[key] = JSON.stringify(obj[key]);
      } else {
        acc[key] = obj[key];
      }
      return acc;
    }, {});
  };

  const [eventTypes] = useState([
    { value: "Wedding", url: "/api/weddings/" },
    { value: "PreWedding", url: "/api/preweddings/" },
    { value: "Celebration", url: "/api/celebrations/" },
  ]);

  const addEventFormik = useFormik({
    initialValues: {
      user: "",
      company: "",
      event_type: "",
      name: "",
      date: "",
      venue: "",
      total_guests: "",
      communication_email: "",
      status: "Active",
      number_of_subevents: "0",
      wedding_invite: "",
      reception_invite: "",
      weddingObj: {
        location: "",
        groom_name: "",
        bride_name: "",
        bride_family: [{ title: "", value: "" }],
        groom_family: [{ title: "", value: "" }],
        events_details: [{ title: "", value: "", date: "" }],
        flight_booking: false,
        ground_transport: false,
        event_dates: [],
        number_of_events: "0",
        wedding_invite: "",
        reception_invite: "",
      },
      preWeddingObj: {
        groom_name: "",
        bride_name: "",
        location: "",
        bride_family: [{ title: "", value: "" }],
        groom_family: [{ title: "", value: "" }],
        event_dates: [{ title: "", value: "", date: "" }],
        flight_booking: false,
        ground_transport: false,
        wedding_invite: "",
        reception_invite: "",
      },
      celebrationObj: {
        location: "",
        host_name: "",
        celebration_type: "",
        is_single_event: false,
        celebration_hotels: [{ title: "", value: "" }],
        event_details: [{ title: "", value: "", date: "" }],
        wedding_invite: "",
        reception_invite: "",
      },
    },
    validate: (values) => {
      const errors = {};

      // Event Type Validation
      if (!values.event_type) {
        errors.event_type = "Please select an event type.";
      }

      // Status Validation
      if (!values.status) {
        errors.status = "Please select a status.";
      }

      // Name Validation
      if (!values.name) {
        errors.name = "Name is required.";
      } else if (values.name.length < 2) {
        errors.name = "Name must be at least 2 characters long.";
      }

      // Venue Validation
      if (!values.venue) {
        errors.venue = "Venue is required.";
      }

      // Total Guests Validation
      if (!values.total_guests) {
        errors.total_guests = "Total guests are required.";
      } else if (!/^([1-9][0-9]{0,3}|10000)$/.test(values.total_guests)) {
        errors.total_guests = "Total guests must be between 1 and 10,000.";
      }

      // Date Validation
      if (!values.date) {
        errors.date = "Date is required.";
      }


      // Email Validation
      if (!values.communication_email) {
        errors.communication_email = "Email is required.";
      } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.communication_email)) {
        errors.communication_email = "Invalid email address.";
      }

      if (values.event_type === "Wedding") {
        // Number of subevents validation
        if (values.number_of_subevents && !/^([0-9]|[1-9][0-9])$/.test(values.number_of_subevents)) {
          errors.number_of_subevents = "Number of subevents must be between 0 and 99.";
        }
      }
      return errors;
    },
    onSubmit: async (values) => {
      let newMainObject;
      let url;

      if (values.event_type === "Wedding") {
        const { weddingObj, ...rest } = values;
        newMainObject = {
          ...processEventObject(weddingObj),
          ...rest
        };
      } else if (values.event_type === "PreWedding") {
        const { preWeddingObj, ...rest } = values;
        newMainObject = {
          ...processEventObject(preWeddingObj),
          ...rest
        };
      } else if (values.event_type === "Celebration") {
        const { celebrationObj, ...rest } = values;
        newMainObject = {
          ...processEventObject(celebrationObj),
          ...rest
        };
      }

      url = eventTypes.find(value => value.value === values.event_type)?.url;
      if (newMainObject && url) {
        await createEventApiFun(newMainObject, url);
      }
    },
  });

  const createEventApiFun = async (newMainObject, url) => {
    try {
      setLoading(true);

      // Handle wedding_invite and reception_invite at the top level
      if (!newMainObject.wedding_invite || newMainObject.wedding_invite === 'nan' || newMainObject.wedding_invite === '"nan"') {
        newMainObject.wedding_invite = '{}';
      }
      if (!newMainObject.reception_invite || newMainObject.reception_invite === 'nan' || newMainObject.reception_invite === '"nan"') {
        newMainObject.reception_invite = '{}';
      }

      // Ensure these fields are valid JSON strings
      try {
        if (typeof newMainObject.wedding_invite === 'string') {
          JSON.parse(newMainObject.wedding_invite);
        } else {
          newMainObject.wedding_invite = JSON.stringify(newMainObject.wedding_invite);
        }
      } catch (e) {
        newMainObject.wedding_invite = '{}';
      }

      try {
        if (typeof newMainObject.reception_invite === 'string') {
          JSON.parse(newMainObject.reception_invite);
        } else {
          newMainObject.reception_invite = JSON.stringify(newMainObject.reception_invite);
        }
      } catch (e) {
        newMainObject.reception_invite = '{}';
      }

      // Process the object before sending
      const processedObject = processEventObject(newMainObject);

      const res = await updateEvent(id, processedObject, url);
      if (res?.status) {
        showNotification("Event Updated successfully", "success");
        navigate("/events");
      } else {
        showNotification(res?.message || "Failed to update event", "danger");
      }
    } catch (error) {
      showNotification("An error occurred while updating the event", "danger");
      console.error("Update event error:", error);
    } finally {
      setLoading(false);
    }
  };

  // type 1 for bride 2 for groom 3 for event Details
  const addMoreFamilyMembers = (type, pertype) => {

    if (pertype == 'wedding') {
      if (type == 1) {
        addEventFormik.setFieldValue("weddingObj.bride_family", [
          ...addEventFormik.values.weddingObj.bride_family,
          { title: "", value: "" },
        ]);
      } else if (type == 2) {
        addEventFormik.setFieldValue("weddingObj.groom_family", [
          ...addEventFormik.values.weddingObj.groom_family,
          { title: "", value: "" },
        ]);
      } else if (type == 3) {
        addEventFormik.setFieldValue("weddingObj.events_details", [
          ...addEventFormik.values.weddingObj.events_details,
          { title: "", value: "" },
        ]);
      }
    } else {
      if (type == 1) {
        addEventFormik.setFieldValue("preWeddingObj.bride_family", [
          ...addEventFormik.values.preWeddingObj.bride_family,
          { title: "", value: "" },
        ]);
      } else if (type == 2) {
        addEventFormik.setFieldValue("preWeddingObj.groom_family", [
          ...addEventFormik.values.preWeddingObj.groom_family,
          { title: "", value: "" },
        ]);
      } else if (type == 3) {
        addEventFormik.setFieldValue("preWeddingObj.events_details", [
          ...addEventFormik.values.preWeddingObj.events_details,
          { title: "", value: "" },
        ]);
      }
    }
  };

  // type 1 for bride 2 for groom 3 for event Details
  const deleteFamilyMembers = (type, ind, pertype) => {
    if (pertype == 'wedding') {
      if (type == 1) {
        addEventFormik.setFieldValue(
          "weddingObj.bride_family",
          addEventFormik.values.weddingObj.bride_family.filter(
            (value, index) => index != ind
          )
        );
      } else if (type == 2) {
        addEventFormik.setFieldValue(
          "weddingObj.groom_family",
          addEventFormik.values.weddingObj.groom_family.filter(
            (value, index) => index != ind
          )
        );
      } else if (type == 3) {
        addEventFormik.setFieldValue(
          "weddingObj.events_details",
          addEventFormik.values.weddingObj.events_details.filter(
            (value, index) => index != ind
          )
        );
      }
    } else {
      if (type == 1) {
        addEventFormik.setFieldValue(
          "preWeddingObj.bride_family",
          addEventFormik.values.preWeddingObj.bride_family.filter(
            (value, index) => index != ind
          )
        );
      } else if (type == 2) {
        addEventFormik.setFieldValue(
          "preWeddingObj.groom_family",
          addEventFormik.values.preWeddingObj.groom_family.filter(
            (value, index) => index != ind
          )
        );
      } else if (type == 3) {
        addEventFormik.setFieldValue(
          "preWeddingObj.events_details",
          addEventFormik.values.preWeddingObj.events_details.filter(
            (value, index) => index != ind
          )
        );
      }
    }
  };

  const getCompany = async () => {
    try {
      const res = await getCompanyDetails();
      if (res.status) {
        addEventFormik.setFieldValue("company", res.data.id);
      } else {
        showNotification("Failed to fetch company details", "warning");
      }
    } catch (error) {
      console.error("Get company error:", error);
      showNotification("Error fetching company details", "danger");
    }
  };

  const getUser = async () => {
    try {
      const res = await getUserInfo();
      if (res.status) {
        addEventFormik.setFieldValue("user", res.data.id);
      } else {
        showNotification("Failed to fetch user info", "warning");
      }
    } catch (error) {
      console.error("Get user error:", error);
      showNotification("Error fetching user info", "danger");
    }
  };

  const getEventDetails = async () => {
    try {
      setLoading(true);
      const res = await fetchEventDetails(id);
      if (res.status) {
        const { event_object, ...eventData } = res.data;

        // Set basic event details
        Object.keys(eventData).forEach(key => {
          // Handle special object fields
          if (key === 'wedding_invite' || key === 'reception_invite') {
            try {
              // Ensure we store these as strings
              const value = eventData[key];
              if (value === 'nan' || value === '"nan"' || !value) {
                addEventFormik.setFieldValue(key, '{}');
              } else {
                const parsedValue = typeof value === 'string' ? value : JSON.stringify(value);
                addEventFormik.setFieldValue(key, parsedValue);
              }
            } catch (e) {
              addEventFormik.setFieldValue(key, '{}');
            }
          }
          // Parse any stringified objects
          else if (typeof eventData[key] === 'string' && (eventData[key].startsWith('{') || eventData[key].startsWith('['))) {
            try {
              addEventFormik.setFieldValue(key, JSON.parse(eventData[key]));
            } catch (e) {
              addEventFormik.setFieldValue(key, eventData[key]);
            }
          } else {
            addEventFormik.setFieldValue(key, eventData[key]);
          }
        });

        // Set event object details based on event type
        if (event_object) {
          const eventObjKey = `${eventData.event_type.toLowerCase()}Obj`;
          Object.keys(event_object).forEach(key => {
            const value = event_object[key];
            // Handle special object fields
            if (key === 'wedding_invite' || key === 'reception_invite') {
              try {
                // Ensure we store these as strings
                if (value === 'nan' || value === '"nan"' || !value) {
                  addEventFormik.setFieldValue(`${eventObjKey}.${key}`, '{}');
                } else {
                  const parsedValue = typeof value === 'string' ? value : JSON.stringify(value);
                  addEventFormik.setFieldValue(`${eventObjKey}.${key}`, parsedValue);
                }
              } catch (e) {
                addEventFormik.setFieldValue(`${eventObjKey}.${key}`, '{}');
              }
            }
            // Parse any stringified objects
            else if (typeof value === 'string' && (value.startsWith('{') || value.startsWith('['))) {
              try {
                addEventFormik.setFieldValue(`${eventObjKey}.${key}`, JSON.parse(value));
              } catch (e) {
                addEventFormik.setFieldValue(`${eventObjKey}.${key}`, value);
              }
            } else {
              addEventFormik.setFieldValue(`${eventObjKey}.${key}`, value);
            }
          });
        }
      } else {
        showNotification("Failed to fetch event details", "warning");
      }
    } catch (error) {
      console.error("Get event details error:", error);
      showNotification("Error fetching event details", "danger");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCompany();
    if (id) {
      getEventDetails();
    }

    getUser();


  }, [id]);

  return (
    <div>
      <div className="dashboard_head">
        <div className="dashboard_head-left">
          <h2 className="dashboard_title">Edit Event</h2>
        </div>
      </div>
      <div className="payout_data-content">
        {loading ? (
          <div className="d-flex justify-content-center align-items-center" style={{ minHeight: "200px" }}>
            <div className="loader"></div>
          </div>
        ) : (
          <form onSubmit={addEventFormik.handleSubmit} className="event-edit-form">
            <div className="row">
              <div className="col-lg-6">
                <div className="input-box">
                  <label className="input-label">Select Event</label>
                  <div className="input_field">
                    <select
                      name="event_type"
                      disabled={true}
                      value={addEventFormik.values.event_type}
                      onChange={addEventFormik.handleChange}
                      onBlur={addEventFormik.handleBlur}
                      className="input form-select"
                    >
                      <option value="">Select Event Type</option>
                      {eventTypes.map((value, index) => (
                        <option key={index} value={value.value}>
                          {value.value}
                        </option>
                      ))}
                    </select>
                  </div>
                  {addEventFormik.touched.event_type && addEventFormik.errors.event_type && (
                    <div className="error text-danger">{addEventFormik.errors.event_type}</div>
                  )}
                </div>
                <div className="input-box">
                  <label className="input-label">Event Name</label>
                  <div className="input_field">
                    <input
                      name="name"
                      value={addEventFormik.values.name}
                      onChange={addEventFormik.handleChange}
                      onBlur={addEventFormik.handleBlur}
                      type="text"
                      className="input"
                      placeholder="Enter name"
                    />
                  </div>
                  {addEventFormik.touched.name && addEventFormik.errors.name && (
                    <div className="error text-danger">{addEventFormik.errors.name}</div>
                  )}
                </div>
                <div className="input-box">
                  <label className="input-label">Venue</label>
                  <div className="input_field">
                    <input
                      name="venue"
                      value={addEventFormik.values.venue}
                      onChange={addEventFormik.handleChange}
                      onBlur={addEventFormik.handleBlur}
                      type="text"
                      className="input"
                      placeholder="Enter venue"
                    />
                  </div>
                  {addEventFormik.touched.venue && addEventFormik.errors.venue && (
                    <div className="error text-danger">{addEventFormik.errors.venue}</div>
                  )}
                </div>
                <div className="input-box">
                  <label className="input-label">Select Date</label>
                  <div className="input_field">
                    <input
                      type="date"
                      className="input"
                      min={moment().format("YYYY-MM-DD")}
                      placeholder="Select Date"
                      name="date"
                      value={addEventFormik.values.date}
                      onChange={addEventFormik.handleChange}
                      onBlur={addEventFormik.handleBlur}
                    />
                  </div>
                  {addEventFormik.touched.date && addEventFormik.errors.date && (
                    <div className="error text-danger">{addEventFormik.errors.date}</div>
                  )}
                </div>
              </div>
              <div className="col-lg-6">
                <div className="input-box">
                  <label className="input-label">Total Guests</label>
                  <div className="input_field">
                    <input
                      type="text"
                      className="input"
                      placeholder="Enter Number of Guests"
                      name="total_guests"
                      value={addEventFormik.values.total_guests}
                      onChange={(e) => {
                        addEventFormik.setFieldValue(
                          "total_guests",
                          e.target.value.replace(/\D/g, "")
                        );
                      }}
                      onBlur={addEventFormik.handleBlur}
                    />
                  </div>
                  {addEventFormik.touched.total_guests && addEventFormik.errors.total_guests && (
                    <div className="error text-danger">{addEventFormik.errors.total_guests}</div>
                  )}
                </div>
                <div className="input-box">
                  <label className="input-label">Email</label>
                  <div className="input_field">
                    <input
                      type="text"
                      className="input"
                      placeholder="Enter Email"
                      name="communication_email"
                      value={addEventFormik.values.communication_email}
                      onChange={addEventFormik.handleChange}
                      onBlur={addEventFormik.handleBlur}
                    />
                  </div>
                  {addEventFormik.touched.communication_email && addEventFormik.errors.communication_email && (
                    <div className="error text-danger">{addEventFormik.errors.communication_email}</div>
                  )}
                </div>
                {addEventFormik.values.event_type === "Wedding" && (
                  <div className="input-box">
                    <label className="input-label">Number of sub events</label>
                    <div className="input_field">
                      <input
                        type="text"
                        className="input"
                        placeholder="Enter number of sub events"
                        name="number_of_subevents"
                        value={addEventFormik.values?.number_of_subevents}
                        onChange={(e) => {
                          addEventFormik.setFieldValue(
                            "number_of_subevents",
                            e.target.value.replace(/\D/g, "")
                          );
                        }}
                        onBlur={addEventFormik.handleBlur}
                      />
                    </div>
                    {addEventFormik.touched.number_of_subevents && addEventFormik.errors.number_of_subevents && (
                      <div className="error text-danger">{addEventFormik.errors.number_of_subevents}</div>
                    )}
                  </div>
                )}
              </div>

              {addEventFormik.values.event_type && (
                <div className="col-12 mt-4">
                  {addEventFormik.values.event_type === "Wedding" ? (
                    <WeddingForm
                      addEventFormik={addEventFormik}
                      addMoreFamilyMembers={addMoreFamilyMembers}
                      deleteFamilyMembers={deleteFamilyMembers}
                    />
                  ) : addEventFormik.values.event_type === "PreWedding" ? (
                    <PreWeddingForm
                      addEventFormik={addEventFormik}
                      addMoreFamilyMembers={addMoreFamilyMembers}
                      deleteFamilyMembers={deleteFamilyMembers}
                    />
                  ) : addEventFormik.values.event_type === "Celebration" ? (
                    <CelebrationForm
                      addEventFormik={addEventFormik}
                      addMoreFamilyMembers={addMoreFamilyMembers}
                      deleteFamilyMembers={deleteFamilyMembers}
                    />
                  ) : null}
                </div>
              )}
            </div>


            <div className="d-flex justify-content-end mt-4 mb-4">
              <button
                type="submit"
                className="btn btn-dark px-4"
                disabled={loading || !addEventFormik.isValid}
              >
                {loading ? (
                  <>
                    <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                    Updating...
                  </>
                ) : (
                  'Update Event'
                )}
              </button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default EventEdit;
