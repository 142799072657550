import React from "react";
import "../../styles/style.css";

const PrivacyPolicy = () => {
  return (
    <div class="privacy-policy">
      <h1>Privacy Policy</h1>
      <span class="privacy-text-bold">
        Welcome to wedtechpro.com (the “Site”). We understand that privacy
        online is important to users of our Site, especially when conducting
        business. This statement governs our privacy policies with respect to
        those users of the Site (“Visitors”) who visit without transacting
        business and Visitors who register to transact business on the Site and
        make use of the various services offered by Kogniti fzco (collectively,
        “Services”) (“Authorized Customers”). “Personally Identifiable
        Information”
      </span>
      <span class="privacy-text-light">
        refers to any information that identifies or can be used to identify,
        contact, or locate the person to whom such information pertains,
        including, but not limited to, name, address, phone number, fax number,
        email address, financial profiles, social security number, and credit
        card information. Personally Identifiable Information does not include
        information that is collected anonymously (that is, without
        identification of the individual user) or demographic information not
        connected to an identified individual.
      </span>
      <p class="privacy-text-bold">
        What Personally Identifiable Information is collected?
      </p>
      <p class="privacy-text-light">
        We may collect basic user profile information from all of our Visitors.
        We collect the following additional information from our Authorized
        Customers: the name, email address, phone number, address, social media
        profile information,
      </p>
      <p class="privacy-text-bold">
        What organizations are collecting the information?
      </p>
      <p class="privacy-text-light">
        In addition to our direct collection of information, our third party
        service vendors (such as credit card companies, clearinghouses and
        banks) who may provide such services as credit, insurance, and escrow
        services may collect this information from our Visitors and Authorized
        Customers. We do not control how these third parties use such
        information, but we do ask them to disclose how they use personal
        information provided to them from Visitors and Authorized Customers.
        Some of these third parties may be intermediaries that act solely as
        links in the distribution chain, and do not store, retain, or use the
        information given to them.
      </p>
      <p class="privacy-text-bold">
        How does the Site use Personally Identifiable Information?
      </p>
      <p class="privacy-text-light">
        We use Personally Identifiable Information to customize the Site, to
        make appropriate service offerings, and to fulfill buying and selling
        requests on the Site. We may email Visitors and Authorized Customers
        about research or purchase and selling opportunities on the Site or
        information related to the subject matter of the Site. We may also use
        Personally Identifiable Information to contact Visitors and Authorized
        Customers in response to specific inquiries, or to provide requested
        information.
      </p>
      <p class="privacy-text-bold">
        With whom may the information may be shared?
      </p>
      <p class="privacy-text-light">
        Personally Identifiable Information about Authorized Customers may be
        shared with other Authorized Customers who wish to evaluate potential
        transactions with other Authorized Customers. We may share aggregated
        information about our Visitors, including the demographics of our
        Visitors and Authorized Customers, with our affiliated agencies and
        third party vendors. We also offer the opportunity to “opt out” of
        receiving information or being contacted by us or by any agency acting
        on our behalf.
      </p>
      <p class="privacy-text-bold">
        How is Personally Identifiable Information stored?
      </p>
      <p class="privacy-text-light">
        Personally Identifiable Information collected by Kogniti fzco is
        securely stored and is not accessible to third parties or employees of
        Kogniti fzco except for use as indicated above.
      </p>
      <p class="privacy-text-bold">
        What choices are available to Visitors regarding collection, use and
        distribution of the information?
      </p>
      <p class="privacy-text-light">
        Visitors and Authorized Customers may opt out of receiving unsolicited
        information from or being contacted by us and/or our vendors and
        affiliated agencies by responding to emails as instructed, or by
        contacting us at help@wedtechpro.com
      </p>
      <p class="privacy-text-bold">Cookies</p>
      <p class="privacy-text-light">
        A cookie is a string of information that a website stores on a visitor’s
        computer, and that the visitor’s browser provides to the website each
        time the visitor returns. We use “cookies” to collect information. You
        can instruct your browser to refuse all cookies or to indicate when a
        cookie is being sent. However, if you do not accept cookies, you may not
        be able to use some portions of our Service.
      </p>
      <p class="privacy-text-bold">Are Cookies Used on the Site?</p>
      <p class="privacy-text-light">
        are used for a variety of reasons. We use Cookies to obtain information
        about the preferences of our Visitors and the services they select. We
        also use Cookies for security purposes to protect our Authorized
        Customers. For example, if an Authorized Customer is logged on and the
        site is unused for more than 10 minutes, we will automatically log the
        Authorized Customer off. Visitors who do not wish to have cookies placed
        on their computers should set their browsers to refuse cookies before
        using https://kogniti.ai, with the drawback that certain features of
        website may not function properly without the aid of cookies.
      </p>
      <p class="privacy-text-bold">Cookies used by our service providers </p>
      <p class="privacy-text-light">
        Our service providers use cookies and those cookies may be stored on
        your computer when you visit our website. You can find more details
        about which cookies are used in our cookies info page.
      </p>
      <p class="privacy-text-bold">
        How does Kogniti fzco use login information?{" "}
      </p>
      <p class="privacy-text-light">
        Kogniti fzco uses login information, including, but not limited to, IP
        addresses, ISPs, and browser types, browser version, pages visited, date
        and time of visit, to analyze trends, administer the Site, track a
        user’s movement and use, and gather broad demographic information.
      </p>
      <p class="privacy-text-bold">
        What partners or service providers have access to Personally
        Identifiable Information from Visitors and/or Authorized Customers on
        the Site?
      </p>
      <p class="privacy-text-light">
        Kogniti fzco hasentered into and will continue to enter into
        partnerships and other affiliations with a number of vendors. Such
        vendors may have access to certain Personally Identifiable Information
        on a need to know the basis for evaluating Authorized Customers for
        service eligibility. Our privacy policy does not cover their collection
        or use of this information.
      </p>
      <p class="privacy-text-bold">
        How does the Site keep Personally Identifiable Information secure?
      </p>
      <p class="privacy-text-light">
        All of our employees are familiar with our security policy and
        practices. The Personally Identifiable Information of our Visitors and
        Authorized Customers is only accessible to a limited number of qualified
        employees who are given a password in order to gain access to the
        information. We audit our security systems and processes on a regular
        basis. Sensitive information, such as credit card numbers or social
        security numbers, is protected by encryption protocols, in place to
        protect information sent over the Internet. While we take commercially
        reasonable measures to maintain a secure site, electronic communications
        and databases are subject to errors, tampering, and break-ins, and we
        cannot guarantee or warrant that such events will not take place and we
        will not be liable to Visitors or Authorized Customers for any such
        occurrences.
      </p>
      <p class="privacy-text-bold">
        How can Visitors correct any inaccuracies in Personally Identifiable
        Information?
      </p>
      <p class="privacy-text-light">
        Visitors and Authorized Customers may contact us to update Personally
        Identifiable Information about them or to correct any inaccuracies by
        emailing us at help@wedtechpro.com .
      </p>
      <p class="privacy-text-bold">
        Can a Visitor delete or deactivate Personally Identifiable Information
        collected by the Site?
      </p>
      <p class="privacy-text-light">
        We provide Visitors and Authorized Customers with a mechanism to
        delete/deactivate Personally Identifiable Information from the Site’s
        database by contacting. However, because of backups and records of
        deletions, it may be impossible to delete a Visitor’s entry without
        retaining some residual information. An individual who requests to have
        Personally Identifiable Information deactivated will have this
        information functionally deleted, and we will not sell, transfer, or use
        Personally Identifiable Information relating to that individual in any
        way moving forward.
      </p>
      <p class="privacy-text-bold">Your rights </p>
      <p class="privacy-text-light">
        These are summarized rights that you have under data protection law
      </p>
      <ul style={{ listStyleType: "disc", marginLeft: "20px" }}>
        <li>The right to access</li>
        <li>The right to rectification</li>
        <li>The right to erasure</li>
        <li>The right to restrict processing</li>
        <li>The right to object to processing</li>
        <li>The right to data portability</li>
        <li>The right to complain to a supervisory authority</li>
        <li>The right to withdraw consent</li>
      </ul>
      <p class="privacy-text-bold">Children’s</p>
      <p class="privacy-text-light">
        Privacy Our Service does not address “Children”, anyone under the age of
        18 years , and we do not knowingly collect personally identifiable
        information from children under 18 years. If you are a parent or
        guardian and you are aware that your child has provided us with Personal
        Information, please get in touch with us immediately in the contact
        details provided. If we come to know that children below 18 years have
        provided personal information, we will delete the information from our
        servers immediately.
      </p>
      <p class="privacy-text-bold">Compliance With Laws</p>
      <p class="privacy-text-light">
        Disclosure of Personally Identifiable Information to comply with the
        law. We will disclose Personally Identifiable Information in order to
        comply with a court order or subpoena or a request from a law
        enforcement agency to release information. We will also disclose
        Personally Identifiable Information when reasonably necessary to protect
        the safety of our Visitors and Authorized Customers.
      </p>
      <p class="privacy-text-bold">
        What happens if the Privacy Policy Changes?
      </p>
      <p class="privacy-text-light">
        We will let our Visitors and Authorized Customers know about changes to
        our privacy policy by posting such changes on the Site. However, if we
        are changing our privacy policy in a manner that might cause disclosure
        of Personally Identifiable Information that a Visitor or Authorized
        Customer has previously requested not be disclosed, we will contact such
        Visitor or Authorized Customer to allow such Visitor or Authorized
        Customer to prevent such disclosure.
      </p>
      <p class="privacy-text-bold">Contact Us </p>
      <span class="privacy-text-light" style={{ paddingRight: "15px" }}>
        If you have any questions about this Privacy Policy, please contact us
        at
      </span>
      <a
        href="https://www.help@wedtechpro.com"
        style={{ textDecoration: "underline", color: "blue" }}
      >
        help@wedtechpro.com
      </a>
      <p class="privacy-text-light">Last Updated: June 24, 2024 </p>
    </div>
  );
};

export default PrivacyPolicy;
