import { useFormik } from "formik";
import moment from "moment";
import React, { useEffect, useState } from "react";
import {

  fetchEventDetails,
  getCompanyDetails,
  updateEvent,
} from "../../action/events";
import {
  getUserInfo,
  
} from "../../action/user";
import { useNavigate, useParams } from "react-router-dom";
import showNotification from "../../components/notification/notification";
import WeddingForm from "../../components/eventForms/weddingForm";
import PreWeddingForm from "../../components/eventForms/preWeddingForm";
import CelebrationForm from "../../components/eventForms/celebrationForm";

const EventEdit = () => {
  const { id, eventType } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [userinfo, setUser] = useState({});
  
  const [eventTypes] = useState([
    { value: "Wedding", url: "/api/weddings/" },
    { value: "PreWedding", url: "/api/preweddings/" },
    { value: "Celebration", url: "/api/celebrations/" },
  ]);

  const addEventFormik = useFormik({
    initialValues: {
      user:"",
      company: "",
      event_type: "",
      name: "",
      date: "",
      venue: "",
      total_guests: "",
      communication_email: "",
      communication_whatsapp: "",
      status: "Active",
      number_of_subevents: "0",
      weddingObj: {
        location: "",
        groom_name: "",
        bride_name: "",
        bride_family: [{ title: "", value: "" }],
        groom_family: [{ title: "", value: "" }],
        events_details: [{ title: "", value: "", date: "" }],
        flight_booking: false,
        ground_transport: false,
        event_dates: [],
        number_of_events: "0",
      },
      preWeddingObj: {
        groom_name: "",
        bride_name: "",
        location: "",
        bride_family: [{ title: "", value: "" }],
        groom_family: [{ title: "", value: "" }],
        event_dates: [{ title: "", value: "", date: "" }],
        flight_booking: false,
        ground_transport: false,
      },
      celebrationObj: {
        location: "",
        host_name: "",
        celebration_type: "",
        is_single_event: false,
        celebration_hotels: [{ title: "", value: "" }],
        event_details: [{ title: "", value: "", date: "" }],
      },
    },
    validate: (values) => {
      const errors = {};
    
      // Event Type Validation
      if (!values.event_type) {
        errors.event_type = "Please select an event type.";
      }
    
      // Status Validation
      if (!values.status) {
        errors.status = "Please select a status.";
      }
    
      // Name Validation
      if (!values.name) {
        errors.name = "Name is required.";
      }
    
      // Venue Validation
      if (!values.venue) {
        errors.venue = "Venue is required.";
      }
    
      // Total Guests Validation
      if (!values.total_guests) {
        errors.total_guests = "Total guests are required.";
      } else if (!/^([1-9][0-9]{0,2}|10000)$/.test(values.total_guests)) {
        errors.total_guests = "Total guests number must be between 1 and 10,000.";
      }
    
      // Date Validation
      if (!values.date) {
        errors.date = "Date is required.";
      }
    
      // WhatsApp Number Validation
      if (!values.communication_whatsapp) {
        errors.communication_whatsapp = "WhatsApp number is required.";
      } else if (!/^\d{10}$/.test(values.communication_whatsapp)) {
        errors.communication_whatsapp = "WhatsApp number must be a valid 10-digit number.";
      }
    
      // Email Validation
      if (!values.communication_email) {
        errors.communication_email = "Email is required.";
      } else if (!/^\S+@\S+\.\S+$/.test(values.communication_email)) {
        errors.communication_email = "Email must be a valid email address.";
      }
    
      console.log('Validation errors:', errors);
      return errors;
    },
    onSubmit: async (values) => {
   
      console.log('Form submitted with values:', values);
      if (values.event_type == "Wedding") {
        delete values.preWeddingObj;
        delete values.celebrationObj;
        const { weddingObj, ...rest } = values;
        const newMainObject = { ...rest, ...weddingObj };
        const url = eventTypes.find(
          (value) => value.value == values.event_type
        ).url;
        createEventApiFun(newMainObject, url);
      }
      if (values.event_type == "PreWedding") {
        delete values.weddingObj;
        delete values.celebrationObj;
        const { preWeddingObj, ...rest } = values;
        const newMainObject = { ...rest, ...preWeddingObj };
        const url = eventTypes.find(
          (value) => value.value == values.event_type
        ).url;
        createEventApiFun(newMainObject, url);
      }
      if (values.event_type == "Celebration") {
        delete values.preWeddingObj;
        delete values.weddingObj;
        const { celebrationObj, ...rest } = values;
        const newMainObject = { ...rest, ...celebrationObj };
        const url = eventTypes.find(
          (value) => value.value == values.event_type
        ).url;
        createEventApiFun(newMainObject, url);
      }
    },
  });

  const createEventApiFun = async (newMainObject, url) => {
    const res = await updateEvent(id, newMainObject, url);
    if (res?.status) {
      showNotification("Event Updated successfully", "success");
      navigate("/events");
    } else {
      showNotification(res?.message, "danger");
    }
  };

  // type 1 for bride 2 for groom 3 for event Details
  const addMoreFamilyMembers = (type,pertype) => {

    if(pertype == 'wedding'){
    if (type == 1) {
      addEventFormik.setFieldValue("weddingObj.bride_family", [
        ...addEventFormik.values.weddingObj.bride_family,
        { title: "", value: "" },
      ]);
    } else if (type == 2) {
      addEventFormik.setFieldValue("weddingObj.groom_family", [
        ...addEventFormik.values.weddingObj.groom_family,
        { title: "", value: "" },
      ]);
    } else if (type == 3) {
      addEventFormik.setFieldValue("weddingObj.events_details", [
        ...addEventFormik.values.weddingObj.events_details,
        { title: "", value: "" },
      ]);
    }
  }else{
    if (type == 1) {
      addEventFormik.setFieldValue("preWeddingObj.bride_family", [
        ...addEventFormik.values.preWeddingObj.bride_family,
        { title: "", value: "" },
      ]);
    } else if (type == 2) {
      addEventFormik.setFieldValue("preWeddingObj.groom_family", [
        ...addEventFormik.values.preWeddingObj.groom_family,
        { title: "", value: "" },
      ]);
    } else if (type == 3) {
      addEventFormik.setFieldValue("preWeddingObj.events_details", [
        ...addEventFormik.values.preWeddingObj.events_details,
        { title: "", value: "" },
      ]);
    }
  }
  };

  // type 1 for bride 2 for groom 3 for event Details
  const deleteFamilyMembers = (type, ind,pertype) => {
    if(pertype =='wedding'){
    if (type == 1) {
      addEventFormik.setFieldValue(
        "weddingObj.bride_family",
        addEventFormik.values.weddingObj.bride_family.filter(
          (value, index) => index != ind
        )
      );
    } else if (type == 2) {
      addEventFormik.setFieldValue(
        "weddingObj.groom_family",
        addEventFormik.values.weddingObj.groom_family.filter(
          (value, index) => index != ind
        )
      );
    } else if (type == 3) {
      addEventFormik.setFieldValue(
        "weddingObj.events_details",
        addEventFormik.values.weddingObj.events_details.filter(
          (value, index) => index != ind
        )
      );
    }
  }else{
    if (type == 1) {
      addEventFormik.setFieldValue(
        "preWeddingObj.bride_family",
        addEventFormik.values.preWeddingObj.bride_family.filter(
          (value, index) => index != ind
        )
      );
    } else if (type == 2) {
      addEventFormik.setFieldValue(
        "preWeddingObj.groom_family",
        addEventFormik.values.preWeddingObj.groom_family.filter(
          (value, index) => index != ind
        )
      );
    } else if (type == 3) {
      addEventFormik.setFieldValue(
        "preWeddingObj.events_details",
        addEventFormik.values.preWeddingObj.events_details.filter(
          (value, index) => index != ind
        )
      );
    }
  }
  };

  const getCompany = async () => {
    const res = await getCompanyDetails();
    if (res.status) {
      addEventFormik.setFieldValue("company", res.data.id);
    }
  };
  const getUser = async () => {
    const res = await getUserInfo();
    if (res.status) {
      addEventFormik.setFieldValue("user", res.data.id);
    }
  };

  const getEventDetails = async () => {
    setLoading(true);
    const res = await fetchEventDetails(id);
    if (res.status) {
      console.log('res : '+ res.data);
      addEventFormik.setFieldValue("event_type", res.data.event_type);
      addEventFormik.setFieldValue("total_guests", res.data.total_guests);
      addEventFormik.setFieldValue("date", res.data.date);
      addEventFormik.setFieldValue("name", res.data.name);
      addEventFormik.setFieldValue(
        "number_of_subevents",
        res.data.event_object.number_of_subevents
      );
      addEventFormik.setFieldValue(
        "communication_whatsapp",
        res.data.communication_whatsapp
      );
      addEventFormik.setFieldValue("venue", res.data.venue);
      addEventFormik.setFieldValue(
        "communication_email",
        res.data.communication_email
      );

      if (res.data.event_type == "Wedding") {
        addEventFormik.setFieldValue("status", res.data.status);
        addEventFormik.setFieldValue(
          "weddingObj.location",
          res.data.event_object.location
        );
        addEventFormik.setFieldValue(
          "weddingObj.bride_name",
          res.data.event_object.bride_name
        );
        addEventFormik.setFieldValue(
          "weddingObj.groom_name",
          res.data.event_object.groom_name
        );
        addEventFormik.setFieldValue(
          "weddingObj.bride_family",
          res.data.event_object.bride_family
        );
        addEventFormik.setFieldValue(
          "weddingObj.groom_family",
          res.data.event_object.groom_family
        );
        addEventFormik.setFieldValue(
          "weddingObj.number_of_events",
          res.data.event_object.number_of_events
        );
        addEventFormik.setFieldValue(
          "weddingObj.events_details",
          res.data.event_object.events_details
        );
        addEventFormik.setFieldValue(
          "weddingObj.flight_booking",
          res.data.event_object.flight_booking
        );
        addEventFormik.setFieldValue(
          "weddingObj.ground_transport",
          res.data.event_object.ground_transport
        );
      } else if (res.data.event_type == "PreWedding") {
        addEventFormik.setFieldValue(
          "preWeddingObj.location",
          res.data.event_object.location
        );
        addEventFormik.setFieldValue(
          "preWeddingObj.bride_name",
          res.data.event_object.bride_name
        );
        addEventFormik.setFieldValue(
          "preWeddingObj.groom_name",
          res.data.event_object.groom_name
        );
        addEventFormik.setFieldValue(
          "preWeddingObj.bride_family",
          res.data.event_object.bride_family
        );
        addEventFormik.setFieldValue(
          "preWeddingObj.groom_family",
          res.data.event_object.groom_family
        );
        addEventFormik.setFieldValue(
          "preWeddingObj.event_dates",
          res.data.event_object.event_dates
        );
        addEventFormik.setFieldValue(
          "preWeddingObj.flight_booking",
          res.data.event_object.flight_booking
        );
        addEventFormik.setFieldValue(
          "preWeddingObj.ground_transport",
          res.data.event_object.ground_transport
        );
      } else {
        addEventFormik.setFieldValue(
          "celebrationObj.location",
          res.data.event_object.location
        );
        addEventFormik.setFieldValue(
          "celebrationObj.celebration_type",
          res.data.event_object.celebration_type
        );
        addEventFormik.setFieldValue(
          "celebrationObj.host_name",
          res.data.event_object.host_name
        );
        addEventFormik.setFieldValue(
          "celebrationObj.is_single_event",
          res.data.event_object.is_single_event
        );
        addEventFormik.setFieldValue(
          "celebrationObj.event_details",
          res.data.event_object.event_details
        );
      }
    }

    setLoading(false);
  };

  useEffect(() => {
    getCompany();
    if (id) {
      getEventDetails();
    }

    getUser();
    

  }, [id]);

  return (
    <div>
      <div class="dashboard_head">
        <div class="dashboard_head-left">
          <div class="dashboard_title">Edit Event</div>
        </div>
      </div>
      <div class="payout_data-content">
        {!loading ? (
          <form onSubmit={addEventFormik.handleSubmit}>
            <div class="row">
              <div class="col-lg-6">
                <div class="input-box">
                  <div class="input-label">Select Event</div>
                  <div class="input_field">
                    <select
                      name="event_type"
                      disabled={true}
                      value={addEventFormik.values.event_type}
                      onChange={addEventFormik.handleChange}
                      onBlur={addEventFormik.handleBlur}
                      class="input"
                    >
                      <option value="">Select Event Type</option>
                      {eventTypes.map((value) => {
                        return (
                          <option value={value.value}>{value.value}</option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                {addEventFormik.touched.event_type &&
                addEventFormik.errors.event_type ? (
                  <p className="error">{addEventFormik.errors.event_type}</p>
                ) : null}
                <div class="input-box">
                  <div class="input-label">Event Name</div>
                  <div class="input_field">
                    <input
                      name="name"
                      value={addEventFormik.values.name}
                      onChange={addEventFormik.handleChange}
                      onBlur={addEventFormik.handleBlur}
                      type="text"
                      class="input"
                      placeholder="Enter name"
                    />
                  </div>
                </div>
                {addEventFormik.touched.name && addEventFormik.errors.name ? (
                  <p className="error">{addEventFormik.errors.name}</p>
                ) : null}

                <div class="input-box">
                  <div class="input-label">Venue</div>
                  <div class="input_field">
                    <input
                      name="venue"
                      value={addEventFormik.values.venue}
                      onChange={addEventFormik.handleChange}
                      onBlur={addEventFormik.handleBlur}
                      type="text"
                      class="input"
                      placeholder="Enter venue"
                    />
                  </div>
                </div>
                {addEventFormik.touched.venue && addEventFormik.errors.venue ? (
                  <p className="error">{addEventFormik.errors.venue}</p>
                ) : null}

                <div class="input-box">
                  <div class="input-label">Select Date</div>
                  <div class="input_field">
                    <input
                      type="date"
                      class="input"
                      min={moment().format("YYYY-MM-DD")}
                      placeholder="Select Date"
                      name="date"
                      value={addEventFormik.values.date}
                      onChange={addEventFormik.handleChange}
                      onBlur={addEventFormik.handleBlur}
                    />
                  </div>
                </div>
                {addEventFormik.touched.date && addEventFormik.errors.date ? (
                  <p className="error">{addEventFormik.errors.date}</p>
                ) : null}
              </div>
              <div class="col-lg-6">
                <div class="input-box">
                  <div class="input-label">Total Guests</div>
                  <div class="input_field">
                    <input
                      type="text"
                      class="input"
                      placeholder="Enter Number of Guests"
                      name="total_guests"
                      value={addEventFormik.values.total_guests}
                      onChange={(e) => {
                        addEventFormik.setFieldValue(
                          "total_guests",
                          e.target.value.replace(/\D/g, "")
                        );
                      }}
                      onBlur={addEventFormik.handleBlur}
                    />
                  </div>
                </div>
                {addEventFormik.touched.total_guests &&
                addEventFormik.errors.total_guests ? (
                  <p className="error">{addEventFormik.errors.total_guests}</p>
                ) : null}
                <div class="input-box">
                  <div class="input-label">Whatsapp Number</div>
                  <div class="input_field">
                    <input
                      type="text"
                      class="input"
                      placeholder="Enter Whatsapp Number"
                      name="communication_whatsapp"
                      value={addEventFormik.values.communication_whatsapp}
                      onChange={(e) => {
                        addEventFormik.setFieldValue(
                          "communication_whatsapp",
                          e.target.value.replace(/\D/g, "")
                        );
                      }}
                      onBlur={addEventFormik.handleBlur}
                    />
                  </div>
                </div>
                {addEventFormik.touched.communication_whatsapp &&
                addEventFormik.errors.communication_whatsapp ? (
                  <p className="error">
                    {addEventFormik.errors.communication_whatsapp}
                  </p>
                ) : null}

                <div class="input-box">
                  <div class="input-label">Email</div>
                  <div class="input_field">
                    <input
                      type="text"
                      class="input"
                      placeholder="Enter Email"
                      name="communication_email"
                      value={addEventFormik.values.communication_email}
                      onChange={addEventFormik.handleChange}
                      onBlur={addEventFormik.handleBlur}
                    />
                  </div>
                </div>
                {addEventFormik.touched.communication_email &&
                addEventFormik.errors.communication_email ? (
                  <p className="error">
                    {addEventFormik.errors.communication_email}
                  </p>
                ) : null}

                {/* <div class="input-box">
                  <div class="input-label">Select Status</div>
                  <div class="input_field">
                    <select
                      name="status"
                      value={addEventFormik.values.status}
                      onChange={addEventFormik.handleChange}
                      onBlur={addEventFormik.handleBlur}
                      class="input"
                    >
                      <option value="Active">Active</option>
                      <option value="Past">Past</option>
                      <option value="Cancelled">Cancelled</option>
                    </select>
                  </div>
                </div>
                {addEventFormik.touched.status &&
                addEventFormik.errors.status ? (
                  <p className="error">{addEventFormik.errors.status}</p>
                ) : null} */}
                <div class="input-box">
                  <div class="input-label">Number of sub events</div>
                  <div class="input_field">
                    <input
                      type="text"
                      class="input"
                      placeholder="Enter number of sub events"
                      name="number_of_subevents"
                      value={addEventFormik.values?.number_of_subevents}
                      onChange={(e) => {
                        addEventFormik.setFieldValue(
                          "number_of_subevents",
                          e.target.value.replace(/\D/g, "")
                        );
                      }}
                      onBlur={addEventFormik.handleBlur}
                    />
                  </div>
                </div>
                {addEventFormik.touched.number_of_subevents &&
                addEventFormik.errors.number_of_subevents ? (
                  <p className="error">
                    {addEventFormik.errors.number_of_subevents}
                  </p>
                ) : null}
              </div>

              {addEventFormik.values.event_type == "Wedding" ? (
                <WeddingForm
                  addEventFormik={addEventFormik}
                  addMoreFamilyMembers={addMoreFamilyMembers}
                  deleteFamilyMembers={deleteFamilyMembers}
                />
              ) : addEventFormik.values.event_type == "PreWedding" ? (
                <PreWeddingForm
                  addEventFormik={addEventFormik}
                  addMoreFamilyMembers={addMoreFamilyMembers}
                  deleteFamilyMembers={deleteFamilyMembers}
                />
              ) : addEventFormik.values.event_type == "Celebration" ? (
                <CelebrationForm
                  addEventFormik={addEventFormik}
                  addMoreFamilyMembers={addMoreFamilyMembers}
                  deleteFamilyMembers={deleteFamilyMembers}
                />
              ) : (
                <></>
              )}
            </div>

            <div class="d-flex about-button-center mt-3">
              <button type="submit" class="submit-btn active btn bg-dark text-white mb-4 right" >
                Submit
              </button>
            </div>
          </form>
        ) : (
          <>
            <div className="loader"></div>
          </>
        )}
      </div>
    </div>
  );
};

export default EventEdit;
