import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  id: "",
  email: "",
  is_companyAdmin: false,
  is_companyUser: false,
  company: "",
  first_name: "",
  last_name: "",
};

export const userDetails = createSlice({
  name: "userDetails",
  initialState,
  reducers: {
    addUserDetails: (state, provider) => {
      state.value = provider.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { addUserDetails } = userDetails.actions;

export default userDetails.reducer;
