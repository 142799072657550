import { requestWithToken } from "../helper/helper";

const fetchLogisticList = async (guestId) => {
  try {
    const response = await requestWithToken(
      "GET",
      `/api/guests/${guestId}/logistics/`
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchAllLogisticList = async () => {
  try {
    const response = await requestWithToken("GET", `/api/logistics/`);

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchEventLogisticList = async (eventId, pageNo, pageSize, search) => {
  try {
    const response = await requestWithToken(
      "GET",
      `/api/logistics/${eventId}?pageno=${pageNo}&pagesize=${pageSize}&search=${search}`
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchFlightBookingsList = async (guestId) => {
  try {
    const response = await requestWithToken(
      "GET",
      `/api/guests/${guestId}/flight-bookings/`
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchAllFlightBookingsList = async () => {
  try {
    const response = await requestWithToken("GET", `/api/flight-booking/`);

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const createLogistic = async (payload) => {
  try {
    const response = await requestWithToken("POST", `/api/logistics/`, payload);

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const editLogistic = async (id, payload) => {
  try {
    const response = await requestWithToken(
      "PUT",
      `/api/logistics/${id}/`,
      payload
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchLogisticDetails = async (id) => {
  try {
    const response = await requestWithToken(
      "GET",
      `/api/logisticsDetails/${id}/`
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const createFlightBooking = async (payload) => {
  try {
    const response = await requestWithToken(
      "POST",
      `/api/flight-booking/`,
      payload
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const editFlightBooking = async (id, payload) => {
  try {
    const response = await requestWithToken(
      "PUT",
      `/api/flight-booking/${id}/`,
      payload
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchFlightBookingDetails = async (id) => {
  try {
    const response = await requestWithToken(
      "GET",
      `/api/flight-booking/${id}/`
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const deleteLogistic = async (id) => {
  try {
    const response = await requestWithToken("DELETE", `/api/logistics/${id}`);

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchLogisticSummary = async (id) => {
  try {
    const response = await requestWithToken(
      "GET",
      `/api/logisticsguestsumarry/${id}/`
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

export {
  fetchLogisticList,
  createLogistic,
  editLogistic,
  fetchLogisticDetails,
  createFlightBooking,
  fetchFlightBookingsList,
  editFlightBooking,
  fetchFlightBookingDetails,
  fetchAllLogisticList,
  fetchAllFlightBookingsList,
  fetchEventLogisticList,
  deleteLogistic,
  fetchLogisticSummary,
};
