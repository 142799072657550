// CustomDropdown.js
import React, { useState, useEffect, useRef } from "react";
import "../styles/CustomDropdown.css"; // Include your CSS styles
import selectIcon from "../assets/images/selectArrow.svg";
const CustomSelect = ({
  options,
  placeholder,
  selectedOptions,
  onChange,
  message,
  extraclass,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleSelectOption = (option) => {
    onChange(option);
  };

  const isSelected = (option) => selectedOptions.includes(option);

  return (
    <div
      className={`custom-select ${extraclass}`}
      ref={dropdownRef}>
      <div
        className="custom-select__control"
        onClick={() => setIsOpen(!isOpen)}>
        <div className="custom-select__selected">Bulk Action</div>
        <div className="custom-select__arrow">
          {isOpen ? (
            <img src={selectIcon} />
          ) : (
            <img
              src={selectIcon}
              className="selectDownImg"
            />
          )}
        </div>
      </div>
      {isOpen && (
        <div className="custom-select__menu">
          <div className="custom-select__options">
            <div className="custom-select__option">Move to trash</div>
            <div className="custom-select__option">
              Mark ‘Save the date’ as sent
            </div>
            <div className="custom-select__option">
              Mark ‘Wedding Invitation’ as sent
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomSelect;
