import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function FacebookCallback() {
    const navigate = useNavigate();

    useEffect(() => {
        // Handle the Facebook response here
        // Typically, you would parse the URL to extract the access token
        // Then you might store it in your app's state or perform an API call

        // For example:
        const params = new URLSearchParams(window.location.search);
        const accessToken = params.get('access_token');

        if (accessToken) {
            // Process the access token
            // For example, you could dispatch it to your Redux store or call an API
        } else {
            // Handle error or redirect back to the login page
            navigate('/');
        }
    }, [navigate]);

    return <div>Processing Facebook login...</div>;
}

export default FacebookCallback;
