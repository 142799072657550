/* global FB */
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import CustomDropdown from "../../components/customSearchDropdown";
import { useDispatch, useSelector } from "react-redux";
import { changeHeaderState } from "../../reducer/slice/header";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import showNotification from "../../components/notification/notification";
import { fetchEventDetails, fetchActiveEvents } from "../../action/events";
import { setSelectedEventType } from "../../reducer/slice/eventSlice";
import { fetchAllGuests } from "../../reducer/slice/guestListSlice";
import { Link } from "react-router-dom";
import * as conversation from "../../action/conversation";
import { sendWhatsappMessage } from "../../action/whatsappMessage";

export function CommunicationPage() {
  const { eventId } = useParams();
  const dispatch = useDispatch();
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [message, setMessage] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [code, setCode] = useState("");
  const [accessToken, setAccessToken] = useState("");

  const [optionsOfTemplates, setOptionsOfTemplates] = useState([
    {
      text: "Save_the_date", id: 1, content: {
        name: "Wedding RSVP",
        category: "Transactional",
        allow_category_change: false,
        language: "en_US",
        components: [
          {
            type: "body",
            text: `Dear Family & Friends,\n\nWe are pleased to introduce ourselves as the planners of [Wedding Name].\n\nWe kindly request you to save this official wedding RSVP number for all further communication and updates related to the wedding.\n\n**[Custom Message]**\n\nBest regards,\n[Your Name/Company]\n\nPhone – [Your Phone Number]\n\n[Wedding Name] Hospitality Team`
          }
        ]
      }
    },
    {
      text: "(Templet Component) Post-Wedding", id: 2, content: {
        name: "Post-Wedding Thank You",
        category: "Transactional",
        allow_category_change: false,
        language: "en_US",
        components: [
          {
            type: "body",
            text: `Dear Family & Friends,\n\nThank you for being part of [Wedding Name]. We appreciate your presence and support.\n\n**[Custom Message]**\n\nBest regards,\n[Your Name/Company]\n\nPhone – [Your Phone Number]\n\n[Wedding Name] Hospitality Team`
          }
        ]
      }
    },
    {
      text: "(Templet Component) Wedding Date Change", id: 3, content: {
        name: "Wedding Date Change Notification",
        category: "Transactional",
        allow_category_change: false,
        language: "en_US",
        components: [
          {
            type: "body",
            text: `Dear Family & Friends,\n\nWe regret to inform you that the date of [Wedding Name] has been changed.\n\nThe new date is [New Date].\n\n**[Custom Message]**\n\nBest regards,\n[Your Name/Company]\n\nPhone – [Your Phone Number]\n\n[Wedding Name] Hospitality Team`
          }
        ]
      }
    },
  ]);


  window.addEventListener('message', (event) => {/* store the data from this event in local state */ });

  const selectedEventType = useSelector(
    (state) => state.events.selectedEventType
  );
  const guestAllList = useSelector((state) => state.guestList.allGuests);


  const addEventFormik = useFormik({
    initialValues: {
      event: "",
      guests: [],
      sendEmail: false,
      sendWhatsapp: false,
      whatsappTemplate: "",
      emailMessage: "",
      whatsappTemplateMessage: optionsOfTemplates[1].content,
      whatsappPhoneNumber: "",
    },
    validate: (values) => {
      const errors = {};
      if (!values.event) {
        errors.event = "Please select event";
      }
      return errors;
    },

  });

  const handleOptionChange = (selectedOptions) => {
    setSelectedOptions(selectedOptions);
    console.log("🚀 ~ handleOptionChange ~ selectedOptions:", selectedOptions)

  };

  const finalAllguestList = guestAllList.map((guest) => {
    return {
      id: guest.id,
      name: guest.name,
    };
  });
  const handleTemplateChange = (option) => {
    setSelectedTemplate(option);
    setIsOpen(false);
    addEventFormik.setFieldValue("whatsappTemplate", option.text);
    addEventFormik.setFieldValue("whatsappTemplateMessage", option.content);
  };

  useEffect((value) => {
    const getEventDetails = async () => {
      const res = await fetchEventDetails(eventId);
      if (res.status) {
        dispatch(
          changeHeaderState({
            showDefault: false,
            headerTitle: "Communication Management",
            navigationList: [
              { name: "Events", url: "/events" },
              {
                name: res.data.name,
                url: "",
              },
              {
                name: "Communication Management",
                url: "",
              },
            ],
          })
        );

        dispatch(setSelectedEventType(res.data));
        dispatch(fetchAllGuests({ eventId }));
      }
    };

    getEventDetails();
  }, [dispatch, eventId]);

  const fbLoginCallback = (response) => {
    if (response.authResponse) {
      const authCode = response.authResponse.code;
      setCode(authCode);

      let sdkResponseElement = document.getElementById("sdk-response");
      sdkResponseElement.textContent = JSON.stringify(authCode, null, 2);
      console.log("🚀 ~ fbLoginCallback ~ code:", response);

      // Prepare URL-encoded parameters
      const params = new URLSearchParams({
        client_id: "1030288362049862",
        client_secret: "155d1bc43c98b345047a57f3a1463a3c",
        redirect_uri: "",
        code: authCode,
      });

      fetch("https://graph.facebook.com/v20.0/oauth/access_token?" + params.toString(), {
        method: "POST",
      })
        .then((res) => res.json())
        .then((data) => {
          console.log("🚀 ~ fbLoginCallback ~ data:", data);
          const at = data.access_token;
          setAccessToken(at);
          console.log("🚀 ~ fbLoginCallback ~ access token:", at);
          localStorage.setItem('accessToken', at);

        })
        .catch((error) => {
          console.error("Error fetching access token:", error);
        });

    } else {
      showNotification("Facebook login cancelled", "warning");
    }
  };


  function launchWhatsAppSignup() {
    window.FB.login(fbLoginCallback, {
      config_id: "1656357241790927",
      response_type: "code",
      redirect_uri: "https://wedtechpro.dev2.prodevr.com",
      override_default_response_type: true,
      extras: {
        setup: {},
        featureType: "",
        sessionInfoVersion: "2",
      },
    });
  }

  useEffect(() => {
    const loadFacebookSDK = () => {
      window.fbAsyncInit = function () {
        FB.init({
          appId: '1030288362049862',
          autoLogAppEvents: true,
          xfbml: true,
          version: 'v20.0'
        });
      };

      const script = document.createElement('script');
      script.src = "https://connect.facebook.net/en_US/sdk.js";
      script.async = true;
      script.defer = true;
      script.crossOrigin = "anonymous";

      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    };
    console.log("Facebook SDK initialized");
    loadFacebookSDK();
  }, []);

  const handleSendWhatsapp = async () => {
    try {
      let response;
      let selectedGuests = [];
      finalAllguestList.forEach((guest) => {
        if (selectedOptions.includes(guest.name)) {
          selectedGuests.push(guest);
        }
      });

      for (const option of selectedGuests) {

  
        const res = await conversation.sendMessage({
          event: eventId,
          guest: option.id,
          message: addEventFormik.values.whatsappTemplateMessage.components[0].text,
          conversation: option.id,
          access_token: accessToken,
        });
        response = res;
      }

      Swal.fire({
        title: "Success",
        text: "Message sent successfully!",
        icon: "success",
        confirmButtonText: "Go to Conversation Page",
        cancelButtonText: "Cancel",
        showCancelButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.href = `/conversation/${eventId}`;
        }
      });
    } catch (error) {
      console.log("🚀 ~ handleSendWhatsapp ~ error", error);
      Swal.fire({
        title: "Error",
        text: "Message sending failed!",
        icon: "error",
        confirmButtonText: "Ok",
      });
    }
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    if (name === "sendEmail" && checked) {
      addEventFormik.setFieldValue("sendEmail", true);
      addEventFormik.setFieldValue("sendWhatsapp", false);
    } else if (name === "sendWhatsapp" && checked) {
      addEventFormik.setFieldValue("sendWhatsapp", true);
      addEventFormik.setFieldValue("sendEmail", false);
    } else {
      addEventFormik.handleChange(e);
    }
  };

  return (
    <div className="payout_data-content">
      <div className="payout_data-content-box">
        <form onSubmit={addEventFormik.handleSubmit}>
          <div className="row">
            <div className="col-lg-7">
              <div className="card-custom card3">
                <div className="inline-checkbox">
                  <div className="custom_checkbox">
                    <input
                      name="sendEmail"
                      id="sendEmail"
                      type="checkbox"
                      className="m-2"
                      value={addEventFormik.values.sendEmail}
                      checked={addEventFormik.values.sendEmail}
                      onChange={handleCheckboxChange}
                    />
                    <label for="sendEmail">Email</label>
                  </div>
                  <div id="sdk-response" hidden></div>

                  <div className="custom_checkbox">

                    <input
                      id="sendWhatsapp"
                      name="sendWhatsapp"
                      type="checkbox"
                      className="m-2"
                      value={addEventFormik.values.sendWhatsapp}
                      checked={addEventFormik.values.sendWhatsapp}
                      onChange={handleCheckboxChange}
                    />
                    <label for="sendWhatsapp">Whatsapp</label>
                  </div>
                </div>

                <div className="row">
                  <div className="selected-event-name">
                    {selectedEventType.name}
                  </div>

                  <div className="col-lg-6">
                    <div className="input-box">
                      <div className="input-label">Select Guests</div>
                      <div className="custom-dropdown-box">
                        <CustomDropdown
                          options={finalAllguestList}
                          placeholder="Select options"
                          onChange={handleOptionChange}
                          selectedOptions={selectedOptions}
                          message={"guest selected"}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6">
                    {addEventFormik.values.sendWhatsapp ? (
                      <div>
                        <div className="input-box">
                          <div className="input-label">
                            Select Whatsapp Template
                          </div>
                          <div className="input_field">
                            <div className="custom-dropdown">
                              <div
                                className="custom-dropdown__control"
                                onClick={() => setIsOpen(!isOpen)}
                              >
                                <div className="custom-dropdown__selected">
                                  {selectedTemplate
                                    ? selectedTemplate.text
                                    : "Select Whatsapp Template"}
                                </div>
                                <div className="custom-dropdown__arrow">
                                  {isOpen ? "▲" : "▼"}
                                </div>
                              </div>
                              {isOpen && (
                                <div className="custom-dropdown__menu">
                                  <div className="custom-dropdown__options">
                                    {optionsOfTemplates.map((template) => (
                                      <div
                                        key={template.id}
                                        className="custom-dropdown__option"
                                        onClick={() =>
                                          handleTemplateChange(template)
                                        }
                                      >
                                        {template.text}
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <button
                          onClick={launchWhatsAppSignup}
                          className="btn-theme bg-dark"
                        >
                          <span>WhatsApp</span>
                        </button>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                <div className="col-lg-7"></div>
                <div className="col-lg-7"></div>
              </div>
            </div>
            {addEventFormik.values.sendEmail ? (
              <div className="col-lg-5">
                <div className="card-custom card3">
                  <div className="input-box">
                    <div className="input-label">Email Body Message</div>
                    <div className="input_field">
                      <textarea
                        name="emailMessage"
                        value={addEventFormik.values.emailMessage}
                        onChange={addEventFormik.handleChange}
                        className="message_box mt-1 p-2"
                        rows="5"
                      ></textarea>
                    </div>
                    <div className="btngrp mt-3">
                      <button type="submit" className="btn-theme bg-dark">
                        Send Email
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}

            {addEventFormik.values.whatsappTemplate && !addEventFormik.values.sendEmail ? (
              <div className="col-lg-5">
                <div className="card-custom card3">
                  <div className="input-box">
                    <div className="input-label">Whatsapp Message Template</div>
                    <div className="input_field">
                      <textarea
                        name="whatsappTemplateMessage.components[0].text"
                        rows={16}
                        value={addEventFormik.values.whatsappTemplateMessage.components[0].text}
                        onChange={addEventFormik.handleChange}
                        onBlur={addEventFormik.handleBlur}
                        type="text"
                        className="input"
                        placeholder="Message"
                      />
                    </div>

                    <button
                      type="submit"
                      className="btn-theme btn bg-dark mt-3"
                      onClickCapture={() => {

                        handleSendWhatsapp();


                      }}
                    >
                      Send Message
                    </button>

                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </form>
      </div>
    </div>
  );
}

export default CommunicationPage;
