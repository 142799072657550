import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { createEvent, getCompanyDetails } from "../../action/events";
import { fetchUserDetails } from "../../action/auth";
import moment from "moment";
import { setSelectedEventType } from "../../reducer/slice/eventSlice";
import { useDispatch } from "react-redux";

//images
import squar from "../../assets/images/squar.svg";
import WeddingForm from "./weddingForm";
import PreWeddingForm from "./preWeddingForm";
import CelebrationForm from "./celebrationForm";
import showNotification from "../notification/notification";
import { number } from "prop-types";




const EventDetailForm = ({ setIsWedding, changeStep, onEventSelect }) => {

  const dispatch = useDispatch();
  const [eventTypes] = useState([
    { value: "Wedding", url: "/api/weddings/" },
    { value: "PreWedding", url: "/api/preweddings/" },
    { value: "Celebration", url: "/api/celebrations/" },
  ]);
  const [userData, setUserData] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await fetchUserDetails();
        const user = res.id.toString();
        console.log("🚀 ~ fetchData ~ user:", user);
        setUserData(user);
      } catch (error) {
        console.error("Error fetching user details:", error);
      }
    };

    fetchData();
  }, [userData]);


  
  const addEventFormik = useFormik({
    initialValues: {
      company: "",
      name: "",
      date: "",
      venue: "",
      total_guests: "",
      communication_email: "",
      communication_whatsapp: "+1234567891",
      status: "Active",
      user: userData, 
      location: "",
      flight_booking: false,
      ground_transport: false,
      event_type: "",
      point_of_contact: "",

      // wedding and preWedding
      groom_name: "",
      bride_name: "",
      bride_family: [{ title: "", value: "" }],
      groom_family: [{ title: "", value: "" }],

      // wedding
      number_of_subevents: 10,

      // preWedding
      event_dates: "",

      // celebration
      host_name: "",
      celebration_type: "",
      is_single_event: false,
      event_details: "",
    },
    validate: (values) => {
      const errors = {};
      if (!values.event_type) {
        errors.event_type = "Please select event type";
      }
      if (!values.status) {
        errors.status = "Please select status";
      }
      if (!values.name) {
        errors.name = "Name is required";
      }
      if (!values.venue) {
        errors.venue = "Venue is required";
      }
      if (!values.date) {
        errors.date = "Date is required";
      }
      if (!values.location) {
        errors.location = "Location is required";
      }
      if (!values.total_guests) {
        errors.total_guests = "Total guests is required";
      } else if (!/^([1-9][0-9]{0,2}|10000)$/.test(values.total_guests)) {
        errors.total_guests = "Total number of guests are too many";
      }
      if (!values.point_of_contact) {
        errors.point_of_contact = "Point of contact is required";
      } else if (!/^\d{10}$/.test(values.point_of_contact)) {
        errors.point_of_contact = "Point of contact is invalid";
      }
      if (!values.communication_email) {
        errors.communication_email = "Email is required";
      } else if (!/\S+@\S+\.\S+/.test(values.communication_email)) {
        errors.communication_email = "Email is invalid";
      }

      if (!values.groom_name && values.event_type !== "Celebration") {
        errors.groom_name = "Groom name is required";
      }
      if (!values.bride_name && values.event_type !== "Celebration") {
        errors.bride_name = "Bride name is required";
      }
      if (!values.host_name && values.event_type === "Celebration") {
        errors.host_name = "Host name is required";
      }
      return errors;
    },
    onSubmit: async (values) => {
      values.user = await userData;
      const url = eventTypes.find(
        (value) => value.value === values.event_type
      ).url;
      console.log("Form data submitted: ", values);
      createEventApiFun(values, url);
      dispatch(setSelectedEventType(values.event_type));
    },
  });

  const createEventApiFun = async (value, url) => {
    if (addEventFormik.values.event_type === "Wedding") {
      changeStep(1, value, url);
    } else {
      changeStep(2, value, url);
    }
  };
 
  // type 1 for bride 2 for groom 3 for event Details
  const addMoreFamilyMembers = (type,pertype) => {

    if(pertype == 'wedding'){
    if (type == 1) {
      addEventFormik.setFieldValue("bride_family", [
        ...addEventFormik.values.bride_family,
        { title: "", value: "" },
      ]);
    } else if (type == 2) {
      addEventFormik.setFieldValue("groom_family", [
        ...addEventFormik.values.groom_family,
        { title: "", value: "" },
      ]);
    } else if (type == 3) {
      addEventFormik.setFieldValue("events_details", [
        ...addEventFormik.values.events_details,
        { title: "", value: "" },
      ]);
    }
  }else{
    if (type == 1) {
      addEventFormik.setFieldValue("bride_family", [
        ...addEventFormik.values.bride_family,
        { title: "", value: "" },
      ]);
    } else if (type == 2) {
      addEventFormik.setFieldValue("groom_family", [
        ...addEventFormik.values.groom_family,
        { title: "", value: "" },
      ]);
    } else if (type == 3) {
      addEventFormik.setFieldValue("events_details", [
        ...addEventFormik.values.events_details,
        { title: "", value: "" },
      ]);
    }
  }
  };

  // type 1 for bride 2 for groom 3 for event Details
  const deleteFamilyMembers = (type, ind,pertype) => {
    if(pertype =='wedding'){
    if (type == 1) {
      addEventFormik.setFieldValue(
        "bride_family",
        addEventFormik.values.bride_family.filter(
          (value, index) => index != ind
        )
      );
    } else if (type == 2) {
      addEventFormik.setFieldValue(
        "groom_family",
        addEventFormik.values.groom_family.filter(
          (value, index) => index != ind
        )
      );
    } else if (type == 3) {
      addEventFormik.setFieldValue(
        "events_details",
        addEventFormik.values.events_details.filter(
          (value, index) => index != ind
        )
      );
    }
  }else{
    if (type == 1) {
      addEventFormik.setFieldValue(
        "bride_family",
        addEventFormik.values.bride_family.filter(
          (value, index) => index != ind
        )
      );
    } else if (type == 2) {
      addEventFormik.setFieldValue(
        "groom_family",
        addEventFormik.values.groom_family.filter(
          (value, index) => index != ind
        )
      );
    } else if (type == 3) {
      addEventFormik.setFieldValue(
        "events_details",
        addEventFormik.values.events_details.filter(
          (value, index) => index != ind
        )
      );
    }
  }
  };

  const getCompany = async () => {
    const res = await getCompanyDetails();
    if (res.status) {
      addEventFormik.setFieldValue("company", res.data.id);
    }
  };

  useEffect(() => {
    getCompany();
  }, []);

  const handleTextInput = (event) => {
    const newValue = event.target.value;
    const regex = /^[a-zA-Z0-9\s!@#$%^&*()_+={}\[\]|\\:;"'<>,.?/]*$/;
    if (regex.test(newValue)) {
      addEventFormik.setFieldValue(event.target.name, newValue);
    }
  };
  if (!userData) return <div>Loading...</div>;

  return (
    <form onSubmit={addEventFormik.handleSubmit}>
      <h2 className="form-sec-heading">
        <img src={squar} alt="" /> <span>Event Details</span>
      </h2>
      <div className="row">
        <div className="col-lg-4">
          <div className="input-box">
            <div className="input-label">Select Event Type</div>
            <div className="input_field">
              <select
                name="event_type"
                value={addEventFormik.values.event_type}
                onChange={(e) => {
                  if (e.target.value === "Wedding") {
                    setIsWedding(true);
                  } else {
                    setIsWedding(false);
                  }
                  addEventFormik.setFieldValue("event_type", e.target.value);
                }}
                onBlur={addEventFormik.handleBlur}
                className="input"
              >
                <option value="">Select Event Type</option>
                {eventTypes.map((value) => (
                  <option key={value.value} value={value.value}>
                    {value.value}
                  </option>
                ))}
              </select>
            </div>
            {addEventFormik.touched.event_type &&
              addEventFormik.errors.event_type ? (
              <p className="error">{addEventFormik.errors.event_type}</p>
            ) : null}
          </div>
        </div>
        <div className="col-md-4">
          <div className="input-box">
            <div className="input-label">Event Name</div>
            <div className="input_field">
              <input
                name="name"
                value={addEventFormik.values.name}
                onChange={handleTextInput}
                onBlur={addEventFormik.handleBlur}
                type="text"
                className="input"
                placeholder="Enter name"
              />
            </div>
            {addEventFormik.touched.name && addEventFormik.errors.name ? (
              <p className="error">{addEventFormik.errors.name}</p>
            ) : null}
          </div>
        </div>
        <div className="col-md-4">
          <div className="input-box">
            <div className="input-label">Venue</div>
            <div className="input_field">
              <input
                name="venue"
                value={addEventFormik.values.venue}
                onChange={handleTextInput}
                onBlur={addEventFormik.handleBlur}
                type="text"
                className="input"
                placeholder="Enter venue"
              />
            </div>
            {addEventFormik.touched.venue && addEventFormik.errors.venue ? (
              <p className="error">{addEventFormik.errors.venue}</p>
            ) : null}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-4">
          <div className="input-box">
            <div className="input-label">Select Date</div>
            <div className="input_field">
              <input
                type="date"
                className="input"
                onKeyDown={(e) => e.preventDefault()}
                min={moment().format("YYYY-MM-DD")}
                placeholder="Select Date"
                name="date"
                value={addEventFormik.values.date}
                onChange={addEventFormik.handleChange}
                onBlur={addEventFormik.handleBlur}
              />
            </div>
            {addEventFormik.touched.date && addEventFormik.errors.date ? (
              <p className="error">{addEventFormik.errors.date}</p>
            ) : null}
          </div>
        </div>
        <div className="col-lg-4">
          <div className="input-box">
            <div className="input-label">Approx. Number of Guests</div>
            <div className="input_field">
              <input
                type="text"
                className="input"
                placeholder="Enter Number of Guests"
                name="total_guests"
                value={addEventFormik.values.total_guests}
                onChange={(e) => {
                  addEventFormik.setFieldValue(
                    "total_guests",
                    e.target.value.replace(/\D/g, "")
                  );
                }}
                onBlur={addEventFormik.handleBlur}
              />
            </div>
            {addEventFormik.touched.total_guests &&
              addEventFormik.errors.total_guests ? (
              <p className="error">{addEventFormik.errors.total_guests}</p>
            ) : null}
          </div>
        </div>
        <div className="col-md-4">
          <div className="input-box">
            <div className="input-label">Location</div>
            <div className="input_field">
              <input
                type="text"
                className="input"
                placeholder="Enter Location"
                name="location"
                value={addEventFormik.values.location}
                onChange={addEventFormik.handleChange}
                onBlur={addEventFormik.handleBlur}
              />
            </div>
            {addEventFormik.touched.location &&
              addEventFormik.errors.location ? (
              <p className="error">{addEventFormik.errors.location}</p>
            ) : null}
          </div>
        </div>
      </div>
      <h2 className="form-sec-heading">
        <img src={squar} alt="" /> <span>Contact Details</span>
      </h2>

      <div className="row">
        <div className="col-md-4">
          <div className="input-box">
            <div className="input-label">Point of Contact</div>
            <div className="input_field">
              <input
                type="text"
                className="input"
                placeholder="Enter contact number"
                name="point_of_contact"
                value={addEventFormik.values.point_of_contact}
                onChange={(e) => {
                  addEventFormik.setFieldValue(
                    "point_of_contact",
                    e.target.value.replace(/\D/g, "")
                  );
                }}
                onBlur={addEventFormik.handleBlur}
              />
            </div>
            {addEventFormik.touched.point_of_contact &&
              addEventFormik.errors.point_of_contact ? (
              <p className="error">{addEventFormik.errors.point_of_contact}</p>
            ) : null}
          </div>
        </div>
        <div className="col-md-4">
          <div className="input-box">
            <div className="input-label">Email</div>
            <div className="input_field">
              <input
                type="text"
                className="input"
                placeholder="Enter Email"
                name="communication_email"
                value={addEventFormik.values.communication_email}
                onChange={addEventFormik.handleChange}
                onBlur={addEventFormik.handleBlur}
              />
            </div>
            {addEventFormik.touched.communication_email &&
              addEventFormik.errors.communication_email ? (
              <p className="error">
                {addEventFormik.errors.communication_email}
              </p>
            ) : null}
          </div>
        </div>
      </div>

      {addEventFormik.values.event_type === "Wedding" ? (
        <WeddingForm
          addEventFormik={addEventFormik}
          addMoreFamilyMembers={addMoreFamilyMembers}
          deleteFamilyMembers={deleteFamilyMembers}
        />
      ) : addEventFormik.values.event_type === "PreWedding" ? (
        <PreWeddingForm
          addEventFormik={addEventFormik}
          addMoreFamilyMembers={addMoreFamilyMembers}
          deleteFamilyMembers={deleteFamilyMembers}
        />
      ) : addEventFormik.values.event_type === "Celebration" ? (
        <CelebrationForm
          addEventFormik={addEventFormik}
          addMoreFamilyMembers={addMoreFamilyMembers}
          deleteFamilyMembers={deleteFamilyMembers}
        />
      ) : null}

      <div className="d-flex frm-sbmit-grp mt-3">
        <button type="submit" className="btn-theme bg-brown color-white">
          Next
        </button>
      </div>
    </form>
  );
};

export default EventDetailForm;
