import React from "react";
import squar from "../../assets/images/squar.svg";
import addBrown from "../../assets/images/add-brown.svg";

const CelebrationForm = ({
  addEventFormik,
  addMoreFamilyMembers,
  deleteFamilyMembers,
}) => {
  const handleTextInput = (event) => {
    const newValue = event.target.value;
    const regex = /^[a-zA-Z\s]*$/;
    if (regex.test(newValue)) {
      addEventFormik.setFieldValue(event.target.name, newValue);
    }
  };

  return (
    <div className="row">
      <h2 className="form-sec-heading">
        <img src={squar} alt="" /> <span>Celebration Details</span>
      </h2>
      <div className="col-lg-4">
        <div class="input-box">
          <div class="input-label">Celebration Type</div>
          <div class="input_field">
            <input
              name="celebration_type"
              value={addEventFormik.values.celebrationObj?.celebration_type}
              onChange={handleTextInput}
              onBlur={addEventFormik.handleBlur}
              type="text"
              class="input"
              placeholder="Enter name"
            />
          </div>
        </div>
      </div>

      <div className="col-lg-4">
        <div class="input-box">
          <div class="input-label">Host Name</div>
          <div class="input_field">
            <input
              name="host_name"
              value={addEventFormik.values.celebrationObj?.host_name}
              onChange={handleTextInput}
              onBlur={addEventFormik.handleBlur}
              type="text"
              class="input"
              placeholder="Enter name"
            />
          </div>
        </div>
      </div>

      <div className="col-lg-4">
        <div className="input-box">
          <label className="mt-lg-4">
            <input
              className="me-2"
              name="is_single_event"
              type="checkbox"
              value={addEventFormik.values.celebrationObj?.is_single_event}
              checked={addEventFormik.values.celebrationObj?.is_single_event}
              onChange={addEventFormik.handleChange}
            />
            Single Event
          </label>
        </div>
      </div>

      <div className="col-lg-4">
        <div class="input-box">
          <div class="input-label">Event Description</div>
          <div class="input_field">
            <textarea
              name="event_details"
              value={addEventFormik.values.celebrationObj?.event_details}
              onChange={addEventFormik.handleChange}
              onBlur={addEventFormik.handleBlur}
              type="text"
              rows={6}
              class="input"
              placeholder="Enter name"
            ></textarea>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CelebrationForm;
