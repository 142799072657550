import React, { useEffect, useState } from "react";
import user from "../assets/images/user.jpg";
import coins from "../assets/images/credits.svg";
import {
  getDashboardDetails,
} from "../action/events";
import { uploadLogo } from "../action/upload";

import { useNavigate } from "react-router-dom";

const Settings = () => {
  const navigate = useNavigate();
  const [companyDetails, setCompanyDetails] = useState();
  const [coinsSub, setCoins] = useState(0);
  const [image, setImage] = useState(null);
  const [baseURL, setBaseUrl] = useState(process.env.REACT_APP_API_ENDPOINT);


  useEffect(() => {
    const getCompany = async () => {
      const res = await getDashboardDetails();
      if (res.status) {
        setCompanyDetails(res.data);
        setCoins(res.data.company.coins)
        if (res.data.company.logo) {

          setImage(baseURL + res.data.company.logo);

        }
      }
    };
    getCompany();
  }, []);


  const handleImageChange = async (e) => {
    const file = e.target.files[0];

    if (!file) return;

    const reader = new FileReader();
    reader.onloadend = () => {
      setImage(reader.result);
    };
    reader.readAsDataURL(file);

    const formData = new FormData();
    formData.append("logo", file);
    formData.append("company_id", companyDetails.company.id);

    try {
      const response = await uploadLogo(formData);
      if (response?.status) {
        const imageUrl = `${baseURL}${response.data.logo}`;
        setImage(imageUrl);
        console.log(imageUrl);
      } else {
        console.error("Failed to upload image");
      }
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };



  return (
    <div>
      <div className="profile_page">
        <div className="row">
          <div className="col-lg-8">
            <div className="profile_container">
              <div className="row">
                <div className="col-lg-8">
                  <div className="companyName">Company Details</div>
                  <div className="row">
                    <div className="col-lg-12 mg-t-1">
                      <div className="input-box">
                        <div className="input-label">Company Name</div>
                        <div className="input_field">
                          <p
                            style={{
                              fontSize: "1.5rem",
                              fontWeight: "bold",
                              color: "#000",
                            }}
                          >
                            {companyDetails?.company?.name}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="manageTeam-box">
                    <div className="active-user-block">
                      {companyDetails?.user?.slice(0, 4)?.map((value) => {
                        return (
                          <span className="active-user">
                            {`${value?.first_name[0] ?? value.email[0]}`.toUpperCase()}
                            {`${value?.last_name[0] ?? value.email[1]}`.toUpperCase()}
                          </span>
                        );
                      })}
                      {companyDetails?.user?.length > 4 ? (
                        <span className="active-user">
                          +{companyDetails?.user?.length - 4}
                        </span>
                      ) : (
                        <></>
                      )}
                    </div>
                    <button
                      className="manageBtn bg-dark"
                      onClick={()=>{
                        navigate('/manage-teams')
                      }
                      }
                    >
                      Manage Team
                    </button>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="profile_card profile_card2">
                    <div className="profile-image-box">
                      {image ? (
                        <img src={image} alt="Company Logo" />
                      ) : (
                        "Company Logo"
                      )}
                    </div>
                    <div className="profile-action">
                      <button
                        className="btn-theme outline"
                        onClick={() =>
                          document.getElementById("fileInput").click()
                        }
                      >
                        Change
                      </button>
                      <input
                        id="fileInput"
                        type="file"
                        style={{ display: "none" }}
                        accept="image/*"
                        onChange={handleImageChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="profile_container mg-t-1">
              <div className="companyName">Plan Details</div>
              <div className="manageTeam-box">
                <div className="credits">
                  <div className="credit_text">Remaining Credits:</div>
                  <div className="credit-count">
                    <img src={coins} alt="Coins" />
                    <span>
                      {" "}
                      {coinsSub}
                    </span>
                  </div>
                </div>
                <button className="manageBtn bg-dark" onClick={()=>{
                     navigate("/subscription" )
                }}>Manage Subscription</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      
    </div>
  );
};

export default Settings;
