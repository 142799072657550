import React from "react";
import Slider from "react-slick";
import logo from "../assets/images/logo.png";
import banner from "../assets/images/login-banner1.png";
import { useFormik } from "formik";
import { login } from "../action/auth";
import showNotification from "../components/notification/notification";
import { Link, useNavigate } from "react-router-dom";

const Login = () => {
  const navigate = useNavigate();

  var settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const loginFormik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit: async (values) => {
      const res = await login(values);
      if (res.status) {
        localStorage.setItem("token", res.data.access);
        navigate("/");
      } else {
        showNotification(res.message, "danger");
      }
    },
  });
  return (
    <div className="login_page">
      <div className="login-container">
        <div className="login_content">
          <div className="login_left">
            <div className="login_right-content">
              <div className="login_logo-box">
                <img src={logo} alt="" className="login_logo" />
              </div>
              <div className="login-form-box">
                <form onSubmit={loginFormik.handleSubmit}>
                  <div className="login_inner-content">
                    <div className="login_desc">Log into your account</div>
                    <div className="input-box">
                      <div className="input-label login">Username</div>
                      <div className="input_grp">
                        <input
                          required
                          name="email"
                          type="email"
                          className="input log"
                          placeholder="example@mail.com"
                          value={loginFormik.values.email}
                          onChange={loginFormik.handleChange}
                        />
                      </div>
                    </div>
                    <div className="input-box">
                      <div className="input-label login">Password</div>
                      <div className="input_grp">
                        <input
                          required
                          name="password"
                          type="password"
                          className="input pass log"
                          placeholder="**********"
                          value={loginFormik.values.password}
                          onChange={loginFormik.handleChange}
                        />
                        <a href="#" className="forgotLink">
                          Forgot Password?
                        </a>
                      </div>
                    </div>
                    {/* <div id="loginLoader" className="loader"></div> */}
                    <button type="submit" className="login-button">
                      Log In
                    </button>

                    <p className="helplink">
                      For assistance:{" "}
                      <Link to="mailto:help@wedtechpro.com">
                        help@wedtechpro.com
                      </Link>
                    </p>
                  </div>
                </form>
              </div>
              <div className="login_right-footer">
                <div className="login_footer-left">© WedTech Pro 2024</div>
              </div>
            </div>
          </div>
          <div className="login_rigt">
            <div className="loginbanner">
              <Slider {...settings} className="login-slider">
                <div className="Login-slider-item">
                  <img src={banner} alt="" className="login-banner-img" />
                  <h2 className="login-banner-heading">
                    Guest Data Management
                  </h2>
                </div>
                <div className="Login-slider-item">
                  <img src={banner} alt="" className="login-banner-img" />
                  <h2 className="login-banner-heading">
                    Guest Data Management
                  </h2>
                </div>
                <div className="Login-slider-item">
                  <img src={banner} alt="" className="login-banner-img" />
                  <h2 className="login-banner-heading">
                    Guest Data Management
                  </h2>
                </div>
                <div className="Login-slider-item">
                  <img src={banner} alt="" className="login-banner-img" />
                  <h2 className="login-banner-heading">
                    Guest Data Management
                  </h2>
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
