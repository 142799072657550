import React from "react";
import deleteIcon from "../../assets/images/trash-brown.svg";
import squar from "../../assets/images/squar.svg";
import addBrown from "../../assets/images/add-brown.svg";

const WeddingForm = ({
  addEventFormik,
  addMoreFamilyMembers,
  deleteFamilyMembers,
}) => {
  const handleTextInput = (event) => {
    const { name, value } = event.target;
    const regex = /^[a-zA-Z\s]*$/;
    if (regex.test(value)) {
      addEventFormik.setFieldValue(name, value);
    }
  };

  // Helper function to safely parse JSON strings
  const safeParseJSON = (jsonString) => {
    try {
      return typeof jsonString === 'string' ? JSON.parse(jsonString) : jsonString;
    } catch (e) {
      return {};
    }
  };

  // Helper function to safely stringify objects
  const safeStringifyJSON = (obj) => {
    try {
      return typeof obj === 'string' ? obj : JSON.stringify(obj);
    } catch (e) {
      return '{}';
    }
  };

  return (
    <div>
      <h2 className="form-sec-heading">
        <img src={squar} alt="" /> <span>Wedding Details</span>
      </h2>
      <div className="row">
        <div className="col-lg-4">
          <div className="input-box">
            <div className="input-label">Groom Name</div>
            <div className="input_field">
              <input
                name="weddingObj.groom_name"
                value={addEventFormik.values.weddingObj?.groom_name || ""}
                onChange={handleTextInput}
                onBlur={addEventFormik.handleBlur}
                type="text"
                className={`input ${
                  addEventFormik.touched?.weddingObj?.groom_name &&
                  addEventFormik.errors?.weddingObj?.groom_name
                    ? "input-error"
                    : ""
                }`}
                placeholder="Enter name"
              />
            </div>
            {addEventFormik.touched?.weddingObj?.groom_name &&
              addEventFormik.errors?.weddingObj?.groom_name && (
                <p className="error">
                  {addEventFormik.errors.weddingObj.groom_name}
                </p>
              )}
          </div>
        </div>

        <div className="col-lg-4">
          <div className="input-box">
            <div className="input-label">Bride Name</div>
            <div className="input_field">
              <input
                name="weddingObj.bride_name"
                value={addEventFormik.values.weddingObj?.bride_name || ""}
                onChange={handleTextInput}
                onBlur={addEventFormik.handleBlur}
                type="text"
                className={`input ${
                  addEventFormik.touched?.weddingObj?.bride_name &&
                  addEventFormik.errors?.weddingObj?.bride_name
                    ? "input-error"
                    : ""
                }`}
                placeholder="Enter name"
              />
            </div>
            {addEventFormik.touched?.weddingObj?.bride_name &&
              addEventFormik.errors?.weddingObj?.bride_name && (
                <p className="error">
                  {addEventFormik.errors.weddingObj.bride_name}
                </p>
              )}
          </div>
        </div>

        <div className="col-lg-4">
          <div className="input-box">
            <div className="input-label">Number of Sub Events</div>
            <div className="input_field">
              <input
                name="weddingObj.number_of_subevents"
                value={addEventFormik.values.weddingObj?.number_of_subevents || ""}
                onChange={(e) => {
                  const value = e.target.value.replace(/\D/g, "");
                  addEventFormik.setFieldValue(
                    "weddingObj.number_of_subevents",
                    value ? parseInt(value) : ""
                  );
                }}
                onBlur={addEventFormik.handleBlur}
                type="text"
                className="input"
                placeholder="Enter number of sub events"
              />
            </div>
          </div>
        </div>

        <div className="col-lg-6">
          <div className="input-box">
            <div className="input-label">Wedding Invite Details</div>
            <div className="input_field">
              <textarea
                name="weddingObj.wedding_invite"
                value={safeStringifyJSON(addEventFormik.values.weddingObj?.wedding_invite || {})}
                onChange={(e) => {
                  try {
                    const parsed = JSON.parse(e.target.value);
                    addEventFormik.setFieldValue("weddingObj.wedding_invite", parsed);
                  } catch (err) {
                    // If invalid JSON, store as is
                    addEventFormik.setFieldValue("weddingObj.wedding_invite", e.target.value);
                  }
                }}
                onBlur={addEventFormik.handleBlur}
                className="input"
                placeholder="Enter wedding invite details in JSON format"
                rows={4}
              />
            </div>
          </div>
        </div>

        <div className="col-lg-6">
          <div className="input-box">
            <div className="input-label">Reception Invite Details</div>
            <div className="input_field">
              <textarea
                name="weddingObj.reception_invite"
                value={safeStringifyJSON(addEventFormik.values.weddingObj?.reception_invite || {})}
                onChange={(e) => {
                  try {
                    const parsed = JSON.parse(e.target.value);
                    addEventFormik.setFieldValue("weddingObj.reception_invite", parsed);
                  } catch (err) {
                    // If invalid JSON, store as is
                    addEventFormik.setFieldValue("weddingObj.reception_invite", e.target.value);
                  }
                }}
                onBlur={addEventFormik.handleBlur}
                className="input"
                placeholder="Enter reception invite details in JSON format"
                rows={4}
              />
            </div>
          </div>
        </div>
      </div>

      <h2 className="form-sec-heading">
        <img src={squar} alt="" /> <span>Bride Family Details</span>
      </h2>
      <div className="row">
        {(addEventFormik.values.weddingObj?.bride_family || []).map(
          (value, index) => (
            <div className="col-lg-4" key={`bride-family-${index}`}>
              <div className="input-box">
                <div
                  className="input-label"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  Member Title {index + 1}
                  {addEventFormik.values.weddingObj.bride_family.length > 1 && (
                    <img
                      style={{ width: "20px", cursor: "pointer" }}
                      src={deleteIcon}
                      alt="Delete"
                      onClick={() => deleteFamilyMembers(1, index, 'wedding')}
                    />
                  )}
                </div>
                <div className="input_field">
                  <input
                    name={`weddingObj.bride_family.${index}.title`}
                    value={value.title || ""}
                    onChange={handleTextInput}
                    onBlur={addEventFormik.handleBlur}
                    type="text"
                    className="input"
                    placeholder="Eg (Mother, Father)"
                  />
                </div>
              </div>
              <div className="input-box">
                <div className="input-label">Member Name {index + 1}</div>
                <div className="input_field">
                  <input
                    name={`weddingObj.bride_family.${index}.value`}
                    value={value.value || ""}
                    onChange={handleTextInput}
                    onBlur={addEventFormik.handleBlur}
                    type="text"
                    className="input"
                    placeholder="Name"
                  />
                </div>
              </div>
            </div>
          )
        )}
      </div>

      <div className="btnggrp mt-4">
        <button
          className="btn-theme dotted-border"
          type="button"
          onClick={() => addMoreFamilyMembers(1, 'wedding')}
        >
          <img src={addBrown} alt="Add" />
          <span>Add More Bride Family Member</span>
        </button>
      </div>

      <h2 className="form-sec-heading">
        <img src={squar} alt="" /> <span>Groom Family Details</span>
      </h2>
      <div className="row">
        {(addEventFormik.values.weddingObj?.groom_family || []).map(
          (value, index) => (
            <div className="col-lg-4" key={`groom-family-${index}`}>
              <div className="input-box">
                <div
                  className="input-label"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  Member Title {index + 1}
                  {addEventFormik.values.weddingObj.groom_family.length > 1 && (
                    <img
                      style={{ width: "20px", cursor: "pointer" }}
                      src={deleteIcon}
                      alt="Delete"
                      onClick={() => deleteFamilyMembers(2, index, 'wedding')}
                    />
                  )}
                </div>
                <div className="input_field">
                  <input
                    name={`weddingObj.groom_family.${index}.title`}
                    value={value.title || ""}
                    onChange={handleTextInput}
                    onBlur={addEventFormik.handleBlur}
                    type="text"
                    className="input"
                    placeholder="Eg (Mother, Father)"
                  />
                </div>
              </div>
              <div className="input-box">
                <div className="input-label">Member Name {index + 1}</div>
                <div className="input_field">
                  <input
                    name={`weddingObj.groom_family.${index}.value`}
                    value={value.value || ""}
                    onChange={handleTextInput}
                    onBlur={addEventFormik.handleBlur}
                    type="text"
                    className="input"
                    placeholder="Name"
                  />
                </div>
              </div>
            </div>
          )
        )}
      </div>

      <div className="btnggrp mt-4">
        <button
          className="btn-theme dotted-border"
          type="button"
          onClick={() => addMoreFamilyMembers(2, 'wedding')}
        >
          <img src={addBrown} alt="Add" />
          <span>Add More Groom Family Member</span>
        </button>
      </div>
    </div>
  );
};

export default WeddingForm;
