import { useFormik } from "formik";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { addGuest } from "../../action/guests";
import showNotification from "../../components/notification/notification";
import { uploadFile } from "../../action/upload";
import CustomDropdown from "../../components/customSearchDropdown";
import squar from "../../assets/images/squar.svg";
import addBrown from "../../assets/images/add-brown.svg";
import deleteIcon from "../../assets/images/trash-brown.svg";
import { useNavigate } from "react-router-dom";
import MyModal from "../model";

const GuestAddForm = ({
  isWedding,
  mySubEvents,
  changeStep,
  showUploadSheet,
}) => {
  const [guestListFile, setGuestListFile] = useState();
  const [subEvents, setSubEvents] = useState([]);
  const [selectedFile, setSelectedFile] = useState("");
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  const addGuestFormik = useFormik({
    initialValues: {
      data: [
        {
          company: "",
          event: "",
          subevent: [],
          name: "",
          email: "",
          Address: "",
          group: "",
          subGroup: "",
          contact_number: "",
          alternate_contact_number: "",
          whatsapp_verified: false,
          rsvp: "Yes",
          notes: "",
          hampergift: "test",
          wedding_invite: "Pending",
          reception_invite: "Pending",
          save_the_date_email: false,
          save_the_date_whatsapp: false,
          whatsapp_verified: false,
        },
      ],
    },
    onSubmit: async (values) => {
      try {
        if (selectedFile) {
          await changeStep(2, selectedFile, 1);
        } else {
          await changeStep(2, values.data, 2);
        }
      } catch (error) {}

      setShowModal(true);
      setTimeout(() => {
        navigate("/events");
      }, 3000);
    },
  });

  const handleOptionChange = (index, selectedOptions) => {
    let isExist = addGuestFormik.values.data[index].subevent.some(
      (value) => value === selectedOptions.value
    );
    if (isExist) {
      addGuestFormik.setFieldValue(
        `data[${index}].subevent`,
        addGuestFormik.values.data[index].subevent.filter(
          (value) => value !== selectedOptions.value
        )
      );
    } else {
      addGuestFormik.setFieldValue(`data[${index}].subevent`, [
        ...addGuestFormik.values.data[index].subevent,
        selectedOptions.value,
      ]);
    }
  };

  const addMoreGuests = (type) => {
    addGuestFormik.setFieldValue("data", [
      ...addGuestFormik.values.data,
      {
        company: "",
        event: "",
        subevent: [],
        name: "",
        email: "",
        contact_number: "",
        alternate_contact_number: "",
        whatsapp_verified: false,
        rsvp: "No",
        notes: "",
        hampergift: "",
        wedding_invite: "Pending",
        reception_invite: "Pending",
        save_the_date_email: false,
        save_the_date_whatsapp: false,
        whatsapp_verified: false,
      },
    ]);
  };

  const deleteGuests = (ind) => {
    addGuestFormik.setFieldValue(
      "data",
      addGuestFormik.values.data.filter((value, index) => index !== ind)
    );
  };

  useEffect(() => {
    setSubEvents(
      mySubEvents.map((value) => {
        return { value: value.name, label: value.name };
      })
    );
  }, [mySubEvents]);

  const handleTextInput = (event) => {
    const newValue = event.target.value;
    const regex = /^[a-zA-Z\s]*$/;
    if (regex.test(newValue)) {
      addGuestFormik.setFieldValue(event.target.name, newValue);
    }
  };

  return (
    <form onSubmit={addGuestFormik.handleSubmit}>
      <h2 className="form-sec-heading">
        <img src={squar} alt="" /> <span>Guests Details</span>
      </h2>

      <div class="row">
        <div className="col-md-4">
          <div class="input-box">
            <div class="input-label">
              Upload Guest Sheet (.csv, .xml, .xlsx)
            </div>
            <div class="files-wr">
              <div class="one-file">
                <label for="file-1">Attach file</label>
                <input
                  className="hidden"
                  name="file-1"
                  id="file-1"
                  type="file"
                  onChange={(e) => {
                    setSelectedFile(e.target.files[0]);
                  }}
                />
                <div class="file-item hide-btn">
                  <span class="file-name"></span>
                  <span class="btn btn-del-file">x</span>
                </div>
              </div>
            </div>
            <p>{selectedFile?.name}</p>
          </div>
        </div>
      </div>

      {!showUploadSheet && !selectedFile ? (
        <>
          <div className="orr">or</div>
          {addGuestFormik.values.data.map((value, index) => {
            return (
              <div class="row">
                {isWedding ? (
                  <div class="col-lg-4">
                    <div class="input-box">
                      <div class="input-label">Select Function *</div>
                      <div class="custom-dropdown-box">
                        <CustomDropdown
                          options={subEvents}
                          placeholder="Select options"
                          onChange={(e) => {
                            handleOptionChange(index, e);
                          }}
                          selectedOptions={
                            addGuestFormik.values.data[index].subevent
                          }
                          message={"sub event selected"}
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  <></>
                )}

                <div class="col-lg-4">
                  <div class="input-box">
                    <div class="input-label">Select Status *</div>
                    <div class="input_field">
                      <select
                        required
                        name={`data[${index}].rsvp`}
                        value={addGuestFormik.values.data[index].rsvp}
                        onChange={(e) => {
                          addGuestFormik.setFieldValue(
                            `data[${index}].rsvp`,
                            e.target.value
                          );
                        }}
                        class="input"
                      >
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                        <option value="Maybe">Maybe</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div class="col-lg-4">
                  <div class="input-box">
                    <div class="input-label">Guest Name *</div>
                    <div class="input_field">
                      <input
                        required
                        name={`data[${index}].name`}
                        type="text"
                        class="input"
                        placeholder="Enter guest name"
                        value={addGuestFormik.values.data[index].name}
                        onChange={handleTextInput}
                        onBlur={addGuestFormik.handleBlur}
                      />
                    </div>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="input-box">
                    <div class="input-label">Guest Group</div>
                    <div class="input_field">
                      <input
                        name={`data[${index}].group`}
                        type="text"
                        class="input"
                        placeholder="Enter guest group"
                        value={addGuestFormik.values.data[index].group}
                        onChange={handleTextInput}
                        onBlur={addGuestFormik.handleBlur}
                      />
                    </div>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="input-box">
                    <div class="input-label">Guest Sub Group</div>
                    <div class="input_field">
                      <input
                        name={`data[${index}].subGroup`}
                        type="text"
                        class="input"
                        placeholder="Enter guest sub group"
                        value={addGuestFormik.values.data[index].subGroup}
                        onChange={handleTextInput}
                        onBlur={addGuestFormik.handleBlur}
                      />
                    </div>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div className="input-box">
                    <div class="input-box">
                      <div class="input-label">Guest Email *</div>
                      <div class="input_field">
                        <input
                          required
                          type="email"
                          class="input"
                          placeholder="Enter email"
                          name={`data[${index}].email`}
                          value={addGuestFormik.values.data[index].email}
                          onChange={(e) => {
                            addGuestFormik.setFieldValue(
                              `data[${index}].email`,
                              e.target.value
                            );
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4">
                  <div className="input-box">
                    <div class="input-box">
                      <div class="input-label">Guest Number *</div>
                      <div class="input_field">
                        <input
                          required
                          type="text"
                          class="input"
                          pattern="\d{10}"
                          placeholder="Enter number"
                          name={`data[${index}].contact_number`}
                          value={
                            addGuestFormik.values.data[index].contact_number
                          }
                          onChange={(e) => {
                            addGuestFormik.setFieldValue(
                              `data[${index}]contact_number`,
                              e.target.value.replace(/\D/g, "")
                            );
                          }}
                        />
                      </div>
                      {addGuestFormik.touched.contact_number &&
                      addGuestFormik.errors.contact_number ? (
                        <p className="error">
                          {addGuestFormik.errors.contact_number}
                        </p>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="input-box">
                    <div class="input-box">
                      <div class="input-label">Alternate Number</div>
                      <div class="input_field">
                        <input
                          type="text"
                          class="input"
                          pattern="\d{10}"
                          placeholder="Enter number"
                          name={`data[${index}].alternate_contact_number`}
                          value={
                            addGuestFormik.values.data[index]
                              .alternate_contact_number
                          }
                          onChange={(e) => {
                            addGuestFormik.setFieldValue(
                              `data[${index}]alternate_contact_number`,
                              e.target.value.replace(/\D/g, "")
                            );
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div className="input-box">
                    <div class="input-box">
                      <div class="input-label">Wedding Invite</div>
                      <div class="input_field">
                        <select
                          name={`data[${index}].wedding_invite`}
                          value={
                            addGuestFormik.values.data[index].wedding_invite
                          }
                          onChange={(e) => {
                            addGuestFormik.setFieldValue(
                              `data[${index}].wedding_invite`,
                              e.target.value
                            );
                          }}
                          class="input"
                        >
                          <option value="Sent">Sent</option>
                          <option value="Pending">Pending</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div className="input-box">
                    <div class="input-box">
                      <div class="input-label">Reception Invite</div>
                      <div class="input_field">
                        <select
                          name={`data[${index}].reception_invite`}
                          value={
                            addGuestFormik.values.data[index].reception_invite
                          }
                          onChange={(e) => {
                            addGuestFormik.setFieldValue(
                              `data[${index}].reception_invite`,
                              e.target.value
                            );
                          }}
                          class="input"
                        >
                          <option value="Sent">Sent</option>
                          <option value="Pending">Pending</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="input-box">
                    <div class="input-label">Address</div>
                    <div class="input_field">
                      <input
                        name={`data[${index}].Address`}
                        type="text"
                        class="input"
                        placeholder="Enter guest address"
                        value={addGuestFormik.values.data[index].Address}
                        onChange={handleTextInput}
                        onBlur={addGuestFormik.handleBlur}
                      />
                    </div>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div className="input-box">
                    <div class="input-box">
                      <div class="input-label">Notes</div>
                      <div class="input_field">
                        <textarea
                          type="text"
                          class="input"
                          placeholder="Enter notes"
                          name={`data[${index}].notes`}
                          value={addGuestFormik.values.data[index].notes}
                          onChange={(e) => {
                            addGuestFormik.setFieldValue(
                              `data[${index}]notes`,
                              e.target.value
                            );
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div className="input-box">
                    <div class="input-box">
                      <div class="input-label">Save The Date Email</div>
                      <div class="input_field">
                        <input
                          type="checkbox"
                          class="input"
                          placeholder="Enter notes"
                          name={`data[${index}].save_the_date_email`}
                          value={
                            addGuestFormik.values.data[index]
                              .save_the_date_email
                          }
                          onChange={(e) => {
                            addGuestFormik.setFieldValue(
                              `data[${index}]save_the_date_email`,
                              !addGuestFormik.values.data[index]
                                .save_the_date_email
                            );
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div className="input-box">
                    <div class="input-box">
                      <div class="input-label">Save The Date WhatsApp</div>
                      <div class="input_field">
                        <input
                          type="checkbox"
                          class="input"
                          name={`data[${index}].save_the_date_whatsapp`}
                          value={
                            addGuestFormik.values.data[index]
                              .save_the_date_whatsapp
                          }
                          onChange={(e) => {
                            addGuestFormik.setFieldValue(
                              `data[${index}]save_the_date_whatsapp`,
                              !addGuestFormik.values.data[index]
                                .save_the_date_whatsapp
                            );
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div className="input-box">
                    <div class="input-box">
                      <div class="input-label">Whatsapp Verified</div>
                      <div class="input_field">
                        <input
                          type="checkbox"
                          class="input"
                          placeholder="Enter notes"
                          name={`data[${index}].whatsapp_verified`}
                          value={
                            addGuestFormik.values.data[index].whatsapp_verified
                          }
                          onChange={(e) => {
                            addGuestFormik.setFieldValue(
                              `data[${index}]whatsapp_verified`,
                              !addGuestFormik.values.data[index]
                                .whatsapp_verified
                            );
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="delrow">
                  {addGuestFormik.values.data.length > 1 ? (
                    <div
                      className="deletEvent-box"
                      onClick={() => deleteGuests(index)}
                    >
                      <img className="eventRemoveIcon" src={deleteIcon} />
                      <span>Remove Above Guest</span>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            );
          })}
          <div className="btnggrp mt-4">
            <button
              class="btn-theme dotted-border"
              type="button"
              onClick={() => {
                addMoreGuests();
              }}
            >
              <img src={addBrown} />
              <span> Add More Guest</span>
            </button>
          </div>
        </>
      ) : (
        <></>
      )}

      <div class="d-flex frm-sbmit-grp mt-3">
        <button
          type="button"
          class="btn-theme outline"
          onClick={() => {
            if (isWedding) {
              changeStep(1);
            } else {
              changeStep(0);
            }
          }}
        >
          Previous
        </button>
        <button
          type="button"
          class="btn-theme bg-dark"
          onClick={() => {
            changeStep(2, []);
          }}
        >
          Skip & Submit
        </button>
        <button type="submit" class="btn-theme bg-brown color-white">
          Submit
        </button>
      </div>
      {showModal && (
        <MyModal
          label={""}
          titleclass={""}
          showHeader={false}
          show={showModal}
          handleClose={() => {
            setShowModal(false);
          }}
        >
          <div className="modelBody-content">
            <div className="del_ttl">
              The Event has been created successfully!!!
            </div>

            <div class="btn-box model_btns"></div>
          </div>
        </MyModal>
      )}
    </form>
  );
};

export default GuestAddForm;
