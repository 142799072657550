import React, { useEffect } from "react";
import whatsAppGreen from "../../assets/images/whats-app-green.png";
import workerImage from "../../assets/images/worker.png";
import "./LeftSideBar.css";

const LeftSideBar = ({ onSelectGuest, selectedEvent = {}, guests = [], error, loading }) => {

  useEffect(()=>{
    
  },[guests]);

  return (
    <div className="left-side-bar">
      <div className="left-side-bar-header">
        <div className="event-details">
          <img src={whatsAppGreen} alt="WhatsApp Icon" />
          <p>WhatsApp</p>
          <span>{`${selectedEvent.event_type || ''} / ${selectedEvent.name || ''}`}</span>
        </div>
        <div className="header-title">
          <img src={workerImage} alt="Profile" />
          <h1>Chats</h1>
        </div>
      </div>

      <h2>{selectedEvent.name || "Select an Event"}</h2>

      {loading ? (
        <p>Loading guests...</p>
      ) : error ? (
        <p className="error">{error}</p>
      ) : (
        <ul className="guest-list">
          {guests.length > 0 ? (
            guests.map((guest) => (
              <li
                key={guest.id}
                className="guest-item"
                onClick={() => onSelectGuest(guest.id)}
              >
                <img
                  src={guest.profile_picture || workerImage}
                  alt={`${guest.name}'s profile`}
                />
                <div>
                  <p>{guest.name}</p>
                  <small>Last message or status</small>
                </div>
              </li>
            ))
          ) : (
            <li>No guests available</li>
          )}
        </ul>
      )}
    </div>
  );
};

export default LeftSideBar;
