import { useFormik } from "formik";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { createEvent, getCompanyDetails } from "../../action/events";
import { useNavigate } from "react-router-dom";
import showNotification from "../../components/notification/notification";
import WeddingForm from "../../components/eventForms/weddingForm";
import PreWeddingForm from "../../components/eventForms/preWeddingForm";
import CelebrationForm from "../../components/eventForms/celebrationForm";

import squar from "../../assets/images/squar.svg";
import EventDetailForm from "../../components/eventForms/eventDetailForm";
import SubEventForm from "../../components/eventForms/subEventForm";
import GuestAddForm from "../../components/eventForms/guestAddForm";
import { addGuest } from "../../action/guests";
import { createSubEvent } from "../../action/subEvents";
import { uploadFile } from "../../action/upload";

const EventAdd = () => {
  const navigate = useNavigate();
  const [currentStatus, setCurrentStatus] = useState(0);
  const [isWedding, setIsWedding] = useState(false);

  const [eventUrlType, setEventUrlType] = useState("");
  const [eventDetail, setEventDetails] = useState({});
  const [subEventDetails, setSubEventDetails] = useState([]);
  const [guestDetails, setGuestDetails] = useState([]);
  const [selectedFile, setSelectedFile] = useState("");
  const [showUploadSheet, setShowUploadSheet] = useState(false);

  const finalApi = () => {
    addEvent(eventDetail, eventUrlType);
  };

  const addEvent = async (eventDetail, url) => {
    
    const res = await createEvent(eventDetail, url);
    if (res.status) {
      if (eventDetail.event_type != "Wedding") {
        let allGuestDetails = [...guestDetails];
        guestDetails.map((value, index1) => {
          allGuestDetails[index1].company = eventDetail.company;
          allGuestDetails[index1].event = res.data.id;
        });

        setTimeout(() => {
          if (selectedFile) {
            uploadGuestListFile(res.data.id, selectedFile);
          } else {
            createGuest(res.data.id, allGuestDetails);
          }
        }, 3000);
      } else {
        addSubEvents(res.data.id, subEventDetails);
      }
    } else {
      showNotification(res.message, "danger");
    }
  };

  const addSubEvents = async (eventId, value) => {
    let allGuestDetails = [...guestDetails];
    if (value.length) {
      const res = await createSubEvent(eventId, value);
      if (res.status) {
        res.data.map((mainSub) => {
          guestDetails.map((value, index1) => {
            allGuestDetails[index1].company = eventDetail.company;
            allGuestDetails[index1].event = eventId;

            value.subevent.map((sub, index2) => {
              if (mainSub.name == sub) {
                allGuestDetails[index1].subevent[index2] = mainSub.id;
              }
            });
          });
        });
      }
    }
    console.log("JDIF", allGuestDetails);
    setTimeout(() => {
      if (selectedFile) {
        uploadGuestListFile(eventId, selectedFile);
      } else {
        createGuest(eventId, allGuestDetails);
      }
    }, 3000);
  };

  const createGuest = async (eventId, payload) => {
    const res = await addGuest(payload, eventId);

    if (res.status) {
      showNotification("Event Created Successfully");
      navigate("/");
    } else {
      showNotification(res.message, "danger");
    }
  };

  const uploadGuestListFile = async (eventId, file) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("event_id", eventId);
    const res = await uploadFile(formData);
    console.log("🚀 ~ uploadGuestListFile ~ res:", res)
    if (res.status) {
      showNotification("Event Created Successfully");
      navigate("/");
    }
  };

  useEffect(() => {
    if (guestDetails.length || selectedFile) {
      finalApi();
    }
  }, [guestDetails, selectedFile]);

  return (
    <div className="content-body">
      <div class="dashboard_head">
        <div class="dashboard_head-left">
          <h1 class="heading1">Create an Event</h1>
        </div>
      </div>
      <div className="tab-bar-sec">
        <ul className="tab-bar">
          <li
            className={currentStatus === 0 ? "active" : ""}
            // onClick={() => {
            //   setCurrentStatus(0);
            // }}
          >
            Event Details
          </li>
          {isWedding ? (
            <li
              className={currentStatus === 1 ? "active" : ""}
              // onClick={() => {
              //   setCurrentStatus(1);
              // }}
            >
              Function Details
            </li>
          ) : (
            <></>
          )}

          <li
            className={currentStatus === 2 ? "active" : ""}
            // onClick={() => {
            //   setCurrentStatus(2);
            // }}
          >
            Guest Details
          </li>
        </ul>
      </div>
      <div class="form_data-content">
        <div style={{ display: currentStatus !== 0 ? "none" : "" }}>
          <EventDetailForm
            setIsWedding={setIsWedding}
            changeStep={(step, data, url) => {
              setCurrentStatus(step);
              if (data && url) {
                setEventDetails(data);
                setEventUrlType(url);
              }
            }}
          />
        </div>
        <div style={{ display: currentStatus !== 1 ? "none" : "" }}>
          <SubEventForm
            changeStep={(step, data, type) => {
              setCurrentStatus(step);
              if (type === 1) {
                setShowUploadSheet(true);
              } else {
                if (data) {
                  setSubEventDetails(data);
                }
              }
            }}
          />
        </div>
        <div style={{ display: currentStatus !== 2 ? "none" : "" }}>
          <GuestAddForm
            isWedding={isWedding}
            showUploadSheet={showUploadSheet}
            mySubEvents={subEventDetails}
            changeStep={(step, data, type) => {
              setCurrentStatus(step);
              if (data && type) {
                if (type === 1) {
                  setSelectedFile(data);
                } else {
                  setGuestDetails(data);
                }
              }
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default EventAdd;
