import React, { useEffect, useState } from "react";
import { fetchAllEvents } from "../../action/events";
import ActiveEventCard from "../../components/eventCards/activeEventCard";
import moment from "moment";

// images
import { useDispatch, useSelector } from "react-redux";
import Pagination from "../../components/pagination/paginationView";
import { changeHeaderState } from "../../reducer/slice/header";

const EventList = () => {
  const dispatch = useDispatch();
  const searchText = useSelector((state) => state.changeSearchText.value ?? "");
  const [type, setType] = useState(1);
  const [activeEvents, setActiveEvents] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);

  const getAllEvents = async () => {
    const res = await fetchAllEvents(type, pageNo, pageSize, searchText);
    if (res?.status) {
      setActiveEvents(res.data.objects);
      setTotalRecords(res.data.total_items);
    }
  };

  useEffect(() => {
    getAllEvents();
  }, [searchText, type, pageNo, pageSize]);

  useEffect(() => {
    dispatch(
      changeHeaderState({
        showDefault: true,
        headerTitle: "",
        navigationList: [],
      })
    );
  }, []);

  return (
    <div className="content-container">
      <div className="content-container-left">
        {activeEvents.length ? (
          <div className="section-row">
            <div className="heading-row">
              <div className="heading-left-group">
                <h2 className="item-heading1">All Events</h2>
                <select
                  className="custom-selectbx"
                  value={type}
                  onChange={(e) => {
                    setType(Number(e.target.value));
                  }}
                >
                  <option value={1}>All Events</option>
                  <option value={2}>Active Events</option>
                  <option value={3}>Past Events</option>
                </select>
              </div>
            </div>
            <div className="row g-3">
              {activeEvents.map((value) => {
                return <ActiveEventCard value={value} colClass="col-md-12" />;
              })}
            </div>
          </div>
        ) : (
          <></>
        )}
        <div className="page-footer">
          <Pagination
            currentPage={pageNo}
            totalCount={totalRecords}
            pageSize={pageSize}
            onPageChange={(page) => setPageNo(page)}
            changePageSize={(page) => {
              setPageSize(page);
              setPageNo(1);
            }}
          />
        </div>
      </div>
      <div className="content-container-right">
        {/* <div className="card-custom card4">
          <div className="card4-head">
            <h2 className="heading-with-badge">
              <div>
                Important Tasks <span className="theme-badge red up">6</span>
              </div>
            </h2>
            <button className="btn-theme circle plus">
              <img src={plusSmallIcon} alt="" />
            </button>
          </div>
          <div className="card4-body">
            <ul className="card4ul custom-scroll-bar">
              <li>
                <p className="text1">Book pending flight tickets</p>
                <p className="text2">Simran & Raj | Wedding</p>
                <p className="postdate">
                  <span className="cloicon">
                    <img src={clockIcon} alt="" />
                  </span>{" "}
                  2 hrs ago
                </p>
              </li>
              <li>
                <p className="text1">Book pending flight tickets</p>
                <p className="text2">Simran & Raj | Wedding</p>
                <p className="postdate">
                  <span class="cloicon">
                    <img src={clockIcon} alt="" />
                  </span>{" "}
                  2 hrs ago
                </p>
              </li>
              <li>
                <p class="text1">Book pending flight tickets</p>
                <p class="text2">Simran & Raj | Wedding</p>
                <p class="postdate">
                  <span class="cloicon">
                    <img src={clockIcon} alt="" />
                  </span>{" "}
                  2 hrs ago
                </p>
              </li>
              <li>
                <p class="text1">Book pending flight tickets</p>
                <p class="text2">Simran & Raj | Wedding</p>
                <p class="postdate">
                  <span class="cloicon">
                    <img src={clockIcon} alt="" />
                  </span>{" "}
                  2 hrs ago
                </p>
              </li>
            </ul>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default EventList;
