import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
  deleteRoomBooking,
  fetchAccommodationList,
  fetchHotelsListing,
  fetchHotelsSummary,
} from "../../action/hotels";
import editIcon from "../../assets/images/edit-brown.svg";
import eyeIcon from "../../assets/images/eye-brown.svg";
import trashIcon from "../../assets/images/trash-brown.svg";
import uploadIcon from "../../assets/images/upload.svg";
import printIcon from "../../assets/images/print.svg";
import addBrown from "../../assets/images/addhtl2.svg";
import hotel from "../../assets/images/htl.svg";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "../../components/pagination/paginationView";
import CustomSelect from "../../components/customSelect";
import CustomSearchBox from "../../components/customSearchBox";
import CustomSelectWithIcon from "../../components/CustomSelectWithIcon";
import HotelAdd from "./hotesAdd";
import { Offcanvas } from "react-bootstrap";
import AddRoomToGuest from "./addRoomToGuest";
import HotelDetails from "./hotelDetails";
import { changeHeaderState } from "../../reducer/slice/header";
import EditRoomToGuest from "./editRoomToGuest";
import DetailRoomToGuest from "./detailRoomToGuest";
import moment from "moment";
import MyModal from "../../components/model";
import showNotification from "../../components/notification/notification";
import { fetchEventDetails } from "../../action/events";
import CustomSelectWithIconGuest from "../../components/CustomSelectWithIconGuest";
import { Loader } from "../../components/loader";

const HotelList = () => {
  const dispatch = useDispatch();
  const { eventId } = useParams();
  const userDetails = useSelector((state) => state.userDetails.value);

  const [hotelSummary, setHotelSummary] = useState([]);
  const [hotelList, setHotelList] = useState([]);
  const [loading, setShowLoading] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState("");
  const [totalRecords, setTotalRecords] = useState(0);
  const [showAddHotelCanvas, setShowAddHotelCanvas] = useState(false);
  const [showAddGuestCanvas, setShowAddGuestCanvas] = useState(false);
  const [showEditGuestCanvas, setShowEditGuestCanvas] = useState(false);
  const [showDetailGuestCanvas, setShowDetailGuestCanvas] = useState(false);
  const [showHotelsCanvas, setShowHotelsCanvas] = useState(false);
  const [selectedGuest, setSelectedGuest] = useState("");
  const [selectedHotel, setSelectedHotel] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const getBookingList = async () => {
    setShowAddHotelCanvas(false);
    setShowAddGuestCanvas(false);
    setShowEditGuestCanvas(false);
    setShowDeleteModal(false);
    setShowLoading(true)
    const res = await fetchAccommodationList(eventId);
    if (res.status) {
      if (res.data[0]?.hotels?.length) {
        res.data[0]?.hotels.map(el => {
          el.roomBooking = el.roomBooking.map(l => ({
            hotel_name: el.name,
            'guestDetails.name': l.guestDetails.name,
            ...l
          }))
        })

        let h = [];
        for (let x of res.data[0]?.hotels) {
          h = [...h, ...x.roomBooking]
        }
        setHotelList(h);
        console.log(h, 'ss')
      }
      setShowLoading(false)

    }
  };

  const getHotelList = async () => {
    const res = await fetchHotelsSummary(eventId, search);
    if (res.status) {
      setHotelSummary(res.data);
    }
  };

  const removeRoomBooking = async (id) => {
    const res = await deleteRoomBooking(selectedHotel, id);
    if (res.status) {
      showNotification("Accommodation deleted successfully", "success");
      getBookingList();
    }
  };

  const getEventDetails = async () => {
    const res = await fetchEventDetails(eventId);
    if (res.status) {
      dispatch(
        changeHeaderState({
          showDefault: false,
          headerTitle: "Accommodation Management",
          navigationList: [
            { name: "Events", url: "/events" },
            {
              name: res.data.name,
              url: "",
            },
            {
              name: "Accommodation Management",
              url: "",
            },
          ],
        })
      );
    }
  };

  useEffect(() => {
    getEventDetails();
  }, []);

  useEffect(() => {
    getBookingList();
    getHotelList();
  }, [pageNo, pageSize, search]);

  const handleSort = (setData, sortType, keyName) => {
    if (sortType === 'asc') {
      setColumns((c) => {
        for (let x of c) {
          x.sortType = '';
          if (x.keyName === keyName) {
            x.sortType = 'dsc';
          }
        }
        return [...c]
      })

      setData((f) => {
        f.sort((a, b) => {
          if (!a[keyName]) {
            return 1;
          }
          if (!b[keyName]) {
            return -1;
          }
          if (a[keyName].toString().toLowerCase() < b[keyName].toString().toLowerCase()) {
            return 1;
          }
          if (a[keyName].toString().toLowerCase() > b[keyName].toString().toLowerCase()) {
            return -1;
          }
          return 0;
        })
        return [...f]
      })
    } else if (sortType === 'dsc') {
      setColumns((c) => {
        for (let x of c) {
          x.sortType = '';
          if (x.keyName === keyName) {
            x.sortType = 'asc';
          }
        }
        return [...c]
      })
      setData((f) => {
        f.sort((a, b) => {
          if (!a[keyName]) {
            return -1;
          }
          if (!b[keyName]) {
            return 1;
          }
          if (a[keyName].toString().toLowerCase() < b[keyName].toString().toLowerCase()) {
            return -1;
          }
          if (a[keyName].toString().toLowerCase() > b[keyName].toString().toLowerCase()) {
            return 1;
          }
          return 0;
        })
        return [...f]
      })
    } else {
      setColumns((c) => {
        for (let x of c) {
          x.sortType = '';
          if (x.keyName === keyName) {
            x.sortType = 'asc';
          }
        }
        return [...c]
      })
      setData((f) => {
        f.sort((a, b) => {
          if (!a[keyName]) {
            return -1;
          }
          if (!b[keyName]) {
            return 1;
          }
          if (a[keyName].toString().toLowerCase() < b[keyName].toString().toLowerCase()) {
            return -1;
          }
          if (a[keyName].toString().toLowerCase() > b[keyName].toString().toLowerCase()) {
            return 1;
          }
          return 0;
        })
        return [...f]
      })
    } 
  }


  const [columns, setColumns] = useState([
    {
      name: "Guest Name",
      keyName: "guestDetails.name",
      locked: true,
      show: true,
      sort: true,
      sortType: "", // asc or dsc,
      handleSort: (data, sortType) => handleSort(data, sortType, "guestDetails.name"),
      render: (v) => <div className="roundedcell">{v || "-"}</div>
    },
    {
      name: "Team",
      keyName: "team",
      locked: false,
      show: true,
      sort: true,
      sortType: "", // asc or dsc,
      handleSort: (data, sortType) => handleSort(data, sortType, "team"),
      render: (v) => v || "-"
    },
    {
      name: "Group",
      keyName: "group",
      locked: false,
      show: true,
      sort: true,
      sortType: "", // asc or dsc,
      handleSort: (data, sortType) => handleSort(data, sortType, "group"),
      render: (v) => v || "-"
    },
    {
      name: "Hotel Name",
      keyName: "hotel_name",
      locked: false,
      show: true,
      sort: true,
      sortType: "", // asc or dsc,
      handleSort: (data, sortType) => handleSort(data, sortType, "hotel_name"),
      render: (v) => v || "-"
    },
    {
      name: "Check In",
      keyName: "checkin_date",
      locked: false,
      show: true,
      sort: true,
      sortType: "", // asc or dsc,
      handleSort: (data, sortType) => handleSort(data, sortType, "checkin_date"),
      render: (v) => v ? moment(v).format("DD/MM/YYYY") : "-"
    },
    {
      name: "Check Out",
      keyName: "checkout_date",
      locked: false,
      show: true,
      sort: true,
      sortType: "", // asc or dsc,
      handleSort: (data, sortType) => handleSort(data, sortType, "checkout_date"),
      render: (v) => v ? moment(v).format("DD/MM/YYYY") : "-"
    },
    {
      name: "Share Count",
      keyName: "share_count",
      locked: false,
      show: true,
      sort: true,
      sortType: "", // asc or dsc,
      handleSort: (data, sortType) => handleSort(data, sortType, "share_count"),
      render: (v) => v || "-"
    },
    {
      name: "Room No.",
      keyName: "room_number",
      locked: false,
      show: true,
      sort: true,
      sortType: "", // asc or dsc,
      handleSort: (data, sortType) => handleSort(data, sortType, "room_number"),
      render: (v) => v || "-"
    },
    {
      name: "Room Type",
      keyName: "room_type",
      locked: false,
      show: true,
      sort: true,
      sortType: "", // asc or dsc,
      handleSort: (data, sortType) => handleSort(data, sortType, "room_type"),
      render: (v) => v || "-"
    },
    {
      name: "Billing",
      keyName: "billing",
      locked: false,
      show: true,
      sort: true,
      sortType: "", // asc or dsc,
      handleSort: (data, sortType) => handleSort(data, sortType, "billing"),
      render: (v) => v || "-"
    },
    {
      name: "Remarks",
      keyName: "remarks",
      locked: false,
      show: true,
      sort: true,
      sortType: "", // asc or dsc,
      handleSort: (data, sortType) => handleSort(data, sortType, "remarks"),
      render: (v) => v || "-"
    },
    {
      name: "Status",
      keyName: "status",
      locked: false,
      show: true,
      sort: true,
      sortType: "", // asc or dsc,
      handleSort: (data, sortType) => handleSort(data, sortType, "status"),
      render: (v) => v || "-"
    },

  ]);

  const lockedCol = columns.filter((el) => el.locked);
  const unlockedCol = columns.filter((el) => !el.locked);
  return (
    <div>
      <div class="dashboard_head">
        <div className="hotels_box-container">
          {hotelSummary.map((value) => {
            return (
              <div className="hotelDetails-box">
                <div className="hotelDetails-box-head">
                  <img src={hotel} alt="" />
                  {value.name}
                </div>
                <div className="hotelDetails-box-content">
                  <div className="hotelDetail">
                    <div className="hotelDetail-ttl">Booked</div>
                    <div className="hotelDetail-status">
                      {value.total_rooms}
                    </div>
                  </div>
                  <div className="hotelDetail">
                    <div className="hotelDetail-ttl">Allotted</div>
                    <div className="hotelDetail-status">
                      {value.room_booking_count}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}

          <button
            class="addHotel-btn dotted-border"
            onClick={setShowAddHotelCanvas}
          >
            <img src={addBrown} />
            <span>Add Hotel</span>
          </button>
        </div>

        <div class="searchbar-box">
          {/* <div class="searchbar-box-left">
            <div class="searchbar-box-left_content">
              <CustomSelect extraclass="w-100" />
              <button class="btn-theme bg-dark">Apply</button>
            </div>
          </div> */}
          <div class="searchbar-box-mid">
            <div class="searchbar-box-mid_content-left">
              <CustomSearchBox
                search={search}
                placeholder="Search"
                setSearch={setSearch}
              />
            </div>
            <div class="searchbar-box-mid_content-right">
              <CustomSelectWithIconGuest
                columns={columns}
                setColumns={setColumns}
              />
            </div>
          </div>
          <div class="searchbar-box-right">
            <div class="searchbar-box-right-content">
              {/* <button className="iconbtn bg-dark">
                <img src={uploadIcon} alt="" />
              </button> */}
              <button
                className="iconbtn"
                onClick={() => {
                  window.print();
                }}
              >
                <img src={printIcon} alt="" />
              </button>

              {userDetails?.is_companyAdmin && hotelSummary.length ? (
                <button
                  class="btn-theme dotted-border"
                  onClick={setShowAddGuestCanvas}
                >
                  <img src={addBrown} />
                  <span>Add Room To Guest</span>
                </button>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
      <div class="payout_data-content">
        <div class="custom-table-container custom-scroll-bar custom-scroll-bar2">

        {
            !loading && !hotelList?.length
              ?
              <div colSpan={20} className="text-center">No data found</div>
              :
              ""
          }
          {
            loading
              ?
              <Loader />
              :
              ""
          }

          { !loading && hotelList?.length ? <table class="custom-table">
            <thead>
              <tr class="tableHead">
                {lockedCol.map((l, i) => {
                  return (
                    <th
                      class="fixed-col"
                      key={`fcol-${i + 1}`}
                      onClick={() => {
                        if (l.sort) {
                          l.handleSort(setHotelList, l.sortType)
                        }
                      }}
                      hidden={!l.show}>
                      <div className="roundedcell">{l.name}&nbsp; {l.sort ? <i className={`fa fa-sort${l.sortType === 'asc' ? '-asc' : l.sortType === 'dsc' ? '-desc' : ''}`} ariaHidden="true"/> : ""}</div>
                    </th>
                  );
                })}

                {
                  unlockedCol.map((l, i) => {
                    return (
                      <th key={`ufcol-${i + 1}`}
                      onClick={() => {
                        if (l.sort) {
                          l.handleSort(setHotelList, l.sortType)
                        }
                      }}
                      hidden={!l.show}>
                        <div className="roundedcell">{l.name}&nbsp; {l.sort ? <i className={`fa fa-sort${l.sortType === 'asc' ? '-asc' : l.sortType === 'dsc' ? '-desc' : ''}`} ariaHidden="true"/> : ""}</div>
                      </th>
                    )
                  })
                }
                {/* <th className="fixed-col">
                  <div class="roundedcell">Guest Name</div>
                </th>
                <th>Team</th>
                <th>Group</th>
                <th>Hotel Name</th>
                <th>Check In</th>
                <th>Check Out</th>
                <th>Share Count</th>
                <th>Room No.</th>
                <th>Room Type</th>
                <th>Billing</th>
                <th>Remarks</th>
                <th>Status</th> */}
                <th className="fixed-col">
                  <div class="roundedcell"> Action </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {!loading ? (
                !hotelList?.length ? (
                  <tr>
                    <td colSpan={15} className="text-center">
                      No Hotels found
                    </td>
                  </tr>
                ) : (
                  hotelList?.map((rooms) => {
                    return (
                      <tr>
                        {lockedCol.map((l, i) => {
                          return (
                            <td
                              class="fixed-col"
                              key={`dfcol-${i + 1}`}
                              hidden={!l.show}>
                              {l.render(rooms[l.keyName])}
                            </td>
                          );
                        })}
                        {unlockedCol.map((l, i) => {
                          return (
                            <td
                              key={`fdcol-${i + 1}`}
                              hidden={!l.show}>
                              {l.render(rooms[l.keyName])}
                            </td>
                          );
                        })}
                        {/* <td className="fixed-col">
                          <div class="roundedcell">
                            {" "}
                            {rooms?.guestDetails?.name}
                          </div>
                        </td>
                        <td>{rooms.team}</td>
                        <td>{rooms.group}</td>
                        <td>{rooms.hotel_name}</td>
                        <td>
                          {moment(rooms.checkin_date).format("DD/MM/YYYY")}
                        </td>
                        <td>
                          {moment(rooms.checkout_date).format("DD/MM/YYYY")}
                        </td>
                        <td>{rooms.share_count}</td>
                        <td>{rooms.room_number}</td>
                        <td>{rooms.room_type}</td>
                        <td>{rooms.billing}</td>
                        <td>{rooms.remarks}</td>
                        <td>{rooms.status}</td> */}
                        <td className="fixed-col">
                          <div class="action-div roundedcell">
                            <span
                              onClick={() => {
                                setShowEditGuestCanvas(true);
                                setSelectedGuest(rooms.id);
                              }}
                            >
                              <img className="btn-icon" src={editIcon} />
                            </span>
                            <span
                              onClick={() => {
                                setShowDetailGuestCanvas(true);
                                setSelectedGuest(rooms.id);
                              }}
                            >
                              <img className="btn-icon" src={eyeIcon} />
                            </span>

                            <span
                              onClick={() => {
                                setShowDeleteModal(true);
                                setSelectedGuest(rooms.id);
                                setSelectedHotel(rooms.hotel);
                              }}
                            >
                              <img
                                className="btn-icon no-border"
                                src={trashIcon}
                              />
                            </span>
                          </div>
                        </td>
                      </tr>
                    );
                  })
                )
              ) : (
                <tr>
                  <td colSpan={6}>
                    <div className="loader-box">
                      <div className="loader"></div>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table> : ""}
        </div>
      </div>
      { !loading && hotelList?.length ? <div className="page-footer">
        <Pagination
          currentPage={pageNo}
          totalCount={totalRecords}
          pageSize={pageSize}
          onPageChange={(page) => setPageNo(page)}
          changePageSize={(page) => {
            setPageSize(page);
            setPageNo(1);
          }}
        />
      </div> : ""}

      <MyModal
        label={""}
        titleclass={""}
        showHeader={false}
        show={showDeleteModal}
        handleClose={() => {
          setShowDeleteModal(false);
        }}
      >
        <div className="modelBody-content">
          <div className="del_ttl">
            Are you sure you want to delete this room booking?
          </div>
          <div class="btn-box model_btns">
            <button
              class="btn-theme outline modenBtn"
              onClick={() => {
                setShowDeleteModal(false);
              }}
            >
              Cancel
            </button>
            <button
              class="btn-theme bg-brown color_white modenBtn"
              onClick={() => {
                removeRoomBooking(selectedGuest);
              }}
            >
              Delete
            </button>
          </div>
        </div>
      </MyModal>

      <Offcanvas
        show={showAddHotelCanvas}
        onHide={setShowAddHotelCanvas}
        placement="end"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Add Hotel</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <HotelAdd
            onCloseCanvas={() => {
              getBookingList();
              getHotelList();
            }}
          />
        </Offcanvas.Body>
      </Offcanvas>

      <Offcanvas
        show={showAddGuestCanvas}
        onHide={setShowAddGuestCanvas}
        placement="end"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Add Guest</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <AddRoomToGuest
            onCloseCanvas={() => {
              getBookingList();
            }}
          />
        </Offcanvas.Body>
      </Offcanvas>

      <Offcanvas
        show={showEditGuestCanvas}
        onHide={setShowEditGuestCanvas}
        placement="end"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Edit Guest</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <EditRoomToGuest
            id={selectedGuest}
            onCloseCanvas={() => {
              getBookingList();
            }}
          />
        </Offcanvas.Body>
      </Offcanvas>

      <Offcanvas
        show={showDetailGuestCanvas}
        onHide={setShowDetailGuestCanvas}
        placement="end"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Details Room</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <DetailRoomToGuest
            id={selectedGuest}
            onCloseCanvas={() => {
              getBookingList();
            }}
          />
        </Offcanvas.Body>
      </Offcanvas>

      <Offcanvas
        show={showHotelsCanvas}
        onHide={setShowHotelsCanvas}
        placement="end"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title> Hotel Details</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <HotelDetails
            onCloseCanvas={() => {
              getBookingList();
            }}
          />
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default HotelList;
