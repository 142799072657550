import { useFormik } from "formik";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { addGuest, fetchAllGuest } from "../../action/guests";
import showNotification from "../../components/notification/notification";
import { Link, useNavigate, useParams } from "react-router-dom";
import { createLogistic, fetchLogisticDetails } from "../../action/logistics";

const LogisticDetails = ({ id, closeCanvas }) => {
  const addLogisticFormik = useFormik({
    initialValues: {
      guest: "",
      travel_mode: "",
      team: "",
      group: "",
      address: "",
      card_number: "",
      pre_stay: "No",
      pre_pickup_from: "",
      pre_flight_no: "",
      pre_airline: "",
      pre_arrival_from: "",
      post_stay: "No",
      post_drop_to: "",
      post_flight_no: "",
      post_airline: "",
      exception_transfer: "No",
      remarks: "",
      car_type: "",
      car_number: "",
      driver_info: "",
      travel_own_arrangement: false,
    },
  });

  const getLogisticDetails = async () => {
    const res = await fetchLogisticDetails(id);
    if (res.status) {
      addLogisticFormik.setFieldValue("guest", res.data[0].guest_data.name);
      addLogisticFormik.setFieldValue("travel_mode", res.data[0].travel_mode);
      addLogisticFormik.setFieldValue("team", res.data[0].team);
      addLogisticFormik.setFieldValue("group", res.data[0].group);
      addLogisticFormik.setFieldValue("address", res.data[0].address);
      addLogisticFormik.setFieldValue("card_number", res.data[0].card_number);
      addLogisticFormik.setFieldValue("pre_stay", res.data[0].pre_stay);
      addLogisticFormik.setFieldValue(
        "pre_check_in_date",
        res.data[0].pre_check_in_date
      );
      addLogisticFormik.setFieldValue(
        "pre_arrival_date",
        res.data[0].pre_arrival_date
      );
      addLogisticFormik.setFieldValue(
        "pre_pickup_from",
        res.data[0].pre_pickup_from
      );
      addLogisticFormik.setFieldValue(
        "pre_flight_no",
        res.data[0].pre_flight_no
      );
      addLogisticFormik.setFieldValue("pre_airline", res.data[0].pre_airline);
      addLogisticFormik.setFieldValue(
        "pre_arrival_time",
        res.data[0].pre_arrival_time
      );
      addLogisticFormik.setFieldValue(
        "pre_arrival_from",
        res.data[0].pre_arrival_from
      );
      addLogisticFormik.setFieldValue("post_stay", res.data[0].post_stay);
      addLogisticFormik.setFieldValue(
        "post_check_out_date",
        res.data[0].post_check_out_date
      );
      addLogisticFormik.setFieldValue(
        "post_departure_date",
        res.data[0].post_departure_date
      );
      addLogisticFormik.setFieldValue("post_drop_to", res.data[0].post_drop_to);
      addLogisticFormik.setFieldValue(
        "pre_depature_time",
        res.data[0].pre_depature_time
      );
      addLogisticFormik.setFieldValue(
        "post_flight_no",
        res.data[0].post_flight_no
      );
      addLogisticFormik.setFieldValue("post_airline", res.data[0].post_airline);
      addLogisticFormik.setFieldValue(
        "exception_transfer",
        res.data[0].exception_transfer
      );
      addLogisticFormik.setFieldValue("remarks", res.data[0].remarks);
      addLogisticFormik.setFieldValue("car_type", res.data[0].car_type);
      addLogisticFormik.setFieldValue("car_number", res.data[0].car_number);
      addLogisticFormik.setFieldValue("driver_info", res.data[0].driver_info);
      addLogisticFormik.setFieldValue(
        "travel_own_arrangement",
        res.data[0].travel_own_arrangement
      );
    }
  };

  useEffect(() => {
    getLogisticDetails();
  }, []);

  return (
    <div className="addGuest-container custom-scroll-bar">
      <div class="payout_data-content">
        <form onSubmit={addLogisticFormik.handleSubmit}>
          <div className="row">
            <div class="input-box">
              <div class="input-label">Guest</div>
              <div class="input_field">
                <input
                  readOnly
                  disabled
                  type="text"
                  class="input"
                  name="guest"
                  value={addLogisticFormik.values.guest}
                  onChange={addLogisticFormik.handleChange}
                />
              </div>
            </div>

            <div class="input-box">
              <div class="input-label">Travel Mode</div>
              <div class="input_field">
                <input
                  readOnly
                  disabled
                  type="text"
                  class="input"
                  name="travel_mode"
                  value={addLogisticFormik.values.travel_mode}
                  onChange={addLogisticFormik.handleChange}
                />
              </div>
            </div>

            <div className="col-lg-6">
              <div class="input-box">
                <div class="input-label">Team</div>
                <div class="input_field">
                  <input
                    readOnly
                    disabled
                    type="text"
                    class="input"
                    name="team"
                    value={addLogisticFormik.values.team}
                    onChange={addLogisticFormik.handleChange}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div class="input-box">
                <div class="input-label">Group</div>
                <div class="input_field">
                  <input
                    readOnly
                    disabled
                    type="text"
                    class="input"
                    name="group"
                    value={addLogisticFormik.values.group}
                    onChange={addLogisticFormik.handleChange}
                  />
                </div>
              </div>
            </div>
            <div class="input-box">
              <div class="input-label">Address</div>
              <div class="input_field">
                <textarea
                  readOnly
                  disabled
                  type="text"
                  class="input"
                  name="address"
                  rows={3}
                  value={addLogisticFormik.values.address}
                  onChange={addLogisticFormik.handleChange}
                />
              </div>
            </div>

            <div class="input-box">
              <div class="input-label">ID Card Number</div>
              <div class="input_field">
                <input
                  readOnly
                  disabled
                  type="text"
                  class="input"
                  name="card_number"
                  value={addLogisticFormik.values.card_number}
                  onChange={addLogisticFormik.handleChange}
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div class="input-box">
                <div class="input-label">Pre Stay</div>
                <div class="input_field">
                  <input
                    readOnly
                    disabled
                    type="text"
                    class="input"
                    name="pre_stay"
                    value={addLogisticFormik.values.pre_stay}
                    onChange={addLogisticFormik.handleChange}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div class="input-box">
                <div class="input-label">Check In Date</div>
                <div class="input_field">
                  <input
                    readOnly
                    disabled
                    type="text"
                    class="input"
                    name="pre_check_in_date"
                    value={
                      addLogisticFormik.values.pre_check_in_date
                        ? moment(
                            addLogisticFormik.values.pre_check_in_date
                          ).format("DD/MM/YYYY")
                        : ""
                    }
                    onChange={addLogisticFormik.handleChange}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div class="input-box">
                <div class="input-label">Arrival Date</div>
                <div class="input_field">
                  <input
                    readOnly
                    disabled
                    type="text"
                    class="input"
                    name="pre_arrival_date"
                    value={
                      addLogisticFormik.values.pre_arrival_date
                        ? moment(
                            addLogisticFormik.values.pre_arrival_date
                          ).format("DD/MM/YYYY")
                        : ""
                    }
                    onChange={addLogisticFormik.handleChange}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div class="input-box">
                <div class="input-label">Pickup From</div>
                <div class="input_field">
                  <input
                    readOnly
                    disabled
                    type="text"
                    class="input"
                    name="pre_pickup_from"
                    value={addLogisticFormik.values.pre_pickup_from}
                    onChange={addLogisticFormik.handleChange}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div class="input-box">
                <div class="input-label">Flight No.</div>
                <div class="input_field">
                  <input
                    readOnly
                    disabled
                    type="text"
                    class="input"
                    name="pre_flight_no"
                    value={addLogisticFormik.values.pre_flight_no}
                    onChange={addLogisticFormik.handleChange}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div class="input-box">
                <div class="input-label">Airline</div>
                <div class="input_field">
                  <input
                    readOnly
                    disabled
                    type="text"
                    class="input"
                    name="pre_airline"
                    value={addLogisticFormik.values.pre_airline}
                    onChange={addLogisticFormik.handleChange}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div class="input-box">
                <div class="input-label">Arrival Time</div>
                <div class="input_field">
                  <input
                    readOnly
                    disabled
                    type="text"
                    class="input"
                    name="pre_arrival_time"
                    value={""}
                    onChange={addLogisticFormik.handleChange}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div class="input-box">
                <div class="input-label">Arrival From</div>
                <div class="input_field">
                  <input
                    readOnly
                    disabled
                    type="text"
                    class="input"
                    name="pre_arrival_from"
                    value={addLogisticFormik.values.pre_arrival_from}
                    onChange={addLogisticFormik.handleChange}
                  />
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div class="input-box">
                <div class="input-label">Post Stay</div>
                <div class="input_field">
                  <input
                    readOnly
                    disabled
                    type="text"
                    class="input"
                    name="post_stay"
                    value={addLogisticFormik.values.post_stay}
                    onChange={addLogisticFormik.handleChange}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div class="input-box">
                <div class="input-label">Check Out Date</div>
                <div class="input_field">
                  <input
                    readOnly
                    disabled
                    type="text"
                    class="input"
                    name="post_check_out_date"
                    value={
                      addLogisticFormik.values.post_check_out_date
                        ? moment(
                            addLogisticFormik.values.post_check_out_date
                          ).format("DD/MM/YYYY")
                        : ""
                    }
                    onChange={addLogisticFormik.handleChange}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div class="input-box">
                <div class="input-label">Drop To</div>
                <div class="input_field">
                  <input
                    readOnly
                    disabled
                    type="text"
                    class="input"
                    name="post_drop_to"
                    value={addLogisticFormik.values.post_drop_to}
                    onChange={addLogisticFormik.handleChange}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div class="input-box">
                <div class="input-label">Departure Date</div>
                <div class="input_field">
                  <input
                    readOnly
                    disabled
                    type="text"
                    class="input"
                    name="post_departure_date"
                    value={
                      addLogisticFormik.values.post_departure_date
                        ? moment(
                            addLogisticFormik.values.post_departure_date
                          ).format("DD/MM/YYYY")
                        : ""
                    }
                    onChange={addLogisticFormik.handleChange}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div class="input-box">
                <div class="input-label">Flight No.</div>
                <div class="input_field">
                  <input
                    readOnly
                    disabled
                    type="text"
                    class="input"
                    name="post_flight_no"
                    value={addLogisticFormik.values.post_flight_no}
                    onChange={addLogisticFormik.handleChange}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div class="input-box">
                <div class="input-label">Airline</div>
                <div class="input_field">
                  <input
                    readOnly
                    disabled
                    type="text"
                    class="input"
                    name="post_airline"
                    value={addLogisticFormik.values.post_airline}
                    onChange={addLogisticFormik.handleChange}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div class="input-box">
                <div class="input-label">Car Type</div>
                <div class="input_field">
                  <input
                    readOnly
                    disabled
                    type="text"
                    class="input"
                    name="car_type"
                    value={addLogisticFormik.values.car_type}
                    onChange={addLogisticFormik.handleChange}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div class="input-box">
                <div class="input-label">Car Type</div>
                <div class="input_field">
                  <input
                    readOnly
                    disabled
                    type="text"
                    class="input"
                    name="car_number"
                    value={addLogisticFormik.values.car_number}
                    onChange={addLogisticFormik.handleChange}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div class="input-box">
                <div class="input-label">Driver Info</div>
                <div class="input_field">
                  <input
                    readOnly
                    disabled
                    type="text"
                    class="input"
                    name="driver_info"
                    value={addLogisticFormik.values.driver_info}
                    onChange={addLogisticFormik.handleChange}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div class="input-box">
                <div class="input-label">Travel Own Arrangement</div>
                <div class="input_field">
                  <input
                    readOnly
                    disabled
                    type="text"
                    class="input"
                    name="travel_own_arrangement"
                    value={addLogisticFormik.values.travel_own_arrangement}
                    onChange={addLogisticFormik.handleChange}
                  />
                </div>
              </div>
            </div>

            <div class="input-box">
              <div class="input-label">Exception Transfer</div>
              <div class="input_field">
                <input
                  readOnly
                  disabled
                  type="text"
                  class="input"
                  name="exception_transfer"
                  value={addLogisticFormik.values.exception_transfer}
                  onChange={addLogisticFormik.handleChange}
                />
              </div>
            </div>

            <div class="input-box">
              <div class="input-label">Remarks</div>
              <div class="input_field">
                <input
                  readOnly
                  disabled
                  type="text"
                  class="input"
                  name="remarks"
                  value={addLogisticFormik.values.remarks}
                  onChange={addLogisticFormik.handleChange}
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default LogisticDetails;
