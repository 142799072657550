import { useFormik } from "formik";
import moment from "moment";
import React from "react";

import squar from "../../assets/images/squar.svg";
import deleteIcon from "../../assets/images/trash-brown.svg";
import addBrown from "../../assets/images/add-brown.svg";

const SubEventForm = ({ changeStep }) => {
  const addEventFormik = useFormik({
    initialValues: {
      data: [{ name: "", date: "", venue: "", description: "" }],
    },
    onSubmit: async (values) => {
      changeStep(2, values.data);
    },
  });

  const addMoreSubEvent = (type) => {
    addEventFormik.setFieldValue("data", [
      ...addEventFormik.values.data,
      { name: "", date: "", venue: "", description: "" },
    ]);
  };

  const deleteFamilyMembers = (ind) => {
    addEventFormik.setFieldValue(
      "data",
      addEventFormik.values.data.filter((value, index) => index != ind)
    );
  };

  const handleTextInput = (event) => {
    const newValue = event.target.value;
    const regex = /^[a-zA-Z\s]*$/;
    if (regex.test(newValue)) {
      addEventFormik.setFieldValue(event.target.name, newValue);
    }
  };

  return (
    <form onSubmit={addEventFormik.handleSubmit}>
      <h2 className="form-sec-heading">
        <img src={squar} alt="" /> <span>Function Details</span>
      </h2>
      {addEventFormik.values.data.map((value, index) => {
        return (
          <div className="row">
            <div className="col-md-4">
              <div class="input-box">
                <div class="input-label">Function Name *</div>
                <div class="input_field">
                  <input
                    required
                    name={`data[${index}].name`}
                    value={addEventFormik.values.data[index].name}
                    onChange={handleTextInput}
                    type="text"
                    class="input"
                    placeholder="Enter name"
                  />
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div class="input-box">
                <div class="input-label">Select Date *</div>
                <div class="input_field">
                  <input
                    required
                    type="date"
                    class="input"
                    onKeyDown={(e) => e.preventDefault()}
                    min={moment().format("YYYY-MM-DD")}
                    placeholder="Select Date"
                    name={`data[${index}].date`}
                    value={addEventFormik.values.data[index].date}
                    onChange={addEventFormik.handleChange}
                  />
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div class="input-box">
                <div class="input-label">Venue *</div>
                <div class="input_field">
                  <input
                    required
                    name={`data[${index}].venue`}
                    value={addEventFormik.values.data[index].venue}
                    onChange={handleTextInput}
                    type="text"
                    class="input"
                    placeholder="Enter venue"
                  />
                </div>
                {addEventFormik.touched.venue && addEventFormik.errors.venue ? (
                  <p className="error">{addEventFormik.errors.venue}</p>
                ) : null}
              </div>
            </div>
            <div className="col-md-4">
              <div class="input-box">
                <div class="input-label">Description</div>
                <div class="input_field">
                  <textarea
                    type="text"
                    class="input"
                    rows={8}
                    placeholder="Enter sub event description"
                    name={`data[${index}].description`}
                    value={addEventFormik.values.data[index].description}
                    onChange={(e) => {
                      addEventFormik.setFieldValue(
                        `data[${index}].description`,
                        e.target.value
                      );
                    }}
                  ></textarea>
                </div>
              </div>
            </div>

            <div className="delrow">
              {addEventFormik.values.data.length > 1 ? (
                <div
                  className="deletEvent-box"
                  onClick={() => deleteFamilyMembers(index)}
                >
                  <img className="eventRemoveIcon" src={deleteIcon} />
                  <span>Remove Above Event</span>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        );
      })}

      <div className="btnggrp mt-4">
        <button
          class="btn-theme dotted-border"
          type="button"
          onClick={() => {
            addMoreSubEvent();
          }}
        >
          <img src={addBrown} />
          <span> Add More Function</span>
        </button>
      </div>
      <div class="d-flex frm-sbmit-grp mt-3">
        <button
          type="button"
          class="btn-theme outline"
          onClick={() => {
            changeStep(0, []);
          }}
        >
          Previous
        </button>
        <button
          type="button"
          class="btn-theme bg-dark"
          onClick={() => {
            changeStep(2, [], 1);
          }}
        >
          Skip & Add From Guest Sheet
        </button>
        <button type="submit" class="btn-theme bg-brown color-white">
          Next
        </button>
      </div>
    </form>
  );
};

export default SubEventForm;
