import { requestWithToken } from "../helper/helper";

const fetchEventsList = async (id) => {
  try {
    const response = await requestWithToken("GET", `/api/company_dashboard/`);

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const createEventHotel = async (eventId, payload) => {
  try {
    const response = await requestWithToken(
      "POST",
      `/api/events/${eventId}/hotels/`,
      payload
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchHotelsListing = async (eventId, pageNo, pageSize) => {
  try {
    const response = await requestWithToken(
      "GET",
      `/api/events/${eventId}/hotels/?pageno=${pageNo}&pagesize=${pageSize}`
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchAccommodationList = async (eventId) => {
  try {
    const response = await requestWithToken(
      "GET",
      `/api/events/${eventId}/accommodation-status/`
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchRoomsList = async (hotelId, pageNo, pageSize) => {
  try {
    const response = await requestWithToken(
      "GET",
      `/api/hotels/${hotelId}/room-bookings/?pageno=${pageNo}&pagesize=${pageSize}`
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const addUserHotelRooms = async (hotelId, payload) => {
  try {
    const response = await requestWithToken(
      "POST",
      `/api/hotels/${hotelId}/room-bookings/`,
      payload
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const editUserHotelRooms = async (hotelId, roomId, payload) => {
  try {
    const response = await requestWithToken(
      "PUT",
      `/api/hotels/${hotelId}/room-bookings-update/${roomId}`,
      payload
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchUserHotelRoomsDetails = async (id) => {
  try {
    const response = await requestWithToken(
      "GET",
      `/api/hotels/${id}/room-bookings-details/`
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetRoomBookedStatus = async (hotelId) => {
  try {
    const response = await requestWithToken(
      "GET",
      `/api/hotels/${hotelId}/status/`
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchHotelsSummary = async (id, search) => {
  try {
    const response = await requestWithToken("GET", `/api/hotels_summary/${id}`);

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const deleteRoomBooking = async (hotel, id) => {
  try {
    const response = await requestWithToken(
      "DELETE",
      `/api/hotels/${hotel}/room-bookings-update/${id}`
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

export {
  fetchEventsList,
  createEventHotel,
  fetchHotelsListing,
  fetchRoomsList,
  addUserHotelRooms,
  fetRoomBookedStatus,
  fetchHotelsSummary,
  fetchAccommodationList,
  fetchUserHotelRoomsDetails,
  editUserHotelRooms,
  deleteRoomBooking,
};
