import { useFormik } from "formik";
import moment from "moment";
import React, { useEffect, useState } from "react";
import showNotification from "../../components/notification/notification";
import { useNavigate, useParams } from "react-router-dom";
import { addUserHotelRooms } from "../../action/hotels";
import { fetchAllGuest, fetchGuestList } from "../../action/guests";

const RoomBookingAdd = ({ closeCanvas }) => {
  const { hotelId, eventId } = useParams();
  const navigate = useNavigate();
  const [type, setType] = useState("");
  const [users, setUsers] = useState([]);
  const addGuestFormik = useFormik({
    initialValues: {
      hotel: hotelId,
      guest: "",
      room_number: "",
      room_type: "",
      checkin_date: "",
      checkout_date: false,
    },
    validate: (values) => {
      const errors = {};
      if (!values.guest) {
        errors.guest = "Guest is required";
      }
      if (!values.room_number) {
        errors.room_number = "Room number is required";
      }
      if (!values.room_type) {
        errors.room_type = "Room Type is required";
      }
      if (!values.checkin_date) {
        errors.checkin_date = "Checkin date is required";
      }
      if (!values.checkout_date) {
        errors.checkout_date = "Checkout date is required";
      }
      return errors;
    },
    onSubmit: async (values) => {
      const res = await addUserHotelRooms(hotelId, values.guest, values);
      closeCanvas();
      showNotification("Room booking confirm");
    },
  });

  const getEventUsersList = async () => {
    const res = await fetchAllGuest(eventId);
    if (res.status) {
      setUsers(res.data.objects.guestDetails);
    }
  };

  useEffect(() => {
    getEventUsersList();
  }, [eventId]);

  return (
    <div className="m-3">
      <form onSubmit={addGuestFormik.handleSubmit}>
        <div class="input-box">
          <div class="input-label">Users</div>
          <div class="input_field">
            <select
              name="guest"
              class="input"
              value={addGuestFormik.values.guest}
              onChange={addGuestFormik.handleChange}
            >
              <option value="">Select User</option>
              {users.map((value) => {
                return (
                  <option value={value.id}>
                    {value.name} -- {value.email}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
        {addGuestFormik.touched.guest && addGuestFormik.errors.guest ? (
          <p className="error">{addGuestFormik.errors.guest}</p>
        ) : null}

        <div className="input-box">
          <div class="input-box">
            <div class="input-label">Room Number</div>
            <div class="input_field">
              <input
                type="text"
                class="input"
                placeholder="Enter details"
                name="room_number"
                value={addGuestFormik.values.room_number}
                onChange={addGuestFormik.handleChange}
              />
            </div>
          </div>
        </div>
        {addGuestFormik.touched.room_number &&
        addGuestFormik.errors.room_number ? (
          <p className="error">{addGuestFormik.errors.room_number}</p>
        ) : null}

        <div className="input-box">
          <div class="input-box">
            <div class="input-label">Room Type</div>
            <div class="input_field">
              <input
                type="text"
                class="input"
                placeholder="Enter details"
                name="room_type"
                value={addGuestFormik.values.room_type}
                onChange={addGuestFormik.handleChange}
              />
            </div>
          </div>
        </div>
        {addGuestFormik.touched.room_type && addGuestFormik.errors.room_type ? (
          <p className="error">{addGuestFormik.errors.room_type}</p>
        ) : null}

        <div class="input-box">
          <div class="input-label">Checkin Date</div>
          <div class="input_field">
            <input
              type="date"
              class="input"
              min={moment().format("YYYY-MM-DD")}
              placeholder="Select Date"
              name="checkin_date"
              value={addGuestFormik.values.checkin_date}
              onChange={addGuestFormik.handleChange}
            />
          </div>
        </div>
        {addGuestFormik.touched.checkin_date &&
        addGuestFormik.errors.checkin_date ? (
          <p className="error">{addGuestFormik.errors.checkin_date}</p>
        ) : null}

        <div class="input-box">
          <div class="input-label">Checkout Date</div>
          <div class="input_field">
            <input
              type="date"
              class="input"
              min={moment().format("YYYY-MM-DD")}
              placeholder="Select Date"
              name="checkout_date"
              value={addGuestFormik.values.checkout_date}
              onChange={addGuestFormik.handleChange}
            />
          </div>
        </div>
        {addGuestFormik.touched.checkout_date &&
        addGuestFormik.errors.checkout_date ? (
          <p className="error">{addGuestFormik.errors.checkout_date}</p>
        ) : null}

        <div class="d-flex about-button-center mt-3">
          <button type="submit" class="submit-btn active">
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default RoomBookingAdd;
