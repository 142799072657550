// CustomDropdown.js
import React, { useState, useEffect, useRef } from "react";
import "../styles/CustomDropdown.css"; // Include your CSS styles
import selectIcon from "../assets/images/selectArrow.svg";
import selectControl from "../assets/images/selectControll.svg";
import reArrange from "../assets/images/re-arrange.svg";
const CustomSelectWithIcon = ({
  extraclass,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);


  return (
    <div
      className={`custom-select withIcon ${extraclass}`}
      ref={dropdownRef}>
      <div
        className="custom-select__control"
        onClick={() => setIsOpen(!isOpen)}>
        <div className="custom-select__arrow">
          <img src={selectControl} />
        </div>
        <div className="custom-select__selected">Manage Columns</div>
        <div className="custom-select__arrow last">
          {isOpen ? (
            <img src={selectIcon} />
          ) : (
            <img
              src={selectIcon}
              className="selectDownImg"
            />
          )}
        </div>
      </div>
      {isOpen && (
        <div className="custom-select__menu">
          <div className="custom-select__options">
            <div className="custom-select__option d-flex align-items-center">
              <span className="reArrange_icon-box">
                <img src={reArrange} />
              </span>
              <div className="custom_checkbox">
                <input
                  id="c1"
                  name="c1"
                  type="checkbox"
                />
                <label for="c1">Accommodation</label>
              </div>
            </div>
            <div className="custom-select__option d-flex align-items-center">
              <span className="reArrange_icon-box">
                <img src={reArrange} />
              </span>
              <div className="custom_checkbox">
                <input
                  id="c2"
                  name="c2"
                  type="checkbox"
                />
                <label for="c2"> Mark ‘Save the date’ as sent</label>
              </div>
            </div>
            <div className="custom-select__option d-flex align-items-center">
              <span className="reArrange_icon-box">
                <img src={reArrange} />
              </span>
              <div className="custom_checkbox">
                <input
                  id="c3"
                  name="c3"
                  type="checkbox"
                />
                <label for="c3">Mark ‘Wedding Invitation’ as sent</label>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomSelectWithIcon;