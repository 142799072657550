import React from "react";
import moment from "moment";
import defaultEventIcon from "../../assets/images/eventimg1.jpg";
import starIcon from "../../assets/images/star.svg";
import guestIcon from "../../assets/images/guest.svg";
import logisticIcon from "../../assets/images/logis.svg";
import acomoIcon from "../../assets/images/acomo.svg";
import communicationIcon from "../../assets/images/communication.svg";
import calendarIcon from "../../assets/images/calendor.svg";
import locationIcon from "../../assets/images/location.svg";
import { Link } from "react-router-dom";
import { changeHeaderState } from "../../reducer/slice/header";

const ActiveEventCard = ({ value, colClass = "col-md-9" }) => {
  return (
    <div className={colClass}>
      <div className="card-custom card2">
        <Link to={`/event-details/${value.id}`}>
          <div className="row">
            <div className="col-md-8">
              <div className="card2-leftbox">
                <div className="card2-infobx-left">
                  <img src={defaultEventIcon} className="feimg" alt="" />
                </div>
                <div className="card2-infobx-right">
                  <div className="headingbx">
                    <div className="heading4">{value.event_type}</div>
                    <h3 className="heading3">{value.name}</h3>
                  </div>
                  <ul className="li-with-icon">
                    <li>
                      <span className="feaicon">
                        <img src={calendarIcon} alt="" />
                      </span>
                      <p>
                        {moment(value.date).format("DD-MM MMM YYYY, hh:mm a")}
                      </p>
                    </li>
                    <li>
                      <span className="feaicon">
                        <img src={locationIcon} alt="" />
                      </span>
                      <p>{value.location}</p>
                    </li>
                    <li>
                      <span className="feaicon">
                        <img src={starIcon} alt="Location" />
                      </span>
                      <p>{value.venue}</p>
                    </li>
                    <li>
                      {value.event_type == "Wedding" ? (
                        <span className="feaicon">
                          <img src={starIcon} alt="" />
                        </span>
                      ) : (
                        <></>
                      )}

                      <p>
                        {value?.event_object?.sub_events
                          ?.slice(0, 2)
                          ?.map((subEvent, index) => {
                            return `${subEvent.name} ${index - 1 ? "," : ""} `;
                          })}
                        {value?.event_object?.sub_events?.length > 2 ? (
                          <span className="moreitem">
                            +{value?.event_object?.sub_events?.length - 2} More
                          </span>
                        ) : (
                          <></>
                        )}
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-4 d-flex align-items-center">
              <div className="featurebx">
                <ul>
                  <li>
                    <Link
                      to={`/guests/${value?.id}/${value?.company}`}
                      className="featureLink"
                    >
                      <span className="feaicon">
                        <img src={guestIcon} />
                      </span>
                      <span>Guest Management</span>
                    </Link>
                  </li>
                  <li>
                    <Link to={`/logistics/${value?.id}`}>
                      <span className="feaicon">
                        <img src={logisticIcon} />
                      </span>
                      <span>Logistics</span>
                    </Link>
                  </li>
                  <li>
                    <Link to={`/hotels/${value?.id}`}>
                      <span className="feaicon">
                        <img src={acomoIcon} />
                      </span>
                      <span>Accomodation</span>
                    </Link>
                  </li>
                  <li>
                    <Link to={`/communication/${value?.id}`}>
                      <span className="feaicon">
                        <img src={communicationIcon} />
                      </span>
                      <span>Communication</span>
                    </Link>
                  </li>
                  <li>
                    <Link to={`/conversation/${value?.id}`}>
                      <span className="feaicon">
                        <img src={communicationIcon} />
                      </span>
                      <span>Conversation</span>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default ActiveEventCard;
