import { useState, useRef } from "react";
import { Spinner } from "react-bootstrap";

import fileIcon from "../assets/images/file.svg";

function SelectFileDragDropFile({ onFileSelect, loader }) {
  const [dragActive, setDragActive] = useState(false);
  const inputRef = useRef(null);

  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      onFileSelect(e.dataTransfer.files[0]);
    }
  };

  const handleChange = function (e) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      onFileSelect(e.target.files[0]);
    }
  };

  const onButtonClick = () => {
    inputRef.current.click();
  };

  return (
    <div class="drag-file-area">
      <form
        id="form-file-upload"
        className="form-file-upload"
        onDragEnter={handleDrag}
        onSubmit={(e) => e.preventDefault()}
      >
        <input
          ref={inputRef}
          accept="image/png, image/gif, image/jpeg"
          type="file"
          id="input-file-upload"
          class="default-file-input"
          onChange={handleChange}
        />
        <label
          id="label-file-upload"
          htmlFor="input-file-upload"
          className={`label-file-upload ${dragActive ? "drag-active" : ""}`}
        >
          {loader ? (
            <Spinner />
          ) : (
            <div className="upbtninfo">
              <span class="material-icons-outlined upload-icon">
                <img src={fileIcon} alt="" />
              </span>
              <div className="upinfo">
                <h3 class="dynamic-message">
                  Drop files to begin upload or <span className="color-brown brsw">browse</span>
                </h3>
                <span className="text1">
                  File Formats: .csv, .xls, .xlsx (file size limit 5mb)
                </span>
              </div>
            </div>
          )}
        </label>
        {dragActive && (
          <div
            id="drag-file-element"
            onDragEnter={handleDrag}
            onDragLeave={handleDrag}
            onDragOver={handleDrag}
            onDrop={handleDrop}
          ></div>
        )}
      </form>
    </div>
  );
}
export default SelectFileDragDropFile;
