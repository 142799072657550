import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  showDefault: true,
  headerTitle: "",
  navigationList: [],
};

export const headerState = createSlice({
  name: "headerState",
  initialState,
  reducers: {
    changeHeaderState: (state, provider) => {
      state.value = provider.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { changeHeaderState } = headerState.actions;

export default headerState.reducer;
