import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import { createCheckoutSession, getSubscriptionPlans } from '../../action/subscription';
import coinIcon from "../../assets/images/coins.png";
import '../../styles/plans.css';

const Plans = () => {
    const [selectedPlan, setSelectedPlan] = useState(null);
    const [plans, setPlans] = useState(null);

    useEffect(() => {
        getPlans();
    }, []);

    async function getPlans() {
        try {
            let res = await getSubscriptionPlans();
            setPlans(res.data);
        } catch (error) {
            console.log("Error fetching subscription plans:", error);
        }
    }

    const handlePlanClick = (plan) => {
        setSelectedPlan(plan.name);
        Swal.fire({
            title: `You selected plan ${plan.name}`,
            text: "Confirm your selection",
            icon: "info",
            showCancelButton: true,
            confirmButtonText: "Subscribe Now",
            cancelButtonText: "Cancel",
        }).then(async (result) => {
            if (result.isConfirmed) {
                console.log(`Confirmed selection: Plan ${plan.name}`);
                let data = {
                    plan_id: plan.id
                };
                try {
                    let res = await createCheckoutSession(data);
                    if (res.status === 200) {
                        window.location.href = res.data.url;
                    } else {
                        Swal.fire({
                            title: 'Error',
                            text: 'Failed to create checkout session',
                            icon: 'error'
                        });
                        console.log(res);
                    }
                } catch (error) {
                    Swal.fire({
                        title: 'Error',
                        text: 'Failed to create checkout session',
                        icon: 'error'
                    });
                    console.log(error);
                }
            } else if (result.isDismissed) {
                console.log("Selection canceled");
            }
        });
    };


    return (
        <div className="cards-page">
            <h2 className="cards-title">Choose Your Plan</h2>
            <div className="cards-container">
                {plans && plans.map((plan) => (
                    <div
                        key={plan.id}
                        className={`card-card ${selectedPlan === plan.name ? 'selected' : ''}`}
                        onClick={() => handlePlanClick(plan)}
                    >
                        <img src={coinIcon} alt="Coin" className="card-icon" />
                        <h3 className="card-title">{plan.name}</h3>
                        <p className="card-description">{plan.description}</p>
                        <p className="card-type">{plan.type}</p>
                        <p className="card-price">${plan.price}</p>
                        <button className="subscribe-button">Subscribe</button>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Plans;
