import axios from "axios";

export const requestWithoutToken = async (method, url, body) => {
  let request;
  url = process.env.REACT_APP_API_ENDPOINT + url;

  switch (method) {
    case "GET":
      request = await axios.get(url);
      break;
    case "POST":
      request = await axios.post(url, body);
      break;
    default:
      break;
  }

  return request;
};
export const callStripeWebhook = async (sessionId, success) => {
  try {
    let url = process.env.REACT_APP_API_ENDPOINT + 'api/webhook/stripe/';
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ session_id: sessionId, success }),
    });
    return await response.json();
  } catch (error) {
    console.error('Error calling webhook:', error);
    throw error;
  }
};


export const requestWithToken = async (method, url, body, responseType) => {
  let request;
  url = process.env.REACT_APP_API_ENDPOINT + url;

  const headers = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "Access-Control-Allow-Origin": "",
    },
  };
  if (responseType) {
    headers[`responseType`] = responseType;
  }

  try {
    switch (method) {
      case "GET":
        request = await axios.get(url, headers);
        break;
      case "POST":
        request = await axios.post(url, body, headers);
        break;
      case "PUT":
        request = await axios.put(url, body, headers);
        break;
      case "DELETE":
        request = await axios.delete(url, {
          data: body,
          headers: headers.headers,
        });
        break;

      default:
        break;
    }

    return request;
  } catch (error) {
  console.log("🚀 ~ requestWithToken ~ error:", error)


    if (error.request.status == 401) {
      window.location.href = `${window.location.origin.toString()}/login`;
    }
    return error;
  }
};
