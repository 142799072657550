import React, { useState, useEffect, useRef } from "react";
import "../styles/CustomDropdown.css";

const CustomDropdown = ({
  options,
  placeholder,
  selectedOptions,
  onChange,
  message,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredOptions, setFilteredOptions] = useState(options);
  const [isSelectAllChecked, setIsSelectAllChecked] = useState(false);

  const dropdownRef = useRef(null);

  useEffect(() => {
    setFilteredOptions(
      options.filter((option) =>
        option.name?.toLowerCase().includes(searchTerm?.toLowerCase())
      )
    );
  }, [searchTerm, options]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleSelectOption = (option) => {
    let newSelectedOptions;
    if (selectedOptions.includes(option.name)) {
      newSelectedOptions = selectedOptions.filter(
        (value) => value !== option.name
      );
    } else {
      newSelectedOptions = [...selectedOptions, option.name];
    }
    onChange(newSelectedOptions);

    if (newSelectedOptions.length === filteredOptions.length) {
      setIsSelectAllChecked(true);
    } else {
      setIsSelectAllChecked(false);
    }
  };

  const handleSelectAll = () => {
    if (isSelectAllChecked) {
      onChange([]);
    } else {
      onChange(filteredOptions.map((option) => option.name));
    }
    setIsSelectAllChecked(!isSelectAllChecked);
  };

  return (
    <div className="custom-dropdown" ref={dropdownRef}>
      <div
        className="custom-dropdown__control"
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className="custom-dropdown__selected">
          {selectedOptions.length > 0
            ? `${selectedOptions.length} ${message}`
            : placeholder}
        </div>
        <div className="custom-dropdown__arrow">{isOpen ? "▲" : "▼"}</div>
      </div>

      {isOpen && (
        <div className="custom-dropdown__menu">
          <input
            type="text"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="custom-dropdown__search"
            placeholder="Search..."
          />
          <div className="custom-dropdown__option">
            <label>
              <input
                type="checkbox"
                checked={isSelectAllChecked}
                onChange={handleSelectAll}
              />
              <span>SELECT All</span>
            </label>
          </div>

          <div className="custom-dropdown__options">
            {filteredOptions.map((option) => (
              <label key={option.id} className="custom-dropdown__option">
                <input
                  type="checkbox"
                  checked={selectedOptions.includes(option.name)}
                  onChange={() => handleSelectOption(option)}
                />
                {option.name}
              </label>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomDropdown;
